import { RefObject, useEffect, useRef } from 'react';

export const useClickOutsideElement = <T extends HTMLElement>(outsideClickHandler: () => void): RefObject<T> => {
  const containerRef = useRef<T>(null);

  useEffect(() => {
    const clickListener = (e: MouseEvent): void => {
      const isOutsideClick = !containerRef.current?.contains(e.target as Node);

      if (isOutsideClick) {
        outsideClickHandler();
      }
    };

    window.addEventListener('click', clickListener);

    return () => window.removeEventListener('click', clickListener);
  }, []);

  return containerRef;
};
