import Image from 'next/legacy/image';
import Link from 'next/link';
import React from 'react';

import { getIsNewYearEve } from '@features/header';
import christmasLogoImage from '@public/playdex_christmas_logo.svg';
import baseLogoImage from '@public/playdex_logo.svg';

export const PlaydexLogoImage = (): JSX.Element => {
  const isNewYearEve = getIsNewYearEve();
  const logoSrc = isNewYearEve ? christmasLogoImage : baseLogoImage;

  return (
    <Link
      href="/"
      passHref={true}
      className="min-w-[96px] flex font-medium items-center md:justify-start justify-start"
    >
      <Image src={logoSrc} width={140} height={35} className="min-w-[140px] min-h-[35px]" alt="Playdex" />
    </Link>
  );
};
