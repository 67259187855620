import { memo } from 'react';
import { useFormContext } from 'react-hook-form';

import { JsonSchema } from '@features/common';

import { FilterButton } from '../FilterButton';
import { FilterInputText } from '../FilterInputText';

export const FilterNumberField = memo(
  ({ name }: { name: string; schema: JsonSchema; rootSchema: JsonSchema }): JSX.Element => {
    const { register } = useFormContext();

    const props = register(name, {
      valueAsNumber: true,
    });
    return (
      <div className="flex flex-col gap-5 items-stretch">
        <FilterInputText type="number" {...props} />

        <FilterButton>Apply</FilterButton>
      </div>
    );
  },
);
// eslint-disable-next-line functional/immutable-data
FilterNumberField.displayName = FilterNumberField.name;
