import { SliceLikeRestV2 } from '@prismicio/react';
import getConfig from 'next/config';
import { add } from 'ramda';

import { GameConfig, Erc721ContractMeta, formatNumberDecimals } from '@features/common';
import {
  AssetsGridSlice,
  AssetsGridSliceItem,
  EnvVisibilitySelect,
  GameDocument,
  GameReviewsSlice,
  MediaGallerySlice,
} from '@globalTypes/prismic/documents';
import { NftMeta } from '@gql/types';

import { GameNftAssetsQueryItem } from '../types';

const { publicRuntimeConfig } = getConfig();
const {
  FEATURE__GAMES_WITH_DYNAMIC_ASSETS,
  FEATURE__MARKETPLACE_V3_GAMES,
  FEATURE__MARKETPLACE_V3_GAMES_FOR_WHITELIST,
  APP_ENV,
} = publicRuntimeConfig;

const checkIsGameAvailable = (availability: EnvVisibilitySelect): boolean => {
  if (!availability) {
    return false;
  }

  return availability.split('-').includes(APP_ENV);
};

export const isMediaGallerySlice = (slice: SliceLikeRestV2): slice is MediaGallerySlice =>
  slice.slice_type === 'media_gallery';

export const isAssetGridSlice = (slice: SliceLikeRestV2): slice is AssetsGridSlice =>
  slice.slice_type === 'assets_grid';

export const isGameReviewsSlice = (slice: SliceLikeRestV2): slice is GameReviewsSlice =>
  slice.slice_type === 'game_review';

export const mapSliceAssetToNftMeta = ({ asset: { data } }: AssetsGridSliceItem): NftMeta => {
  const { name, image, traits } = data;

  return {
    name: name ?? '',
    image: image?.url ?? '',
    attributes:
      traits.map(({ name, value }) => ({
        trait_type: name ?? '',
        value: value ?? '',
      })) ?? [],
    description: '',
    external_url: '',
  };
};

export const isNftAssetType = (it: unknown): it is GameNftAssetsQueryItem => {
  return (it as { __typename?: string })?.__typename === 'NftAssetType';
};

export const checkIfShouldDisplayDynamicAssets = (gameUid?: string): boolean => {
  return Boolean((FEATURE__GAMES_WITH_DYNAMIC_ASSETS as string)?.split(',').includes(gameUid as string));
};

export const checkIsMarketplaceV3Game = (gameUid?: string): boolean => {
  return Boolean((FEATURE__MARKETPLACE_V3_GAMES as string)?.split(',').includes(gameUid as string));
};

export const checkIsMarketplaceV3GameForWhitelist = (gameUid?: string): boolean => {
  return Boolean((FEATURE__MARKETPLACE_V3_GAMES_FOR_WHITELIST as string)?.split(',').includes(gameUid as string));
};

export const formatPrismicGameToGameConfig = (game: GameDocument): GameConfig => {
  const { uid, data } = game;

  return {
    id: uid,
    applicationName: data.applicationName || uid,
    isAvailable: checkIsGameAvailable(data.dashboardVisibility),
    isVisible: checkIsGameAvailable(data.marketplaceVisibility),
    hasMarketplace: Boolean(data.hasMarketplace),
    title: data.name ?? uid,
    smartContracts: formatGameContracts(game),
    sidebarStatus: data.sidebarStatus || null,
    redirectLocation: data.redirectLocation,
    imgs: {
      selectGameImage: data.cover?.url ?? null,
      logo: data.logo?.url ?? null,
    },
  };
};

export const formatGameContracts = (game: GameDocument): Erc721ContractMeta[] => {
  if (!game.data.smartContracts) {
    return [];
  }

  const validContracts = game.data.smartContracts.filter(
    (contract: GameDocument['data']['smartContracts'][number]): contract is Erc721ContractMeta => {
      return !!contract && Object.values(contract).every(Boolean);
    },
  ) as Erc721ContractMeta[];

  return validContracts;
};

export const getPrismicSlice = <
  T extends {
    data: {
      slices: SliceLikeRestV2<string>[];
    };
  },
  SliceType extends SliceLikeRestV2<string>,
>(
  document: T,
  sliceName: string,
): SliceType | undefined => {
  return document.data.slices.find(({ slice_type }) => slice_type === sliceName) as SliceType | undefined;
};

type GameReviewsRatings = {
  ratingAvg: number;
  ratersCount: number;
  ratingTotal: number;
};

export const getGameRatings = (gameReviews: GameReviewsSlice['items']): GameReviewsRatings => {
  const ratingTotal = gameReviews.map(({ rating }) => Number(rating)).reduce(add, 0);
  const ratersCount = gameReviews?.length || 0;
  return {
    ratingAvg: Number(formatNumberDecimals(ratingTotal / ratersCount)),
    ratingTotal,
    ratersCount,
  };
};
