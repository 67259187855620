import cn from 'classnames';

import ElModal from '@components/elements/ElModal';
import { ModalCloseButton } from '@features/common';

import { TournamentRegistrationType } from '../constants';
import { ReactComponent as CaptainIcon } from '../icons/captain.svg';
import { ReactComponent as JoinTeamIcon } from '../icons/join-a-team.svg';

type Props = {
  onRegistrationTypeSelect: (type: TournamentRegistrationType) => void;
  closeModal: () => void;
};

export const TournamentRegistrationTypeSelectionModal = ({
  onRegistrationTypeSelect,
  closeModal,
}: Props): JSX.Element => {
  return (
    <ElModal
      onCloseModal={closeModal}
      className={cn(
        'relative w-[100vw] md:w-[400px] h-[100vh] md:h-max',
        '!justify-start p-6 pt-10',
        'text-labelPrimary',
      )}
      modalWrapperStyles="!p-0"
    >
      <ModalCloseButton className="absolute right-3 top-3" onClick={closeModal} />
      <header className="w-full">
        <h1 className="font-medium text-lg leading-6">Select your role for the tournament</h1>
      </header>

      <div
        className="flex gap-3 mt-4 p-3 bg-bgSecondary cursor-pointer rounded-lg hover:bg-bgTertiary"
        onClick={() => {
          onRegistrationTypeSelect(TournamentRegistrationType.Captain);
          closeModal();
        }}
      >
        <CaptainIcon className="shrink-0" width={24} height={24} />
        <div>
          <h2 className="text-sm text-labelPrimary">I am the captain and I want to create a team</h2>
          <p className="text-xs text-labelSecondary">
            You will recieve a team-code which you need send to your team members
          </p>
        </div>
      </div>

      <div
        className="flex gap-3 p-3 bg-bgSecondary cursor-pointer mt-4 rounded-lg hover:bg-bgTertiary"
        onClick={() => {
          onRegistrationTypeSelect(TournamentRegistrationType.Member);
          closeModal();
        }}
      >
        <JoinTeamIcon className="shrink-0" width={24} height={24} />
        <div>
          <h2 className="text-sm text-labelPrimary">I am a team member and want to join the team</h2>
          <p className="text-xs text-labelSecondary">You need to enter a code which you get from your team captain</p>
        </div>
      </div>
    </ElModal>
  );
};
