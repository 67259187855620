import classNames from 'classnames';
import { HTMLProps } from 'react';

export const ElInputDecoration = ({
  isFocused,
  isError,
  ...props
}: HTMLProps<HTMLDivElement> & {
  isFocused?: boolean;
  isError?: boolean;
}): JSX.Element => {
  return (
    <div
      {...props}
      className={classNames(
        'flex items-center leading-5 w-full',
        'shadow-fieldBorder',
        'rounded-lg',
        isFocused && 'shadow-fieldBorderFocused',
        isError && 'shadow-fieldBorderError',
        props.disabled ? 'bg-fieldBgDisabled text-labelPlaceholder' : 'bg-fieldBg text-labelPrimary',
        props.className,
      )}
    />
  );
};
