import { DateTime } from 'luxon';

const timeUnits: Intl.RelativeTimeFormatUnit[] = ['year', 'month', 'week', 'day', 'hour', 'minute', 'second'];

export const getLocaleDateTime = (dateTime = '', timeZone = 'local', locale = 'en-US'): DateTime =>
  DateTime.fromISO(dateTime).setZone(timeZone).setLocale(locale);

export const getShortMonthName = (monthNumber: number): string =>
  ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][monthNumber];

export const timeAgo = (dateTime: DateTime): string => {
  const diff = dateTime.diffNow().shiftTo(...timeUnits);
  const unit = timeUnits.find(unit => diff.get(unit) !== 0) || 'second';

  const relativeFormatter = new Intl.RelativeTimeFormat('en', {
    numeric: 'auto',
  });

  return relativeFormatter.format(Math.trunc(diff.as(unit)), unit);
};
