import { getCurrencyTokenListByChainId } from '@entities/currency';
import { fetchBalance } from '@wagmi/core';
import { BigNumberish, ethers } from 'ethers';
import { useEffect } from 'react';
import { useAccount, useNetwork, useQuery } from 'wagmi';

import { Address, HexString } from '@globalTypes/contracts/metamask';

import { TokenBalance } from '../types';

export const useERC20TokenBalance = (): {
  erc20TokenBalanceList: TokenBalance[];
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
} => {
  const { address } = useAccount();
  const { chain } = useNetwork();
  const fetchTokenBalanceValue = async (address: Address, token: HexString): Promise<string> => {
    const data = await fetchBalance({
      address,
      token,
      formatUnits: 'gwei',
    });
    return ethers.utils.formatEther(data?.value as BigNumberish);
  };

  const {
    data: erc20TokenBalanceList = [],
    isLoading,
    isSuccess,
    isIdle,
    isError,
    refetch,
  } = useQuery<Array<TokenBalance>>(['erc20BalanceTokenList', address, chain], async () => {
    if (address && chain) {
      const erc20TokenList = getCurrencyTokenListByChainId(chain.id)?.filter(
        tokenMeta => tokenMeta.address !== ethers.constants.AddressZero,
      );

      return await Promise.all(
        erc20TokenList.map(async currencyToken => {
          const balanceValue = await fetchTokenBalanceValue(address, currencyToken.address as HexString);
          return {
            balanceValue,
            currencyToken,
          };
        }),
      );
    }
    return [];
  });

  useEffect(() => {
    if (isIdle) {
      refetch();
    }
  }, [chain, address]);

  return {
    erc20TokenBalanceList,
    isLoading,
    isSuccess,
    isError,
  };
};
