import cn from 'classnames';
import { insert } from 'ramda';
import { ChangeEvent, useMemo, useRef, useState } from 'react';

import TableConstructor from '@components/modules/TableConstructor/TableConstructor';
import { Input, Pagination, SegmentControl, compareAddresses } from '@features/common';
import { ReactComponent as SearchIcon } from '@public/icons/custom/search.svg';

import { CellValue } from './CellValue';
import { LastUpdateStatistic } from './LastUpdateStatistic';
import { LeaderboardStatistic } from './LeaderboardStatistic';
import {
  ColumnsConfig,
  FormattedRowData,
  Leaderboard,
  LeaderboardRewardClaimer,
  LeaderboardRewardStructure,
} from '../../../types';
import { parseErc20Amount } from '../../../utils';
import { useRowsMatchingSearchString, useGroupRows } from '../hooks';
import { ReactComponent as ParticipantsIcon } from '../icons/participants.svg';
import { ReactComponent as PointsIcon } from '../icons/points.svg';
import { ReactComponent as PrizeIcon } from '../icons/prize.svg';
import { ReactComponent as RankIcon } from '../icons/rank.svg';
import { ReactComponent as TargetIcon } from '../icons/target.svg';
import {
  formatNumberValue,
  formatRankingRows,
  getUserIndex,
  getUserPoints,
  getWalletColumnKey,
  sortKeysByOrderProperty,
} from '../utils';

const PAGE_SIZE = 10;
const REWARD_COLUMN_KEY = 'estimated_reward';

type Props = {
  leaderboard: Leaderboard;
  walletAddress?: string | null;
  rewardClaimers?: LeaderboardRewardClaimer[];
};

const addEstimatedRewardColumn = (
  columns: Leaderboard['ranking']['columns'],
  rewardClaimers?: LeaderboardRewardClaimer[],
): ColumnsConfig => {
  if (!rewardClaimers) {
    return columns;
  }

  return {
    ...columns,
    [REWARD_COLUMN_KEY]: {
      title: 'Estimated prize',
    },
  };
};

const formatReward = (
  rewardClaimer?: LeaderboardRewardClaimer,
  rewardStructure?: LeaderboardRewardStructure,
): Array<JSX.Element | string> | null => {
  if (!rewardClaimer || !rewardStructure) {
    return null;
  }

  return (
    rewardClaimer?.erc20Amounts.map((amount, index) => {
      const token = rewardStructure.erc20Tokens[index];

      return token ? (
        <div key={index}>
          {parseErc20Amount(amount, token.decimals)} <span className="text-labelSecondary text-sm">${token.name}</span>
        </div>
      ) : (
        <div key={index} />
      );
    }) ?? null
  );
};

const getEstimatedRewardCellValue = (
  row: FormattedRowData,
  walletColumnKey?: string,
  rewardClaimers?: LeaderboardRewardClaimer[],
  rewardStructure?: LeaderboardRewardStructure,
): Array<string | JSX.Element> | null => {
  if (!walletColumnKey || !rewardClaimers) {
    return ['—'];
  }

  const walletAddress = row[walletColumnKey] as string | undefined;

  if (!walletAddress) {
    return ['—'];
  }

  const rewardClaimer = rewardClaimers.find(claimer => compareAddresses(claimer.walletAddress, walletAddress));

  return formatReward(rewardClaimer, rewardStructure);
};

export const LeaderboardTable = ({ walletAddress, leaderboard, rewardClaimers }: Props): JSX.Element => {
  const currentUserRowRef = useRef<HTMLDivElement>(null);

  const { columns: columnsWithoutReward, rows, groups } = leaderboard.ranking;
  const columns = addEstimatedRewardColumn(columnsWithoutReward, rewardClaimers);
  const walletColumnKey = getWalletColumnKey(columns);

  const sortedColumnKeysWithoutReward = sortKeysByOrderProperty(columnsWithoutReward);
  const sortedColumnKeys = rewardClaimers
    ? insert(sortedColumnKeysWithoutReward.length - 1, REWARD_COLUMN_KEY, sortedColumnKeysWithoutReward)
    : sortedColumnKeysWithoutReward;
  const sortedGroupKeys = sortKeysByOrderProperty(groups);

  const [page, setPage] = useState(1);
  const [searchString, setSearchString] = useState('');

  const currentUserGroup = useMemo(() => {
    const index = getUserIndex({ walletAddress, walletColumnKey, rows });
    const group = index !== null ? rows[index].group : null;

    return group;
  }, [walletAddress, columns, rows]);

  const currentUserRawReward = rewardClaimers?.find(reward => compareAddresses(reward.walletAddress, walletAddress));
  const currentUserReward = formatReward(currentUserRawReward, leaderboard.rewardStructure);

  const [activeGroup, setActiveGroup] = useState(currentUserGroup ?? sortedGroupKeys[0]);
  const groupRows = useGroupRows(rows, activeGroup);
  const currentUserIndex = useMemo(
    () => getUserIndex({ walletAddress, walletColumnKey, rows: groupRows }),
    [walletAddress, columns, groupRows],
  );
  const currentUserRank = currentUserIndex !== null ? currentUserIndex + 1 : null;
  const currentUserPoints = getUserPoints({ index: currentUserIndex, columns, rows: groupRows });
  const filteredRows = useRowsMatchingSearchString(groupRows, searchString);
  const pagesCount = Math.ceil(filteredRows.length / PAGE_SIZE);
  const tableData = formatRankingRows(filteredRows.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE));

  const showCurrentUserPosition = (): void => {
    if (currentUserIndex === null) {
      return;
    }

    const pageIndex = Math.ceil(currentUserIndex / PAGE_SIZE);

    setPage(pageIndex);

    // page change doesn't cause instant rerender,
    // we need to schedule scroll to user row as a macrotask
    setTimeout(() => currentUserRowRef.current?.scrollIntoView());
  };

  const columnSchema = sortedColumnKeys.map((key, columnIndex) => {
    const columnConfig = columns[key];

    return {
      columnTitle: columnConfig.title,
      cellContent: (item: FormattedRowData, rowIndex: number) => {
        const absoluteRowIndex = (page - 1) * PAGE_SIZE + rowIndex;
        const value =
          key === REWARD_COLUMN_KEY
            ? getEstimatedRewardCellValue(item, walletColumnKey, rewardClaimers, leaderboard.rewardStructure)
            : item[key];
        const display = columnConfig?.display;
        const isCurrentUserRow = absoluteRowIndex === currentUserIndex;
        const innerRef = isCurrentUserRow && columnIndex === 0 ? currentUserRowRef : null;
        const className = 'scroll-my-4';

        if (!Array.isArray(value)) {
          return (
            <CellValue innerRef={innerRef} value={value as string | number} display={display} className={className} />
          );
        }

        return (
          <div className={cn('flex items-center gap-4', className)}>
            {value
              .map(
                (value: JSX.Element | string | number, i): React.ComponentProps<typeof CellValue> => ({
                  display: Array.isArray(display) ? display[i] : display,
                  value,
                  innerRef,
                }),
              )
              .map((props, key) => (
                <CellValue key={key} {...props} />
              ))}
          </div>
        );
      },
    };
  });

  return (
    <div>
      {groups && activeGroup ? (
        <div className="flex mt-5 gap-3 flex-col md:flex-row">
          <div>
            <SegmentControl
              selectedIndex={sortedGroupKeys.indexOf(activeGroup)}
              segments={sortedGroupKeys.map(group => ({
                label: groups[group].title,
                badge: currentUserGroup === group && (
                  <div className="py-1 px-2 text-link text-xs leading-4 rounded bg-link/10">You&apos;re here</div>
                ),
              }))}
              onChange={index => setActiveGroup(sortedGroupKeys[index])}
            />
          </div>
          <div className="w-0.5 my-1.5 h-auto ml-3 hidden md:block bg-bgStripe" />
          <div className="text-sm text-labelSecondary mt-1">{groups[activeGroup].description}</div>
        </div>
      ) : null}
      <div className="flex flex-col-reverse 2xl:justify-between 2xl:flex-row flex-wrap mt-6 gap-4">
        {(groupRows.length > 0 || currentUserRank) && (
          <div className="flex w-full 2xl:w-fit gap-6 overflow-auto">
            {groupRows.length > 0 && (
              <LeaderboardStatistic
                title="Participants"
                value={formatNumberValue(groupRows.length)}
                IconComponent={ParticipantsIcon}
              />
            )}
            {Boolean(currentUserRank) && (
              <LeaderboardStatistic
                title="Your rank"
                value={formatNumberValue(currentUserRank)}
                IconComponent={RankIcon}
                ExtraIconComponent={TargetIcon}
                onClick={showCurrentUserPosition}
              />
            )}
            {currentUserPoints != null && (
              <LeaderboardStatistic
                title="Your points"
                value={formatNumberValue(currentUserPoints)}
                IconComponent={PointsIcon}
              />
            )}
            {currentUserReward && (
              <LeaderboardStatistic title="Your estimated prize" value={currentUserReward} IconComponent={PrizeIcon} />
            )}
          </div>
        )}
        <div className="flex flex-col sm:flex-row gap-4 sm:gap-6 sm:items-center justify-between">
          {leaderboard.updatedAt && <LastUpdateStatistic leaderboardUpdatedAt={leaderboard.updatedAt} />}
          <Input
            placeholder="Rank, name, wallet address"
            className="w-[248px]"
            onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchString(e.target.value)}
            value={searchString}
            renderInputElement={defaultProps => (
              <div className="flex w-full">
                <SearchIcon width={20} className="shrink-0 ml-1.5 text-labelTertiary" />
                <input {...defaultProps} className={cn(defaultProps.className, 'flex-1 !pl-2')} />
              </div>
            )}
          />
        </div>
      </div>
      <div className="mt-4">
        <TableConstructor<FormattedRowData>
          headerCellClassName="!py-3 !px-2 last:text-right"
          cellClassName={(columnIndex, rowIndex) => {
            const absoluteRowIndex = (page - 1) * PAGE_SIZE + rowIndex;
            const isCurrentUserRow = absoluteRowIndex === currentUserIndex;

            return cn(
              'text-labelPrimary px-2 py-3 last:text-right',
              isCurrentUserRow && columnIndex === 0 && 'bg-gradient-to-r from-[#2A2C3C] to-transparent',
            );
          }}
          tableData={tableData}
          columnSchema={columnSchema}
        />

        {pagesCount > 1 && (
          <div className="flex mt-4 justify-center">
            <Pagination total={filteredRows.length} pageSize={PAGE_SIZE} onChange={setPage} page={page} />
          </div>
        )}
      </div>
    </div>
  );
};
