import { Crisp } from 'crisp-sdk-web';
import getConfig from 'next/config';

import { UserProfile } from '@services/userProfileService/types';
import { extractAddressBody } from '@utils/helpers';

const { publicRuntimeConfig } = getConfig();
const { APP_ENV } = publicRuntimeConfig;

export const crispInit = (key: string): void => {
  Crisp.configure(key);
};

export const hideCrispChat = () => {
  Crisp.chat.hide();
};

export const crispAddUser = (user: UserProfile): void => {
  // adding data to crisp
  if (user.email) Crisp.user.setEmail(user.email);
  if (user.name) Crisp.user.setNickname(user.name);
  if (user.diallingCode && user.phoneNumber) Crisp.user.setPhone(`${user.diallingCode}${user.phoneNumber}`);

  Crisp.session.setData({
    id: user.id,
    wallet: extractAddressBody(user.walletAddress ?? ''),
    appEnv: APP_ENV,
  });
};

export const crispReset = (): void => {
  Crisp.session.reset();
};

export const crispOpenChat = (): void => {
  Crisp.chat.open();
};

export const crispShowTextMessage = (message: string): void => {
  Crisp.message.showText(message);
};

// TODO: Since crisp api is quite strict with handling different data types, its might to caugh an unexpected errors
//  so if something went wrong with pushing events with data, its try to push event just with eventName
export const crispPushEvent = (eventName: string, data?: object): void => {
  try {
    Crisp.session.pushEvent(eventName, data);
  } catch (error) {
    console.error(error);
    try {
      Crisp.session.pushEvent(eventName);
    } catch (error) {
      console.log(error);
    }
  }
};
