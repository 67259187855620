import cn from 'classnames';
import { MdFilterAlt } from 'react-icons/md';

import { FieldIcon } from './FieldIcon';
import { FilterButton } from './FilterButton';
import { useNftFilterContext } from './NftFilterContext';

type Props = {
  className?: string;
  onClick: () => void;
};

export const FilterToggle = ({ className, onClick }: Props): JSX.Element => {
  const { applied } = useNftFilterContext();

  return (
    <FilterButton className={cn('flex flex-nowrap items-center gap-2.5 bg-earlGrey-700', className)} onClick={onClick}>
      <FieldIcon>
        <MdFilterAlt />
      </FieldIcon>
      <span className="whitespace-nowrap">Filter ({applied})</span>
    </FilterButton>
  );
};
