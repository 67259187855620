import {
  GetServerSideProps,
  GetServerSidePropsContext,
  GetStaticPaths,
  GetStaticProps,
  GetStaticPropsContext,
  PreviewData,
} from 'next';
import { ParsedUrlQuery } from 'querystring';
import { mergeDeepLeft } from 'ramda';

import { fetchTournamentsPreview } from '@features/tournaments';
import { createPlaydexClient } from '@gql/apollo-client';
import { FeatureGroup, featureChecker } from '@services/featureChecker';
import { InitialStoreState } from '@store/types';

import { fetchSidebarGames } from './fetchSidebarGames';
import { createClient } from '../../../prismicio';

export type CommonStaticProps = {
  initialZustandState: InitialStoreState;
};

export const getInitialZustandState = async (): Promise<InitialStoreState> => {
  const client = createClient();

  const sidebarGamesPromise = fetchSidebarGames({ client });
  const tournamentsPreviewPromise = fetchTournamentsPreview({
    prismicClient: client,
    playdexClient: createPlaydexClient(),
  });
  const [sidebarGames, tournamentsPreview] = await Promise.all([sidebarGamesPromise, tournamentsPreviewPromise]);

  return {
    gameSlice: { sidebarGames },
    tournamentSlice: { tournamentsPreview },
  };
};

type InjectCommonServerSideProps = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Props extends Record<string, any>,
  Q extends ParsedUrlQuery = ParsedUrlQuery,
  D extends PreviewData = PreviewData,
>(
  getServerSideProps: GetServerSideProps<Props, Q, D>,
) => (ctx: GetServerSidePropsContext<Q, D>) => ReturnType<GetServerSideProps<CommonStaticProps | Props, Q, D>>;

/**
 * Injects common props to getServerSideProps result
 */
export const injectCommonServerSideProps: InjectCommonServerSideProps = getSsrProps => {
  return async function (ctx) {
    const [initialZustandState, initialProps] = await Promise.all([getInitialZustandState(), getSsrProps(ctx)]);

    return mergeDeepLeft(initialProps, {
      props: { initialZustandState },
    });
  };
};

type InjectCommonStaticProps = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Props extends Record<string, any>,
  Q extends ParsedUrlQuery = ParsedUrlQuery,
  D extends PreviewData = PreviewData,
>(
  getServerSideProps: GetStaticProps<Props, Q, D>,
) => (ctx: GetStaticPropsContext<Q, D>) => ReturnType<GetStaticProps<CommonStaticProps | Props, Q, D>>;

/**
 * Injects common props to getStaticProps result
 * @returns
 */
export const injectCommonStaticProps: InjectCommonStaticProps = getSsrProps => {
  return async function (ctx) {
    const [initialZustandState, initialProps] = await Promise.all([getInitialZustandState(), getSsrProps(ctx)]);

    return mergeDeepLeft(initialProps, {
      props: { initialZustandState },
    });
  };
};

export const getStaticProps: GetStaticProps<CommonStaticProps> = async () => {
  const initialZustandState = await getInitialZustandState();

  return {
    props: { initialZustandState },
  };
};

export const getServerSideProps: GetServerSideProps<CommonStaticProps> = async () => {
  const initialZustandState = await getInitialZustandState();

  return {
    props: { initialZustandState },
  };
};

export const applyStaticGenerationRules = (fn: GetStaticPaths): GetStaticPaths => {
  if (!featureChecker.isAvailable(FeatureGroup.Build, 'STATIC_GENERATION')) {
    return () => ({
      paths: [],
      fallback: 'blocking',
    });
  }

  return fn;
};
