/* eslint-disable functional/immutable-data */

import { Draft } from 'immer';

import { Address } from '@globalTypes/contracts/metamask';
import { StateCreatorWithInitialState } from '@store/types';

export type InnerWalletSlice = {
  innerWalletSlice: {
    address: Address | null;
  };
  innerWalletSliceActions: {
    setAddress: (innerWallet: Address) => void;
  };
};

export const createInnerWalletSlice: StateCreatorWithInitialState<InnerWalletSlice> = (
  set,
  _get,
  _api,
  initialState,
) => {
  return {
    innerWalletSlice: {
      address: initialState?.innerWalletSlice?.address ?? null,
    },
    innerWalletSliceActions: {
      setAddress: (innerWallet: Address) => {
        set((state: Draft<InnerWalletSlice>) => {
          state.innerWalletSlice.address = innerWallet;
        });
      },
    },
  };
};
