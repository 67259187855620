import dynamic from 'next/dynamic';
import { ComponentType } from 'react';

function withSafeHydration<T>(Component: ComponentType<T>): ComponentType<T> {
  return dynamic(() => Promise.resolve(Component), {
    ssr: false,
  });
}

export default withSafeHydration;
