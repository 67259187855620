import { TournamentBracketType } from '@graphql/generated';

export const translateTournamentBracketType = (bracketType: TournamentBracketType): string => {
  switch (bracketType) {
    case TournamentBracketType.DoubleElimination:
      return 'Double Elimination';
    case TournamentBracketType.RoundRobin:
      return 'Round Robin';
    case TournamentBracketType.SingleElimination:
      return 'Single Elimination';
    default:
      return '';
  }
};
