import { Tab } from '@headlessui/react';
import classNames from 'classnames';
import cn from 'classnames';
import Link from 'next/link';
import React, { Fragment, PropsWithChildren, ReactNode } from 'react';

export type TabEntry = {
  label: ReactNode | string;
  content: JSX.Element | null;
  action?: (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>) => void | Promise<void>;
  disabled?: boolean;
  href?: string;
  badge?: string;
};

type ElTabsProps = {
  tabListClassName?: string;
  tabPanelClassName?: string;
  defaultIndex?: number;
  tabs: TabEntry[];
  onChange?: (newIndex: number) => void;
};

const ElTabs = ({ defaultIndex, tabs, onChange, tabListClassName, tabPanelClassName }: ElTabsProps): JSX.Element => {
  return (
    <Tab.Group defaultIndex={defaultIndex ?? tabs.findIndex(tab => !tab.disabled)} onChange={onChange}>
      <div className="border-solid border-bgStripe md:border-b">
        <Tab.List className={cn('flex overflow-x-auto mx-auto', 'gap-1 md:gap-0', tabListClassName)}>
          {tabs.map((tab, i) => {
            const className = cn(
              'px-[11px] py-[9px] md:px-6 md:py-2 font-sans cursor-pointer text-sm',
              'border-solid border-fieldBorder md:border-0 md:border-b-1 md:border-transparent',
              'outline-none whitespace-nowrap rounded-lg md:rounded-none transition-colors',
              'text-labelPrimary md:text-labelSecondary hover:text-labelPrimary md:hover:text-labelPrimary',
              tab.disabled && 'opacity-70 cursor-default md:hover:text-labelSecondary md:hover:text-labelSecondary',
            );

            if (tab.href) {
              return (
                <Tab key={i} as={Fragment}>
                  {() => (
                    <Link
                      href={tab.href as string}
                      target="_blank"
                      rel="noopener noreferer"
                      className={classNames('flex gap-2 items-center', className)}
                      onClick={tab.action}
                    >
                      {tab.label}

                      {tab.badge ? <TabBadge>{tab.badge}</TabBadge> : null}
                    </Link>
                  )}
                </Tab>
              );
            }

            return (
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore because Tab actually has `disabled` prop and it works as expected
              <Tab key={i} as={Fragment} disabled={tab.disabled}>
                {({ selected }) => (
                  <button
                    onClick={tab.action}
                    className={cn(
                      'flex gap-2 items-center',
                      className,
                      selected && 'bg-controlSecondary md:bg-transparent md:border-white md:text-labelPrimary',
                    )}
                  >
                    {tab.label}

                    {tab.badge ? <TabBadge>{tab.badge}</TabBadge> : null}
                  </button>
                )}
              </Tab>
            );
          })}
        </Tab.List>
      </div>

      <Tab.Panels className={cn('mx-auto', tabPanelClassName)}>
        {tabs
          .filter(tab => !!tab.content)
          .map((tab, i) => (
            <Tab.Panel key={i}>{tab.content}</Tab.Panel>
          ))}
      </Tab.Panels>
    </Tab.Group>
  );
};

const TabBadge = ({ children }: PropsWithChildren): JSX.Element => {
  return (
    <span
      className={classNames(
        'w-4 h-4 text-xs flex items-center justify-center',
        'bg-bgSecondary border-1 border-bgStripe rounded-lg',
      )}
    >
      {children}
    </span>
  );
};

export default ElTabs;
