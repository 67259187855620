import { ApolloClient } from '@apollo/client';

import { createQueryFactory } from '@features/common';
import {
  GetMarketplaceOffersDocument,
  GetMarketplaceOffersQuery,
  GetMarketplaceOffersQueryVariables,
} from '@graphql/generated';

export type OfferRepository = {
  getMarketplaceOffers: (variables: GetMarketplaceOffersQueryVariables) => Promise<GetMarketplaceOffersQuery['offers']>;
};

export const createOfferRepository = <T>({ playdexClient }: { playdexClient: ApolloClient<T> }): OfferRepository => {
  const queryFromDocument = createQueryFactory(playdexClient);

  return {
    getMarketplaceOffers: queryFromDocument<GetMarketplaceOffersQuery, GetMarketplaceOffersQueryVariables>(
      GetMarketplaceOffersDocument,
      ({ offers }) => offers,
    ),
  };
};
