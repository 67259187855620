/* eslint-disable max-len */
import cn from 'classnames';
import { MouseEventHandler } from 'react';

type Props = {
  title: string;
  value: React.ReactNode;
  IconComponent?: ({ className }: { className: string }) => JSX.Element;
  ExtraIconComponent?: ({ className }: { className: string }) => JSX.Element;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

export const LeaderboardStatistic = ({
  title,
  value,
  IconComponent,
  ExtraIconComponent,
  onClick,
}: Props): JSX.Element => {
  return (
    <div className={cn('flex gap-2 shrink-0', onClick && 'cursor-pointer')} onClick={onClick}>
      {IconComponent && (
        <div className="flex justify-center items-center w-12 h-12 rounded-full bg-bgSecondary border border-solid border-buttonSecondaryBorder">
          <IconComponent className="w-6 h-6 text-iconBlue" />
        </div>
      )}
      <div className="flex flex-col justify-between">
        <div className="text-xs text-labelSecondary">{title}</div>
        <div className="text-xl text-labelPrimary flex items-center gap-1">
          {value}
          {ExtraIconComponent && (
            <ExtraIconComponent className="w-6 h-6 text-labelTertiary rounded-[6px] hover:bg-dropdownHoveredItem" />
          )}
        </div>
      </div>
    </div>
  );
};
