import { DateTime, Duration } from 'luxon';

import { getLocaleDateTime } from '@utils/dateTime';
import { getTimerFormat } from '@utils/getTimerFormat';

export type Countdown = {
  countdown: number;
  countdownString: string;
  countdownObject: Duration | null;
};

export type CountdownFormatConfig = {
  withSeconds?: boolean;
  short?: boolean;
};

export const getCountdown = (dateIsoString?: string, formatConfig?: CountdownFormatConfig): Countdown => {
  if (!dateIsoString) {
    return {
      countdown: 0,
      countdownString: '',
      countdownObject: null,
    };
  }

  const dateTime = getLocaleDateTime(dateIsoString);
  const countdownObject = dateTime.diff(DateTime.now(), ['days', 'hours', 'minutes', 'seconds']);
  const countdown = countdownObject.toMillis();

  if (countdown < 0) {
    return {
      countdown: 0,
      countdownString: '',
      countdownObject: Duration.fromMillis(0),
    };
  }

  return {
    countdownObject,
    countdown,
    countdownString: countdownObject.toFormat(getTimerFormat(countdownObject, formatConfig ?? {})),
  };
};
