import { ApolloClient, DocumentNode, useApolloClient } from '@apollo/client';
import { RTNode } from '@prismicio/types';
import assert from 'assert';
import { useMemo } from 'react';

import { createEntityLocalStorage } from './createEntityLocalStorage';
import { JsonSchema, JsonSchemaPropertiesValues, RefSchema } from '../types';

export const hookWithApolloService =
  <T>(
    // eslint-disable-next-line @typescript-eslint/ban-types
    factoryFn: (props: { playdexClient: ApolloClient<object> }) => T,
  ) =>
  (): T => {
    const playdexClient = useApolloClient();

    return useMemo(
      () =>
        factoryFn({
          playdexClient,
        }),
      [],
    );
  };

type Mapper<T, R> = (data: T) => R;

export const createQueryFactory =
  <CacheShape>(client: ApolloClient<CacheShape>) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  <Q, QV, QR = any, DN extends DocumentNode = DocumentNode>(document: DN, mapperFn: Mapper<Q, QR>) =>
  (variables: QV): Promise<QR> =>
    client
      .query<Q, QV>({
        query: document,
        fetchPolicy: 'no-cache',
        variables,
      })
      .then(({ data }) => {
        assert(data);
        return mapperFn(data);
      });

export const createMutationFactory =
  <CacheShape>(client: ApolloClient<CacheShape>) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  <M, MV, MR = any, DN extends DocumentNode = DocumentNode>(document: DN, mapperFn: Mapper<M, MR>) =>
  (variables: MV): Promise<MR> =>
    client
      .mutate<M, MV>({
        mutation: document,
        variables,
      })
      .then(({ data }) => {
        assert(data);
        return mapperFn(data);
      });

const currentUrlLocalStorage = createEntityLocalStorage('URL_BEFORE_OAUTH_REDIRECT_STORAGE_KEY');

export const persistCurrentUrlBeforeOauthRedirect = (): void =>
  currentUrlLocalStorage.set(window.location.pathname + window.location.search);

export const getPersistedLocationBeforeOauthRedirect = (): string | null => currentUrlLocalStorage.get();

export const removePersistedLocationBeforeOauthRedirect = (): void => currentUrlLocalStorage.remove();

/** @todo: if we use 'x-name' format as custom schema keys we won't need this function */
export const isSchemaObject = (value: JsonSchemaPropertiesValues): value is JsonSchema => {
  return !('$ref' in value);
};

export function isReferenceObject(value: JsonSchemaPropertiesValues): value is RefSchema {
  return '$ref' in value;
}

export const checkIsPrismicRichTextBlank = (
  field: RTNode | RTNode[] | undefined,
): boolean => {
  if (!field) {
    return true;
  }

  const checkIsNodeBlank = (node: RTNode): boolean => node.type === 'paragraph' && !node.text.trim();

  if (Array.isArray(field)) {
    return field.every(checkIsNodeBlank);
  }

  return checkIsNodeBlank(field);
};
