import cn from 'classnames';
import React from 'react';

import ElSectionTitle from '@components/elements/ElSectionTitle';
import { GuardWrapper } from '@components/elements/GuardWrapper';
import { Pagination } from '@features/common';
import { NftAssetsFilterInput, SmartContractName } from '@graphql/generated';
import { useBoolean } from '@hooks/useBoolean';
import { gameSliceSelector } from '@store/slices';
import { useStore } from '@store/zustand';
import { Suspended } from '@utils/suspend';

import { NftAssetItem, NftAssetList } from '../../types';
import { FilterToggle, NftFilter, NftFilterContextProvider } from '../NftFilter';

type Props = {
  resource: Suspended<NftAssetList>;
  pageSize: number;
  onPageChange: (limit: number, offset: number, filter?: NftAssetsFilterInput) => void;
  children: (item: NftAssetItem, i: number) => JSX.Element;
  page: number;
};

const Items = ({
  children,
  className,
  isNarrowed: isNarrow,
}: React.PropsWithChildren<{
  className?: string;
  isNarrowed: boolean;
}>): JSX.Element => {
  return (
    <div
      className={cn(
        'grid gap-4',
        isNarrow
          ? 'md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2'
          : 'sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3',
        className,
      )}
    >
      {children}
    </div>
  );
};

export const GameAssetsGrid = ({ resource, pageSize, onPageChange, children, page }: Props): JSX.Element => {
  const { items, total } = resource.read();

  const { currentGameSettings, currentGame } = useStore(gameSliceSelector);

  /** @todo consider all smartContracts */
  const smartContract = currentGame?.smartContracts[0];
  const smartContractSettings = smartContract && currentGameSettings[smartContract.name as SmartContractName];

  const [isFilterVisible, , hideFilter, toggleFilter] = useBoolean(smartContractSettings);

  return (
    <GuardWrapper
      patch={{ settings: smartContractSettings }}
      wrapper={NftFilterContextProvider}
      onSubmit={(filter: NftAssetsFilterInput) => onPageChange(pageSize, 0, filter)}
    >
      <section>
        <div className="flex mb-4 items-center">
          <ElSectionTitle>Available for waitlisted players</ElSectionTitle>

          {smartContractSettings && <FilterToggle className="ml-auto mb-4" onClick={toggleFilter} />}
        </div>
        <div className={cn('flex gap-4 mb-4 flex-col-reverse', 'lg:flex-row lg:items-start')}>
          {isFilterVisible && smartContractSettings && (
            <NftFilter
              className="order-1 lg:basis-96"
              onFilter={filter => onPageChange(pageSize, 0, filter)}
              onClose={hideFilter}
            />
          )}

          <Items className="flex-1" isNarrowed={isFilterVisible}>
            {items.map(children)}
          </Items>
        </div>
        <Pagination
          total={total}
          pageSize={pageSize}
          onChange={(page, pageSize) => onPageChange(pageSize, (page - 1) * pageSize)}
          page={page}
        />
      </section>
    </GuardWrapper>
  );
};
