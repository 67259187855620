import { ApolloClient } from '@apollo/client';
import assert from 'assert';

import {
  GetAuthLinkDocument,
  GetAuthLinkMutation,
  GetAuthLinkMutationVariables,
  GetUserProfileDocument,
  GetUserProfileQuery,
  LinkPhoneNumberDocument,
  LinkPhoneNumberMutation,
  LinkPhoneNumberMutationVariables,
  LinkSocialConnectionDocument,
  LinkSocialConnectionMutation,
  LinkSocialConnectionMutationVariables,
  UpdateUserProfileDocument,
  UpdateUserProfileMutation,
  UpdateUserProfileMutationVariables,
  UserLinkSocialConnectionInput,
  UserSocialLinkGetAuthLinkInput,
} from '@graphql/generated';
import {
  LinkPhone,
  CreateUserProfileService,
  GetSocialConnectionAuthLink,
  GetUserProfile,
  LinkSocialConnection,
  UpdateUserProfile,
} from '@services/userProfileService/types';
import { apolloErrorHandler, ExceptionResultType, isExceptionResultType } from '@utils/error';

const handleError = (error: unknown): void => {
  apolloErrorHandler(error);
};
const handleException = <T>(result: T): Exclude<T, ExceptionResultType> => {
  if (isExceptionResultType(result)) {
    // eslint-disable-next-line functional/no-throw-statement
    throw result;
  }
  return result as Exclude<T, ExceptionResultType>;
};

const createUserProfileService: CreateUserProfileService = ({
  playdexClient,
}: {
  playdexClient: ApolloClient<unknown>;
}) => {
  const getUserProfile: GetUserProfile = async () => {
    return playdexClient
      .query<GetUserProfileQuery>({
        query: GetUserProfileDocument,
        fetchPolicy: 'no-cache',
      })
      .then(({ data: { me } }) => me)
      .then(handleException)
      .catch(handleError);
  };

  const linkPhoneNumber: LinkPhone = async ({ token }) => {
    return await playdexClient
      .mutate<LinkPhoneNumberMutation, LinkPhoneNumberMutationVariables>({
        mutation: LinkPhoneNumberDocument,
        variables: {
          userLinkPhoneNumberInput: {
            token,
          },
        },
      })
      .then(({ data }) => {
        assert(data);
        return data.linkPhoneNumber;
      })
      .then(handleException)
      .catch(handleError);
  };

  const updateUserProfile: UpdateUserProfile = async ({ name }) => {
    return await playdexClient
      .mutate<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>({
        mutation: UpdateUserProfileDocument,
        variables: {
          input: {
            name: name,
          },
        },
      })
      .then(({ data }) => data?.updateProfile)
      .then(handleException)
      .catch(handleError);
  };

  const linkSocialConnection: LinkSocialConnection = async (
    linkSocialConnectionInput: UserLinkSocialConnectionInput,
  ) => {
    return await playdexClient
      .mutate<LinkSocialConnectionMutation, LinkSocialConnectionMutationVariables>({
        mutation: LinkSocialConnectionDocument,
        variables: {
          userLinkSocialConnectionInput: linkSocialConnectionInput,
        },
      })
      .then(({ data }) => data?.linkSocialConnection)
      .catch(handleError);
  };

  const getSocialConnectionAuthLink: GetSocialConnectionAuthLink = async (
    getSocialConnectionAuthLinkInput: UserSocialLinkGetAuthLinkInput,
  ) => {
    return await playdexClient
      .mutate<GetAuthLinkMutation, GetAuthLinkMutationVariables>({
        mutation: GetAuthLinkDocument,
        variables: {
          userSocialLinkGetAuthLinkInput: getSocialConnectionAuthLinkInput,
        },
      })
      .then(({ data }) => data?.getAuthLink)
      .catch(handleError);
  };

  return {
    getUserProfile,
    linkPhoneNumber,
    linkSocialConnection,
    getSocialConnectionAuthLink,
    updateUserProfile,
  };
};

export default createUserProfileService;
