import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useAccount } from 'wagmi';

import {
  AXIE_INFINITY_HISTORY,
  BALANCE_PAGE_PARENT_ROUTE,
  INVENTORY_ASSETS_ROUTE,
  SETTINGS_ROUTE,
} from '@constants/routes';
import { useAuthGuardConnectWalletCallback, useToken } from '@features/auth';
import { ReactComponent as AxieIcon } from '@public/icons/custom/axie.svg';
import { ReactComponent as IconClock } from '@public/icons/custom/clock.svg';
import { ReactComponent as LogoutIcon } from '@public/icons/custom/log-out.svg';
import { ReactComponent as SettingsIcon } from '@public/icons/custom/settings.svg';
import { ReactComponent as IconWallet } from '@public/icons/custom/wallet.svg';
import { ReactComponent as IconPicture } from '@public/icons/picture.svg';

type UserStaticLinksProps = Pick<UserMenuItemProps, 'onItemClick' | 'itemClassName' | 'onSidebar'>;

type StaticLinkItem = {
  headerLabel?: string;
  Icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  label: string;
  link?: string;
  action?: () => void;
};
type StaticLinkGroup = {
  items: Array<StaticLinkItem>;
};
type DropdownMenu = Array<StaticLinkGroup>;

const getStaticLinks = ({
  onLogout,
  onMyNftsClick,
}: {
  onLogout: VoidFunction;
  onMyNftsClick: VoidFunction;
}): DropdownMenu => [
  {
    items: [
      {
        Icon: IconPicture,
        label: 'My NFTs',
        action: onMyNftsClick,
      },
      {
        Icon: IconWallet,
        label: 'Balances',
        link: `${BALANCE_PAGE_PARENT_ROUTE}/axie-infinity`,
      },
      {
        Icon: SettingsIcon,
        label: 'Settings',
        link: SETTINGS_ROUTE,
      },
      {
        headerLabel: 'Axie Infinity',
        Icon: AxieIcon,
        label: 'Axie assets',
        link: `${INVENTORY_ASSETS_ROUTE}/axie-infinity`,
      },
      {
        Icon: IconClock,
        label: 'Axie history',
        link: AXIE_INFINITY_HISTORY,
      },
    ],
  },
  {
    items: [
      {
        Icon: LogoutIcon,
        label: 'Logout',
        action: onLogout,
      },
    ],
  },
];

type UserMenuItemProps = {
  onItemClick?: () => void;
  itemClassName?: string;
  item: StaticLinkItem;
  onSidebar?: boolean;
};

const MenuItem = ({ onItemClick, itemClassName, item, onSidebar }: UserMenuItemProps): JSX.Element => {
  const onClickHandler = (): void => {
    onItemClick?.();
    item.action?.();
  };

  const { Icon } = item;

  const itemContent = (
    <>
      <div className="text-labelTertiary">
        <Icon width={20} height={20} />
      </div>
      <div className={classNames('text-sm font-medium text-labelPrimary', onSidebar && 'flex items-center h-6')}>
        <span>{item.label}</span>
      </div>
    </>
  );

  const defaultItemProps = {
    className: classNames(
      'flex gap-3 p-2.5 items-center cursor-pointer',
      onSidebar ? 'rounded-lg hover:bg-bgSecondary' : 'rounded hover:bg-dropdownHoveredItem',
      itemClassName,
    ),
    onClick: onClickHandler,
  };

  return (
    <>
      {item.headerLabel && (
        <label className="whitespace-nowrap text-xs text-labelSecondary mt-3 pl-3 pr-2.5 py-1 font-medium">
          {item.headerLabel}
        </label>
      )}

      {item.link ? (
        <Link href={item.link ?? ''} passHref {...defaultItemProps}>
          {itemContent}
        </Link>
      ) : (
        <div {...defaultItemProps}>{itemContent}</div>
      )}
    </>
  );
};

export const UserStaticLinks = ({ onItemClick, itemClassName, onSidebar }: UserStaticLinksProps): JSX.Element => {
  const { disconnect } = useToken();
  const { address } = useAccount();
  const router = useRouter();

  const onMyNftsClick = useAuthGuardConnectWalletCallback(() => {
    router.push({ pathname: `/inventory/${address}` });
  });

  const staticLinks = getStaticLinks({ onLogout: disconnect, onMyNftsClick });

  return (
    <div className="p-2 min-w-[169px]">
      {staticLinks.map(({ items }, sectionIndex) => (
        <section
          className={classNames(
            'flex flex-col border-dropdownBorder first:border-t-0 border-t-1',
            'py-2 first:pt-0 last:pb-0',
          )}
          key={`section-${sectionIndex}`}
        >
          {items.map((item, sectionItemIndex) => (
            <MenuItem
              key={`${sectionIndex} - ${sectionItemIndex}`}
              item={item}
              itemClassName={itemClassName}
              onItemClick={onItemClick}
              onSidebar={onSidebar}
            />
          ))}
        </section>
      ))}
    </div>
  );
};
