import { useQuery } from '@apollo/client';

import { JsonSchema, RefSchema } from '@features/common';
import {
  TournamentExtraFieldsSchemasDocument,
  TournamentExtraFieldsSchemasQuery,
  TournamentFragmentFragment as TournamentFragment,
} from '@graphql/generated';

type UseTournamentExtraFields = (tournament: TournamentFragment) => {
  areExtraFieldsSchemasLoading: boolean;
  participantExtraFields: Record<string, JsonSchema | RefSchema>;
  teamExtraFields: Record<string, JsonSchema | RefSchema>;
};

export const useTournamentExtraFields: UseTournamentExtraFields = tournament => {
  const { loading: areExtraFieldsSchemasLoading, data: extraFieldsSchemasData } =
    useQuery<TournamentExtraFieldsSchemasQuery>(TournamentExtraFieldsSchemasDocument, {
      variables: { slug: tournament.slug },
      skip: !tournament.extraFieldsSchema && !tournament.teamExtraFieldsSchema,
    });

  const participantExtraFields =
    (extraFieldsSchemasData?.tournamentExtraFieldsSchemas.participantExtraFieldsSchema as JsonSchema)?.properties ?? {};

  const teamExtraFields =
    (extraFieldsSchemasData?.tournamentExtraFieldsSchemas.teamExtraFieldsSchema as JsonSchema)?.properties ?? {};

  return {
    areExtraFieldsSchemasLoading,
    participantExtraFields,
    teamExtraFields,
  };
};
