import { decode } from 'jsonwebtoken';

/**
 * @description The payload is always contains `uid: string`, but in case of malfunction token it cannot be decoded properly.
 * Because of that reason, this function can return undefined. But in a reality it shouldn't happen.
 */
export const getUserIdFromFirebaseCustomToken = (token: string): string | undefined => {
  const jwtPayload = decode(token, { json: true });
  return jwtPayload?.uid;
};
