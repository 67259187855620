import { Duration } from 'luxon';

export type TimerFormatConfig = {
  withSeconds?: boolean;
  short?: boolean;
};

const createFormatMap = (
  withSeconds: boolean,
): {
  minutesLeft: string;
  hoursLeft: string;
  daysLeft: string;
} =>
  withSeconds
    ? {
        minutesLeft: "m 'minutes' s 'seconds'",
        hoursLeft: "hh:mm:ss'",
        daysLeft: "d 'days', hh:mm:ss",
      }
    : {
        minutesLeft: "m 'minutes'",
        hoursLeft: "h 'hours'",
        daysLeft: "d 'days'",
      };

const createShortFormatMap = (
  withSeconds: boolean,
): {
  minutesLeft: string;
  hoursLeft: string;
  daysLeft: string;
} =>
  withSeconds
    ? {
        minutesLeft: "m 'm' s 's'",
        hoursLeft: "hh:mm:ss'",
        daysLeft: "d 'd', hh:mm:ss",
      }
    : {
        minutesLeft: "m 'm'",
        hoursLeft: "h 'h'",
        daysLeft: "d 'd'",
      };

export const getTimerFormat = (duration: Duration, { withSeconds, short }: TimerFormatConfig): string => {
  const formatMap = short ? createShortFormatMap(withSeconds === true) : createFormatMap(withSeconds === true);

  if (duration.days > 0) {
    return formatMap.daysLeft;
  }

  if (duration.hours === 0) {
    return withSeconds ? "m 'minutes' s 'seconds'" : "m 'minutes'";
  }

  return withSeconds ? 'hh:mm:ss' : "h 'hours'";
};
