import { useConnectModal } from '@rainbow-me/rainbowkit';
import { useAccount } from 'wagmi';

import { useAuthController } from '@features/auth';

/**
 * @description: This hook wrap accepted callback and return another which guarded with authentication flow
 */
export const useAuthGuardConnectWalletCallback = (cb: () => void | Promise<void>): (() => void) => {
  const { requireAuth } = useAuthController();

  const { address } = useAccount();
  const { openConnectModal } = useConnectModal();

  return (): void => {
    const isUserAuthenticated = requireAuth({ includeDiscordVerification: false });

    if (isUserAuthenticated) {
      if (!address && openConnectModal) {
        return openConnectModal();
      }

      cb();
    }
  };
};
