import { useConnectModal } from '@rainbow-me/rainbowkit';
import * as Sentry from '@sentry/nextjs';
import { ethers } from 'ethers';
import { useState } from 'react';
import {
  Chain,
  useAccount,
  useContractWrite,
  useNetwork,
  usePrepareContractWrite,
  UserRejectedRequestError,
  useSwitchNetwork,
  useWaitForTransaction,
} from 'wagmi';

import { Button, truncateWalletAddress } from '@features/common';
import { useDebounce } from '@features/common';
import { HexString } from '@globalTypes/contracts/metamask';
import { TournamentFragmentFragment as TournamentFragment } from '@graphql/generated';
import { notice, ToastType } from '@utils/notice';
import { socialEventRewardsABI } from 'typings/abis';

import { ClaimModal } from './ClaimModal';
import { RewardContainer } from './RewardContainer';
import { RewardTokens } from './RewardTokens';
import { TxProgressModal } from './TxProgressModal';
import { useFormattedErc20Tokens, useSocialEventRewardContractRead } from '../hooks';
import { EventState, RewardsMerkleTreeData } from '../types';
import { formatClaimArgs, getClaimableReward } from '../utils';

type Props = {
  tournament: TournamentFragment;
  contractAddress: HexString;
  chainId: number;
  merkleTree: RewardsMerkleTreeData;
  className?: string;
};

const getTxUrl = (txHash: string | undefined, chains: Chain[], chainId: number): string | null => {
  if (!txHash) {
    return null;
  }

  const chain = chains.find(c => c.id === chainId);
  const explorerUrl = chain?.blockExplorers?.default.url;

  return explorerUrl ? `${explorerUrl}/tx/${txHash}` : null;
};

export const ClaimableReward = ({
  tournament,
  contractAddress,
  chainId,
  merkleTree,
  className,
}: Props): JSX.Element | null => {
  const [isClaimModalOpen, setIsClaimModalOpen] = useState(false);
  const [isTxProgressModalOpen, setIsTxProgressModalOpen] = useState(false);
  const [isReceiveToAnotherAddressChecked, setIsReceiveToAnotherAddressChecked] = useState(false);
  const [recipientWalletAddress, setRecipientWalletAddress] = useState('');

  const isCustomRecipientAddressCorrect = ethers.utils.isAddress(recipientWalletAddress.trim());
  const isRecipientAddressCorrect = isReceiveToAnotherAddressChecked ? isCustomRecipientAddressCorrect : true;

  const { address, isConnected, isConnecting } = useAccount();
  const { openConnectModal } = useConnectModal();

  const { chain, chains } = useNetwork();
  const { switchNetworkAsync } = useSwitchNetwork();

  const { amounts, rewardHash, proof } = getClaimableReward(address, merkleTree);

  const { eventRewardOnchainData, eventId, isClaimed } = useSocialEventRewardContractRead({
    tournamentId: tournament.id,
    leaderboardId: tournament.leaderboard?.id as string,
    contractAddress,
    chainId,
    rewardHash,
  });

  const debouncedRecipientWalletAddress = useDebounce(
    isReceiveToAnotherAddressChecked ? recipientWalletAddress : address,
    500,
  );

  const { config } = usePrepareContractWrite({
    address: contractAddress,
    chainId: chainId,
    abi: socialEventRewardsABI,
    functionName: 'claim',
    args: formatClaimArgs(eventId, amounts, debouncedRecipientWalletAddress, proof),
    enabled: Boolean(eventId && rewardHash && eventRewardOnchainData?.isClaimingEnabled && !isClaimed),
  });
  const claimWrite = useContractWrite({
    ...config,
    onError: error => {
      if (!(error instanceof UserRejectedRequestError)) {
        notice(ToastType.ERROR, {
          title: 'Error',
          description: 'Unable to process the transaction, please try again',
        });

        Sentry.captureException(error, {
          extra: {
            eventId,
            tournamentSlug: tournament.slug,
          },
        });
      }

      setIsTxProgressModalOpen(false);
    },
  });

  const waitForClaimWrite = useWaitForTransaction({
    hash: claimWrite.data?.hash,
  });

  const claimableErc20Tokens = useFormattedErc20Tokens({
    tokens: eventRewardOnchainData?.erc20Addresses as HexString[] | undefined,
    chainId,
    amounts,
    enabled: Boolean(rewardHash) && Boolean(eventRewardOnchainData),
  });

  const modals = (
    <>
      {isClaimModalOpen && (
        <ClaimModal
          tokens={claimableErc20Tokens}
          tournament={tournament}
          isReceiveToAnotherAddressChecked={isReceiveToAnotherAddressChecked}
          setIsReceiveToAnotherAddressChecked={setIsReceiveToAnotherAddressChecked}
          recipientWalletAddress={recipientWalletAddress}
          setRecipientWalletAddress={setRecipientWalletAddress}
          isRecipientAddressCorrect={isRecipientAddressCorrect}
          onClaim={async () => {
            if (chain?.id !== chainId) {
              await switchNetworkAsync?.(chainId);
            }

            if (claimWrite?.write) {
              claimWrite.write();
              setIsClaimModalOpen(false);
              setIsTxProgressModalOpen(true);
            }
          }}
          onClose={() => setIsClaimModalOpen(false)}
        />
      )}

      {isTxProgressModalOpen && (
        <TxProgressModal
          txUrl={getTxUrl(claimWrite.data?.hash, chains, chainId)}
          tournamentTitle={tournament.title}
          isSuccess={waitForClaimWrite.isSuccess}
          onClose={() => setIsTxProgressModalOpen(false)}
        />
      )}
    </>
  );

  if (!isConnected) {
    return (
      <RewardContainer className={className} modals={modals}>
        <div>Connect wallet to check the availability for claiming the prize</div>

        <Button className="shrink-0" onClick={() => openConnectModal?.()}>
          Connect wallet
        </Button>
      </RewardContainer>
    );
  }

  const isEmptyState =
    !eventRewardOnchainData ||
    eventRewardOnchainData.state < EventState.GOT_DEPOSIT ||
    !eventRewardOnchainData.isClaimingEnabled;

  if (isEmptyState) {
    return <RewardContainer modals={modals} />;
  }

  if (isConnecting) {
    return (
      <RewardContainer className={className} modals={modals}>
        Loading...
      </RewardContainer>
    );
  }

  if (!rewardHash) {
    return (
      <RewardContainer className={className} modals={modals}>
        <div>
          <div className="text-md font-medium">
            Sorry, no reward available for{' '}
            {address ? (
              <>
                <span className="inline-block px-2 py-1 text-xs bg-bgTertiary rounded">
                  {truncateWalletAddress(address)}
                </span>{' '}
                wallet
              </>
            ) : (
              'you.'
            )}
          </div>
          <div className="text-labelSecondary mt-1.5">
            It looks like this wallet is not eligible for reward. You can still enter the following tournaments and try
            your luck there!
          </div>
        </div>
      </RewardContainer>
    );
  }

  if (isClaimed || waitForClaimWrite.isSuccess) {
    return (
      <RewardContainer className={className} modals={modals}>
        <div>
          <div className="text-md font-medium">Reward claimed!</div>
          <div className="text-labelSecondary mt-1.5">
            You&apos;ve claimed {claimableErc20Tokens ? <RewardTokens tokens={claimableErc20Tokens} /> : 'the reward!'}
            <br />
            Thank you for participating
          </div>
        </div>
      </RewardContainer>
    );
  }

  const isClaiming = waitForClaimWrite.isLoading || claimWrite.isLoading;

  return (
    <RewardContainer className={className} modals={modals}>
      <div>
        <div className="text-md font-medium">Claim your prize</div>
        <div className="text-labelSecondary mt-1.5">
          You are eligible for claiming{' '}
          {claimableErc20Tokens ? <RewardTokens tokens={claimableErc20Tokens} /> : 'the reward!'}
        </div>
      </div>

      <Button className="shrink-0" disabled={isClaiming} onClick={() => setIsClaimModalOpen(true)}>
        {isClaiming ? <>Claiming</> : <>Claim</>}
      </Button>
    </RewardContainer>
  );
};
