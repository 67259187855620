import classNames from 'classnames';
import { useMemo } from 'react';
import { Address } from 'wagmi';

import { Select, SelectOption } from '@features/common';
import { ReactComponent as IconChevronDown } from '@public/icons/custom/chevron-down.svg';

import { getTokenIconAndSymbol } from '../utils';

type Props = {
  value?: string | null;
  onChange: (currency: string) => void;
  currencyAddresses?: string[];
  className?: string;
  chainId: number;
};

export const CurrencySelect = ({ value, onChange, currencyAddresses, className, chainId }: Props): JSX.Element => {
  const currencyOptions: SelectOption<string>[] = useMemo(
    () =>
      currencyAddresses?.map(currencyAddress => {
        const { Icon, label: symbol } =
          getTokenIconAndSymbol({
            address: currencyAddress as Address,
            chainId,
          }) ?? {};

        return {
          label: symbol ?? '',
          value: currencyAddress,
          icon: Icon ? <Icon /> : undefined,
        };
      }) ?? [],
    [currencyAddresses],
  );

  const { Icon, label: symbol } =
    getTokenIconAndSymbol({
      address: value as Address,
      chainId,
    }) ?? {};

  return (
    <Select
      options={currencyOptions}
      onChange={option => onChange(option.value)}
      className="h-full"
      popoverClassName="!w-30"
      popoverPosition="left"
    >
      <div
        className={classNames(
          'border-l-1 border-selectBorder h-full flex pl-3 items-center pr-1 gap-1 leading-5',
          className,
        )}
      >
        {Icon ? <Icon className="w-4 h-4" /> : null}
        {symbol}
        <div className="w-5 h-5">
          <IconChevronDown />
        </div>
      </div>
    </Select>
  );
};
