import cn from 'classnames';
import Link from 'next/link';
import React from 'react';

import { GameDocument } from '@globalTypes/prismic/documents';
import { ReactComponent as DiscordIcon } from '@public/icons/brands/discord.svg';
import { ReactComponent as FacebookIcon } from '@public/icons/brands/facebook.svg';
import { ReactComponent as InstagramIcon } from '@public/icons/brands/instagram.svg';
import { ReactComponent as GlobeIcon } from '@public/icons/brands/internet.svg';
import { ReactComponent as MediumIcon } from '@public/icons/brands/medium.svg';
import { ReactComponent as RedditIcon } from '@public/icons/brands/reddit.svg';
import { ReactComponent as TelegramIcon } from '@public/icons/brands/telegram.svg';
import { ReactComponent as TwitterIcon } from '@public/icons/brands/twitter.svg';
import { ReactComponent as FileTextIcon } from '@public/icons/file-text.svg';

const iconByLinkType = {
  website: GlobeIcon,
  facebook: FacebookIcon,
  twitter: TwitterIcon,
  discord: DiscordIcon,
  telegram: TelegramIcon,
  instagram: InstagramIcon,
  medium: MediumIcon,
  docs: FileTextIcon,
  reddit: RedditIcon,
};

export const GameLinks = ({
  className,
  gameLinks,
}: {
  className?: string;
  gameLinks: GameDocument['data']['links'];
}): JSX.Element => {
  return (
    <div className={cn('flex', 'gap-2', className)}>
      {gameLinks.map(({ type, url }, i) => {
        const Icon = type ? iconByLinkType[type] : null;

        return (
          <Link
            href={url as string}
            key={i}
            className={cn('flex items-center hover:text-labelPrimary text-labelSecondary transition duration-250')}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="cursor-pointer">
              {Icon ? (
                <Icon className="w-4 h-4" />
              ) : (
                <div className="rounded bg-bgSecondary px-2 py-1 whitespace-nowrap">{type}</div>
              )}
            </div>
          </Link>
        );
      })}
    </div>
  );
};
