/* eslint-disable functional/immutable-data */
import { RTNode } from '@prismicio/types';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { empty } from 'ramda';
import { useMemo } from 'react';

import ElTabs from '@elements/ElTabs';
import { checkIsPrismicRichTextBlank } from '@features/common';
import { PrismicRichTextWrapper } from '@features/prismic';
import { TournamentPrismicDocument } from '@globalTypes/prismic/documents';
import { LeaderboardQueryResult, TournamentFragmentFragment as TournamentFragment } from '@graphql/generated';
import { ReactComponent as IconBrackets } from '@public/icons/brackets.svg';

import { LeaderboardTab } from './LeaderboardTab';

type TournamentTabsProps = {
  tournament: TournamentFragment;
  prismicTournament: TournamentPrismicDocument | null;
  onBracketsPreviewClick: VoidFunction;
  leaderboardQueryResult: LeaderboardQueryResult;
};

type Tab = {
  label: string;
  content: JSX.Element;
};

export const TournamentTabs = ({
  tournament,
  leaderboardQueryResult,
  prismicTournament,
  onBracketsPreviewClick,
}: TournamentTabsProps): JSX.Element | null => {
  const router = useRouter();

  const tabs: Tab[] = useMemo(() => {
    const tabs: Tab[] = [];

    const renderRichTextTab = (field: [] | [RTNode, ...RTNode[]] | undefined): JSX.Element => {
      return (
        <div className="pt-4 text-sm leading-5 text-labelSecondary">
          <PrismicRichTextWrapper field={field} />
        </div>
      );
    };

    if (!checkIsPrismicRichTextBlank(prismicTournament?.data?.registration)) {
      tabs.push({
        label: 'Registration',
        content: renderRichTextTab(prismicTournament?.data?.registration),
      });
    }

    if (tournament.leaderboard) {
      tabs.push({
        label: 'Leaderboard',
        content: <LeaderboardTab leaderboardQueryResult={leaderboardQueryResult} />,
      });
    }

    if (!checkIsPrismicRichTextBlank(prismicTournament?.data?.results)) {
      tabs.push({
        label: 'Results',
        content: renderRichTextTab(prismicTournament?.data?.results),
      });
    }

    if (!checkIsPrismicRichTextBlank(prismicTournament?.data?.mechanics)) {
      tabs.push({
        label: 'Mechanics',
        content: renderRichTextTab(prismicTournament?.data?.mechanics),
      });
    }

    if (!empty(prismicTournament?.data.brackets ?? {})) {
      tabs.push({
        label: 'Brackets',
        content: (
          <div
            className="group rounded-lg w-full mt-4 cursor-zoom-in overflow-hidden relative"
            onClick={onBracketsPreviewClick}
          >
            <IconBrackets />
            <div
              className={classNames(
                'absolute inset-0 bg-black/20 opacity-0',
                'group-hover:opacity-100 transition-opacity duration-500',
              )}
            />
            <div
              className={classNames(
                'absolute bg-bgSecondary px-2 py-1 rounded text-sm opacity-0',
                'border-bgStripe border-1',
                'left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2',
                'group-hover:opacity-100 transition-opacity duration-500 whitespace-nowrap',
              )}
            >
              Click to open in full screen
            </div>
          </div>
        ),
      });
    }

    if (!empty(prismicTournament?.data.facebookLiveUrl ?? {})) {
      tabs.push({
        label: 'Live stream',
        content: (
          <iframe
            src={prismicTournament?.data?.facebookLiveUrl?.embed_url}
            className="border-none mt-4 max-w-lg min-w-0 w-full aspect-video"
            scrolling="no"
            frameBorder="0"
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
            allowFullScreen
          />
        ),
      });
    }

    return tabs;
  }, [prismicTournament, leaderboardQueryResult]);

  const initialTabIndex = router.query['initialTab']
    ? tabs.findIndex(e => e.label === router.query['initialTab'])
    : undefined;

  if (!tabs.length) {
    return null;
  }

  return <ElTabs tabs={tabs} defaultIndex={initialTabIndex} />;
};
