import cn from 'classnames';
import { PropsWithChildren } from 'react';

type RewardContainerProps = {
  className?: string;
  modals: JSX.Element | null;
} & PropsWithChildren;

export const RewardContainer = ({ className, children, modals }: RewardContainerProps): JSX.Element | null => {
  if (!children) {
    return modals;
  }

  return (
    <div className={cn('text-sm overflow-hidden', className)}>
      <div className="flex gap-4 items-center justify-between">{children}</div>
      {modals}
    </div>
  );
};
