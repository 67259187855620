import classNames from 'classnames';
import React, { ButtonHTMLAttributes } from 'react';

const btnStyleMap = {
  'solid-primary': classNames(
    'border-blue-800 bg-blue-800 hover:bg-white hover:text-blue-800',
    'text-white transition-opacity hover:opacity-90',
  ),
  'solid-blue': classNames(
    'py-4 font-bold border-0 w-full flex justify-center',
    'hover:scale-[98%] transition ease-in-out duration-[300ms]',
    'bg-gradient-to-r from-sky-500 to-blue-600 text-white',
    'disabled:from-gray-700 disabled:to-gray-800 disabled:text-gray-400',
  ),
  outline: 'border-gray-500 text-gray-500 transition-colors hover:bg-gray-800 hover:text-white',
  disabled: 'border-gray-600 !text-white/100 !disabled:opacity-1 bg-gray-500',
};

type ElButtonProps = {
  onDisabledClick?: () => void;
  variant?: keyof typeof btnStyleMap;
  children?: React.ReactNode;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const ElButton = ({ variant, children, className, ...props }: ElButtonProps): JSX.Element => {
  return (
    <button
      className={classNames(
        'm-0 px-3',
        'flex items-center',
        'transition ease-in-out',
        'border-2',
        'rounded-md',
        variant && btnStyleMap[variant],
        className,
      )}
      style={{
        boxShadow: variant === 'solid-blue' ? '0px 0px 20px 4px #3b82f694' : 'initial',
      }}
      disabled={variant === 'disabled'}
      {...props}
    >
      {children}
    </button>
  );
};

export default ElButton;
