import { EmbedField } from '@prismicio/types';
import React from 'react';

import ElModal from '@components/elements/ElModal';
import { ModalCloseButton } from '@features/common';

export const TournamentBracketsModal = ({
  bracketsEmbedField,
  onCloseClick,
}: {
  bracketsEmbedField?: EmbedField;
  onCloseClick: VoidFunction;
}): JSX.Element => {
  return (
    <ElModal
      className="scrollbar-none w-screen h-screen rounded-none sm:w-[85vw] sm:h-[80vh] sm:rounded"
      modalWrapperStyles="p-0"
      onCloseModal={onCloseClick}
    >
      <div className="bg-bgPrimary h-full">
        <div className="px-2 pb-1 pt-2">
          <ModalCloseButton onClick={onCloseClick} className="ml-auto w-6 h-6" />
        </div>

        <iframe
          className="rounded w-full h-[calc(100%_-_2rem)]"
          src={bracketsEmbedField?.embed_url}
          scrolling="auto"
          allowTransparency
        />
      </div>
    </ElModal>
  );
};
