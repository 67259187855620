import { PrismicNextImage } from '@prismicio/next';
import React, { useMemo } from 'react';

import ElShinyButton from '@components/elements/ElShinyButton';
import { useAuthController } from '@features/auth';
import { ApplicationSettings, isSchemaObject } from '@features/common';
import { useNftMetaTraits as useSettingsMetaTraits } from '@features/gamehub';
import { AssetsGridSliceItem } from '@globalTypes/prismic/documents';
import { OfferState, SmartContractName } from '@graphql/generated';
import { sentenceCase } from '@utils/strings';

import { AssetCard } from './AssetCard';
import { AdditionalMetaKeyword, GameNftAssetsQueryItem } from '../../types';
import { mapSliceAssetToNftMeta } from '../../utils';
import GameWaitlistButton from '../GameWaitlistButton';

type Offer = GameNftAssetsQueryItem['offers'][number];

type PropsQueryItem = {
  settings: Partial<Record<SmartContractName, ApplicationSettings>>;
  item: GameNftAssetsQueryItem;
  onButtonClick: (offer: Offer) => void;
};

export const GameAssetGridQueryItem = ({ item, settings, onButtonClick }: PropsQueryItem): JSX.Element => {
  const { requireAuth } = useAuthController();

  const offerTerms = useMemo<Array<[name: string, value: string]>>(() => {
    const offer = item.offers[0];
    const smartContractSettings = offer && settings[offer.smartContractName as SmartContractName];

    return Object.entries(offer?.terms ?? {})
      .filter(([name]) => name !== 'smartContractName')
      .map<[name: string, value: string]>(([name, value]) => {
        const offerTerm = smartContractSettings?.schemas.offerTerms?.properties?.[name];
        const units = offerTerm && isSchemaObject(offerTerm) && offerTerm?.[AdditionalMetaKeyword.Units];
        const formattedValue = String(value ?? '') + (units ? ` ${units}` : '');
        const formattedName = sentenceCase(name);
        return [formattedName, formattedValue];
      });
  }, [item, settings]);

  const meta = useSettingsMetaTraits(item);

  const offer = item.offers[0];
  const isAvailable = item.isAvailable && offer?.state == OfferState.Published;
  const handleButtonClick =
    offer &&
    (() =>
      requireAuth({
        includeDiscordVerification: false,
        includePhoneVerification: false,
      }) && onButtonClick(offer));

  return (
    <AssetCard
      meta={meta}
      image={<img src={meta.image} className="object-contain" alt="" />}
      button={
        isAvailable ? (
          <ElShinyButton variant="active-no-shine" onClick={handleButtonClick}>
            Rent
          </ElShinyButton>
        ) : (
          <ElShinyButton variant="outline" disabled>
            Rented
          </ElShinyButton>
        )
      }
      terms={offerTerms}
    />
  );
};

type PropsSliceItem = {
  item: AssetsGridSliceItem;
};

export const GameAssetGridSliceItem = ({ item }: PropsSliceItem): JSX.Element => {
  const { image, isAvailable } = item.asset.data;
  return (
    <AssetCard
      meta={mapSliceAssetToNftMeta(item)}
      image={<PrismicNextImage field={image} className="object-contain" />}
      button={
        isAvailable ? (
          <GameWaitlistButton />
        ) : (
          <ElShinyButton variant="outline" disabled>
            Rented
          </ElShinyButton>
        )
      }
    />
  );
};
