import { useConnectModal } from '@rainbow-me/rainbowkit';
import { useEffect } from 'react';
import { useAccount } from 'wagmi';

import {
  AUTH_PROVIDER,
  AUTH_SOURCE,
  createAutoConnectStatusRepository,
  useAuth,
  useAuthController,
  useToken,
} from '@features/auth';
import useAsync from '@hooks/useAsync';
import { getLocalStorage } from '@utils/storage';

type UseLoginModalParams =
  | {
      onLoginSuccess?: () => Promise<void> | void;
      onLoginFailed?: () => Promise<void> | void;
      source?: AUTH_SOURCE;
    }
  | undefined;
export const useLoginModal = ({ onLoginSuccess, onLoginFailed, source }: UseLoginModalParams = {}) => {
  const { openConnectModal: openWalletConnectModal } = useConnectModal();
  const { signInByAuthProvider } = useAuth();

  const { address, isConnected } = useAccount();
  const { writeAutoConnectStatus } = createAutoConnectStatusRepository(getLocalStorage());

  const { user, isUserLoading } = useToken();

  const [loginState, loginHandler] = useAsync(async (ongoingAuthProvider: AUTH_PROVIDER) => {
    try {
      await signInByAuthProvider(ongoingAuthProvider, source);
      await onLoginSuccess?.();
    } catch (error) {
      onLoginFailed?.();
    }
  });

  const loginByWalletConnector = (): Promise<void> => loginHandler(AUTH_PROVIDER.WALLET_CONNECTOR);
  const loginGoogle = (): Promise<void> => loginHandler(AUTH_PROVIDER.GOOGLE);
  const loginFacebook = (): Promise<void> => loginHandler(AUTH_PROVIDER.FACEBOOK);

  useEffect(() => {
    const isWalletConnected = address && isConnected;

    if (isWalletConnected && !isUserLoading && !user) {
      writeAutoConnectStatus(true);
      loginByWalletConnector();
    }
  }, [address, isConnected, isUserLoading]);

  return { loginState, openWalletConnectModal };
};
