import { Button } from '@features/common/components/Button';
import withSafeHydration from '@features/common/hocs/withSafeHydration';
import { TournamentFragmentFragment as TournamentFragment } from '@graphql/generated';
import { ReactComponent as PlusIcon } from '@public/icons/plus.svg';

import { useShareTournamentModal } from '../../../hooks';

type Props = {
  tournament: TournamentFragment;
};

export const InviteButton = withSafeHydration(({ tournament }: Props): JSX.Element => {
  const { shareTournamentModal, openShareModal } = useShareTournamentModal(tournament);

  const { registrationEndDate } = tournament;
  const isDisabled = new Date(registrationEndDate).getTime() < Date.now();

  return (
    <>
      <Button disabled={isDisabled} onClick={openShareModal} className="flex w-full gap-1 items-center">
        {isDisabled ? (
          'Registration is closed'
        ) : (
          <>
            <PlusIcon className="w-4 h-4" />
            Invite
          </>
        )}
      </Button>
      {shareTournamentModal}
    </>
  );
});
