/* eslint-disable functional/no-throw-statement */
import { useApolloClient } from '@apollo/client';
import { FirebaseError } from '@firebase/app';
import { AuthProvider, FacebookAuthProvider, getAuth, GoogleAuthProvider, signInWithPopup } from '@firebase/auth';
import * as Sentry from '@sentry/nextjs';

import { AUTH_PROVIDER, AUTH_SOURCE, useToken, useWalletConnectorsLogin } from '@features/auth';
import { logAuthFailed, logAuthStarted, logAuthSuccess } from '@features/common';
import {
  SocialConnectionProvider,
  SocialSignInDocument,
  SocialSignInMutation,
  SocialSignInMutationVariables,
} from '@graphql/generated';
import { clientFirebaseApp } from '@services/firebase/clientFirebase';
import { getFirebaseErrorMessage } from '@services/firebase/errors';
import { setJWTToCookies } from '@utils/cookies';
import { errorHandler } from '@utils/error';
import { notice, ToastType } from '@utils/notice';

type UseAuth = () => {
  googleSignIn: () => Promise<void>;
  facebookSignIn: () => Promise<void>;
  metamaskSignIn: () => Promise<string | void>;
  firebaseProviderSignOut: () => Promise<void>;
  signInByAuthProvider: (authProvider: AUTH_PROVIDER, source?: AUTH_SOURCE) => Promise<void>;
};

export const useAuth: UseAuth = () => {
  const playdexClient = useApolloClient();

  const clientFirebaseAuth = getAuth(clientFirebaseApp);
  const { connect } = useToken();
  const { connectWalletToPlaydex } = useWalletConnectorsLogin();

  const googleSignIn = async (): Promise<void> => {
    const provider = new GoogleAuthProvider();

    await signInWithFirebaseProvider(provider, SocialConnectionProvider.Google);
  };

  const facebookSignIn = async (): Promise<void> => {
    const provider = new FacebookAuthProvider();

    await signInWithFirebaseProvider(provider, SocialConnectionProvider.Facebook);
  };

  const signInByWallet = connectWalletToPlaydex;

  const signInByAuthProvider: ReturnType<UseAuth>['signInByAuthProvider'] = async (
    authProvider: AUTH_PROVIDER,
    source,
  ) => {
    const provider = authProvider;
    logAuthStarted({ provider, source });

    const authHandlersByProvider = {
      [AUTH_PROVIDER.WALLET_CONNECTOR]: signInByWallet,
      [AUTH_PROVIDER.GOOGLE]: googleSignIn,
      [AUTH_PROVIDER.FACEBOOK]: facebookSignIn,
    };

    return await authHandlersByProvider[authProvider]()
      .then(() => {
        logAuthSuccess({ provider, source });
      })
      .catch(error => {
        if (typeof error === 'string') {
          logAuthFailed({ provider, source, message: error });
          throw error;
        }

        const message = error.message || error?.response?.message || 'Something went wrong!';

        logAuthFailed({ provider, source, message });
        throw error;
      });
  };

  const firebaseProviderSignOut = async (): Promise<void> => {
    try {
      await clientFirebaseAuth.signOut();
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const signInWithFirebaseProvider = async (
    firebaseProvider: AuthProvider,
    socialConnectionProvider: SocialConnectionProvider,
  ): Promise<string | void> => {
    try {
      await signInWithPopup(clientFirebaseAuth, firebaseProvider);
    } catch (error) {
      if (error instanceof FirebaseError) {
        const errorCode = error.code;
        const errorMessage = getFirebaseErrorMessage(errorCode);
        notice(ToastType.ERROR, errorMessage);
        throw errorMessage;
      } else {
        Sentry.captureException(error);
      }
    }

    const firebaseToken = await clientFirebaseAuth.currentUser?.getIdToken();
    if (!firebaseToken) {
      notice(ToastType.ERROR, 'Something went wrong! \n Please try again!');
      return;
    }

    try {
      const { data } = await playdexClient.mutate<SocialSignInMutation, SocialSignInMutationVariables>({
        mutation: SocialSignInDocument,
        variables: {
          userSocialSignInInput: {
            provider: socialConnectionProvider,
            token: firebaseToken,
          },
        },
      });

      if (data && data.socialSignIn.__typename === 'UserExceptionType') {
        notice(ToastType.ERROR, data.socialSignIn.message);
        return;
      }

      if (data && data.socialSignIn.__typename === 'UserSocialSignedInType') {
        const authToken = data.socialSignIn.authDetails.authToken;

        setJWTToCookies(authToken);
        await connect(authToken);
        notice(ToastType.SUCCESS, 'You successfully signed!');

        // we don't need to store session in firebase, we can easy logout from firebase after login in our backend
        await firebaseProviderSignOut();
        return;
      }
    } catch (error) {
      Sentry.captureException(error);
      errorHandler({ error });
    }
  };

  return {
    googleSignIn,
    facebookSignIn,
    metamaskSignIn: signInByWallet,
    firebaseProviderSignOut,
    signInByAuthProvider,
  };
};
