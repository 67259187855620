import { PropsWithChildren, useMemo } from 'react';

import { UrlBuilderContext, UrlBuilderContextType } from './UrlBuilderContext';

export const UrlBuilderContextProvider = ({ children }: PropsWithChildren): JSX.Element => {
  const value: UrlBuilderContextType = useMemo(
    () => ({
      buildGameUrl: ({ gameId }) => `/games/${gameId}`,
      buildGameAssetUrl: ({ gameId, tokenId }) => `/games/${gameId}/assets/${tokenId}`,
    }),
    [],
  );

  return <UrlBuilderContext.Provider value={value}>{children}</UrlBuilderContext.Provider>;
};
