import cn from 'classnames';

import coins from './coins.png';

type Props = {
  children: string;
  RewardClaimSection: ({ className }: { className?: string }) => JSX.Element;
};

export const PrizeSection = ({ children, RewardClaimSection }: Props): JSX.Element => {
  return (
    <section
      className={cn(
        'my-2 rounded-3 overflow-hidden border-1 border-bgStripe text-labelPrimary text-sm',
        'bg-gradient-to-l from-[rgba(121,91,185,0.2)] via-transparent to-transparent',
      )}
    >
      <div className="flex justify-between">
        <div className="p-4 md:p-6">
          <h2 className="text-lg font-medium leading-6">Prizes</h2>
          <div className="mt-2 whitespace-pre-line">{children}</div>
        </div>
        <img src={coins.src} className="object-scale-down h-[100px]" alt="" />
      </div>

      <RewardClaimSection className="py-4 px-4 md:px-6 border-t border-solid border-bgStripe" />
    </section>
  );
};
