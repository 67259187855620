/* eslint-disable functional/no-class */
/* eslint-disable functional/no-this-expression */
import { ErrorProps } from 'next/error';
import { useEffect, useState } from 'react';

type Errored = Error | string | Error[] | string[] | ErrorProps;

type AsyncStatus = 'idle' | 'pending' | 'successful' | 'failed';

abstract class AsyncStatePredicate<T, E extends Errored> {
  constructor(public status: AsyncStatus) {
    status;
  }
  isIdle(): this is IdleState<T, E> {
    return this.status === 'idle';
  }
  isPending(): this is PendingState<T, E> {
    return this.status === 'pending';
  }
  isSuccessful(): this is SuccessfulState<T, E> {
    return this.status === 'successful';
  }
  isFailed(): this is FailedState<T, E> {
    return this.status === 'failed';
  }
  static createIdle<T, E extends Errored>(): IdleState<T, E> {
    return new IdleState();
  }
  static createPending<T, E extends Errored>(): PendingState<T, E> {
    return new PendingState();
  }
  static createSuccessful<T, E extends Errored>(data: T): SuccessfulState<T, E> {
    return new SuccessfulState(data);
  }
  static createFailed<T, E extends Errored>(error: E): FailedState<T, E> {
    return new FailedState(error);
  }
}

class IdleState<T, E extends Errored> extends AsyncStatePredicate<T, E> {
  constructor() {
    super('idle');
  }
}

class PendingState<T, E extends Errored> extends AsyncStatePredicate<T, E> {
  constructor() {
    super('pending');
  }
}
class SuccessfulState<T, E extends Errored> extends AsyncStatePredicate<T, E> {
  constructor(public data: T) {
    super('successful');
  }
}
class FailedState<T, E extends Errored> extends AsyncStatePredicate<T, E> {
  constructor(public error: E) {
    super('failed');
  }
}

export type AsyncState<T, E extends Errored = Errored> =
  | IdleState<T, E>
  | PendingState<T, E>
  | SuccessfulState<T, E>
  | FailedState<T, E>;

// type ExtendedAsyncState<T> = AsyncStatePredicate & AsyncState<T>;
type ExtendedAsyncState<T> = AsyncState<T>;

const useAsync = <P extends unknown[], R>(
  createAsyncProcess: (...arg: P) => Promise<R>,
  config?: {
    immediateCall?: boolean;
    immediateInvocationArgs: P;
  },
): [AsyncState<R>, (...arg: P) => Promise<R>] => {
  const [asyncState, setAsyncState] = useState<ExtendedAsyncState<R>>(AsyncStatePredicate.createIdle());

  const init = (...arg: P): Promise<R> => {
    setAsyncState(AsyncStatePredicate.createPending());
    return createAsyncProcess(...arg)
      .then(result => {
        setAsyncState(AsyncStatePredicate.createSuccessful(result));
        return result;
      })
      .catch(e => {
        setAsyncState(AsyncStatePredicate.createFailed(e));
        // eslint-disable-next-line functional/no-throw-statement
        throw e;
      });
  };

  useEffect(() => {
    if (config?.immediateCall) {
      init(...config.immediateInvocationArgs);
    }
  }, []);

  return [asyncState, init];
};

export default useAsync;
