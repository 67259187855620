import React, { DetailedHTMLProps, ImgHTMLAttributes } from 'react';

import { BadgeProps, StatusBadge } from '../StatusBadge';

type StatusBadgeWithImageProps = {
  src?: DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>['src'] | null;
} & BadgeProps;

export const StatusBadgeWithImage = ({
  src,
  children,
  useBorderContainer,
  onClick,
  isActive,
  className,
}: StatusBadgeWithImageProps): JSX.Element => {
  return (
    <StatusBadge useBorderContainer={useBorderContainer} onClick={onClick} isActive={isActive} className={className}>
      {src ? <img src={src ?? ''} alt="" className="w-4 h-4 mr-2" /> : null}

      {children}
    </StatusBadge>
  );
};
