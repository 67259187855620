/* eslint-disable functional/no-throw-statement */
import { ApolloClient } from '@apollo/client';
import assert from 'assert';

import { SignInWithMetamaskResponse } from '@features/auth/types';
import {
  ChallengeNonceDocument,
  ChallengeNonceQuery,
  ChallengeNonceQueryVariables,
  SignInWithMetamaskDocument,
  SignInWithMetamaskMutation,
  SignInWithMetamaskMutationVariables,
} from '@graphql/generated';

export const createWalletConnectorService = (playdexClient: ApolloClient<unknown>) => {
  const getAccessToken = async ({
    walletAddress,
    signMessageAsync,
  }: {
    walletAddress: `0x${string}`;
    signMessageAsync: (args: { message: string }) => Promise<string>;
  }): Promise<SignInWithMetamaskResponse> => {
    try {
      const {
        data: {
          challengeNonce: { message, nonce },
        },
      } = await playdexClient.query<ChallengeNonceQuery, ChallengeNonceQueryVariables>({
        query: ChallengeNonceDocument,
        variables: {
          challengeNonceInput: {
            walletAddress,
          },
        },
      });

      const signature = await signMessageAsync({
        message,
      });

      const { data } = await playdexClient.mutate<SignInWithMetamaskMutation, SignInWithMetamaskMutationVariables>({
        mutation: SignInWithMetamaskDocument,
        variables: {
          userSignInWithMetamaskInput: {
            message,
            nonce,
            signature,
            walletAddress,
          },
        },
      });

      assert(data);

      return data.signInWithMetamask;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return {
    getAccessToken,
  };
};
