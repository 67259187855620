const KEY = 'auto-connect';

export const createAutoConnectStatusRepository = (
  storage: Storage | undefined,
): {
  writeAutoConnectStatus: (status: boolean) => void;
  readAutoConnectStatus: () => boolean;
} => {
  if (typeof storage === 'undefined') {
    return {
      writeAutoConnectStatus: (): void => {
        /** ignored */
      },
      readAutoConnectStatus: (): boolean => false,
    };
  }

  return {
    writeAutoConnectStatus: (status: boolean): void => {
      storage.setItem(KEY, status.toString());
    },
    readAutoConnectStatus: (): boolean => {
      return storage.getItem(KEY) === 'true';
    },
  };
};
