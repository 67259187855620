/* eslint-disable functional/immutable-data */

function clipboardCopy(text: string): Promise<void> {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  return new Promise((resolve, reject) => {
    try {
      const successful = document.execCommand('copy');

      if (successful) {
        resolve();
      } else {
        reject();
      }
    } catch (err) {
      reject(err);
    }

    document.body.removeChild(textArea);
  });
}

export default clipboardCopy;
