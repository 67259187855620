import cn from 'classnames';
import { DateTime } from 'luxon';
import React from 'react';

import { ConditionalWrapper } from '@elements/ConditionaltWrapper';
import { ExternalLink, InternalLink, NullComponent } from '@features/common';
import { formatEventDate } from '@features/common/utils/dates';
import { INotification } from '@features/notification';
import { ReactComponent as GameHubIcon } from '@public/icons/darkGrey-game-controller.svg';
import { ReactComponent as AnnouncementIcon } from '@public/icons/gray-announcment.svg';
import { ReactComponent as MarketplaceIcon } from '@public/icons/maretplace-smooth.svg';
import { ReactComponent as TournamentsIcon } from '@public/icons/sword.svg';
import { ReactComponent as SecurityIcon } from '@public/icons/verified-shield.svg';
import { getLocaleDateTime } from '@utils/dateTime';

type NotificationItemProps = {
  item: INotification;
  closeDropdown?: () => void;
};
enum NotificationCategory {
  GAMEHUB = 'Gamehub',
  MARKETPLACE = 'Marketplace',
  SECURITY = 'Security',
  TOURNAMENTS = 'Tournaments',
  ANNOUNCEMENT = 'Announcements',
}

const NotificationCategoryIcon = ({
  category,
  ...props
}: React.SVGProps<SVGSVGElement> & {
  category: NotificationCategory;
}): JSX.Element | null => {
  const iconsMap = {
    [NotificationCategory.GAMEHUB]: GameHubIcon,
    [NotificationCategory.SECURITY]: SecurityIcon,
    [NotificationCategory.TOURNAMENTS]: TournamentsIcon,
    [NotificationCategory.MARKETPLACE]: MarketplaceIcon,
    [NotificationCategory.ANNOUNCEMENT]: AnnouncementIcon,
  };

  const Icon = iconsMap[category] || NullComponent;

  return <Icon {...props} />;
};

export const NotificationItem = ({
  item: { title, text, createdAt, categorySlug, resourceUrl },
  closeDropdown,
}: NotificationItemProps): JSX.Element => {
  const createdAtWithLocale = getLocaleDateTime(DateTime.fromJSDate(createdAt).toString());

  const hasLink = Boolean(resourceUrl);
  const isExternalLink = Boolean(resourceUrl?.startsWith('https://'));

  const LinkWrapper = isExternalLink ? ExternalLink : InternalLink;

  const baseContent = (
    <li
      className={cn(
        'flex gap-4 px-4 pt-2 pb-3 border-b-1 border-bgStripe hover:bg-dropdownHoveredItem transition-colors',
      )}
    >
      <section>
        <NotificationCategoryIcon category={categorySlug as NotificationCategory} className="w-6 h-6" />
      </section>
      <section className="w-full flex flex-col gap-1.5">
        <div className="flex justify-between items-center gap-2">
          <span className="text-sm text-labelPrimary font-medium">{title}</span>
          <span className="text-xs text-labelTertiary whitespace-nowrap" suppressHydrationWarning={true}>
            {formatEventDate(createdAtWithLocale)}
          </span>
        </div>
        <div className="text-sm text-labelSecondary md:line-clamp-2" title={text}>
          {text}
        </div>
      </section>
    </li>
  );

  return (
    <ConditionalWrapper
      wrapper={LinkWrapper}
      condition={hasLink}
      href={resourceUrl as string}
      onClick={() => !isExternalLink && closeDropdown?.()}
    >
      {baseContent}
    </ConditionalWrapper>
  );
};
