/* eslint-disable functional/immutable-data */
import type { AppProps } from 'next/app';
import getConfig from 'next/config';
import Head from 'next/head';
import Router from 'next/router';
import Script from 'next/script';
import NProgress from 'nprogress';
import { ReactElement, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';
import 'reactjs-popup/dist/index.css';
import '../styles/global.css';
import { PORTAL_NODE_ID } from '@components/elements/ElModal';
import { UrlBuilderContextProvider } from '@entities/urlBuilder';
import { AuthModalContextProvider } from '@features/auth';
import RainbowWalletConnectorProvider from '@features/auth/contexts/RainbowWalletConnectorProvider';
import { TokenProvider } from '@features/auth/hooks/useToken';
import { ToastContainer, useIsomorphicLayoutEffect } from '@features/common';
import { initAmplitudeAnalytics } from '@features/common/analytics/amplitude';
import { useCanonicalLink } from '@features/common/hooks/useCanonicalLink';
import { crispInit } from '@features/common/utils/crispUtils';
import type { CommonStaticProps } from '@features/gamehub';
import { DiscordProvider } from '@features/social-connections';
import { useCustomViewportProperty } from '@hooks/useCustomViewportProperty';
import AccessGuard from '@modules/AccessGuard';
import { Prismic } from '@modules/prismic';
import { featureChecker, FeatureGroup } from '@services/featureChecker';
import { useCreateStore } from '@store/zustand';
import { WindowSizeContextProvider } from 'lib/context/windowSize';

import { FeatureCheckerContextProvider } from '../lib/context/featureChecker';
import PlaydexApolloClientContext from '../lib/context/playdexApolloClientContext';
import { getClaritySnippet, googleTagSnippet } from '../lib/snippets';

const { publicRuntimeConfig } = getConfig();
const { CRISP_ID, GTM_ID, PLAYDEX_BASE_URL, PLAYDEX_PREVIEW_LINK_DESC, AMPLITUDE_API_KEY, CLARITY_APP_ID } =
  publicRuntimeConfig;

const { isAvailable } = featureChecker;
const isSiteIndexingAvailable = isAvailable(FeatureGroup.SearchEngineIndexing, 'AVAILABLE');

const MyApp = ({ Component, pageProps }: AppProps<CommonStaticProps>): ReactElement => {
  useCustomViewportProperty();
  useCreateStore(pageProps.initialZustandState);
  const { isCanonical, canonicalHref } = useCanonicalLink();

  const routeChangeStartHandler = (): void => {
    NProgress.start();
  };

  const routeChangeCompleteHandler = (): void => {
    NProgress.done();
  };

  useIsomorphicLayoutEffect(() => {
    initAmplitudeAnalytics(AMPLITUDE_API_KEY);
    crispInit(CRISP_ID);
  }, []);

  useEffect(() => {
    Router.events.on('routeChangeStart', routeChangeStartHandler);
    Router.events.on('routeChangeComplete', routeChangeCompleteHandler);
    Router.events.on('routeChangeError', routeChangeCompleteHandler);

    return () => {
      Router.events.off('routeChangeStart', routeChangeStartHandler);
      Router.events.off('routeChangeComplete', routeChangeCompleteHandler);
      Router.events.off('routeChangeError', routeChangeCompleteHandler);
    };
  }, []);

  return (
    <RainbowWalletConnectorProvider>
      <TokenProvider>
        <Head>
          <title>Playdex</title>
          <link rel="shortcut icon" href="/favicon.ico" />
          <meta name="viewport" content="width=device-width,minimum-scale=1,maximum-scale=1,initial-scale=1" />
          <meta name="description" content={PLAYDEX_PREVIEW_LINK_DESC} key="description" />

          {isCanonical && <link href={canonicalHref} rel="canonical" />}
          {!isSiteIndexingAvailable && <meta name="robots" content="noindex, nofollow" />}

          <meta property="og:url" content={PLAYDEX_BASE_URL} />
          <meta property="og:type" content="website" key="og:type" />
          <meta property="og:title" content="Playdex" key="og:title" />
          <meta property="og:description" content={PLAYDEX_PREVIEW_LINK_DESC} key="og:description" />
          <meta property="og:image" content="/link_preview.jpg" key="og:image" />

          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content="playdex.io" />
          <meta property="twitter:url" content={PLAYDEX_BASE_URL} />
          <meta name="twitter:title" content="Playdex" key="twitter:title" />
          <meta name="twitter:description" content={PLAYDEX_PREVIEW_LINK_DESC} key="twitter:description" />
          <meta name="twitter:image" content="/link_preview.jpg" />
        </Head>
        <Script id="gtm-id">{googleTagSnippet(GTM_ID)}</Script>
        <Script id="clarity-script" type="text/javascript">
          {getClaritySnippet(CLARITY_APP_ID as string)}
        </Script>
        <noscript
          dangerouslySetInnerHTML={{
            __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${GTM_ID}"
              height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
          }}
        />

        <FeatureCheckerContextProvider>
          <UrlBuilderContextProvider>
            <PlaydexApolloClientContext>
              <DiscordProvider>
                <WindowSizeContextProvider>
                  <AuthModalContextProvider>
                    <AccessGuard>
                      <Prismic>
                        <>
                          <Component {...pageProps} />
                          <div id={PORTAL_NODE_ID} />
                        </>
                      </Prismic>
                    </AccessGuard>
                  </AuthModalContextProvider>
                </WindowSizeContextProvider>
              </DiscordProvider>
            </PlaydexApolloClientContext>

            <ToastContainer />
          </UrlBuilderContextProvider>
        </FeatureCheckerContextProvider>
      </TokenProvider>
    </RainbowWalletConnectorProvider>
  );
};

export default MyApp;
