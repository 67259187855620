import { BuildQueryURLArgs } from '@prismicio/client';

export enum TournamentRegistrationType {
  Individual,
  Captain,
  Member,
}

export const searchBarGameQueryParams: Partial<BuildQueryURLArgs> = {
  orderings: [{ field: 'my.game.rankNumber', direction: 'asc' }],
  graphQuery: `
    {
      game {
        name
        logo
        redirectLocation
      }
    }
  `,
};
