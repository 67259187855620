import classNames from 'classnames';
import classnames from 'classnames';
import React, { Ref, useId } from 'react';
import { FieldError } from 'react-hook-form';

import ElInput from '@elements/ElInput';
import ElTooltip, { TooltipDirection, TooltipType } from '@elements/ElTooltip';
import { TooltTipConfig } from '@templates/MyAssets/axie-infinity/formFieldsConfig';

export type FormFieldProps = {
  className?: string;
  label?: string | JSX.Element;
  labelClassName?: string;
  inputRef?: Ref<HTMLInputElement>;
  tooltipConfig?: TooltTipConfig;
  postIcon?: React.ReactNode;
  isGradientInput?: boolean;
  shownResultValue?: string | JSX.Element;
  fieldError?: FieldError;
  postfix?: string;
} & React.ComponentPropsWithoutRef<typeof ElInput>;

export type ElFormFieldProps = React.ComponentPropsWithoutRef<'input'> & FormFieldProps;

export const ElFormField = React.forwardRef<HTMLInputElement, ElFormFieldProps>(
  (
    {
      label,
      labelClassName,
      isGradientInput = false,
      tooltipConfig,
      shownResultValue,
      fieldError,
      postfix,
      disabled,
      required,
      postIcon,
      id,
      ...inputProps
    },
    ref,
  ) => {
    const defaultId = useId();
    const inputId = id ?? defaultId;

    return (
      <div className="flex flex-col relative my-2 mt-4">
        {typeof label === 'string' ? (
          <div>
            <label
              className={classNames(
                'flex gap-2 text-sm text-labelSecondary md:whitespace-nowrap mb-2',
                disabled && 'opacity-40 gap-2 mr-2',
                labelClassName,
              )}
              htmlFor={inputId}
            >
              <div>
                {label}
                {required && <span className="text-error"> *</span>}
              </div>
              {tooltipConfig && (
                <ElTooltip
                  config={{
                    direction: TooltipDirection.right,
                    duration: 500,
                    tooltipWindowProps: {
                      className: 'w-[15rem] leading-5 bg-black/90 border-[1px]',
                    },
                    triangleWindowProps: {
                      className: 'bg-black',
                    },
                    iconProps: {
                      className: 'w-4 h-4',
                    },
                    ...tooltipConfig.config,
                  }}
                  tooltipType={TooltipType.QUESTION_WRAPPER}
                  tooltipContent={
                    <div className="max-w-full flex-col gap-2">
                      <div className="text-base font-bold text-cyan-400">{tooltipConfig.title}</div>
                      <div className="whitespace-normal">{String(tooltipConfig.description)}</div>
                    </div>
                  }
                />
              )}
            </label>
          </div>
        ) : (
          label
        )}
        <div
          className={classNames(
            isGradientInput &&
              'bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 p-[1px] rounded-md relative',
          )}
        >
          {shownResultValue ? (
            <div className={classNames('flex', inputProps?.className)}>
              {typeof shownResultValue === 'string' ? `${shownResultValue} ${postfix || ''}` : shownResultValue}
            </div>
          ) : (
            <div className="relative">
              <ElInput
                {...inputProps}
                className={classnames(
                  'text-white bg-transparent text-base max-w-[12rem] min-w-full',
                  disabled && 'opacity-40',
                  inputProps?.className,
                  isGradientInput && 'border-none',
                )}
                ref={ref}
                id={inputId}
              />
              {postIcon && (
                <div className="absolute right-2 top-0 h-full min-w-[10%] flex justify-center items-center">
                  {postIcon}
                </div>
              )}
            </div>
          )}
          {fieldError && (
            <div className="absolute top-[5.5rem]">
              <span className="text-error">*{fieldError.message}</span>
            </div>
          )}
        </div>
      </div>
    );
  },
);

// eslint-disable-next-line functional/immutable-data
ElFormField.displayName = 'ElFormField';
