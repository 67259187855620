import * as Sentry from '@sentry/nextjs';
import { useRouter } from 'next/router';
import React, { useContext, useEffect, useState } from 'react';
import { useDisconnect } from 'wagmi';

import { crispAddUser, crispReset } from '@features/common/utils/crispUtils';
import { createPlaydexClient } from '@gql/apollo-client';
import createUserProfileService from '@services/userProfileService/createUserProfileService';
import { UserProfile } from '@services/userProfileService/types';
import { userSliceSelector } from '@store/slices';
import { useStore } from '@store/zustand';
import { getJWTFromCookies, setJWTToCookies } from '@utils/cookies';
import { notice, ToastType } from '@utils/notice';
import { getLocalStorage } from '@utils/storage';

import { createAutoConnectStatusRepository } from '../utils';

type TokenContextType = {
  token: string | null;
  user: UserProfile | null;
  isUserLoading: boolean;
  connect: (token: string) => Promise<void>;
  disconnect: () => Promise<void>;
};

const TokenContext = React.createContext<TokenContextType>({
  token: null,
  user: null,
  isUserLoading: true,
  connect: () => Promise.resolve(),
  disconnect: () => Promise.resolve(),
});

export const TokenProvider = ({ children }: { children?: React.ReactNode }): JSX.Element => {
  const { disconnectAsync: disconnectWallet } = useDisconnect();
  const [isUserLoading, setIsUserLoading] = useState<boolean>(true);
  const [token, setToken] = useState<string | null>(getJWTFromCookies());
  const { user, setUser } = useStore(userSliceSelector);

  const { writeAutoConnectStatus } = createAutoConnectStatusRepository(getLocalStorage());

  const router = useRouter();

  useEffect(() => {
    if (!token) {
      return setIsUserLoading(false);
    }

    const handleRouteChange = (): void => {
      const jwtToken = getJWTFromCookies();

      if (!jwtToken) {
        setToken(null);
        setUser(null);
      }
    };

    connect(token);

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  const connect = async (newToken: string): Promise<void> => {
    try {
      const playdexClient = createPlaydexClient();
      const { getUserProfile } = createUserProfileService({ playdexClient });

      const user = await getUserProfile();
      setIsUserLoading(false);

      if (user) {
        setToken(newToken);
        setUser(user);

        // saving context of user to crisp
        crispAddUser(user);

        // saving context for sentry
        Sentry.setUser({ username: user.walletAddress || '', id: user.id });
      } else {
        setJWTToCookies(null);
        setToken(null);
        setUser(null);
      }
    } catch (error) {
      Sentry.captureException(error);
      await disconnect();
    }
  };

  const disconnect = async (): Promise<void> => {
    try {
      await disconnectWallet();
      writeAutoConnectStatus(false);

      setJWTToCookies(null);
      setToken(null);
      setUser(null);
      setIsUserLoading(false);

      crispReset();

      // clear the currently set user
      Sentry.configureScope(scope => scope.setUser(null));
    } catch (error) {
      notice(ToastType.ERROR, 'Something went wrong! Please try again!');

      Sentry.captureException(error);
      console.error(error);
    }
  };

  return (
    <TokenContext.Provider
      value={{
        token,
        user,
        isUserLoading,
        connect,
        disconnect,
      }}
    >
      {children}
    </TokenContext.Provider>
  );
};

export const useToken = (): TokenContextType => {
  const { token, user, isUserLoading, connect, disconnect } = useContext(TokenContext);

  return {
    token,
    user,
    connect,
    isUserLoading,
    disconnect,
  };
};
