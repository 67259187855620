/* eslint-disable functional/no-let */
import { BundleRentOut, RentalOrderStates } from '@velocity-studios-co/python-backend-api-client';
import { adjust, compose, join, map, toLower, toUpper } from 'ramda';

const romanMatrix: Array<[number, string]> = [
  [1000, 'M'],
  [900, 'CM'],
  [500, 'D'],
  [400, 'CD'],
  [100, 'C'],
  [90, 'XC'],
  [50, 'L'],
  [40, 'XL'],
  [10, 'X'],
  [9, 'IX'],
  [5, 'V'],
  [4, 'IV'],
  [1, 'I'],
];

// String manipulation
export const capitalize = (s: string): string => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();

// Separators
const EMPTY = '';
const VERTICAL_BAR = '|';
const DASH = '-';
const UNDERSCORE = '_';
const WHITESPACE = ' ';

const SeparatorRe = new RegExp(
  [[DASH, UNDERSCORE, WHITESPACE].join(VERTICAL_BAR), '(?=[A-Z][a-z])'].join(VERTICAL_BAR),
);
const splitWords = (s: string): string[] => s.split(SeparatorRe);

// Case formatters
export const capitalCase = (s: string): string => splitWords(s).map(capitalize).join(WHITESPACE);
export const lowerCase = (s: string): string => splitWords(s).map(toLower).join(WHITESPACE);
export const upperCase = (s: string): string => splitWords(s).map(toUpper).join(WHITESPACE);
export const sentenceCase = compose(capitalize, lowerCase);
export const camelCase = compose(join(EMPTY), adjust(0, toLower), map(capitalize), splitWords);
export const snakeCase = (s: string): string => splitWords(s).map(toLower).join(UNDERSCORE);
export const kebabCase = (s: string): string => splitWords(s).map(toLower).join(DASH);

export const getShortIdView = (fullId: string): string => {
  const fullIdLength = fullId.length;

  if (fullIdLength < 10) {
    return `#${fullId}`;
  }

  return `#${fullId.slice(0, 4)}...${fullId.slice(fullIdLength - 4, fullIdLength)}`;
};

// Shortify ronin address to view: "ronin:ab...cdef"

export const getShortStringView = (str: string, prefixStr?: string): string => {
  const fullAvailableRoninLength = 46;

  const roninAddressOnly = str.slice(str.length === fullAvailableRoninLength ? 6 : 0, str.length);

  const prefixPartOfResultRonin = roninAddressOnly.slice(0, prefixStr ? 2 : 5);
  const posfixPartOfResultRonin = roninAddressOnly.slice(roninAddressOnly.length - 4);

  return `${prefixStr ?? ''}${prefixPartOfResultRonin}...${posfixPartOfResultRonin}`;
};

export const getOrderStatusText = (order: Partial<BundleRentOut>): string => {
  switch (order.state) {
    case RentalOrderStates.ACTIVE:
      return order.isOrderRenewalRequested ? 'Confirmed' : 'Active';
    case RentalOrderStates.FINAL_SNAPSHOT:
      return 'final_snapshot';
    case RentalOrderStates.CANCELLED:
      return 'Cancelled';
    case RentalOrderStates.COMPLETED:
      return 'Completed';
    case RentalOrderStates.CANCEL_REQUESTED:
      return 'Cancel Requested';
    default:
      return 'no status';
  }
};

export function convertToRoman(num: number): string {
  if (num === 0) {
    return '0';
  }
  const [arabicNumber = 0, romanNumber] = romanMatrix.find(([arabicNum]) => num >= arabicNum) || [];

  const diff = num - arabicNumber;
  return romanNumber + (diff > 0 ? convertToRoman(diff) : '');
}

export const truncateTrailingZero = (num: string): string => {
  if (!num.includes('.')) {
    return num;
  }

  return num.replace(/\.0+$/, '');
};
