import classNames from 'classnames';
import { equals, partition, pipe, propOr } from 'ramda';
import { useMemo } from 'react';

import { GameConfig, NftMarketplaceGamesSection } from '@features/common';

import { GamesList, GamesListProps } from './GamesList';
import { SidebarActions } from './SidebarActions';
import { TournamentsPreview, TournamentsPreviewProps } from './TournamentsPreview';

type GamesAndTournamentsSidebarProps = TournamentsPreviewProps &
  Omit<GamesListProps, 'className' | 'style'> & {
    gamesListClassName?: GamesListProps['className'];
    gamesListStyle?: GamesListProps['style'];
    className?: string;
  };

export const GamesAndTournamentsSidebar = ({
  games,
  tournaments,
  onGameClick,
  onTournamentClick,
  selectedGameUid,
  shouldScrollToSelectedGame,
  gamesListClassName,
  gamesListStyle,
  className,
}: GamesAndTournamentsSidebarProps): JSX.Element => {
  const [gamesWithMarketplace, gamesWithoutMarketplace] = useMemo(() => {
    return partition<GameConfig>(pipe(propOr(false, 'hasMarketplace'), equals(true)), games);
  }, [games]);

  return (
    <div className={classNames('h-full overflow-y-auto w-68', className)}>
      <TournamentsPreview tournaments={tournaments} onTournamentClick={onTournamentClick} />

      <NftMarketplaceGamesSection
        games={gamesWithMarketplace}
        selectedGameUid={selectedGameUid}
        onGameClick={onGameClick}
      />

      <GamesList
        games={gamesWithoutMarketplace}
        selectedGameUid={selectedGameUid}
        shouldScrollToSelectedGame={shouldScrollToSelectedGame}
        className={gamesListClassName}
        onGameClick={onGameClick}
        style={gamesListStyle}
      />

      <SidebarActions />
    </div>
  );
};
