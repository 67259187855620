import cookie from 'js-cookie';
import { GetServerSidePropsContext, NextPageContext } from 'next';
import cookies from 'next-cookies';

export const JWT_COOKIES_KEY = 'token';

type GetJWTFromCookies = () => string | null;

export const getJWTFromCookies: GetJWTFromCookies = () => {
  return typeof window !== 'undefined' ? cookie.get(JWT_COOKIES_KEY) || null : null;
};

type SetJWTToCookies = (jwt: string | null, expirationTime?: string) => void;

export const setJWTToCookies: SetJWTToCookies = (jwt, expirationTime) => {
  if (typeof window !== 'undefined') {
    jwt
      ? cookie.set(JWT_COOKIES_KEY, jwt, {
          expires: expirationTime ? new Date(expirationTime) : undefined,
          /**
           * The lines below enable the cookie to be sent to the server in case the the app is embedded in a third-party website.
           */
          secure: true,
          sameSite: 'none',
        })
      : cookie.remove(JWT_COOKIES_KEY);
  }
};

type GetJWTFromPageContext = (ctx: NextPageContext | GetServerSidePropsContext) => string | null;

export const getJWTFromPageContext: GetJWTFromPageContext = ctx => {
  return cookies(ctx)[JWT_COOKIES_KEY] || null;
};
