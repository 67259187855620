import classNames from 'classnames';
import Link from 'next/link';
import React from 'react';

import ElBluredImage from '@elements/ElBluredImage';
import ElModal from '@elements/ElModal';
import { AUTH_SOURCE, ConnectWalletButton } from '@features/auth';
import { useLoginModal } from '@features/auth/hooks/useLoginModal';
import { GradientSpinner, ModalCloseButton } from '@features/common';
import { PlaydexLogoImage } from '@features/header';

type Props = {
  closeModal: () => void;
  onLoginSuccess?: () => Promise<void> | void;
  onLoginFailed?: () => Promise<void> | void;
  source?: AUTH_SOURCE;
};

export const LoginModal = ({ closeModal, onLoginSuccess, onLoginFailed, source }: Props): JSX.Element => {
  const { loginState } = useLoginModal({ onLoginSuccess, onLoginFailed, source });
  return (
    <>
      <ElModal
        onCloseModal={closeModal}
        className={classNames(
          'w-[100vw] md:w-[368px] !border-bgStripe overflow-hidden text-white p-4',
          'h-[100vh] md:h-max !flex !flex-col !justify-start !items-start',
          'relative',
        )}
        modalWrapperStyles="!p-0"
      >
        <header className="flex w-full justify-end mb-[calc(50vh-250px)] md:mb-0">
          <div className="md:hidden mr-auto">
            <PlaydexLogoImage />
          </div>
          <ModalCloseButton onClick={closeModal} />
        </header>
        <main className="w-full flex flex-col gap-4 p-2">
          <section className="flex flex-col items-center">
            <ElBluredImage src="/playdex_single_logo.svg" blur="xl" className="mb-2" />
            <h2 className="text-labelPrimary font-middle text-xl md:text-base">Welcome to Playdex</h2>
          </section>
          <section className="flex flex-col justify-center gap-3">
            <ConnectWalletButton />
          </section>
          <p className="text-[#989db3] text-xs text-center">
            By continuing, you agree to our&nbsp;
            <Link href="/static/tos.html" passHref className="text-[#849FFF] hover:underline" target="_blank">
              Terms of Service
            </Link>
          </p>
        </main>
        {loginState.isPending() && (
          <div className="absolute inset-0 flex items-center justify-center bg-black/70">
            <GradientSpinner />
          </div>
        )}
      </ElModal>
    </>
  );
};
