import classNames from 'classnames';

import { PlaydexLogoImage } from '@features/header';
import { ReactComponent as IconChevronDown } from '@public/icons/custom/chevron-down.svg';

import { ModalCloseButton } from '../ModalCloseButton';

type Props = {
  onCloseModal: VoidFunction;
  onBackClick?: VoidFunction;
};

export const ModalHeader = ({ onCloseModal, onBackClick }: Props): JSX.Element => {
  return (
    <header className="w-full flex justify-between items-center px-2 pt-2 pb-1">
      <div className="xs:hidden">
        <PlaydexLogoImage />
      </div>

      {onBackClick ? (
        <div
          className={classNames(
            'ml-3 w-6 h-6 rotate-[90deg] hidden xs:block',
            'cursor-pointer text-labelSecondary hover:text-labelPrimary',
          )}
          onClick={onBackClick}
        >
          <IconChevronDown />
        </div>
      ) : null}

      <div className="flex-1 flex justify-end">
        <div className="w-7 h-7 flex justify-center items-center">
          <ModalCloseButton onClick={onCloseModal} />
        </div>
      </div>
    </header>
  );
};
