import { LeaderboardQueryResult, TournamentFragmentFragment as TournamentFragment } from '@graphql/generated';

import { RewardClaimSection } from './RewardClaimSection';
import { RewardMilestones } from './RewardMilestones';
import { Leaderboard } from '../types';
import { isLeaderboardExceptionType } from '../utils';

type Props = {
  className?: string;
  tournament: TournamentFragment;
  leaderboardQueryResult: LeaderboardQueryResult;
};

export const RewardMilestonesSection = ({
  className,
  tournament,
  leaderboardQueryResult,
}: Props): JSX.Element | null => {
  const { data, loading, error } = leaderboardQueryResult;

  const leaderboard = data?.leaderboard as Leaderboard | null;

  if (loading || !leaderboard?.rewardStructure) {
    return null;
  }

  if (error || isLeaderboardExceptionType(data?.leaderboard)) {
    return (
      <p className="rounded-lg p-4 my-2 bg-bgSecondary text-sm text-center text-labelSecondary">
        Sorry, there seems to be a problem loading the reward data. We&apos;re working on fixing it as soon as possible.
        <br />
        <br />
        In the meantime, please try again later.
      </p>
    );
  }

  return (
    <RewardMilestones
      className={className}
      tournament={tournament}
      rewardStructure={leaderboard.rewardStructure}
      participantCount={leaderboard.ranking.rows.length}
      RewardClaimSection={props => <RewardClaimSection tournament={tournament} {...props} />}
    />
  );
};
