import classNames from 'classnames';
import React from 'react';
import { MdCancel } from 'react-icons/md';
import { RiCheckboxCircleFill } from 'react-icons/ri';

import ElButton from '@elements/ElButton';
import Loader from '@modules/Loader/Loader';

import { useOrderFormModalContext } from './OrderFormModalContext';

export const SuccessModalContent = (): JSX.Element => {
  const { closeModalAfterSuccess } = useOrderFormModalContext();
  return (
    <div className="flex flex-col items-center justify-center cursor-pointer" onClick={closeModalAfterSuccess}>
      <RiCheckboxCircleFill className="w-[100px] h-[100px] text-lime-500 mb-4" />
      <h2 className="text-3xl font-black mb-6">Rented Successfully!</h2>
      <h4 className="text-gray-500">Click anywhere to proceed</h4>
    </div>
  );
};

export const FailedModalContent = (): JSX.Element => {
  const { closeModal, onTryRentAgain } = useOrderFormModalContext();
  return (
    <div className="flex flex-col items-center justify-center">
      <MdCancel className="w-[100px] h-[100px] text-red-500 mb-4" />
      <h2 className="text-4xl text-center font-black mb-10">Something went wrong...</h2>
      <footer className="flex w-full justify-between">
        <ElButton onClick={closeModal} className="border-gray-600 px-6">
          Cancel
        </ElButton>
        <ElButton
          variant="solid-primary"
          className={classNames(
            'py-4 px-6 font-bold border-none flex justify-center',
            'hover:scale-[98%] transition ease-in-out duration-[300ms]',
            'bg-gradient-to-r from-sky-500 to-blue-600 text-white',
            'disabled:from-gray-700 disabled:to-gray-800 disabled:text-gray-400',
          )}
          onClick={onTryRentAgain}
        >
          Try Again
        </ElButton>
      </footer>
    </div>
  );
};

export const PendingModalContent = (): JSX.Element => (
  <div className="min-h-[350px] relative">
    <header className="text-center text-white">
      <h3 className="text-2xl font-black mb-2">Rent Ongoing</h3>
      <h4 className="text-lg text-gray-500 font-semibold">This might take a few minutes, please sit tight!</h4>
    </header>
    <main>
      <Loader />
    </main>
  </div>
);
