import { ErrorBoundary } from '@sentry/nextjs';
import cn from 'classnames';
import React, { Suspense, useState, useTransition } from 'react';

import { ErrorFallback } from '@components/modules/ErrorFallback';
import Loader from '@components/modules/Loader/Loader';
import withSafeHydration from '@features/common/hocs/withSafeHydration';
import { isNftAssetType } from '@features/gamehub/utils';
import { marketplaceSliceActionsSelector } from '@features/marketplace';
import { NftAssetsFilterInput } from '@graphql/generated';
import { gameSliceSelector } from '@store/slices';
import { useStore } from '@store/zustand';

import { GameAssetsGrid } from './GameAssetGrid';
import { GameAssetGridQueryItem, GameAssetGridSliceItem } from './GameAssetGridItem';
import { gameNftAssetsPageSize } from '../../constants';
import { NftAssetResourceFactory } from '../../types';

const InnerLoader = (): JSX.Element => {
  return (
    <div className="relative aspect-video mx-auto">
      <Loader />
    </div>
  );
};

const GameAssetsLocal = ({
  createGameAssetsResource,
}: {
  createGameAssetsResource: NftAssetResourceFactory;
}): JSX.Element => {
  const [isPending, startTransition] = useTransition();

  const [page, setPage] = useState(1);

  const handlePageChange = (_limit: number, offset: number, filter?: NftAssetsFilterInput): void => {
    setPage(Math.ceil(offset / _limit));

    return startTransition(() => {
      setResource(createGameAssetsResource(offset, filter));
    });
  };

  const { setSelectedOffer } = useStore(marketplaceSliceActionsSelector);

  const [resource, setResource] = useState(() => createGameAssetsResource(0));
  const { currentGameSettings } = useStore(gameSliceSelector);

  /** @todo: Consider multiple smart contracts per game */
  return (
    <ErrorBoundary fallback={ErrorFallback}>
      <Suspense fallback={<InnerLoader />}>
        {isPending && <Loader />}
        <div className={cn({ 'opacity-50': isPending })}>
          <GameAssetsGrid
            resource={resource}
            pageSize={gameNftAssetsPageSize}
            onPageChange={handlePageChange}
            page={page}
          >
            {item => {
              return isNftAssetType(item) ? (
                <GameAssetGridQueryItem
                  key={item.id}
                  settings={currentGameSettings}
                  item={item}
                  onButtonClick={setSelectedOffer}
                />
              ) : (
                <GameAssetGridSliceItem key={item.asset.id} item={item} />
              );
            }}
          </GameAssetsGrid>
        </div>
      </Suspense>
    </ErrorBoundary>
  );
};

export const GameAssets = withSafeHydration(GameAssetsLocal);
