import cn from 'classnames';

const newLabelGradientStyle = 'bg-gradient-to-r from-[#5CC2D6] via-[#5686E7] to-[#8066FF]';
const soonLabelGradientStyle = 'bg-gradient-to-r from-[#7979EC] via-[#8C79EC] to-[#9F79EC]';

export const GameSidebarStatusLabel = ({
  children,
  sharedGradientClass,
}: React.PropsWithChildren<{
  sharedGradientClass: string;
}>): JSX.Element => {
  return (
    <div className={cn(sharedGradientClass, 'rounded-[29px] p-px')}>
      <div className="bg-bgPrimary rounded-[29px] px-1.5 flex items-center">
        <span className={cn(sharedGradientClass, 'text-transparent bg-clip-text text-xxs !leading-4')}>{children}</span>
      </div>
    </div>
  );
};
export const GameSidebarNewStatusLabel = (): JSX.Element => {
  return <GameSidebarStatusLabel sharedGradientClass={newLabelGradientStyle}>New</GameSidebarStatusLabel>;
};
export const GameSidebarSoonStatusLabel = (): JSX.Element => {
  return <GameSidebarStatusLabel sharedGradientClass={soonLabelGradientStyle}>Soon</GameSidebarStatusLabel>;
};
