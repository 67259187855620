import * as prismic from '@prismicio/client';
import { uniq } from 'ramda';

import { GameConfig } from '@features/common';
import { GameDocument } from '@globalTypes/prismic/documents';

import { formatPrismicGameToGameConfig } from './utils';
import { baseSidebarGamesQueryParams, sidebarGamesWithMarketplaceQueryParams } from '../constants';

export const fetchSidebarGames = async ({ client }: { client: prismic.Client }): Promise<GameConfig[]> => {
  const gamehubGamesPromise = await client.getByType<GameDocument>('game', {
    ...baseSidebarGamesQueryParams,
    page: 1,
    pageSize: 100,
  });

  const gamehubGamesWithMarketplacePromise = await client.getByType<GameDocument>('game', {
    ...sidebarGamesWithMarketplaceQueryParams,
    page: 1,
    pageSize: 100,
  });

  const [gamehubGames, gamehubGamesWithMarketplace] = await Promise.all([
    gamehubGamesPromise,
    gamehubGamesWithMarketplacePromise,
  ]);

  // We group games with marketplace and without in single list to have opportunity to safety separate them
  // on two relevant lists in sidebar. Uniq function is required here because there might be the same games
  // from baseSidebarGamesQueryParams and sidebarGamesWithMarketplaceQueryParams.

  const gameList = uniq([...gamehubGames.results, ...gamehubGamesWithMarketplace.results]);

  return gameList.map(formatPrismicGameToGameConfig);
};
