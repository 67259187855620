import { JsonSchema, RefSchema } from '@features/common';
import { sentenceCase } from '@utils/strings';

/**
 * Replaces internal field names with user-friendly field titles
 * in extra field values object, drops 'extraFieldsSchema',
 * return array of tuples
 */
export const formatExtraFieldValues = (
  values: Record<string, string>,
  extraFields: Record<string, JsonSchema | RefSchema>,
): [string, string][] => {
  return Object.entries(values).reduce((result, [field, value]) => {
    if (field === 'extraFieldsSchema') {
      return result;
    }

    return [...result, [extraFields[field]?.title ?? sentenceCase(field), value]];
  }, [] as [string, string][]);
};
