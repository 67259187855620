import { NftMeta } from '@gql/types';
import { NftAssetType } from '@graphql/generated';

type Input = Pick<NftAssetType, 'tokenId' | 'properties'>;

export const guardNftAssetMeta = (item: Input): NftMeta => {
  const { name, image, attributes, description, external_url } = item.properties as Partial<NftMeta>;

  return {
    name: name ?? `#${item.tokenId}`,
    image: image ?? '/nft_asset_image_placeholder.svg',
    attributes: attributes ?? [],
    description: description ?? '',
    external_url: external_url ?? '',
  };
};
