import { AUTH_SOURCE } from '@features/auth';

export enum AuthModalType {
  WALLET = 'WALLET_MODAL',
  DISCORD = 'DISCORD_MODAL',
  VERIFY_PHONE = 'VERIFY_PHONE',
}

export type AuthModalData =
  | {
      type: AuthModalType.WALLET;
      payload?: {
        onLoginSuccess?: () => void | Promise<void>;
        onLoginFailed?: () => void | Promise<void>;
        source?: AUTH_SOURCE;
      };
    }
  | {
      type: AuthModalType.DISCORD;
    }
  | {
      type: AuthModalType.VERIFY_PHONE;
    };

export type RequireAuth = (params?: {
  includePhoneVerification?: boolean;
  includeDiscordVerification?: boolean;
  onLoginSuccess?: () => void | Promise<void>;
  onLoginFailed?: () => void | Promise<void>;
  source?: AUTH_SOURCE;
}) => boolean;

export type AuthModalContextType = {
  requireAuth: RequireAuth;
  setExactAuthModal: (authModalType: AuthModalData) => void;
  closeAuthModal: () => void;
  authModal: null | JSX.Element;
  isAuthCompleted: boolean;
};
