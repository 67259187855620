import { ApolloClient } from '@apollo/client';
import * as prismic from '@prismicio/client';

import { GameDocument } from '@globalTypes/prismic/documents';
import {
  ApplicationName,
  TournamentsPreviewDocument,
  TournamentsPreviewQuery,
  TournamentsPreviewQueryVariables,
} from '@graphql/generated';

export type TournamentsPreviewResponse = {
  id: string;
  applicationName?: ApplicationName | null;
  gameUid?: string | null;
  title: string;
  slug: string;
  imageUrl: string;
  isVisible: boolean;
  registrationEndDate: string;
  tournamentStartDate: string;
  tournamentFinishDate?: string | null;
  gameLogo?: string | null;
}[];

export type FetchTournamentsPreview = (params: {
  prismicClient: prismic.Client;
  playdexClient: ApolloClient<unknown>;
}) => Promise<TournamentsPreviewResponse>;

export const fetchTournamentsPreview: FetchTournamentsPreview = async ({ prismicClient, playdexClient }) => {
  const {
    data: { tournamentsPreview },
  } = await playdexClient.query<TournamentsPreviewQuery, TournamentsPreviewQueryVariables>({
    query: TournamentsPreviewDocument,
  });

  const previewTournaments = tournamentsPreview.items ?? [];

  const promises = previewTournaments.map(async tournament => {
    const initialTournamentsPreview = {
      ...tournament,
      gameLogo: null,
    };

    const gameUid: string | undefined = tournament.gameUid || undefined;

    if (!gameUid) {
      return initialTournamentsPreview;
    }

    try {
      const game = await prismicClient.getByUID<GameDocument>('game', gameUid);

      return {
        ...initialTournamentsPreview,
        gameLogo: game.data?.logo?.url || null,
      };
    } catch (error) {
      console.error({ error, gameUid });
      return initialTournamentsPreview;
    }
  });

  return Promise.all(promises);
};
