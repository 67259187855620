import classNames from 'classnames';
import React from 'react';

import { GameConfig, GamesListItem } from '@features/common';

type NftMarketplaceGamesProps = {
  games: Array<GameConfig>;
  onGameClick?: (game: GameConfig) => void;
  selectedGameUid?: string;
};

export const NftMarketplaceGamesSection = ({
  games,
  onGameClick,
  selectedGameUid,
}: NftMarketplaceGamesProps): JSX.Element | null => {
  const gamesWithNftMarketplace = games.filter(({ hasMarketplace }) => hasMarketplace);

  if (gamesWithNftMarketplace.length === 0) {
    return null;
  }

  return (
    <div className={classNames('px-2')}>
      <div className="px-3 pt-8 pb-2.5 items-center flex">
        <span className="text-labelSecondary text-sm font-medium flex-1">NFT Rental Marketplace</span>
      </div>

      {gamesWithNftMarketplace.map(game => (
        <GamesListItem
          key={game.id}
          game={game}
          isActive={game.id === selectedGameUid}
          showSidebarStatus={true}
          onClick={() => onGameClick?.(game)}
        />
      ))}
    </div>
  );
};
