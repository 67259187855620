import { EmbedField } from '@prismicio/types';
import cn from 'classnames';
import { useMemo, useState } from 'react';

import { getYoutubeVideoId } from '@slices/MediaGallery/utils';

const VideoEmbedCard = ({ embed }: { embed: EmbedField }): JSX.Element => {
  const [isEmbedShown, setIsEmbedShown] = useState(false);

  const youtubeVideoId = useMemo(() => (embed.embed_url ? getYoutubeVideoId(embed.embed_url) : null), [embed]);

  return (
    <div className="relative mb-2 max-w-full pb-[75%] h-0 overflow-hidden">
      <div
        className={cn('absolute inset-0 flex flex-col', !isEmbedShown && 'cursor-pointer')}
        onClick={() => setIsEmbedShown(true)}
      >
        <div className="relative flex w-full flex-1 rounded-md overflow-hidden">
          {isEmbedShown ? (
            <iframe
              className="absolute inset-0 h-full w-full rounded-md"
              src={`https://www.youtube.com/embed/${youtubeVideoId}`}
              frameBorder="0"
              allow="fullscreen;"
            />
          ) : (
            <div
              className="w-full bg-cover bg-center flex-1"
              style={{
                backgroundImage: `url(${embed.thumbnail_url as string})`,
              }}
              title={embed.title as string}
            />
          )}
        </div>

        <div>
          <div className="mt-2 font-semibold text-[12px] leading-[12px]">{embed?.author_name}</div>
          <div className="mt-1 font-semibold text-[14px] leading-[17px]">{embed?.title}</div>
        </div>
      </div>
    </div>
  );
};

export default VideoEmbedCard;
