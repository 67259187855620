import { compose, filter, omit, toPairs } from 'ramda';

import { FieldTuple, JsonSchemaProperties, isSchemaObject, JsonSchema } from '@features/common';

const omitSmartContractName = omit(['smartContractName']);

export const mapToFields: (properties: JsonSchemaProperties) => Array<FieldTuple<JsonSchema>> = compose(
  toPairs<JsonSchema>,
  filter(isSchemaObject),
  omitSmartContractName,
);
