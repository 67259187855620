import { ApolloClient, createHttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { GetServerSidePropsContext } from 'next';
import getConfig from 'next/config';

import { getJWTFromCookies, getJWTFromPageContext } from '@utils/cookies';

const { publicRuntimeConfig } = getConfig();
const { AXIE_INFINITY_GRAPHQL_URL, GRAPHQL_URL } = publicRuntimeConfig;

const axieInfinityClient = new ApolloClient({
  uri: AXIE_INFINITY_GRAPHQL_URL,
  cache: new InMemoryCache(),
});

const playdexClientLink = createHttpLink({
  uri: GRAPHQL_URL,
});

const getToken = (ctx?: GetServerSidePropsContext): string | null => {
  return ctx ? getJWTFromPageContext(ctx) : getJWTFromCookies();
};

const createPlaydexClient = (ctx?: GetServerSidePropsContext): ApolloClient<NormalizedCacheObject> => {
  const accessToken = getToken(ctx);

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: accessToken ? `Bearer ${accessToken}` : '',
      },
    };
  });

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    cache: new InMemoryCache(),
    link: authLink.concat(playdexClientLink),
    credentials: 'same-origin',
  });
};

const playdexClient = createPlaydexClient();

export { createPlaydexClient, playdexClient, axieInfinityClient };
