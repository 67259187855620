type Props = {
  tokens: string[];
};

export const RewardTokens = ({ tokens }: Props): JSX.Element => {
  if (tokens.length === 1) {
    return <span className="text-labelPrimary">{tokens}!</span>;
  }

  return (
    <ul className="text-labelPrimary">
      {tokens.map((token, index) => (
        <li key={index}>- {token}</li>
      ))}
    </ul>
  );
};
