import cn from 'classnames';
import React, { forwardRef } from 'react';

type Props = React.ComponentPropsWithRef<'input'> &
  React.PropsWithChildren<{
    inputClassName?: string;
    type: 'radio' | 'checkbox';
  }>;

export const FilterInputVariant = forwardRef<HTMLInputElement, Props>(
  ({ className, children, inputClassName, ...restProps }: Props, ref): JSX.Element => (
    <label className={className}>
      <input
        className={cn(
          restProps.type === 'checkbox' && 'rounded',
          'leading-7 w-7 h-7',
          'text-yellow-400 bg-white/10 border-white/40',
          'focus:ring-0 focus:ring-offset-0',
          inputClassName,
        )}
        {...restProps}
        ref={ref}
      />
      {children}
    </label>
  ),
);

// eslint-disable-next-line functional/immutable-data
FilterInputVariant.displayName = FilterInputVariant.name;
