import cn from 'classnames';
import React, { forwardRef } from 'react';

type Props = React.ComponentPropsWithRef<'button'>;

export const FilterButton = forwardRef<HTMLButtonElement, Props>(
  ({ children, className, ...restProps }, ref): JSX.Element => (
    <button
      className={cn('text-center font-bold p-3 leading-5 text-sm', 'border border-neutral-700 rounded-lg', className)}
      {...restProps}
      ref={ref}
    >
      {children}
    </button>
  ),
);

// eslint-disable-next-line functional/immutable-data
FilterButton.displayName = FilterButton.name;
