import cn from 'classnames';
import { Dispatch, SetStateAction } from 'react';

import ElModal from '@components/elements/ElModal';
import { Button, Checkbox, GradientSpinner, Input, ModalHeader } from '@features/common';
import { TournamentFragmentFragment as TournamentFragment } from '@graphql/generated';
import { ReactComponent as InfoIcon } from '@public/icons/custom/info-circle.svg';

import { ReactComponent as TrophyIcon } from '../icons/trophy.svg';

type Props = {
  tokens: string[] | null;
  tournament: TournamentFragment;
  isClaiming?: boolean;
  isReceiveToAnotherAddressChecked: boolean;
  setIsReceiveToAnotherAddressChecked: Dispatch<SetStateAction<boolean>>;
  isRecipientAddressCorrect: boolean;
  recipientWalletAddress: string;
  setRecipientWalletAddress: Dispatch<SetStateAction<string>>;
  onClose: () => void;
  onClaim: (customRecipientAddress?: string) => void;
};

export const ClaimModal = ({
  tournament,
  tokens,
  isClaiming,
  recipientWalletAddress,
  setRecipientWalletAddress,
  isReceiveToAnotherAddressChecked,
  setIsReceiveToAnotherAddressChecked,
  isRecipientAddressCorrect,
  onClaim,
  onClose,
}: Props): JSX.Element => {
  return (
    <ElModal className={cn('relative w-screen h-screen xs:max-w-100 xs:h-fit')} onCloseModal={onClose}>
      <div className="relative overflow-hidden">
        <ModalHeader onCloseModal={onClose} />
        <TrophyIcon className="absolute inset-0 -top-6 pointer-events-none" />
        <div className="text-center uppercase text-xs text-labelSecondary tracking-wide">{tournament.title}</div>
        <div className="mt-1 text-center text-primary font-medium text-base">Claim your prize</div>
        <div className="mt-36">
          {tokens?.map(token => (
            <div className="text-center font-medium text-2xl" key={token}>
              {token}
            </div>
          ))}
        </div>
        <div className="mx-6 my-6 select-none">
          <Checkbox
            className="cursor-pointer"
            checked={isReceiveToAnotherAddressChecked}
            onChange={() => setIsReceiveToAnotherAddressChecked(v => !v)}
          >
            Receive to another wallet address
          </Checkbox>

          {isReceiveToAnotherAddressChecked && (
            <Input
              autoFocus={true}
              className="w-full mt-4"
              label="Recipient wallet address"
              placeholder="0xD3caf...c0ff33"
              value={recipientWalletAddress}
              onChange={e => setRecipientWalletAddress(e.target.value)}
            />
          )}

          <div className="flex gap-2 my-3 p-3 rounded-lg bg-bgSecondary">
            <InfoIcon className="h-6 w-6 text-link shrink-0" />
            <div className="text-labelSecondary text-sm">
              Please note that once you click &quot;Claim prize&quot; and sign the transaction, it will not be possible
              to cancel it or change recipient wallet address.
            </div>
          </div>

          <Button
            className="w-full mt-4"
            disabled={!isRecipientAddressCorrect}
            onClick={() => onClaim(isReceiveToAnotherAddressChecked ? recipientWalletAddress : undefined)}
          >
            {isClaiming ? <GradientSpinner /> : <>Claim prize</>}
          </Button>
        </div>
      </div>
    </ElModal>
  );
};
