import { ReactComponent as NoItemsIcon } from '@public/landing/empty_state_search.svg';

import { NotificationItem } from './NotificationItem';
import { INotification } from '../types';

export const SomethingWentWrongWithItemsBlock = (): JSX.Element => (
  <div className="h-full w-full flex flex-col items-center justify-center px-4">
    <NoItemsIcon className="w-[272px] h-[171px]" />
    <div className="text-sm flex flex-col items-center gap-2">
      <span className="text-labelPrimary font-medium">Something went wrong, please try to reload page!</span>
    </div>
  </div>
);
export const NoItemsBlock = (): JSX.Element => (
  <div className="h-full w-full flex flex-col items-center justify-center px-4">
    <NoItemsIcon className="w-[272px] h-[171px] mx-auto" />
    <div className="text-sm flex flex-col items-center gap-2">
      <span className="text-labelPrimary font-medium">No new notifications at the moment</span>
      <span className="text-labelSecondary text-center">
        It will contain notifications about new announcements, tournaments, security, and the marketplace
      </span>
    </div>
  </div>
);

export const NotificationListDropdown = ({
  items,
  closeDropdown,
}: {
  items: Array<INotification>;
  closeDropdown: () => void;
}): JSX.Element => {
  return (
    <ul className="flex flex-col h-full md:h-[398px] overflow-auto">
      {items.length > 0 ? (
        items.map(item => <NotificationItem key={item.id} item={item} closeDropdown={closeDropdown} />)
      ) : (
        <NoItemsBlock />
      )}
    </ul>
  );
};
