import React from 'react';

import { BLOG_ROUTE, GAMES_PAGE_PARENT_ROUTE, TOURNAMENTS_ROUTE } from '@constants/routes';

export type StaticLinksConfig = {
  label: string;
  route?: string;
  action?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void | Promise<void>;
  selectOptions?: Array<StaticLinksConfig>;
  target?: React.HTMLAttributeAnchorTarget;
};

export const useStaticLinks = (): Array<StaticLinksConfig> => {
  const staticLinks: Array<StaticLinksConfig | boolean> = [
    {
      label: 'Games',
      route: GAMES_PAGE_PARENT_ROUTE,
    },
    {
      label: 'Tournaments',
      route: TOURNAMENTS_ROUTE,
    },
    {
      label: 'Blog',
      route: BLOG_ROUTE,
    },
  ];

  return staticLinks.filter(link => !!link) as Array<StaticLinksConfig>;
};
