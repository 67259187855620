import { CurrencyToken } from '../types';

type Props = {
  token: CurrencyToken;
  iconClassName?: string;
  labelClassName?: string;
};

export const CurrencyLabel = ({ token: { Icon, label }, iconClassName, labelClassName }: Props): JSX.Element => {
  return (
    <>
      {Icon ? <Icon className={iconClassName} /> : null}
      <span className={labelClassName}>{label}</span>
    </>
  );
};
