import { Transition } from '@headlessui/react';
import classNames from 'classnames';
import { ethers } from 'ethers';
import React from 'react';
import { match } from 'ts-pattern';
import { mainnet, useAccount, useEnsName } from 'wagmi';

import { fastFadeTransitionClassNames, ModalCloseButton } from '@features/common';
import { useClickOutsideElement } from '@features/common';
import { AccountBalanceLabel, PlaydexLogoImage, AccountCurrencyList } from '@features/header';
import { useBoolean } from '@hooks/useBoolean';
import { ReactComponent as DefaultWalletIcon } from '@public/icons/custom/wallet.svg';
import { ReactComponent as MetamaskWalletIcon } from '@public/icons/metamask_wallet_logo.svg';
import { UserProfile } from '@services/userProfileService/types';
import { getShortStringView } from '@utils/strings';

const getWalletIcon = (
  connectorName: 'MetaMask' | string | undefined,
): ((props: React.SVGProps<SVGSVGElement>) => JSX.Element) => {
  return match(connectorName)
    .with('MetaMask', () => MetamaskWalletIcon)
    .otherwise(() => DefaultWalletIcon);
};

export const MyAccountWalletDropdownSection = ({ user }: { user: UserProfile }): JSX.Element => {
  const { connector } = useAccount();
  const [isDropdownMenuOpen, , closeDropdown, toggleDropdown] = useBoolean(false);

  const containerRef = useClickOutsideElement<HTMLDivElement>(closeDropdown);

  const { data: ensName } = useEnsName({
    address: ethers.utils.getAddress(user.walletAddress ?? ''),
    chainId: mainnet.id,
    enabled: Boolean(user.walletAddress),
    cacheTime: Number.MAX_VALUE,
  });

  const WalletIcon = getWalletIcon(connector?.name);

  return (
    <div className="relative" ref={containerRef}>
      <div
        className={classNames(
          'flex h-9 p-1.5 md:p-2 md:pr-1 items-center rounded-md gap-1 md:gap-2',
          'cursor-pointer hover:bg-dropdownButtonSecondary',
          isDropdownMenuOpen && 'bg-dropdownButtonSecondary',
        )}
        onClick={toggleDropdown}
      >
        <WalletIcon className="w-6 h-6 text-labelTertiary" />
        <div className="hidden md:flex gap-1 items-center">
          <div className="text-xs md:text-sm">{ensName ?? getShortStringView(user.walletAddress as string)}</div>
          <AccountBalanceLabel />
        </div>
      </div>
      {isDropdownMenuOpen && (
        <div
          className={classNames(
            'fixed md:absolute inset-0 md:inset-auto w-screen h-screen z-50',
            'md:w-max md:h-min md:top-12 md:right-0 md:z-1',
          )}
        >
          <Transition className="h-full" show={true} appear={true} {...fastFadeTransitionClassNames}>
            <div
              className={classNames(
                'h-full md:w-[280px] bg-bgPrimary md:bg-dropdownMenuBg',
                'md:border-dropdownBorder md:border-1 md:rounded-md',
              )}
            >
              <header
                className={classNames(
                  'flex md:hidden justify-between md:justify-end items-center h-[71px]',
                  'px-4 py-0 md:px-3 md:py-3',
                )}
              >
                <div className="block md:hidden">
                  <PlaydexLogoImage />
                </div>
                <ModalCloseButton className="m-3" onClick={closeDropdown} />
              </header>
              <div className="md:hidden border-t-1 border-t-bgStripe mb-4" />
              <main className="px-4 py-0 md:px-3 md:py-3">
                <AccountCurrencyList />
              </main>
            </div>
          </Transition>
        </div>
      )}
    </div>
  );
};
