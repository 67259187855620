import { match } from 'ts-pattern';
import { bsc, bscTestnet, hardhat, mainnet, polygon } from 'wagmi/chains';

import { bscTokens, bscTestnetTokens, hardhatTokens, mainnetTokens, polygonTokens } from '../constants';
import { BaseCurrencyTokenList } from '../types';

export const getCurrencyTokenListByChainId = (chainId: number): BaseCurrencyTokenList =>
  match(chainId)
    .with(mainnet.id, () => mainnetTokens)
    .with(polygon.id, () => polygonTokens)
    .with(bscTestnet.id, () => bscTestnetTokens)
    .with(bsc.id, () => bscTokens)
    .with(hardhat.id, () => hardhatTokens)
    .otherwise(() => []);
