import { StandardMerkleTree } from '@openzeppelin/merkle-tree';
import { BigNumber } from 'ethers';
import { isNil } from 'ramda';

import { HexString } from '@globalTypes/contracts/metamask';

import { RewardsMerkleTreeData } from './types';

export const getClaimableReward = (
  walletAddress: HexString | undefined,
  tree: RewardsMerkleTreeData,
): {
  rewardHash?: string;
  amounts?: string[];
  proof?: string[];
} => {
  if (!walletAddress || !tree) {
    return {};
  }

  const reward = tree.values.find(({ value }) => value[0].toLowerCase() === walletAddress.toLowerCase());

  if (!reward) {
    return {};
  }

  const treeInstance = StandardMerkleTree.load(tree);

  return {
    amounts: reward.value[1],
    rewardHash: treeInstance.leafHash(reward.value),
    proof: treeInstance.getProof(reward.value),
  };
};

export const formatClaimArgs = (
  eventId?: string,
  amounts?: string[],
  walletAddress?: string,
  proof?: string[],
): [string, BigNumber[], HexString, HexString[]] | undefined => {
  const isDefined = [eventId, amounts, walletAddress, proof].every(v => !isNil(v));

  if (!isDefined) {
    return;
  }

  return [
    eventId as string,
    amounts!.map(amount => BigNumber.from(amount)),
    walletAddress as HexString,
    proof as HexString[],
  ];
};
