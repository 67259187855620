import { ComponentType, DetailedHTMLProps, ReactEventHandler, useMemo, useState } from 'react';

type ImageWIthFallbackProps = Omit<
  DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
  'onError'
> & {
  fallbackSrc: string;
  Fallback?: ComponentType;
};

export const ImageWithFallback = ({
  src,
  fallbackSrc,
  Fallback = () => null,
  ...rest
}: ImageWIthFallbackProps): JSX.Element => {
  const [shouldRenderFallback, setShouldRenderFallback] = useState(!src);
  const onError = useMemo<ReactEventHandler<HTMLImageElement> | undefined>(() => {
    if (shouldRenderFallback) {
      return;
    }

    return () => setShouldRenderFallback(true);
  }, [shouldRenderFallback]);

  return (
    <>
      <img {...rest} alt={rest.alt} src={shouldRenderFallback ? fallbackSrc : src} onError={onError} />
      {shouldRenderFallback && <Fallback />}
    </>
  );
};
