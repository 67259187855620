import React, { useContext } from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';

import { IconButton } from '@features/common';
import { ReactComponent as IconArrowLeft } from '@public/icons/arrow-left.svg';
import { ReactComponent as IconArrowRight } from '@public/icons/arrow-right.svg';

type ItemType = React.ReactElement<{
  /**
   Required. id for every item, should be unique
   */
  itemId: string;
}>;

type HorizontalScrollMenuProps = {
  itemClassName: string;
  header?: string;
  children: ItemType | ItemType[];
};

export const HorizontalScrollMenu = ({ children, itemClassName, header }: HorizontalScrollMenuProps): JSX.Element => {
  return (
    <ScrollMenu
      itemClassName={itemClassName}
      scrollContainerClassName="scrollbar-none"
      Header={
        <div className="flex items-center pb-4">
          <div className="flex-1 text-lg font-medium">{header ?? ''}</div>

          <LeftArrow />
          <div className="pl-2" />
          <RightArrow />
        </div>
      }
    >
      {children}
    </ScrollMenu>
  );
};

const LeftArrow = (): JSX.Element => {
  const { isFirstItemVisible, scrollPrev } = useContext(VisibilityContext);

  return (
    <IconButton
      disabled={isFirstItemVisible}
      onClick={() => {
        if (isFirstItemVisible) {
          return;
        }

        scrollPrev();
      }}
    >
      <div className="w-6 h-6">
        <IconArrowLeft />
      </div>
    </IconButton>
  );
};

const RightArrow = (): JSX.Element => {
  const { isLastItemVisible, scrollNext } = useContext(VisibilityContext);

  return (
    <IconButton
      disabled={isLastItemVisible}
      onClick={() => {
        if (isLastItemVisible) {
          return;
        }

        scrollNext();
      }}
    >
      <div className="w-6 h-6">
        <IconArrowRight />
      </div>
    </IconButton>
  );
};
