import { isApolloError as checkIsApolloError } from '@apollo/client';
import * as Sentry from '@sentry/nextjs';
import cn from 'classnames';
import { FieldError, useForm } from 'react-hook-form';
import { useAccount } from 'wagmi';

import ElModal from '@components/elements/ElModal';
import { useToken } from '@features/auth';
import {
  Button,
  FieldTuple,
  Input,
  isSchemaObject,
  JsonSchema,
  ModalCloseButton,
  RefSchema,
  ValidationError,
} from '@features/common';
import { formatConstraintErrors } from '@features/marketplace/components/OrderAssetModal/helpers';
import { HexString } from '@globalTypes/contracts/metamask';
import { TournamentFragmentFragment as TournamentFragment } from '@graphql/generated';
import useAsync from '@hooks/useAsync';
import { HttpErrorCodes } from '@utils/error';
import { sentenceCase } from '@utils/strings';

import { TournamentRegistrationType } from '../constants';
import { useMemo } from 'react';

type Props = {
  tournament: TournamentFragment;
  registrationType: TournamentRegistrationType;
  extraFields: Record<string, JsonSchema | RefSchema>;
  closeModal: () => void;
  onSubmit: (extraFieldValues: Record<string, string>) => Promise<void>;
};

type TeamCodeErrorResponse = {
  message: string;
  name: string;
  status: number;
};

type FormErrorResponse = {
  statusCode: number;
  message: ValidationError<unknown>[];
  error: string;
};

const getErrorText = (error?: FieldError): string | JSX.Element | null => {
  if (!error) {
    return null;
  }

  if (error.types) {
    return (
      <div className="mt-1 text-xs text-error">
        {Object.entries(error.types).map(([name, message]) => (
          <div key={name}>{Array.isArray(message) ? message.join(', ') : message?.toString()}</div>
        ))}
      </div>
    );
  }

  return error.message ?? null;
};

const getFieldEntryByTitle = (
  extraFields: Record<string, JsonSchema | RefSchema>,
  title: string,
): [string, JsonSchema | RefSchema] | undefined => {
  return Object.entries(extraFields).find(([, fieldSchema]) => fieldSchema.title === title);
};

const getDefaultValues = (
  extraFields: Record<string, JsonSchema | RefSchema>,
  {
    walletAddress: address,
    email,
  }: {
    walletAddress?: HexString;
    email?: string | null;
  },
): Record<string, string> => {
  /* eslint-disable functional/immutable-data */
  const defaultValues: Record<string, string> = {};
  const walletFieldEntry = getFieldEntryByTitle(extraFields, 'Wallet');
  const emailFieldEntry = getFieldEntryByTitle(extraFields, 'Email');

  if (walletFieldEntry && address) {
    const [walletAddressKey] = walletFieldEntry;

    defaultValues[walletAddressKey] = address;
  }

  if (emailFieldEntry && email) {
    const [emailAddressKey] = emailFieldEntry;

    defaultValues[emailAddressKey] = email;
  }

  return defaultValues;
  /* eslint-enable functional/immutable-data */
};

export const ExtraFieldsFormModal = ({
  tournament,
  registrationType,
  extraFields,
  closeModal,
  onSubmit,
}: Props): JSX.Element => {
  const { address } = useAccount();
  const { user } = useToken();

  const { register, formState, setError, getValues } = useForm<Record<string, string>>({
    defaultValues: getDefaultValues(extraFields, {
      walletAddress: address,
      email: user?.email,
    }),
    mode: 'onChange',
  });

  const [submitState, submitHandler] = useAsync(async () => {
    try {
      const values = getValues();

      await onSubmit(values);
    } catch (error) {
      const isApolloError = error instanceof Error && checkIsApolloError(error);
      const handleableError = isApolloError && error.graphQLErrors[0]?.extensions;

      if (!handleableError) {
        if (isApolloError) {
          Sentry.captureException(error);
        }

        return;
      }

      const teamCodeError =
        (handleableError?.exception as TeamCodeErrorResponse)?.message === 'No TournamentTeam found';
      const formError = handleableError?.response as FormErrorResponse;

      if (teamCodeError) {
        setError('teamCode', {
          type: 'server',
          message: 'Team not found',
        });
      }

      if (formError?.statusCode === HttpErrorCodes.UnprocessableEntity) {
        formError.message.forEach(modelError => {
          modelError.children?.forEach(termError => {
            setError(termError.property.toString(), {
              type: 'server',
              types: formatConstraintErrors(termError),
            });
          });
        });
      }
    }
  });

  const formFields = Object.entries(extraFields).filter(([fieldName, fieldSchema]) => {
    return fieldName !== 'extraFieldsSchema' && isSchemaObject(fieldSchema);
  }) as FieldTuple<JsonSchema>[];

  const isTeamMember = registrationType === TournamentRegistrationType.Member;

  return (
    <ElModal
      onCloseModal={closeModal}
      className={cn(
        'relative w-[100vw] md:w-[400px] h-full md:h-auto overflow-auto',
        '!justify-start md:mt-8 p-6 pt-10',
        'text-labelPrimary',
      )}
      modalWrapperStyles="md:overflow-auto !items-start"
    >
      <ModalCloseButton className="absolute right-3 top-3" onClick={closeModal} />
      <header className="w-full">
        <h1 className="font-medium text-lg leading-6">Submit your information</h1>
        <p className="mt-2 text-sm leading-5 text-labelSecondary">
          Please provide additional information for {tournament.title}.{' '}
          {isTeamMember && <>You need to get a team code from your team captain</>}
        </p>
      </header>

      <div className="w-full mt-2 mb-6">
        {isTeamMember && (
          <Input
            className="mt-4"
            inputWrapClassName="w-[82px] uppercase"
            label="Team code"
            placeholder="_ _ _ _ _ _"
            maxLength={6}
            isRequired={true}
            isError={Boolean(formState.errors.teamCode)}
            hint={getErrorText(formState.errors.teamCode)}
            autoFocus={true}
            {...register('teamCode', {
              required: 'This is required',
              maxLength: 6,
              minLength: 6,
            })}
          />
        )}
        {formFields.map(([fieldName, fieldSchema], index) => {
          const fieldError = formState.errors[fieldName];
          const isError = Boolean(fieldError);
          const errorText = getErrorText(fieldError);

          const isRequired = fieldSchema.type === 'string' && (fieldSchema.minLength ?? 0) > 0;
          const isWalletField = fieldSchema.title === 'Wallet';
          const isDisabled = isWalletField && Boolean(address);

          return (
            <Input
              key={fieldName}
              className="w-full mt-4"
              autoFocus={index === 0 && registrationType !== TournamentRegistrationType.Member}
              label={fieldSchema.title ?? sentenceCase(fieldName)}
              hint={(errorText ? errorText : fieldSchema.description) ?? ''}
              placeholder={fieldSchema.example}
              isError={isError}
              isRequired={isRequired}
              {...register(fieldName, {
                required: isRequired && 'This is required',
              })}
              disabled={isDisabled}
            />
          );
        })}
      </div>

      <Button
        size="m"
        className="w-full"
        disabled={submitState.isPending() || !formState.isValid}
        onClick={submitHandler}
      >
        Submit
      </Button>
    </ElModal>
  );
};
