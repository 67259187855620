import { useDeferredValue, useEffect, useState } from 'react';

import { RawRowData } from '../../../types';
import { filterRowsBySearchString } from '../utils';

export const useRowsMatchingSearchString = (rows: RawRowData[], searchString: string): RawRowData[] => {
  const [filteredRows, setFilteredRows] = useState(rows);
  const deferredSearchString = useDeferredValue(searchString);

  useEffect(() => {
    filterRowsBySearchString(rows, deferredSearchString).then(setFilteredRows);
  }, [rows, deferredSearchString]);

  return filteredRows;
};
