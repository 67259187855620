import cn from 'classnames';
import { ToastContainer as ToastifyContainer } from 'react-toastify';

import { ModalCloseButton } from '../ModalCloseButton';

export const ToastContainer = (): JSX.Element => (
  <ToastifyContainer
    position="top-right"
    autoClose={3000}
    hideProgressBar={true}
    newestOnTop={false}
    draggable={false}
    closeOnClick
    pauseOnHover
    toastClassName={() =>
      cn(
        'relative flex p-2 m-1 rounded-lg justify-between overflow-hidden cursor-pointer',
        'text-labelPrimary text-sm font-medium bg-bgTertiary border border-bgStripe border-solid',
        'shadow-[0px_4px_40px_8px_rgba(0,0,0,0.13)]',
      )
    }
    bodyClassName="items-start"
    closeButton={<ModalCloseButton className="absolute top-2 right-2 w-4 h-4" />}
  />
);
