import classNames from 'classnames';

import { useCountdown, PulsingLiveStatus } from '@features/tournaments';

type TournamentStatusIndicatorProps = {
  tournamentStartDate: string;
  tournamentFinishDate?: string;
};

const CountdownBadge = ({
  isLive,
  isFinished,
  tournamentFinishCountdown,
  tournamentStartCountdownString,
  tournamentFinishCountdownString,
}: {
  isLive: boolean;
  isFinished: boolean;
  tournamentFinishCountdown: number;
  tournamentStartCountdownString: string;
  tournamentFinishCountdownString: string;
}): JSX.Element => {
  if (isLive) {
    return (
      <span className="text-success" suppressHydrationWarning={true}>
        {tournamentFinishCountdownString && tournamentFinishCountdown > 0 ? tournamentFinishCountdownString : 'Live'}
      </span>
    );
  }

  if (isFinished) {
    return (
      <span className="text-white" suppressHydrationWarning={true}>
        Finished
      </span>
    );
  }

  return (
    <span className="text-warning" suppressHydrationWarning={true}>
      {tournamentStartCountdownString}
    </span>
  );
};

export const TournamentStatusIndicator = ({
  tournamentStartDate,
  tournamentFinishDate,
}: TournamentStatusIndicatorProps): JSX.Element => {
  const { countdown, countdownString: tournamentStartCountdownString } = useCountdown(tournamentStartDate, {
    withSeconds: true,
  });

  const { countdown: tournamentFinishCountdown, countdownString: tournamentFinishCountdownString } = useCountdown(
    tournamentFinishDate,
    {
      withSeconds: true,
    },
  );

  const isFinished = new Date(tournamentFinishDate || '').getTime() < Date.now();
  const isLive = !isFinished && countdown <= 0;

  return (
    <div
      className={classNames(
        'h-6 rounded text-xs flex items-center w-fit',
        {
          'bg-successDarkest': isLive,
          'bg-controlSecondary': !isLive && isFinished,
          'bg-warningDark': !isLive && !isFinished,
        },
        isLive ? 'pr-2 pl-1' : 'px-2',
      )}
      suppressHydrationWarning={true}
    >
      {isLive ? <PulsingLiveStatus className="mr-1" /> : null}
      <CountdownBadge
        isLive={isLive}
        isFinished={isFinished}
        tournamentFinishCountdown={tournamentFinishCountdown}
        tournamentStartCountdownString={tournamentStartCountdownString}
        tournamentFinishCountdownString={tournamentFinishCountdownString}
      />
    </div>
  );
};
