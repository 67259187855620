import { FetchResult, useMutation, useQuery } from '@apollo/client';
import { useCallback } from 'react';

import {
  CreateTournamentParticipantDocument,
  CreateTournamentParticipantMutation,
  CreateTournamentParticipantMutationVariables,
  TournamentParticipantDocument,
  TournamentParticipantQuery,
  TournamentFragmentFragment as TournamentFragment,
  TournamentParticipantQueryVariables,
  TournamentParticipantFragment,
  RetryTournamentParticipantRegistrationDocument,
  RetryTournamentParticipantRegistrationMutation,
  RetryTournamentParticipantRegistrationMutationVariables,
} from '@graphql/generated';

type CreateParticipant = (participantProperties?: {
  teamCode?: string;
  extraFieldValues?: Record<string, string>;
}) => Promise<FetchResult<CreateTournamentParticipantMutation> | null>;

type UseTournamentParticipant = (params: { tournament: TournamentFragment; skip?: boolean }) => {
  participant: TournamentParticipantFragment | null;
  isParticipantLoading: boolean;
  isParticipantCreationPending: boolean;
  createParticipant: CreateParticipant;
  retryParticipantRegistration: () => Promise<FetchResult<RetryTournamentParticipantRegistrationMutation>>;
  isRetryParticipantRegistrationPending: boolean;
};

export const useTournamentParticipant: UseTournamentParticipant = ({ tournament, skip }) => {
  const { loading: isParticipantLoading, data: existingTournamentParticipant } = useQuery<
    TournamentParticipantQuery,
    TournamentParticipantQueryVariables
  >(TournamentParticipantDocument, {
    variables: { tournamentId: tournament.id },
    skip,
  });

  const [createParticipantMutation, { loading: isParticipantCreationPending, data: createdParticipantData }] =
    useMutation<CreateTournamentParticipantMutation, CreateTournamentParticipantMutationVariables>(
      CreateTournamentParticipantDocument,
    );

  const [
    retryParticipantRegistration,
    { loading: isRetryParticipantRegistrationPending, data: registeredParticipantData },
  ] = useMutation<
    RetryTournamentParticipantRegistrationMutation,
    RetryTournamentParticipantRegistrationMutationVariables
  >(RetryTournamentParticipantRegistrationDocument, {
    variables: {
      input: {
        tournamentId: tournament.id,
      },
    },
  });

  const createParticipant: CreateParticipant = useCallback(
    participantProperites =>
      createParticipantMutation({
        variables: {
          createTournamentParticipantInput: {
            tournamentId: tournament.id,
            ...participantProperites,
          },
        },
      }),
    [createParticipantMutation, tournament.id],
  );

  const createdParticipant =
    createdParticipantData?.createTournamentParticipant?.__typename == 'TournamentParticipantType'
      ? createdParticipantData?.createTournamentParticipant
      : null;

  const registeredParticipant =
    registeredParticipantData?.retryTournamentParticipantRegistration.__typename == 'TournamentParticipantType'
      ? registeredParticipantData?.retryTournamentParticipantRegistration
      : null;

  const participant =
    registeredParticipant ?? createdParticipant ?? existingTournamentParticipant?.tournamentParticipant ?? null;

  const { data: refetchedParticipant } = useQuery<TournamentParticipantQuery, TournamentParticipantQueryVariables>(
    TournamentParticipantDocument,
    {
      variables: { tournamentId: tournament.id },
      skip: skip || participant?.registrationStatus !== 'Processing',
      pollInterval: 10000,
    },
  );

  return {
    participant: refetchedParticipant?.tournamentParticipant ?? participant,
    isParticipantLoading,
    isParticipantCreationPending,
    isRetryParticipantRegistrationPending,
    createParticipant,
    retryParticipantRegistration,
  };
};
