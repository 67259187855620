import Image from 'next/legacy/image';
import React from 'react';

import loaderSrc from '@public/ongoing_loader.svg';

const Loader = (): JSX.Element => {
  return (
    <div className="w-[25%] max-w-[150px] absolute left-1/2 top-1/2 select-none">
      <div className="origin-top-left animate-spin">
        <Image className="w-full" width={100} height={100} src={loaderSrc} alt="loader" />
      </div>
    </div>
  );
};

export default Loader;
