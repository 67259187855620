/* eslint-disable max-len */
import { usePrismicClient } from '@prismicio/react';
import React, { Fragment } from 'react';
import { match } from 'ts-pattern';
import { useQuery } from 'wagmi';

import ElModal from '@elements/ElModal';
import { BlogPostTemplate } from '@features/blog';
import { Button, GradientSpinner, NullComponent } from '@features/common';
import { getBlogIdFromUrl, TournamentShareDropdown } from '@features/tournaments';
import { BlogPostDocument } from '@globalTypes/prismic/documents';
import { TournamentFragmentFragment as TournamentFragment } from '@graphql/generated';
import { useBoolean } from '@hooks/useBoolean';
import { ReactComponent as CloseIcon } from '@public/icons/close.svg';
import { ReactComponent as IconFileText } from '@public/icons/file-text.svg';

type HowToWinButtonProps = {
  rulesUrl: string;
  tournament: TournamentFragment;
};

type HowToWinRulesModalProps = {
  blogId: string;
  onCloseModal: () => void;
  tournament: TournamentFragment;
};

const HowToWinBlogPostModal = ({ blogId, onCloseModal, tournament }: HowToWinRulesModalProps): JSX.Element => {
  const prismicClient = usePrismicClient();

  const { data: blogPostDocument, status } = useQuery([`fetch-blogModal-${blogId}`], {
    queryFn: async () => await prismicClient.getByUID<BlogPostDocument>('blogPost', blogId),
  });

  return (
    <ElModal onCloseModal={onCloseModal}>
      <div className="h-screen md:w-[800px] md:h-[600px] bg-modalWindowBgSecondary overflow-hidden">
        <header className="border-b-bgStripePrimary border-b-1 p-3 pb-2">
          <CloseIcon
            onClick={onCloseModal}
            className="w-5 h-5 ml-auto cursor-pointer text-labelTertiary hover:text-white transition"
          />
        </header>
        <main className="relative max-h-screen px-4 py-6 md:h-[560px] overflow-auto">
          {match(status)
            .with('loading', () => (
              <div className="h-full flex items-center">
                <GradientSpinner className="w-16 h-16 mx-auto" />
              </div>
            ))
            .with('error', () => (
              <div className="h-full flex items-center justify-center text-labelSecondary">Something went wrong...</div>
            ))
            .with('success', () => (
              <main className="max-w-[590px] mx-auto">
                {blogPostDocument && (
                  <BlogPostTemplate
                    selectedBlogPost={blogPostDocument}
                    metaDataContent={<TournamentShareDropdown tournament={tournament} />}
                  />
                )}
              </main>
            ))
            .otherwise(NullComponent)}
        </main>
      </div>
    </ElModal>
  );
};

export const HowToWinButton = ({ rulesUrl, tournament }: HowToWinButtonProps): JSX.Element => {
  const [isHowToWinModalOpen, openHowToWinModal, closeHowToWinModal] = useBoolean(false);

  const blogId = getBlogIdFromUrl(rulesUrl);

  const redirectToExternalRulesUrl = () => {
    window.open(rulesUrl, '_newtab');
  };

  return (
    <>
      <Button
        onClick={blogId ? openHowToWinModal : redirectToExternalRulesUrl}
        variant="secondary"
        className="w-full xs:w-fit pr-6 pl-5 mb-2"
      >
        <div className="flex items-center gap-1">
          <IconFileText className="w-5 h-5" />
          <span>How to Win</span>
        </div>
      </Button>
      {isHowToWinModalOpen && blogId && (
        <HowToWinBlogPostModal tournament={tournament} blogId={blogId} onCloseModal={closeHowToWinModal} />
      )}
    </>
  );
};
