import { Transition } from '@headlessui/react';
import classNames from 'classnames';
import classnames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';

import { fastFadeTransitionClassNames, GamesAndTournamentsSidebar, LayoutMode } from '@features/common';
import { useWindowSize } from '@hooks/useWindowSize';
import { ReactComponent as IconBurgerMenu } from '@public/icons/custom/burger-menu.svg';
import { gameSliceSelector, tournamentSliceSelector } from '@store/slices';
import { useStore } from '@store/zustand';

import { GamesAndTournamentsSearchBar } from './GamesAndTournamentsSearchBar';
import { PlaydexLogoImage } from './PlaydexLogoImage';
import { UserAuthInfoSection } from './UserAuthInfoSection';
import { HEADER_HEIGHT } from './constants';
import { useStaticLinks, StaticLinksConfig } from './hooks';

type HeaderProps = {
  mode?: LayoutMode;
  className?: string;
};

const StaticLinkSelect = ({ staticLinkWithOptions }: { staticLinkWithOptions: StaticLinksConfig }): JSX.Element => {
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const { label, selectOptions } = staticLinkWithOptions;

  return (
    <div className="relative">
      <div onClick={() => setIsSelectOpen(isOpen => !isOpen)}>
        <div
          className={classNames(
            'flex items-center justify-between gap-2 p-1',
            'mx-4 text-white hover:text-labelSecondary font-semibold cursor-pointer',
          )}
        >
          <span>{label}</span>
          {isSelectOpen ? <TiArrowSortedUp className="" /> : <TiArrowSortedDown className="" />}
        </div>
        {isSelectOpen && selectOptions && (
          <div className="flex items-center absolute top-[50px] z-50 left-0 bg-headerDropdown rounded-b-lg">
            {selectOptions.map(link => (
              <Link
                key={`link_to_${link.label}`}
                href={link.route || '/'}
                passHref
                className={classnames(
                  'p-1 px-6 text-base text-white hover:text-labelPrimary py-6 font-semibold w-full',
                  'whitespace-nowrap lg:text-sm lg:text-base',
                )}
                target={link.target}
                onClick={link.action}
              >
                {label}
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export const Header = ({ className, mode }: HeaderProps): JSX.Element => {
  const {
    orientation: { isMobile },
  } = useWindowSize();

  const router = useRouter();

  const staticLinks = useStaticLinks();

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const { sidebarGames } = useStore(gameSliceSelector);
  const { tournamentsPreview } = useStore(tournamentSliceSelector);

  useEffect(() => {
    if (!isMobile) {
      setIsSidebarOpen(false);
    }
  }, [isMobile]);

  return (
    <header
      style={
        {
          '--header-height': HEADER_HEIGHT,
          height: HEADER_HEIGHT,
        } as React.CSSProperties
      }
      className={className}
    >
      <div
        className="w-full transition-[top] duration-1000 ease-out border-bgStripe border-b-1"
        style={{ height: HEADER_HEIGHT }}
      >
        <nav
          className={classnames(
            'pr-4 lg:pr-6 pl-2.5 mx-auto !min-h-full w-full md:relative flex items-center justify-between z-30',
          )}
        >
          <div className="flex flex-0 gap-0 lg:gap-12 items-center">
            <div
              className={classNames('p-1.5 hover:bg-bgSecondary rounded cursor-pointer', 'text-labelPrimary lg:hidden')}
              onClick={() => setIsSidebarOpen(prevValue => !prevValue)}
            >
              <IconBurgerMenu className="w-6 h-6" />
            </div>

            <PlaydexLogoImage />

            {mode !== 'presentation' && (
              <div className="hidden lg:flex gap-4 items-center">
                <div className="hidden md:block">
                  <GamesAndTournamentsSearchBar />
                </div>
                <div className="flex items-center text-sm font-medium gap-8">
                  {staticLinks.map(link =>
                    link?.selectOptions ? (
                      <StaticLinkSelect staticLinkWithOptions={link} key={`select_link_${link.label}`} />
                    ) : (
                      <Link
                        key={`link_to_${link.label}`}
                        href={link?.route || '/'}
                        passHref
                        className={classnames('px-2 flex text-labelSecondary hover:text-labelPrimary', {
                          'text-labelPrimary': router.pathname === link.route,
                        })}
                        target={link.target}
                        onClick={link.action}
                      >
                        {link.label}
                      </Link>
                    ),
                  )}
                </div>
              </div>
            )}
          </div>

          <UserAuthInfoSection />
        </nav>
      </div>

      <Transition
        {...fastFadeTransitionClassNames}
        as="div"
        appear={true}
        show={isSidebarOpen}
        onClick={() => setIsSidebarOpen(false)}
        className={classNames(
          'fixed z-20 transition-colors w-full h-full opacity-70',
          isSidebarOpen ? 'bg-bgPrimary' : 'bg-transparent',
        )}
      />

      <div
        className={classNames(
          'fixed z-[51] transition-transform bottom-0',
          isSidebarOpen ? 'translate-x-0' : '-translate-x-full',
        )}
        style={{ height: 'calc(100vh - var(--header-height) * 1px)' }}
      >
        <GamesAndTournamentsSidebar
          tournaments={tournamentsPreview}
          games={sidebarGames}
          className="bg-bgPrimary"
          onTournamentClick={() => setIsSidebarOpen(false)}
          onGameClick={() => setIsSidebarOpen(false)}
        />
      </div>
    </header>
  );
};
