import { bsc, bscTestnet } from '@wagmi/chains';

import { Address } from '../types';

function getKeyByValue<K extends keyof unknown, V>(obj: Record<K, V>, value: V): K | undefined {
  return (Object.keys(obj) as (keyof typeof obj)[]).find(key => obj[key] === value);
}

type Token = 'USDT' | 'BUSD' | 'KMON' | 'PXT';

const bscTestnetErc20Tokens = {
  USDT: '0x337610d27c682E347C9cD60BD4b3b107C9d34dDd',
  BUSD: '0xed24fc36d5ee211ea25a80239fb8c4cfd80f12ee',
  KMON: '0xa67375293613b4E081855E605504ba54be6Af139',
  PXT: '0x756a16898506e077fE1d69028f3Cb9081Ba88dd3',
} satisfies Record<Token, Address>;

const bscErc20Tokens = {
  USDT: '0x55d398326f99059ff775485246999027b3197955',
  BUSD: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
  KMON: '0xc732b6586a93b6b7cf5fed3470808bc74998224d',
  PXT: '0xBdCe5aF942489f87569F05688773248FAE60130B',
} satisfies Record<Token, Address>;

export const erc20Tokens = {
  [bscTestnet.id]: bscTestnetErc20Tokens,
  [bsc.id]: bscErc20Tokens,
};

export const getErc20Address = ({ chainId, tokenSymbol }: { chainId: number; tokenSymbol: Token }): Address | null => {
  switch (chainId) {
    case bscTestnet.id:
      return bscTestnetErc20Tokens[tokenSymbol as keyof typeof bscTestnetErc20Tokens] ?? null;
    case bsc.id:
      return tokenSymbol in bscErc20Tokens ? bscErc20Tokens[tokenSymbol as keyof typeof bscErc20Tokens] : null;
    default:
      return null;
  }
};

export const getErc20Symbol = ({ chainId, tokenAddress }: { chainId: number; tokenAddress: Address }): Token | null => {
  switch (chainId) {
    case bscTestnet.id:
      return getKeyByValue(bscTestnetErc20Tokens, tokenAddress) ?? null;
    case bsc.id:
      return getKeyByValue(bscErc20Tokens, tokenAddress) ?? null;
    default:
      return null;
  }
};
