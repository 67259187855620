import amplitude from 'amplitude-js';

export enum AMPLITUDE_EVENTS {
  // COMMON
  OPENED_GAME_PAGE = 'OPENED_GAME_PAGE',

  // AUTH
  AUTH_STARTED = 'AUTH_STARTED',
  AUTH_SUCCESS = 'AUTH_SUCCESS',
  AUTH_FAILED = 'AUTH_FAILED',

  // WAITLIST
  WAITLIST_STARTED = 'WAITLIST_STARTED',
  WAITLIST_SUCCESS = 'WAITLIST_SUCCESS',
  WAITLIST_FAIL = 'WAITLIST_FAIL',

  // TOURNAMENT
  TOURNAMENT_SHARE = 'TOURNAMENT_SHARE',
}

export type LogAmplitudeEvent = (
  event: string,
  data?: unknown,
  callback?: amplitude.Callback | undefined,
  errorCallback?: amplitude.Callback | undefined,
  outOfSession?: boolean | undefined,
) => amplitude.LogReturn;

export type GameEventPayload = {
  game?: string;
  gameUid: string;
};

export type TournamentEventPayload = {
  tournamentId: string;
  shareMethod: string;
};
