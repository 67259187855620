import { match } from 'ts-pattern';
import { Chain } from 'wagmi';
import {
  bsc,
  bscTestnet,
  mainnet,
  polygon,
  polygonMumbai,
  // hardhat, // uncomment in case of hardhat usage
} from 'wagmi/chains';
// import { publicProvider } from 'wagmi/providers/public'; // uncomment in case of hardhat usage

import { featureChecker } from '@services/featureChecker';

import { jsonRpcBatchProvider } from './provider';

export * from './createAutoConnectStatusRepository';

export const getAppEnvDefaultChains = (): Array<Chain> => {
  const { isPreviewAppEnv, isDevelopmentAppEnv } = {
    isPreviewAppEnv: featureChecker.isPreviewAppEnv(),
    isDevelopmentAppEnv: featureChecker.isDevelopmentAppEnv(),
  };

  if (isPreviewAppEnv || isDevelopmentAppEnv)
    return [
      mainnet,
      polygon,
      polygonMumbai,
      bsc,
      // hardhat, // uncomment in case of hardhat usage
      bscTestnet,
    ];

  return [mainnet, polygon, bsc];
};

export const chainProviderConstructors = [
  jsonRpcBatchProvider({
    rpc: chain =>
      match(chain.id)
        .with(mainnet.id, () => ({
          http: 'https://radial-cosmopolitan-bridge.quiknode.pro/0f2e073c9bcb6b647d22e55a3836d69fc548af8c/',
          webSocket: 'wss://radial-cosmopolitan-bridge.quiknode.pro/0f2e073c9bcb6b647d22e55a3836d69fc548af8c/',
        }))
        .with(polygon.id, () => ({
          http: 'https://orbital-orbital-liquid.matic.quiknode.pro/5e175820b92e5399fb26b6248593f1737167ef09/',
          webSocket: 'wss://orbital-orbital-liquid.matic.quiknode.pro/5e175820b92e5399fb26b6248593f1737167ef09/',
        }))
        .with(polygonMumbai.id, () => ({
          http: 'https://rpc-mumbai.maticvigil.com/',
        }))
        .with(bscTestnet.id, () => ({
          http: 'https://practical-prettiest-telescope.bsc-testnet.quiknode.pro/846ff6a510b17e5b5b49d73da8d85936a7972a57/',
          webSocket:
            'wss://practical-prettiest-telescope.bsc-testnet.quiknode.pro/846ff6a510b17e5b5b49d73da8d85936a7972a57/',
        }))
        .with(bsc.id, () => ({
          http: 'https://flashy-few-valley.bsc.quiknode.pro/1fe80044bcaa8a9f7be6bdcfaebb9c4793cc31c4/',
          webSocket: 'wss://flashy-few-valley.bsc.quiknode.pro/1fe80044bcaa8a9f7be6bdcfaebb9c4793cc31c4/',
        }))
        .otherwise(() => null),
  }),
  // publicProvider({ priority: 2 }), // uncomment in case of hardhat usage
];
