import { useEffect } from 'react';

import { useBoolean } from '@hooks/useBoolean';

export const OnlyClientSide = ({ children }: { children: JSX.Element }): JSX.Element | null => {
  const [isShown, show] = useBoolean(false);

  useEffect(() => {
    show();
  }, []);

  return isShown ? children : null;
};
