import { Maybe } from 'graphql/jsutils/Maybe';
import { memo } from 'react';
import { useFormContext } from 'react-hook-form';

import { JsonSchema, RefSchema } from '@features/common';

import { FilterButton } from '../FilterButton';
import { FilterInputText } from '../FilterInputText';

export type RangeFieldInput = {
  gte: Maybe<number>;
  lte: Maybe<number>;
};

export const FilterRangeField = memo(
  ({ name }: { name: string; schema: RefSchema; rootSchema: JsonSchema }): JSX.Element => {
    const { register } = useFormContext();

    const setValueAs = (v: string): number | undefined => {
      const maybeNumber = parseInt(v);
      return isNaN(maybeNumber) ? undefined : maybeNumber;
    };

    return (
      <div className="grid grid-cols-[1fr_min-content_1fr] gap-5 items-center">
        <div>
          <FilterInputText
            className="w-full"
            type="number"
            {...register(name + '.gte', {
              setValueAs,
            })}
          />
        </div>
        <div>to</div>
        <div>
          <FilterInputText
            className="w-full"
            type="number"
            {...register(name + '.lte', {
              setValueAs,
            })}
          />
        </div>
        <FilterButton className="col-span-3">Apply</FilterButton>
      </div>
    );
  },
);
// eslint-disable-next-line functional/immutable-data
FilterRangeField.displayName = FilterRangeField.name;
