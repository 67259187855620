import cn from 'classnames';
import Link, { LinkProps } from 'next/link';
import React from 'react';

import { ReactComponent as ArrowIcon } from '@public/icons/custom/chevron-down.svg';

type Props = {
  href: LinkProps['href'];
  label: string;
  iconClassName?: string;
  linkClassName?: string;
};

export const GoBackLink = ({ href, label, iconClassName, linkClassName }: Props): JSX.Element => {
  return (
    <Link
      href={href}
      className={cn(
        'flex py-1 pr-2 items-center text-xs text-labelSecondary hover:text-white transition cursor-pointer',
        linkClassName,
      )}
    >
      <span>
        <ArrowIcon className={cn('-ml-2 w-6 h-6 rotate-[90deg]', iconClassName)} />
      </span>
      <span className="font-medium">{label}</span>
    </Link>
  );
};
