export enum TournamentEventSource {
  FROM_HEADER = 'from header',
  FROM_MAIN_PAGE = 'from main page',
  TOURNAMENT_LIST = 'from tournament list',
  FROM_GAME_PAGE = 'from game page',
  FROM_TOURNAMENT_PREVIEW = 'from tournament preview link',
  DIRECT = 'direct',
}

export type BaseTournamentEventPayload = {
  gameUid: string | null;
  tournamentSlug?: string;
};

export type TournamentEventWithSourcePayload = BaseTournamentEventPayload & {
  source: TournamentEventSource;
};

// EVENT TYPES

export enum TOURNAMENTS_ANALYTICS_EVENT {
  TOURNAMENT_LIST_OPENED = 'TOURNAMENT_LIST_OPENED',
  TOURNAMENT_TAB_OPENED = 'TOURNAMENT_TAB_OPENED',
  TOURNAMENT_OPENED = 'TOURNAMENT_OPENED',
  TOURNAMENT_REGISTER_STARTED = 'TOURNAMENT_REGISTER_STARTED',
  TOURNAMENT_REGISTER_SUCCESS = 'TOURNAMENT_REGISTER_SUCCESS',
  TOURNAMENT_REGISTER_FAILED = 'TOURNAMENT_REGISTER_FAILED',
}
