import React, { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
  icon: React.ReactNode;
}>;

export const EmptyResultsIndicator = ({ children, icon }: Props): JSX.Element => {
  return (
    <div className="flex flex-col items-center justify-center py-18 h-full w-full">
      <div className="w-48 xs:w-64">{icon}</div>

      <p className="text-sm xs:text-base font-medium pb-4 pt-2 text-center max-w-lg">{children}</p>
    </div>
  );
};
