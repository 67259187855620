// TOURNAMENTS FLOW
import { logAmplitudeEvent } from '@features/common';
import {
  BaseTournamentEventPayload,
  TournamentEventWithSourcePayload,
  TOURNAMENTS_ANALYTICS_EVENT,
} from '@features/tournaments';

export const logTournamentTabOpenedAmplitudeEvent = (data: TournamentEventWithSourcePayload) =>
  logAmplitudeEvent(TOURNAMENTS_ANALYTICS_EVENT.TOURNAMENT_TAB_OPENED, data);

export const logTournamentOpenedAmplitudeEvent = (data: TournamentEventWithSourcePayload) =>
  logAmplitudeEvent(TOURNAMENTS_ANALYTICS_EVENT.TOURNAMENT_OPENED, data);

export const logTournamentRegisterStartedAmplitudeEvent = (data: BaseTournamentEventPayload) =>
  logAmplitudeEvent(TOURNAMENTS_ANALYTICS_EVENT.TOURNAMENT_REGISTER_STARTED, data);

export const logTournamentRegisterSuccessAmplitudeEvent = (data: BaseTournamentEventPayload) =>
  logAmplitudeEvent(TOURNAMENTS_ANALYTICS_EVENT.TOURNAMENT_REGISTER_SUCCESS, data);

export const logTournamentRegisterFailedAmplitudeEvent = (data: BaseTournamentEventPayload) =>
  logAmplitudeEvent(TOURNAMENTS_ANALYTICS_EVENT.TOURNAMENT_REGISTER_FAILED, data);
