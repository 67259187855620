import classNames from 'classnames';
import React, { ButtonHTMLAttributes } from 'react';

type IconButtonProps = {
  children: React.ReactNode;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const IconButton = ({ children, className, disabled, ...props }: IconButtonProps): JSX.Element => {
  return (
    <button
      {...props}
      disabled={disabled}
      className={classNames(
        'w-9 h-9 rounded center-flex',
        disabled ? 'text-labelSecondary' : 'hover:bg-controlSecondaryHover cursor-pointer',
        className,
      )}
    >
      {children}
    </button>
  );
};
