// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { darkTheme, Theme } from '@rainbow-me/rainbowkit';
import tailwindConfig from 'tailwind.config';

const fontFamily = tailwindConfig.theme.fontFamily.sans.join(', ');
const {
  error,
  controlPrimary,
  bgStripe,
  labelPrimary,
  labelSecondary,
  modalWindowOverlayBg,
  modalWindowBgPrimary,
  controlSecondaryHover,
} = tailwindConfig.theme.extend.colors;
const defaultTheme = darkTheme();

export const rainbowkitTheme: Theme = {
  colors: {
    ...defaultTheme.colors,
    accentColor: controlPrimary,
    actionButtonBorder: 'transparent',
    actionButtonBorderMobile: 'transparent',
    closeButton: labelSecondary,
    closeButtonBackground: 'transparent',
    error,
    generalBorder: bgStripe,
    generalBorderDim: bgStripe,
    menuItemBackground: controlSecondaryHover,
    modalBackdrop: modalWindowOverlayBg,
    modalBackground: modalWindowBgPrimary,
    modalBorder: bgStripe,
    modalText: labelPrimary,
    modalTextSecondary: labelSecondary,
    selectedOptionBorder: bgStripe,
  },
  fonts: {
    body: fontFamily,
  },
  radii: {
    actionButton: '4px',
    connectButton: '4px',
    menuButton: '4px',
    modal: '8px',
    modalMobile: '8px',
  },
  shadows: {
    connectButton: 'transparent',
    dialog: 'transparent',
    profileDetailsAction: 'transparent',
    selectedOption: 'transparent',
    selectedWallet: 'transparent',
    walletLogo: 'transparent',
  },
};
