import { ethers } from 'ethers';

import { Address } from '@globalTypes/contracts/metamask';

export const processWhitelistAddresses = (addresses: string): Array<Address> => {
  return addresses
    .split(',')
    .map(str => str.trim().toLowerCase())
    .filter((address): address is Address => ethers.utils.isAddress(address));
};
