import { Menu, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { equals } from 'ramda';
import React, { MouseEventHandler } from 'react';
import { match } from 'ts-pattern';

import { fastFadeTransitionClassNames } from '../constants';

export type SelectOption<T> = {
  icon?: JSX.Element;
  label: string;
  value: T;
};

type SelectProps<T> = {
  selected?: SelectOption<T>;
  children: React.ReactNode;
  options: SelectOption<T>[];
  onChange: (option: SelectOption<T>) => void;
  className?: string;
  popoverClassName?: string;
  popoverPosition?: 'left' | 'right';
};

type SelectOptionItemProps = {
  icon?: JSX.Element;
  label: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  isSelected: boolean;
};

const SelectItem = ({ icon, label, onClick, isSelected }: SelectOptionItemProps): JSX.Element => {
  return (
    <Menu.Item>
      {({ active, close }) => (
        <button
          className={classNames(
            'group flex w-full items-center rounded-md p-2 text-sm gap-2 leading-5',
            (active || isSelected) && 'bg-bgTertiary text-white',
          )}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();

            close();
            onClick(e);
          }}
          type="button"
        >
          {icon ? (
            <span className={classNames('w-4 h-4 text-labelSecondary', active && 'text-white')}>{icon}</span>
          ) : null}
          {label}
        </button>
      )}
    </Menu.Item>
  );
};

export const Select = <T,>({
  selected,
  children,
  options,
  onChange,
  className,
  popoverClassName,
  popoverPosition,
}: SelectProps<T>): JSX.Element => {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button
        as="div"
        className={classNames('cursor-pointer', className)}
        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()}
      >
        {children}
      </Menu.Button>
      <Transition as={React.Fragment} {...fastFadeTransitionClassNames}>
        <Menu.Items
          className={classNames(
            'absolute mt-2 w-56 origin-top-right rounded-md',
            'bg-bgSecondary shadow-lg ring-0 outline-none text-white p-2 z-50',
            match(popoverPosition)
              .with('left', () => 'left-0')
              .otherwise(() => 'right-0'),
            popoverClassName,
          )}
        >
          {options.map(option => (
            <SelectItem
              key={option.label}
              icon={option.icon}
              label={option.label}
              onClick={() => onChange(option)}
              isSelected={equals(selected, option)}
            />
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
