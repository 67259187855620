/* eslint-disable */
import { Menu } from '@headlessui/react';
import React, { Fragment } from 'react';

import { Button, createShareActions, ExternalLink, logTournamentShare } from '@features/common';
import { TournamentFragmentFragment as TournamentFragment } from '@graphql/generated';
import { ReactComponent as ShareIcon } from '@public/icons/share.svg';
import cn from 'classnames';
import Link from 'next/link';

type TournamentShareDropdownProps = {
  tournament: TournamentFragment;
  closeDropdown: () => void;
};

export const TournamentShareDropdown = ({ tournament }: { tournament: TournamentFragment }) => {
  const inviteText = `Join the ${tournament.title} on %playdex% and show your gaming skills to the world! Compete against fellow gamers and climb the ranks to become a top player!`;
  const { facebook, twitter, telegram, link } = createShareActions(window.location.href, inviteText);

  return (
    <div onClick={event => event.stopPropagation()}>
      <Menu>
        <Menu.Button
          as={Button}
          className="text-labelSecondary hover:text-white transition"
          variant="transparent"
          size="xs"
        >
          <ShareIcon className="w-4 h-4" /> Share
        </Menu.Button>
        <Menu.Items className="relative">
          <div className="absolute right-0 w-[220px] z-[1] mt-1 p-2 rounded-lg bg-dashboardSecondaryBg border border-bgStripePrimary">
            {[facebook, twitter, telegram, link].map(action => {
              const Icon = action.icon;
              const className = cn(
                'p-2 w-full flex gap-2 items-center shrink-0 rounded cursor-pointer',
                'cursor-pointer hover:bg-dropdownHoveredItem transition duration-100 ease-out',
              );

              const content = (
                <>
                  <Icon className="w-4 h-4 shrink-0 text-labelSecondary" />
                  <div className="text-sm text-labelPrimary">{action.title}</div>
                </>
              );

              if (action.href) {
                return (
                  <Link
                    key={action.title}
                    href={action.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={className}
                    onClick={() => {
                      logTournamentShare({ tournamentId: tournament.id, shareMethod: action.title });
                    }}
                  >
                    {content}
                  </Link>
                );
              }

              if (action.onClick) {
                return (
                  <button
                    key={action.title}
                    onClick={() => {
                      logTournamentShare({ tournamentId: tournament.id, shareMethod: action.title });
                      action.onClick?.();
                    }}
                    className={className}
                  >
                    {content}
                  </button>
                );
              }
            })}
          </div>
        </Menu.Items>
      </Menu>
    </div>
  );
};
