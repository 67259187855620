import { Maybe } from 'graphql/jsutils/Maybe';
import { mapObjIndexed } from 'ramda';
import React, { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { ApplicationSettings, JsonSchema, JsonSchemaPropertiesValues } from '@features/common';
import { NftAssetsFilterInput } from '@graphql/generated';

import type { RangeFieldInput } from './FilterInput';
import { isRangeFilterField } from './utils';

type NftFilterContextInterface = {
  applied: number;
  onSubmit: (data: NftAssetsFilterInput) => void;
  settings: ApplicationSettings;
};

const initialValue = {} as NftFilterContextInterface;

export const NftFilterContext = createContext<NftFilterContextInterface>(initialValue);

const createDefaultSchema = (): JsonSchema => ({ properties: {} });

type Props = Pick<NftFilterContextInterface, 'onSubmit' | 'settings'>;

export const NftFilterContextProvider = ({
  children,
  settings,
  onSubmit,
}: PropsWithChildren<Props>): JSX.Element | null => {
  const {
    schemas: {
      nftFilter = createDefaultSchema(),
      nftFilterExtra: { attributes = createDefaultSchema(), offerTerms = createDefaultSchema() },
    },
  } = settings;

  const defaultValues = useMemo(() => {
    const mapSchema = mapObjIndexed<
      JsonSchemaPropertiesValues,
      Maybe<string | number | boolean | RangeFieldInput>,
      keyof NftAssetsFilterInput
    >(schema => {
      if (isRangeFilterField(schema)) {
        return {
          gte: undefined,
          lte: undefined,
        };
      }

      return schema.default;
    });

    const values = mapSchema(nftFilter.properties ?? {});
    const attributesValues = mapSchema(attributes.properties ?? {});
    const offersTermsValues = mapSchema(offerTerms.properties ?? {});
    const result = {
      ...values,
      attributes: Object.values(attributesValues).length ? attributesValues : undefined,
      offersTerms: Object.values(offersTermsValues).length ? offersTermsValues : undefined,
    };
    return result as NftAssetsFilterInput;
  }, [nftFilter, attributes, offerTerms]);

  const methods = useForm({
    defaultValues,
  });

  const applied = useMemo(() => {
    const { dirtyFields } = methods.formState;
    return Object.values(dirtyFields).length;
  }, [methods.formState]);

  return (
    <FormProvider {...methods}>
      <NftFilterContext.Provider
        value={{
          onSubmit,
          applied,
          settings,
        }}
      >
        {children}
      </NftFilterContext.Provider>
    </FormProvider>
  );
};

export const useNftFilterContext = (): NftFilterContextInterface => {
  return useContext(NftFilterContext);
};
