import classNames from 'classnames';
import React, { useId } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-types
export const Checkbox = React.forwardRef<
  HTMLInputElement,
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
    children?: React.ReactNode;
    applyFocusRing?: boolean;
  }
>(({ children, applyFocusRing, ...props }, ref) => {
  const defaultId = useId();
  const id = props?.id || defaultId;

  return (
    <div className="flex items-center">
      <input
        id={id}
        type="checkbox"
        {...props}
        ref={ref}
        className={classNames(
          'min-w-[1rem] min-h-[1rem] text-controlPrimary rounded border-gray-300',
          'bg-transparent border-radioCheckboxBorder cursor-pointer',
          applyFocusRing
            ? 'focus:ring-blue-600 focus:text-blue-400 ring-offset-gray-800 focus:ring-2'
            : '!ring-0 !ring-offset-0',
        )}
      />
      {children ? (
        <label htmlFor={id} className="ml-2 text-sm font-medium cursor-pointer">
          {children}
        </label>
      ) : null}
    </div>
  );
});

// eslint-disable-next-line functional/immutable-data
Checkbox.displayName = 'Checkbox';
