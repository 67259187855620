import cn from 'classnames';
import Link from 'next/link';

type CommonProps<T> = {
  disabled?: boolean;
  variant?: 'active' | 'active-no-shine' | 'outline';
  children: string | JSX.Element | JSX.Element[];
  className?: string;
  onClick?: React.MouseEventHandler<T>;
};

type ShinyLinkButtonProps = CommonProps<HTMLAnchorElement> &
  React.ComponentPropsWithRef<'a'> &
  Required<Pick<React.ComponentPropsWithRef<'a'>, 'href'>>;

type ShinyButtonProps = CommonProps<HTMLButtonElement> & React.ComponentPropsWithRef<'button'>;

function isLink(props: ShinyLinkButtonProps | ShinyButtonProps): props is ShinyLinkButtonProps {
  return 'href' in props && Boolean(props.href);
}

const ShinyButton = (props: ShinyLinkButtonProps | ShinyButtonProps): JSX.Element => {
  const { variant = 'outline', children, disabled, className } = props;

  const finalClassName = cn(
    'inline-block w-full p-4 rounded-lg font-extrabold text-[14px]',
    'no-underline text-white text-center',
    !disabled && 'hover:scale-[99%] active:scale-[97%] transition ease-in-out duration-[100ms]',
    disabled ? 'cursor-default text-white/50' : 'cursor-pointer',
    {
      'bg-gradient-to-r from-[#3aacff] to-[#0033e6] shadow-blueShiny hover:shadow-sm-blueShiny active:shadow-none':
        variant === 'active',
      'bg-transparent py-[15px] border border-solid border-disabled': variant === 'outline',
      'bg-gradient-to-r from-[#3aacff] to-[#0033e6]': variant === 'active-no-shine',
    },

    className,
  );

  if (isLink(props)) {
    return (
      <Link
        {...props}
        className={finalClassName}
        onClick={e => {
          if (disabled) {
            e.preventDefault();
          }

          props.onClick?.(e);
        }}
      >
        {children}
      </Link>
    );
  }

  return (
    <button
      {...props}
      onClick={e => {
        if (!disabled && props.onClick) {
          props.onClick(e);
        }
      }}
      className={finalClassName}
    >
      {children}
    </button>
  );
};

export default ShinyButton;
