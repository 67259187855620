import { getFirestore, orderBy, Query, query, where } from 'firebase/firestore';

import { clientFirebaseApp, FirebaseCollectionName, getCollectionRef } from '@services/firebase';

import { IFirebaseFirestoreNotification } from './types';

export const userNotificationsQuery = (recipientId: string): Query<IFirebaseFirestoreNotification> => {
  const db = getFirestore(clientFirebaseApp);

  return query<IFirebaseFirestoreNotification>(
    getCollectionRef<IFirebaseFirestoreNotification>(db, FirebaseCollectionName.Notifications),
    where('recipientId', '==', recipientId),
    orderBy('createdAt', 'desc'),
  );
};
