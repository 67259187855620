import { ApolloClient } from '@apollo/client';

import { ApplicationRentalTermSchemas, createQueryFactory } from '@features/common';
import {
  ApplicationSettingsDocument,
  ApplicationSettingsQuery,
  ApplicationSettingsQueryVariables,
} from '@graphql/generated';

export type ApplicationSettingsRepository = {
  getApplicationSettings: (variables: ApplicationSettingsQueryVariables) => Promise<{
    schemas: ApplicationRentalTermSchemas;
  }>;
};

export const createApplicationSettingsRepository = <T>({
  playdexClient,
}: {
  playdexClient: ApolloClient<T>;
}): ApplicationSettingsRepository => {
  const queryFromDocument = createQueryFactory(playdexClient);

  return {
    getApplicationSettings: queryFromDocument<ApplicationSettingsQuery, ApplicationSettingsQueryVariables>(
      ApplicationSettingsDocument,
      ({ applicationSettings }) => applicationSettings,
    ),
  };
};
