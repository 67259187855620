import { useQuery, useMutation } from '@apollo/client';

import { useAuthController } from '@features/auth';
import { Button } from '@features/common';
import { logWaitlistFail, logWaitlistStarted, logWaitlistSuccess } from '@features/common/analytics/events';
import withSafeHydration from '@features/common/hocs/withSafeHydration';
import { useCurrentGameContext } from '@features/gamehub/contexts/currentGame';
import { CreateWaitlistRequestDocument, WaitlistRequestDocument, WaitlistRequestQuery } from '@graphql/generated';
import { notice, ToastType } from '@utils/notice';

type Props = {
  variant?: React.ComponentProps<typeof Button>['variant'];
};

const GameWaitlistButton = ({ variant }: Props): JSX.Element | null => {
  const game = useCurrentGameContext();

  const { isAuthCompleted, requireAuth } = useAuthController();

  const { loading: waitlistStatusLoading, data: existingWaitlistData } = useQuery<WaitlistRequestQuery>(
    WaitlistRequestDocument,
    {
      variables: { featureId: game.uid },
      skip: !isAuthCompleted,
    },
  );

  const [createWaitlistRequest, { loading: isWaitlistRequestCreationPending, data: createdWaitlistData }] = useMutation(
    CreateWaitlistRequestDocument,
    {
      variables: { featureId: game.uid },
    },
  );

  const isLoading = waitlistStatusLoading || isWaitlistRequestCreationPending;

  if (isLoading) {
    return (
      <Button disabled variant="primary" className="md:min-w-[146px] h-min">
        Loading...
      </Button>
    );
  }

  const waitlistRequest = createdWaitlistData?.createWaitlistRequest || existingWaitlistData?.waitlistRequest;

  const onClickHandler = async (): Promise<void> => {
    const isAuthCompleted = requireAuth({ includeDiscordVerification: false, includePhoneVerification: false });
    const analyticsEventPayload = {
      game: game.data.name ?? undefined,
      gameUid: game.uid,
    };

    if (isAuthCompleted) {
      try {
        logWaitlistStarted(analyticsEventPayload);
        await createWaitlistRequest();
        logWaitlistSuccess(analyticsEventPayload);
      } catch {
        logWaitlistFail(analyticsEventPayload);
        notice(ToastType.ERROR, 'Waitlist application failed 🤕');
      }
    }
  };

  return waitlistRequest ? (
    <Button variant={variant} disabled={true} className="md:min-w-[146px] h-min">
      Subscribed
    </Button>
  ) : (
    <Button variant={variant} className="md:min-w-[146px] h-min" onClick={onClickHandler} disabled={isLoading}>
      Subscribe
    </Button>
  );
};

export default withSafeHydration(GameWaitlistButton);
