import { ApolloClient } from '@apollo/client';

import { createQueryFactory } from '@features/common';
import {
  GameNftAssetsDocument,
  GameNftAssetsQuery,
  GameNftAssetsQueryVariables,
  OwnerNftAssetsDocument,
  OwnerNftAssetsQuery,
  OwnerNftAssetsQueryVariables,
} from '@graphql/generated';

export type NftAssetRepository = {
  getNftAssetList: (variables: OwnerNftAssetsQueryVariables) => Promise<OwnerNftAssetsQuery['ownerNftAssets']>;
  getGameNftAssets: (variables: GameNftAssetsQueryVariables) => Promise<GameNftAssetsQuery['nftAssets']>;
};

export const createNftAssetRepository = <T>({
  playdexClient,
}: {
  playdexClient: ApolloClient<T>;
}): NftAssetRepository => {
  const queryFromDocument = createQueryFactory(playdexClient);

  const getNftAssetList = queryFromDocument<OwnerNftAssetsQuery, OwnerNftAssetsQueryVariables>(
    OwnerNftAssetsDocument,
    ({ ownerNftAssets }) => ownerNftAssets,
  );

  const getGameNftAssets = queryFromDocument<GameNftAssetsQuery, GameNftAssetsQueryVariables>(
    GameNftAssetsDocument,
    ({ nftAssets }) => nftAssets,
  );

  return {
    getNftAssetList,
    getGameNftAssets,
  };
};
