import { Tab } from '@headlessui/react';
import cn from 'classnames';
import { Fragment, ReactNode } from 'react';

type Props = {
  selectedIndex?: number;
  defaultIndex?: number;
  className?: string;
  segments: {
    label: ReactNode;
    badge?: ReactNode;
  }[];
  onChange?: (newIndex: number) => void;
};

export const SegmentControl = ({ selectedIndex, segments, className, onChange }: Props): JSX.Element => {
  return (
    <Tab.Group selectedIndex={selectedIndex} onChange={onChange}>
      <Tab.List
        className={cn(
          className,
          'inline-flex p-0.5 gap-1 text-sm rounded text-labelSecondary bg-bgSecondary select-none',
        )}
      >
        {segments.map(({ label, badge }, i) => (
          <div key={i} className="flex items-center gap-1">
            <Tab as={Fragment}>
              {({ selected }) => (
                <div
                  className={cn(
                    'flex items-center rounded p-0.5 whitespace-nowrap hover:text-labelPrimary',
                    'outline-none cursor-pointer transition hover:transition-none duration-250',
                    selected && 'bg-bgStripePrimary text-labelPrimary',
                  )}
                >
                  <div className="px-2 leading-6">{label}</div> {badge && <div>{badge}</div>}
                </div>
              )}
            </Tab>
            {i < segments.length - 1 && <div className="w-px h-2 bg-bgStripe" />}
          </div>
        ))}
      </Tab.List>
    </Tab.Group>
  );
};
