// Capitalize
import { AnyObjectSchema } from 'yup';

import clipboardCopy from '@utils/clipboardCopy';
import { notice, ToastType } from '@utils/notice';

// Format price
export const formatPrice = (price: number | string): string => {
  const fnumber = parseFloat(String(price));
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(fnumber);
};

export const replaceCumulative = (str: string, find: string[], replace: string[]): string => {
  // eslint-disable-next-line
  let result = str;
  find.map((_, j) => {
    result = result.replace(new RegExp(find[j], 'g'), replace[j]);
  });
  return result;
};

export const visibleAtStep = (currentStep: number, visibleStep: number): string => {
  return currentStep === visibleStep ? 'opacity-100' : 'opacity-40';
};

export const extractAddressBody = (roninFullAddress: string): string =>
  roninFullAddress.startsWith('ronin:')
    ? roninFullAddress.slice(6)
    : roninFullAddress.startsWith('0x')
    ? roninFullAddress.slice(2)
    : roninFullAddress;

export const isValidFieldsBySchema = async <F>(
  fieldList: Array<keyof F>,
  formFieldValues: F,
  schema: AnyObjectSchema,
): Promise<boolean> =>
  Promise.all(
    fieldList.map(field => {
      return schema.validateAt(String(field), formFieldValues);
    }),
  )
    .then(() => true)
    .catch(() => false);

export const copyToBuffer = (value: string | number): Promise<void> => {
  return clipboardCopy(String(value));
};

const notAllowedErrorMessage = "Application doesn't have permissions to copy text";

export const copyToBufferAndNotify = async (
  value: string | number,
  message = 'Successfully copied!',
): Promise<void> => {
  try {
    await copyToBuffer(value);
    notice(ToastType.SUCCESS, message);
  } catch (error) {
    /**
     * In webview clipboard.writeText throws error
     * due to the lack of clipboard-write permission
     * @link https://stackoverflow.com/questions/61243646/clipboard-api-call-throws-notallowederror-without-invoking-onpermissionrequest
     *
     * @todo: Create alternative way to copy text (e.g. simple tooltip input field)
     */
    if (error instanceof DOMException && error.name === 'NotAllowedError') {
      return notice(ToastType.ERROR, notAllowedErrorMessage);
    }
    // eslint-disable-next-line functional/no-throw-statement
    throw error;
  }
};
