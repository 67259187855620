import { useIsomorphicLayoutEffect } from '@features/common';

export const useOnKeyboardKeyPress = (
  key: string,
  cb: (event: KeyboardEvent) => void | Promise<void>,
  keyboardEvent: 'keyup' | 'keypress' | 'keydown' = 'keyup',
): void => {
  useIsomorphicLayoutEffect(() => {
    const onKeyboardPressHandler = (event: KeyboardEvent): void => {
      const isKeyPressed = event.key === key;
      if (isKeyPressed) {
        cb(event);
      }
    };

    window.addEventListener(keyboardEvent, onKeyboardPressHandler);

    return () => window.removeEventListener(keyboardEvent, onKeyboardPressHandler);
  }, []);
};
