/* eslint-disable functional/immutable-data */
import { providers } from 'ethers';
import produce from 'immer';
import { WritableDraft } from 'immer/dist/internal';
import { Chain } from 'wagmi';

type ChainProviderFn<TProvider extends Provider = providers.BaseProvider, TChain extends Chain = Chain> = (
  chain: TChain,
) => {
  chain: TChain;
  provider: () => ProviderWithFallbackConfig<TProvider>;
} | null;

type FallbackProviderConfig = Omit<providers.FallbackProviderConfig, 'provider'>;
type ProviderWithFallbackConfig<TProvider extends Provider = Provider> = TProvider & FallbackProviderConfig;
type Provider = providers.BaseProvider & { chains?: Chain[] };

type JsonRpcBatchProviderConfig = FallbackProviderConfig & {
  rpc: (chain: Chain) => { http: string; webSocket?: string } | null;
  static?: boolean;
};

/**
 * This is wagmi adapter for ethers.js JsonRpcBatchProvider
 * @link https://docs.ethers.org/v5/api/providers/other/#JsonRpcBatchProvider
 */
export function jsonRpcBatchProvider({
  priority,
  rpc,
  stallTimeout,
  weight,
}: JsonRpcBatchProviderConfig): ChainProviderFn<providers.JsonRpcBatchProvider> {
  return function (chain) {
    const rpcConfig = rpc(chain);
    if (!rpcConfig || rpcConfig.http === '') return null;
    const result = {
      chain: produce(chain, draft => {
        /**
         * Under the hook when building request for `wallet_addEthereumChain` method Wagmi uses first endpoint from the public key. So we assign wagmi default endpoints as public
         * @link https://github.com/wagmi-dev/references/blob/main/packages/connectors/src/injected.ts
         * @see wallet_addEthereumChain
         */
        draft.rpcUrls.public = chain.rpcUrls.default as WritableDraft<Chain['rpcUrls']['default']>;
        /**
         * But the same time we use private endpoints outputted by `rpc` callback to be used as default for app read requests
         */
        draft.rpcUrls.default.http = [rpcConfig.http];
      }),
      provider: () => {
        const RpcProvider = providers.JsonRpcBatchProvider;
        const provider = new RpcProvider(rpcConfig.http, {
          chainId: chain.id,
          name: chain.network,
        });
        // eslint-disable-next-line functional/immutable-data
        return Object.assign(provider, { priority, stallTimeout, weight });
      },
    };
    return result;
  };
}
