import * as prismicH from '@prismicio/helpers';
import { PrismicRichText, PrismicRichTextProps } from '@prismicio/react';
import React from 'react';

import { ExternalLink, InternalLink } from '@features/common';

export const PrismicRichTextWrapper = <
  LinkResolverFunction extends prismicH.LinkResolverFunction<unknown> = prismicH.LinkResolverFunction<string>,
>(
  props: PrismicRichTextProps<LinkResolverFunction>,
): JSX.Element | null => {
  const { components, ...leftProps } = props;

  return (
    <PrismicRichText
      components={{
        heading1: ({ children }) => (
          <h1 className="!font-sans text-labelPrimary my-3 font-display font-medium text-8">{children}</h1>
        ),
        heading2: ({ children }) => (
          <h2 className="!font-sans text-labelPrimary my-3 font-display font-medium text-7">{children}</h2>
        ),
        heading3: ({ children }) => (
          <h3 className="!font-sans text-labelPrimary my-3 font-display font-medium text-6">{children}</h3>
        ),
        heading4: ({ children }) => (
          <h4 className="!font-sans text-labelPrimary my-3 font-display font-medium text-5">{children}</h4>
        ),
        hyperlink: ({ children, node }) => {
          const url = node?.data?.url || '#';
          const isExternal = Boolean(url.startsWith('https://'));

          return isExternal ? (
            <ExternalLink href={url} className="text-base">
              {children}
            </ExternalLink>
          ) : (
            <InternalLink href={url} className="text-base">
              {children}
            </InternalLink>
          );
        },
        paragraph: ({ text, children }) =>
          text && <p className="text-labelSecondary text-base mb-10 leading-7">{children}</p>,
        list: ({ children }) => <ul className="list-disc list-inside !text-labelSecondary">{children}</ul>,
        listItem: ({ children }) => <li className="ml-4 mt-2">{children}</li>,
        oList: ({ children }) => <ol className="list-decimal !text-labelSecondary">{children}</ol>,
        oListItem: ({ children }) => <li className=" ml-4 mt-2">{children}</li>,
        strong: ({ children }) => <>{children}</>,
        ...components,
      }}
      {...leftProps}
    />
  );
};
