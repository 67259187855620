/* eslint-disable functional/immutable-data */
import getConfig from 'next/config';

import { Address } from '@globalTypes/contracts/metamask';

import { processWhitelistAddresses } from './whitelistAddresses';

type AvailableFeaturesType = typeof AvailableFeatures;

type KeysOfUnion<T> = T extends T ? keyof T : never;

type ExtractExtending<A, Type> = {
  [K in keyof A as A[K] extends Type ? K : never]: A[K];
};

/**
 * @todo investigate typing and fix
 */
// type DistributiveExtract<A, Type> = A extends A
//   ? ExtractExtending<A, Type>
//   : never;

const { publicRuntimeConfig } = getConfig();

enum FeatureGroup {
  AmplitudeEventLogs = 'AMPLITUDE_EVENT_LOGS',
  GameHub = 'GAME_HUB',
  Notification = 'NOTIFICATION',
  NewGames = 'NEW_GAMES',
  Tournaments = 'TOURNAMENTS',
  SearchEngineIndexing = 'SEARCH_ENGINE_INDEXING',
  Marketplace = 'MARKETPLACE',
  Build = 'BUILD',
  CCE = 'CCE',
}

const AvailableFeatures = {
  [FeatureGroup.AmplitudeEventLogs]: {
    AVAILABLE: false,
  },
  [FeatureGroup.GameHub]: {
    IGNORE_PRISMIC_REDIRECT_LOCATION: false,
    SHOW_INDEX_GENRES: false,
    V3_AVAILABLE: [] as Array<Address>,
  },
  [FeatureGroup.Notification]: {
    AVAILABLE: false,
  },
  [FeatureGroup.NewGames]: {
    AVAILABLE: false,
  },
  [FeatureGroup.Tournaments]: {
    AVAILABLE: false,
  },
  [FeatureGroup.SearchEngineIndexing]: {
    AVAILABLE: false,
  },
  [FeatureGroup.Marketplace]: {
    FILTERS: false,
  },
  [FeatureGroup.Build]: {
    STATIC_GENERATION: false,
  },
  [FeatureGroup.CCE]: {
    AVAILABLE: false,
  },
} satisfies Record<FeatureGroup, Record<string, boolean | Array<Address>>>;

export type FeatureChecker = {
  isDevelopmentAppEnv: () => boolean;
  isProductionAppEnv: () => boolean;
  isPreviewAppEnv: () => boolean;
  isAvailable: <
    Group extends keyof AvailableFeaturesType,
    Feature extends KeysOfUnion<ExtractExtending<AvailableFeaturesType[Group], boolean>>,
  >(
    group: Group,
    feature: Feature,
  ) => boolean;
  isAvailableFor: <
    Group extends keyof AvailableFeaturesType,
    Feature extends KeysOfUnion<ExtractExtending<AvailableFeaturesType[Group], Array<Address>>>,
  >(
    group: Group,
    feature: Feature,
    address: Address,
  ) => boolean;
};

export const createFeatureChecker = (env: NodeJS.ProcessEnv): FeatureChecker => {
  const envToggleFeatures = Object.keys(env).filter(key => key.startsWith(publicRuntimeConfig.FEATURE_TOGGLE_PREFIX));

  const appEnv = env['APP_ENV'];

  envToggleFeatures.forEach(
    <
      Group extends keyof AvailableFeaturesType,
      FilteredFeature extends KeysOfUnion<ExtractExtending<AvailableFeaturesType[Group], boolean>>,
    >(
      key: string,
    ) => {
      const splitted = key.split(publicRuntimeConfig.ENV_FEATURE_DELIMITER);
      const [, group, feature] = splitted as [string, Group, FilteredFeature];
      if (AvailableFeatures[group]?.[feature] == null) {
        return;
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      AvailableFeatures[group][feature] = env[key] === 'true';
    },
  );

  const envWhitelistFeatures = Object.keys(env).filter(key =>
    key.startsWith(publicRuntimeConfig.FEATURE_WHITELIST_PREFIX),
  );

  envWhitelistFeatures.forEach(
    <
      Group extends keyof AvailableFeaturesType,
      Feature extends KeysOfUnion<ExtractExtending<AvailableFeaturesType[Group], Array<Address>>>,
    >(
      key: string,
    ) => {
      const splitted = key.split(publicRuntimeConfig.ENV_FEATURE_DELIMITER);
      const [, group, feature] = splitted as [string, Group, Feature];
      if (AvailableFeatures[group]?.[feature] == null) {
        return;
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      AvailableFeatures[group][feature] = processWhitelistAddresses(env[key] ?? '');
    },
  );

  return {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    isAvailable(group, feature) {
      return AvailableFeatures[group][feature];
    },
    isAvailableFor(group, feature, address) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return AvailableFeatures[group][feature].includes(address.toLowerCase() as Address);
    },

    isDevelopmentAppEnv: () => appEnv === 'development',
    isProductionAppEnv: () => appEnv === 'production',
    isPreviewAppEnv: () => appEnv === 'preview',
  };
};

const featureChecker = createFeatureChecker(publicRuntimeConfig);

export { featureChecker, FeatureGroup };
