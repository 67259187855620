import { createPlaydexClient } from '@gql/apollo-client';
import {
  InputMaybe,
  TournamentsDocument,
  TournamentsFilterInput,
  TournamentsQuery,
  TournamentsQueryVariables,
} from '@graphql/generated';

export const fetchTournaments = async (
  params: {
    filter?: InputMaybe<TournamentsFilterInput>;
    pagination?: {
      limit: number;
      offset: number;
    };
  } = {},
): Promise<TournamentsQuery['tournaments']['items'] | null> => {
  const { filter, pagination = { limit: 100, offset: 0 } } = params;

  const apolloClient = createPlaydexClient();
  const {
    data: { tournaments },
  } = await apolloClient.query<TournamentsQuery, TournamentsQueryVariables>({
    query: TournamentsDocument,
    variables: {
      filter,
      pagination,
    },
  });

  return tournaments?.items ?? null;
};
