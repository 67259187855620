import React, { PropsWithChildren } from 'react';

export const GuardWrapper = <
  WC extends React.ElementType,
  E extends PropsWithChildren<React.ComponentPropsWithoutRef<WC>>,
  P extends Partial<E>,
>({
  patch,
  wrapper,
  children,
  ...props
}: {
  patch: P;
  wrapper?: WC;
  children: React.ReactNode;
} & Omit<E, keyof P>): JSX.Element | null => {
  const Wrapper = wrapper || React.Fragment;
  const satisfy = Object.values(patch).every(Boolean);
  return satisfy ? (
    <Wrapper {...props} {...patch}>
      {children}
    </Wrapper>
  ) : (
    <>{children}</>
  );
};
