import { Disclosure } from '@headlessui/react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

import { isSchemaObject, JsonSchema, JsonSchemaPropertiesValues } from '@features/common';
import { AdditionalMetaKeyword } from '@features/gamehub';

import { FieldIcon } from './FieldIcon';
import { FilterInputFactory, FilterHiddenInput } from './FilterInput';

type FilterFieldProps = {
  name: string;
  schema: JsonSchemaPropertiesValues;
  rootSchema: JsonSchema;
};

export const FilterField = ({ name, schema, rootSchema }: FilterFieldProps): JSX.Element => {
  if (isSchemaObject(schema) && schema[AdditionalMetaKeyword.ReadOnly] === true) {
    return <FilterHiddenInput name={name} />;
  }

  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button className="flex p-5 w-full place-items-center bg-earlGrey-500">
            <span>{schema.title ?? '--'}</span>
            <FieldIcon className="ml-auto">{open ? <IoIosArrowUp /> : <IoIosArrowDown />}</FieldIcon>
          </Disclosure.Button>
          <Disclosure.Panel className="p-5">
            <FilterInputFactory name={name} schema={schema} rootSchema={rootSchema} />
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};
