import { useApolloClient } from '@apollo/client';
import * as Sentry from '@sentry/nextjs';
import React, { useContext, useMemo } from 'react';

import { useToken } from '@features/auth';
import { persistCurrentUrlBeforeOauthRedirect } from '@features/common';
import { LinkSocialConnectionMutation, SocialConnectionProvider } from '@graphql/generated';
import createUserProfileService from '@services/userProfileService/createUserProfileService';
import { UserSocialConnection } from '@services/userProfileService/types';
import { apolloErrorHandler } from '@utils/error';

export const INITIAL_DISCORD_USER = undefined;
export const EMPTY_DISCORD_USER = null;

type UseDiscordValues = {
  discordUser: UserSocialConnection | null | undefined;
  redirectOnDiscord: () => Promise<void>;
  connectDiscordByCode: (authCode: string) => Promise<void | LinkSocialConnectionMutation['linkSocialConnection']>;
};

const DiscordContext = React.createContext<UseDiscordValues>({
  discordUser: INITIAL_DISCORD_USER,
  connectDiscordByCode: async () => undefined,
  redirectOnDiscord: async () => undefined,
});

export const DiscordProvider = ({ children }: { children?: React.ReactNode }): JSX.Element => {
  const playdexClient = useApolloClient();
  const { linkSocialConnection, getSocialConnectionAuthLink } = createUserProfileService({ playdexClient });
  const { user } = useToken();

  const discordUser = useMemo(() => {
    if (user) {
      const discordUserConnection = user.socialConnections?.find(
        ({ provider }) => provider === SocialConnectionProvider.Discord,
      );
      return discordUserConnection || EMPTY_DISCORD_USER;
    }
  }, [user]);

  const redirectOnDiscord = async (): Promise<void> => {
    try {
      const { authLink } =
        (await getSocialConnectionAuthLink({
          provider: SocialConnectionProvider.Discord,
        })) || {};

      if (authLink) {
        persistCurrentUrlBeforeOauthRedirect();
        window.open(authLink, '_self');
      }
    } catch (error) {
      Sentry.captureException(error);
      apolloErrorHandler(error);
    }
  };

  const connectDiscordByCode = async (
    authCode: string,
  ): Promise<void | LinkSocialConnectionMutation['linkSocialConnection']> => {
    return await linkSocialConnection({
      codeOrToken: authCode,
      provider: SocialConnectionProvider.Discord,
    });
  };

  return (
    <DiscordContext.Provider
      value={{
        discordUser,
        redirectOnDiscord,
        connectDiscordByCode,
      }}
    >
      {children}
    </DiscordContext.Provider>
  );
};

export const useDiscord = (): UseDiscordValues => {
  const { discordUser, redirectOnDiscord, connectDiscordByCode } = useContext(DiscordContext);

  return {
    discordUser,
    redirectOnDiscord,
    connectDiscordByCode,
  };
};
