/**
 * @deprecated
 */
export enum GameIds {
  AXIE_INFINITY = 'axie-infinity',
}

export const HOME_PAGE_PARENT_ROUTE = '/';
export const GAMES_PAGE_PARENT_ROUTE = '/games';
export const BALANCE_PAGE_PARENT_ROUTE = '/balance';
export const INVENTORY_ASSETS_ROUTE = '/inventory/assets';
export const SETTINGS_ROUTE = '/settings';
export const TOURNAMENTS_ROUTE = '/tournaments';
export const BLOG_ROUTE = '/blog';

// AXIE-INFINITY ROUTES //

export const AXIE_INFINITY_MARKETPLACE_BUNDLE = `/${GameIds.AXIE_INFINITY}/bundles/[id]`;
export const AXIE_INFINITY_CONFIRM_BUNDLE_RENT = `/${GameIds.AXIE_INFINITY}/bundles/[id]/confirm`;
export const AXIE_INFINITY_EDIT_BUNDLE = `/${GameIds.AXIE_INFINITY}/bundles/[id]/edit/`;

export const AXIE_INFINITY_HISTORY_ORDER = `/history/${GameIds.AXIE_INFINITY}/orders/[id]`;

export const AXIE_INFINITY_HISTORY = '/history/axie-infinity';

// GAME ROUTES

export const GAME_HISTORY_ORDERS_PAGE_ROUTE = '/history/orders/[orderId]';
