import { BuildQueryURLArgs, predicate } from '@prismicio/client';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();
const { APP_ENV } = publicRuntimeConfig;

export const gamesQueryDefaultParams: Partial<BuildQueryURLArgs> = {
  orderings: [{ field: 'my.game.rankNumber', direction: 'asc' }],
  predicates: [predicate.fulltext('my.game.marketplaceVisibility', APP_ENV)],
  graphQuery: `
    {
      game {
        name
        applicationName
        dashboardVisibility
        marketplaceVisibility
        smartContracts
        status
        redirectLocation
        cover
        logo
        isFeatured
        isTrending
        genres {
          genre {
            ...on genre {
              ...genreFields
            }
          }
        }
      }
    }
  `,
};

export const baseSidebarGamesQueryParams: Partial<BuildQueryURLArgs> = {
  orderings: [{ field: 'my.game.rankNumber', direction: 'asc' }],
  predicates: [
    predicate.fulltext('my.game.marketplaceVisibility', APP_ENV),
    predicate.at('my.game.showInSidebar', true),
  ],
  graphQuery: `
    {
      game {
        name
        applicationName
        redirectLocation
        marketplaceVisibility
        hasMarketplace
        sidebarStatus
        logo
        showInSidebar
      }
    }
  `,
};

export const sidebarGamesWithMarketplaceQueryParams: Partial<BuildQueryURLArgs> = {
  orderings: [{ field: 'my.game.rankNumber', direction: 'asc' }],
  predicates: [predicate.at('my.game.hasMarketplace', true)],
  graphQuery: `
    {
      game {
        name
        applicationName
        redirectLocation
        marketplaceVisibility
        hasMarketplace
        sidebarStatus
        logo
        showInSidebar
      }
    }
  `,
};

export const filterGameQueryParams: Partial<BuildQueryURLArgs> = {
  graphQuery: `
  {
    game {
      name
      applicationName
      dashboardVisibility
      marketplaceVisibility
      smartContracts
      redirectLocation
      cover
      status
      logo
      blockchains {
        blockchain {
          ... on supportedblockchain {
            ...supportedblockchainFields
          }
        }
      }
      genres {
        genre {
          ... on genre {
            ...genreFields
          }
        }
      }
      devices {
        device {
          ... on device {
            ...deviceFields
          }
        }
      }
    }
  }
  `,
};

export const gameNftAssetsPageSize = 12;

/* eslint-disable max-len */

/**
 * Query to pull data with linked documents for selected game.
 * Since Prismic docs on GraphQuery are outdated, it was built using examples from community forum threads.
 * Please see https://community.prismic.io/t/how-to-query-data-of-content-relation-field-in-slice/10637
 */
export const selectedGameGraphQuery = `
{
  game {
    ...gameFields
    genres {
      genre {
        ... on genre {
          ...genreFields
        }
      }
    }
    blockchains {
      blockchain {
        ... on supportedblockchain {
          ...supportedblockchainFields
        }
      }
    }
    devices {
      device {
        ... on device {
          ...deviceFields
        }
      }
    }
    slices {
      ... on media_gallery {
        variation {
          ... on default {
            items {
              image
              video
            }
          }
        }
      }
      ... on assets_grid {
        variation {
          ... on default {
            items {
              asset {
                ... on asset {
                  ...assetFields
                }
              }
            }
          }
        }
      }
      ... on game_tokens {
        variation {
          ... on default {
            items {
              icon
              name
              link
            }
          }
        }
      }
      ... on game_review {
        variation {
          ... on default {
            items {
              reviewerName
              reviewDate
              title
              reviewText
              rating
            }
          }
        }
      }
    }
  }
}
`;
