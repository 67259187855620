import { createContext, useContext } from 'react';

export type UrlBuilderContextType = {
  buildGameUrl: (params: { gameId: string }) => string;
  buildGameAssetUrl: (params: { gameId: string; tokenId: string }) => string;
};

const initialValue = {} as UrlBuilderContextType;

export const UrlBuilderContext = createContext<UrlBuilderContextType>(initialValue);

export const useUrlBuilder = (): UrlBuilderContextType => {
  return useContext(UrlBuilderContext);
};
