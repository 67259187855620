import type { List } from '@globalTypes/entities';
import type { AssetsGridSliceItem } from '@globalTypes/prismic/documents';
import type { GameNftAssetsQuery, NftAssetsFilterInput } from '@graphql/generated';
import { Suspended } from '@utils/suspend';

export type GameNftAssetsQueryItem = NonNullable<GameNftAssetsQuery['nftAssets']['items']>[number];
export type NftAssetItem = GameNftAssetsQueryItem | AssetsGridSliceItem;
export type NftAssetList = List<NftAssetItem>;

export type NftAssetResourceFactory = (offset: number, filter?: NftAssetsFilterInput) => Suspended<NftAssetList>;

export enum AdditionalMetaKeyword {
  Definitions = '$defs',
  OriginalFieldName = 'x-originalFieldName',
  EnumTitles = 'x-enumTitles',
  Units = 'x-units',
  ReadOnly = 'x-readOnly',
}

export enum AdditionalFormat {
  DurationSeconds = 'durationSeconds',
}
