/* eslint-disable functional/no-throw-statement */
import { useApolloClient } from '@apollo/client';
import * as Sentry from '@sentry/nextjs';
import { useAccount, useSignMessage } from 'wagmi';

import { useToken } from '@features/auth';
import { createWalletConnectorService } from '@features/auth/services/metamaskService';
import { setJWTToCookies } from '@utils/cookies';
import { EthereumProviderRpcErrorCodes, isEthereumError } from '@utils/error';
import { notice, ToastType } from '@utils/notice';

type UseWalletConnectorsLogin = () => {
  connectWalletToPlaydex: () => Promise<string | void>;
};

export const useWalletConnectorsLogin: UseWalletConnectorsLogin = () => {
  const playdexClient = useApolloClient();
  const { getAccessToken } = createWalletConnectorService(playdexClient);

  const { address: walletAddress } = useAccount();

  const { signMessageAsync } = useSignMessage();
  const { connect } = useToken();

  const connectWalletToPlaydex = async (): Promise<string | void> => {
    try {
      if (!walletAddress) return notice(ToastType.ERROR, 'Not valid wallet address, please try again later!');

      const userTokenResult = await getAccessToken({ walletAddress, signMessageAsync });

      if (userTokenResult.__typename === 'UserSignedInType') {
        const {
          authDetails: { authToken },
        } = userTokenResult;

        setJWTToCookies(authToken);
        await connect(authToken);

        return authToken;
      } else if (userTokenResult.__typename === 'UserExceptionType') {
        const { message } = userTokenResult;
        notice(ToastType.ERROR, message);
      }
    } catch (error) {
      if (isEthereumError(error) && error.code === EthereumProviderRpcErrorCodes.UserRejectedRequest) {
        /**
         * It's expected behavior in case of user's pressed cancel button in Metamesk popup
         * No need to send it to Sentry
         */
        throw error;
      }
      Sentry.captureException(error);
      notice(ToastType.ERROR, 'Something went wrong! \n Please try again!');

      throw error;
    }
  };

  return {
    connectWalletToPlaydex,
  };
};
