import { crispPushEvent } from '@features/common';
import {
  BaseTournamentEventPayload,
  TournamentEventWithSourcePayload,
  TOURNAMENTS_ANALYTICS_EVENT,
} from '@features/tournaments';

export const pushTournamentTabOpenedCrispEvent = (data: TournamentEventWithSourcePayload): void =>
  crispPushEvent(TOURNAMENTS_ANALYTICS_EVENT.TOURNAMENT_TAB_OPENED, data);

export const pushTournamentOpenedCrispEvent = (data: TournamentEventWithSourcePayload): void =>
  crispPushEvent(TOURNAMENTS_ANALYTICS_EVENT.TOURNAMENT_OPENED, data);

export const pushTournamentRegisterStartedCrispEvent = (data: BaseTournamentEventPayload): void =>
  crispPushEvent(TOURNAMENTS_ANALYTICS_EVENT.TOURNAMENT_REGISTER_STARTED, data);

export const pushTournamentRegisterSuccessCrispEvent = (data: BaseTournamentEventPayload): void =>
  crispPushEvent(TOURNAMENTS_ANALYTICS_EVENT.TOURNAMENT_REGISTER_SUCCESS, data);

export const pushTournamentRegisterFailedCrispEvent = (data: BaseTournamentEventPayload): void =>
  crispPushEvent(TOURNAMENTS_ANALYTICS_EVENT.TOURNAMENT_REGISTER_FAILED, data);
