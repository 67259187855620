import Link from 'next/link';

import { RentAssetFormSchema } from './formSchema';

type FormCheckboxes = Array<{
  checkboxRefName: keyof RentAssetFormSchema;
  CheckboxChildren: () => JSX.Element;
}>;

const TermsOfUseCheckboxChildren = (): JSX.Element => (
  <div>
    By&nbsp;checking this box, I&nbsp;agree to&nbsp;
    <Link href="/static/tos.html" passHref className="underline text-yellow-500">
      Terms of Use
    </Link>
  </div>
);

const TermsOfDeductionCheckboxChildren = (): JSX.Element => <div>I have read the terms carefully</div>;

const formCheckboxes: FormCheckboxes = [
  {
    checkboxRefName: 'termsOfUse',
    CheckboxChildren: TermsOfUseCheckboxChildren,
  },
  {
    checkboxRefName: 'termsOfDeduction',
    CheckboxChildren: TermsOfDeductionCheckboxChildren,
  },
];

export default formCheckboxes;
