import { DateTime, DateTime as DateTimePackage } from 'luxon';

/**
 * @description This function format `eventDate` depend on difference between time when event happen and current time. And has custom format variants:
 * 1. If time difference is less than 1hour, return value has format="MM mins";
 * 2. If time difference is less than 24 hour and more than 1, return value has format="HHh MMm";
 * 3. If time difference is less than 2 days but more than 2 days, return value="Yesterday";
 * 4. In all other case, return value format="MMM DD, YYYY, HH:MM AM";
 */
export const formatEventDate = (eventDate: DateTime): string => {
  const now = DateTimePackage.now();
  const diff = now.diff(eventDate, ['hours', 'minutes', 'days']);

  if (diff.as('hours') < 1) {
    return diff.as('minutes').toFixed(0) + ' min';
  } else if (diff.as('days') < 1) {
    const hours = Math.floor(diff.as('hours'));
    const minutes = Math.floor(diff.minus({ hours }).as('minutes'));

    return `${hours}h ${minutes}min`;
  }

  if (diff.as('days') < 2) {
    return 'Yesterday';
  }

  return eventDate.toLocaleString(DateTimePackage.DATETIME_MED);
};
