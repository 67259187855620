import { useEffect, useState } from 'react';

import { RawRowData } from '@features/tournaments/types';

import { filterRowsByGroup } from '../utils';

export const useGroupRows = (rows: RawRowData[], activeGroup: string | null): RawRowData[] => {
  const [filteredRows, setFilteredRows] = useState<RawRowData[]>([]);

  useEffect(() => {
    filterRowsByGroup(rows, activeGroup).then(setFilteredRows);
  }, [rows, activeGroup]);

  return filteredRows;
};
