import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSONObject: any;
};

export type AccountBalanceGetInput = {
  currency: Currency;
};

export type AccountBalanceType = {
  __typename?: 'AccountBalanceType';
  amount: Scalars['String'];
  createdAt: Scalars['DateTime'];
  currency: Currency;
  reference: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export enum ApplicationName {
  Aavegotchi = 'Aavegotchi',
  AnitoLegends = 'AnitoLegends',
  AxieInfinity = 'AxieInfinity',
  CallOfDutyMobile = 'CallOfDutyMobile',
  CryptoRaiders = 'CryptoRaiders',
  CyBall = 'CyBall',
  FancyBirds = 'FancyBirds',
  KanimalClash = 'KanimalClash',
  Kryptomon = 'Kryptomon',
  MobileLegendsBangBang = 'MobileLegendsBangBang',
  NiNoKuniCrossWorlds = 'NiNoKuniCrossWorlds',
  PlaydexTestGame = 'PlaydexTestGame',
  RebelBots = 'RebelBots',
  Revoland = 'Revoland',
  SpecialForceRush = 'SpecialForceRush',
  TapFantasy = 'TapFantasy',
  ThetanArena = 'ThetanArena',
  Warena = 'Warena',
  Wonderhero = 'Wonderhero',
  WorldOfDefish = 'WorldOfDefish',
}

export type ApplicationSettingsType = {
  __typename?: 'ApplicationSettingsType';
  schemas: Scalars['JSONObject'];
};

export type AuthDetailsType = {
  __typename?: 'AuthDetailsType';
  authToken: Scalars['String'];
};

export type AxieDetails = {
  __typename?: 'AxieDetails';
  id: Scalars['Int'];
};

export type AxieInfinityDetails = {
  __typename?: 'AxieInfinityDetails';
  banPenalty?: Maybe<Scalars['Int']>;
  dailyRentAmount?: Maybe<Scalars['Float']>;
  dailyRentCurrency?: Maybe<Currency>;
  depositAmount?: Maybe<Scalars['Float']>;
  depositCurrency?: Maybe<Currency>;
  description?: Maybe<Scalars['String']>;
  earlyReturnPenalty?: Maybe<Scalars['Int']>;
  earningSplitPct?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  isActivationRequested: Scalars['Boolean'];
  isActive: Scalars['Boolean'];
  isPublished: Scalars['Boolean'];
  levelUpBonuses: Array<Scalars['Int']>;
  maxRentDays?: Maybe<Scalars['Int']>;
  minMmrPenalty?: Maybe<Scalars['Int']>;
  minRentDays?: Maybe<Scalars['Int']>;
  mmr: Scalars['Int'];
  mmrLossTolerance?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  roninAddress: Scalars['String'];
};

export type AxieInfinityStakingDetail = {
  __typename?: 'AxieInfinityStakingDetail';
  contractDailyEarningsSlp: Scalars['Int'];
  contractEndAt: Scalars['DateTime'];
  contractStartAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  earnedSlp: Scalars['Int'];
  id: Scalars['ID'];
  ownerId: Scalars['String'];
  teamId: Scalars['String'];
};

export type AxieInfinityStakingDetailCreateInput = {
  contractDailyEarningsSlp: Scalars['Float'];
  contractEndAt: Scalars['String'];
  contractStartAt: Scalars['String'];
  ownerId: Scalars['String'];
  teamId: Scalars['String'];
};

export type AxieInfinityStakingTeamsPerformance = {
  __typename?: 'AxieInfinityStakingTeamsPerformance';
  dailyEarningsSlp: Scalars['Int'];
  earnedSlp: Scalars['Int'];
  teamsCount: Scalars['Int'];
  teamsPerformance: Array<AxieInfinityTeamPerformance>;
};

export type AxieInfinityTeamPerformance = {
  __typename?: 'AxieInfinityTeamPerformance';
  dailyEarningsSlp: Scalars['Int'];
  earnedSlp: Scalars['Int'];
  teamId: Scalars['String'];
};

export type CancelRentalContractInput = {
  reason?: InputMaybe<Scalars['String']>;
  rentalContractId: Scalars['String'];
};

export type ChallengeNonceInput = {
  walletAddress: Scalars['String'];
};

export type ChallengeNonceType = {
  __typename?: 'ChallengeNonceType';
  message: Scalars['String'];
  nonce: Scalars['Int'];
};

export type CreateLeaderboardInput = {
  tournamentId: Scalars['String'];
};

export type CreateOfferInput = {
  applicationName: ApplicationName;
  deferredTokenId: Scalars['String'];
  terms: Scalars['JSONObject'];
  txHash: Scalars['String'];
};

export type CreateOrderInput = {
  offerIds: Array<Scalars['String']>;
  terms: Scalars['JSONObject'];
};

export type CreatePlaydexApiKeyInput = {
  abacRules: Array<Scalars['JSONObject']>;
  expiresAt?: InputMaybe<Scalars['DateTime']>;
  name: Scalars['String'];
};

export type CreateTournamentInput = {
  applicationName?: InputMaybe<ApplicationName>;
  bracketType?: InputMaybe<TournamentBracketType>;
  description?: InputMaybe<Scalars['String']>;
  extraFieldsSchema?: InputMaybe<TournamentExtraFieldsSchema>;
  gameUid?: InputMaybe<Scalars['String']>;
  imageUrl: Scalars['String'];
  isFinished: Scalars['Boolean'];
  isPreviewVisible?: InputMaybe<Scalars['Boolean']>;
  isVisible: Scalars['Boolean'];
  minTeamSize?: InputMaybe<Scalars['Int']>;
  participantsLimitPerTeam?: InputMaybe<Scalars['Int']>;
  participationType: TournamentParticipationType;
  playUrl?: InputMaybe<Scalars['String']>;
  previewRank?: InputMaybe<Scalars['Int']>;
  prize?: InputMaybe<Scalars['String']>;
  registrationCompleteMessage?: InputMaybe<Scalars['String']>;
  registrationEndDate: Scalars['String'];
  rulesUrl?: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
  teamExtraFieldsSchema?: InputMaybe<TournamentExtraFieldsSchema>;
  title: Scalars['String'];
  tournamentFinishDate?: InputMaybe<Scalars['String']>;
  tournamentStartDate: Scalars['String'];
  typeformUrl?: InputMaybe<Scalars['String']>;
  webhookApiKey?: InputMaybe<Scalars['String']>;
  webhookUrl?: InputMaybe<Scalars['String']>;
};

export type CreateTournamentParticipantInput = {
  extraFieldValues?: InputMaybe<Scalars['JSONObject']>;
  teamCode?: InputMaybe<Scalars['String']>;
  tournamentId: Scalars['String'];
};

export type CreateTournamentTeamInput = {
  participantExtraFieldValues?: InputMaybe<Scalars['JSONObject']>;
  teamExtraFieldValues?: InputMaybe<Scalars['JSONObject']>;
  tournamentId: Scalars['String'];
};

export enum Currency {
  Bnb = 'BNB',
  Cbt = 'CBT',
  Hnr = 'HNR',
  Slp = 'SLP',
}

export type ExtraBorrowerDataType = {
  __typename?: 'ExtraBorrowerDataType';
  address: Scalars['String'];
  energy: Scalars['Int'];
  winLoss: WinLossTimeStatType;
};

export type GetByIdParamsInput = {
  id: Scalars['String'];
};

export enum LeaderboardErrorDescription {
  IncorrectRewardStructure = 'IncorrectRewardStructure',
  LeaderboardNotFound = 'LeaderboardNotFound',
  WalletColumnNotFound = 'WalletColumnNotFound',
}

export type LeaderboardExceptionType = {
  __typename?: 'LeaderboardExceptionType';
  description: LeaderboardErrorDescription;
  message: Scalars['String'];
};

export type LeaderboardResult = LeaderboardExceptionType | LeaderboardType;

export type LeaderboardRewardClaimersInput = {
  id: Scalars['String'];
};

export type LeaderboardType = {
  __typename?: 'LeaderboardType';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  ranking: Scalars['JSONObject'];
  rewardStructure?: Maybe<Scalars['JSONObject']>;
  tournamentId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type LendingLoanList = {
  __typename?: 'LendingLoanList';
  items?: Maybe<Array<LoanType>>;
  total: Scalars['Int'];
};

export enum LoanStatus {
  Lending = 'Lending',
}

export type LoanType = {
  __typename?: 'LoanType';
  borrowDate: Scalars['Float'];
  borrowerReward: Scalars['Int'];
  cancelAt: Scalars['Int'];
  class: Scalars['Int'];
  createdAt: Scalars['String'];
  extraBorrowerData: ExtraBorrowerDataType;
  isWhiteList: Scalars['Boolean'];
  lender: Scalars['String'];
  overall: Scalars['Int'];
  ownerReward: Scalars['Int'];
  sharePercent: Scalars['Int'];
  startedAt: Scalars['Float'];
  status: LoanStatus;
  timeUpdateReward: Scalars['Float'];
  tokenId: Scalars['ID'];
  totalBorrowerReward: Scalars['Int'];
  totalOwnerReward: Scalars['Int'];
  updatedAt: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  cancelAccountReservation: Scalars['Boolean'];
  cancelRentalContract: RentalContractType;
  createLeaderboard: LeaderboardType;
  /** Create deferred offer that doesn't contain NFT asset reference, yet. It won't appear in list queries below until all remained data added by a blockchain monitor. */
  createOffer: OwnerOfferType;
  createOrder: OrderType;
  createPayoutRequest: PayoutRequestType;
  createPlaydexApiKey: PlaydexApiKeyType;
  createStakingDetail: AxieInfinityStakingDetail;
  createTournament: TournamentType;
  createTournamentParticipant: TournamentParticipantCreateResult;
  createTournamentTeam: TournamentTeamCreateResult;
  createWaitlistRequest: WaitlistRequestType;
  finalizeOnboarding: Scalars['Boolean'];
  getAuthLink: UserSocialLinkGetAuthLinkType;
  getCustomAuthToken: UserAuthCustomResult;
  linkPhoneNumber: UserProfileResult;
  linkSocialConnection: UserProfileResult;
  reserveAccount: AxieInfinityDetails;
  retryTournamentParticipantRegistration: TournamentParticipantCreateResult;
  sendNotification: NotificationResult;
  sendNotificationBroadcast: SendNotificationBroadcastType;
  signInWithMetamask: UserSignedInResult;
  socialSignIn: UserSocialSignedInResult;
  updateLeaderboard: LeaderboardType;
  updateProfile: UserProfileResult;
  updateTournament: TournamentType;
  upsertNftAsset: NftAssetType;
  upsertSocialEventReward: SocialEventRewardType;
};

export type MutationCancelRentalContractArgs = {
  cancelRentalContractInput: CancelRentalContractInput;
};

export type MutationCreateLeaderboardArgs = {
  createLeaderboardInput: CreateLeaderboardInput;
};

export type MutationCreateOfferArgs = {
  createOfferInput: CreateOfferInput;
};

export type MutationCreateOrderArgs = {
  createOrderInput: CreateOrderInput;
};

export type MutationCreatePayoutRequestArgs = {
  payoutRequestCreateInput: PayoutRequestCreateInput;
};

export type MutationCreatePlaydexApiKeyArgs = {
  createPlaydexApiKeyInput: CreatePlaydexApiKeyInput;
};

export type MutationCreateStakingDetailArgs = {
  stakingDetailCreateParams: AxieInfinityStakingDetailCreateInput;
};

export type MutationCreateTournamentArgs = {
  createTournamentInput: CreateTournamentInput;
};

export type MutationCreateTournamentParticipantArgs = {
  createTournamentParticipantInput: CreateTournamentParticipantInput;
};

export type MutationCreateTournamentTeamArgs = {
  createTournamentTeamInput: CreateTournamentTeamInput;
};

export type MutationCreateWaitlistRequestArgs = {
  featureId: Scalars['String'];
};

export type MutationGetAuthLinkArgs = {
  userSocialLinkGetAuthLinkInput: UserSocialLinkGetAuthLinkInput;
};

export type MutationLinkPhoneNumberArgs = {
  userLinkPhoneNumberInput: UserLinkPhoneNumberInput;
};

export type MutationLinkSocialConnectionArgs = {
  userLinkSocialConnectionInput: UserLinkSocialConnectionInput;
};

export type MutationRetryTournamentParticipantRegistrationArgs = {
  retryTournamentParticipantRegistrationInput: RetryTournamentParticipantRegistrationInput;
};

export type MutationSendNotificationArgs = {
  sendNotificationInput: SendNotificationInput;
};

export type MutationSendNotificationBroadcastArgs = {
  sendNotificationBroadcastInput: SendNotificationBroadcastInput;
};

export type MutationSignInWithMetamaskArgs = {
  userSignInWithMetamaskInput: UserSignInWithMetamaskInput;
};

export type MutationSocialSignInArgs = {
  userSocialSignInInput: UserSocialSignInInput;
};

export type MutationUpdateLeaderboardArgs = {
  updateLeaderboardInput: UpdateLeaderboardInput;
};

export type MutationUpdateProfileArgs = {
  userProfileUpdateInput: UserProfileUpdateInput;
};

export type MutationUpdateTournamentArgs = {
  updateTournamentInput: UpdateTournamentInput;
};

export type MutationUpsertNftAssetArgs = {
  input: NftAssetUpsertInput;
};

export type MutationUpsertSocialEventRewardArgs = {
  upsertSocialEventRewardInput: UpsertSocialEventRewardInput;
};

export enum NftAssetDisplayStatus {
  Available = 'Available',
  Rented = 'Rented',
}

export enum NftAssetScalarFieldEnum {
  ApplicationName = 'applicationName',
  BlockchainAccountId = 'blockchainAccountId',
  CreatedAt = 'createdAt',
  Id = 'id',
  IsAvailable = 'isAvailable',
  IsBurned = 'isBurned',
  IsDeleted = 'isDeleted',
  OwnerId = 'ownerId',
  Properties = 'properties',
  SmartContractName = 'smartContractName',
  TokenId = 'tokenId',
  TokenUri = 'tokenUri',
  UpdatedAt = 'updatedAt',
}

export type NftAssetType = {
  __typename?: 'NftAssetType';
  applicationName: ApplicationName;
  createdAt: Scalars['DateTime'];
  displayStatus: NftAssetDisplayStatus;
  id: Scalars['ID'];
  isAvailable: Scalars['Boolean'];
  offers: Array<OfferType>;
  ownerId: Scalars['String'];
  properties: Scalars['JSONObject'];
  smartContractName: Scalars['String'];
  tokenId: Scalars['String'];
  tokenUri: Scalars['String'];
};

export type NftAssetTypeOffersArgs = {
  take: Scalars['Int'];
};

export type NftAssetUpsertInput = {
  applicationName: ApplicationName;
  blockchainAccountId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  isAvailable: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  ownerId: Scalars['ID'];
  properties: Scalars['JSONObject'];
  smartContractName: SmartContractName;
  tokenId: Scalars['String'];
  tokenUri: Scalars['String'];
};

export type NftAssetsFilterInput = {
  applicationName?: InputMaybe<ApplicationName>;
  attributes?: InputMaybe<Scalars['JSONObject']>;
  isAvailable?: InputMaybe<Scalars['Boolean']>;
  offersTerms?: InputMaybe<Scalars['JSONObject']>;
};

export type NftAssetsSortInput = {
  direction: SortOrder;
  order: NftAssetScalarFieldEnum;
};

export type NftAssetsType = {
  __typename?: 'NftAssetsType';
  items?: Maybe<Array<NftAssetType>>;
  total: Scalars['Int'];
};

export enum NotificationDeliveryType {
  Broadcast = 'Broadcast',
  Personal = 'Personal',
}

export enum NotificationErrorDescription {
  NotificationRecipientNotFound = 'NotificationRecipientNotFound',
}

export type NotificationExceptionType = {
  __typename?: 'NotificationExceptionType';
  description: NotificationErrorDescription;
  message: Scalars['String'];
};

export type NotificationResult = NotificationExceptionType | NotificationType;

export type NotificationType = {
  __typename?: 'NotificationType';
  categoryId?: Maybe<Scalars['String']>;
  categorySlug?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deliveryType: NotificationDeliveryType;
  expireAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  isRead: Scalars['Boolean'];
  recipientId: Scalars['String'];
  resourceUrl?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export enum OfferState {
  Pending = 'pending',
  Published = 'published',
  Unpublished = 'unpublished',
}

export type OfferType = {
  __typename?: 'OfferType';
  applicationName: ApplicationName;
  createdAt: Scalars['DateTime'];
  deferredTokenId: Scalars['String'];
  id: Scalars['ID'];
  nftAsset?: Maybe<NftAssetType>;
  smartContractName: Scalars['String'];
  state: OfferState;
  status: Status;
  terms: Scalars['JSONObject'];
  updatedAt: Scalars['DateTime'];
};

export type OffersFilterInput = {
  applicationName?: InputMaybe<ApplicationName>;
};

export type OffersType = {
  __typename?: 'OffersType';
  items?: Maybe<Array<OfferType>>;
  total: Scalars['Int'];
};

export type OffsetPagination = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export type OrderItemPartyType = {
  __typename?: 'OrderItemPartyType';
  role: OrderPartyRole;
  terms: Scalars['JSONObject'];
};

export type OrderItemType = {
  __typename?: 'OrderItemType';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  nftAsset: NftAssetType;
  orderId: Scalars['String'];
  parties: Array<OrderItemPartyType>;
  rentalContract?: Maybe<RentalContractType>;
  terms: Scalars['JSONObject'];
};

export type OrderList = {
  __typename?: 'OrderList';
  items?: Maybe<Array<OrderType>>;
  total: Scalars['Int'];
};

export type OrderLogType = {
  __typename?: 'OrderLogType';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  orderId: Scalars['String'];
  status: Status;
  updatedBy: Scalars['String'];
};

export enum OrderPartyRole {
  Application = 'Application',
  AssetOwner = 'AssetOwner',
  Community = 'Community',
  Manager = 'Manager',
  Platform = 'Platform',
  Player = 'Player',
  Renter = 'Renter',
}

export type OrderType = {
  __typename?: 'OrderType';
  createdAt: Scalars['DateTime'];
  customerId: Scalars['String'];
  id: Scalars['ID'];
  items: Array<OrderItemType>;
  logs: Array<OrderLogType>;
  status: Status;
  updatedAt: Scalars['DateTime'];
};

export type OwnerOfferType = {
  __typename?: 'OwnerOfferType';
  applicationName: ApplicationName;
  createdAt: Scalars['DateTime'];
  deferredTokenId: Scalars['String'];
  id: Scalars['ID'];
  nftAsset?: Maybe<NftAssetType>;
  rentalContract?: Maybe<RentalContractType>;
  smartContractName: Scalars['String'];
  state: OfferState;
  status: Status;
  terms: Scalars['JSONObject'];
  txHash?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type OwnerOffersType = {
  __typename?: 'OwnerOffersType';
  items?: Maybe<Array<OwnerOfferType>>;
  total: Scalars['Int'];
};

export type PayoutRequestCreateInput = {
  amount: Scalars['String'];
  currency: Currency;
  destination: Scalars['String'];
};

export type PayoutRequestList = {
  __typename?: 'PayoutRequestList';
  items?: Maybe<Array<PayoutRequestType>>;
  total: Scalars['Int'];
};

export type PayoutRequestListInput = {
  currency?: InputMaybe<Currency>;
};

export type PayoutRequestType = {
  __typename?: 'PayoutRequestType';
  amount: Scalars['String'];
  createdAt: Scalars['DateTime'];
  currency: Currency;
  destination: Scalars['String'];
  id: Scalars['ID'];
  status: Status;
  txnHash?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type PlaydexApiKeyType = {
  __typename?: 'PlaydexApiKeyType';
  abacRules: Array<Scalars['JSONObject']>;
  createdAt: Scalars['DateTime'];
  expiresAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  key: Scalars['String'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type Query = {
  __typename?: 'Query';
  accountBalance: AccountBalanceType;
  applicationSettings: ApplicationSettingsType;
  challengeNonce: ChallengeNonceType;
  getOrderById: OrderType;
  getPublishedOfferById: OfferType;
  getStakingTeamsPerformance: AxieInfinityStakingTeamsPerformance;
  leaderboard: LeaderboardResult;
  leaderboardRewardClaimers: SocialEventRewardClaimersType;
  lendingLoans: LendingLoanList;
  /** Published offers that the owner might change or cancel, and the customer can accept right now */
  listPublishedOffers: OffersType;
  listRentalContracts: RentalContractList;
  listStakingDetails: Array<AxieInfinityStakingDetail>;
  me: UserProfileResult;
  myTournaments: TournamentList;
  nftAssets: NftAssetsType;
  offer: OfferType;
  offers: OffersType;
  ownerNftAssets: Array<NftAssetType>;
  /** Following offer is available only for an asset owner who've created that offer */
  ownerOffer: OwnerOfferType;
  /** Following offers that the owner might change or cancel prior to being accepted by a customer in the future */
  ownerOffers: OwnerOffersType;
  payoutRequests: PayoutRequestList;
  socialEventReward: SocialEventRewardResult;
  socialEventRewardUnion: SocialEventRewardUnionResult;
  tournament: TournamentType;
  tournamentExtraFieldsSchemas: TournamentExtraFieldsSchemasType;
  tournamentParticipant?: Maybe<TournamentParticipantType>;
  tournamentParticipants: TournamentParticipantListType;
  tournamentTeamByCode?: Maybe<TournamentTeamType>;
  tournamentTeamByTournamentId?: Maybe<TournamentTeamType>;
  tournaments: TournamentList;
  tournamentsPreview: TournamentList;
  waitlistRequest?: Maybe<WaitlistRequestType>;
};

export type QueryAccountBalanceArgs = {
  accountBalanceGetInput: AccountBalanceGetInput;
};

export type QueryApplicationSettingsArgs = {
  smartContractName: SmartContractName;
};

export type QueryChallengeNonceArgs = {
  challengeNonceInput: ChallengeNonceInput;
};

export type QueryGetOrderByIdArgs = {
  orderGetByIdParams: GetByIdParamsInput;
};

export type QueryGetPublishedOfferByIdArgs = {
  offerGetByIdParams: GetByIdParamsInput;
};

export type QueryLeaderboardArgs = {
  id: Scalars['String'];
};

export type QueryLeaderboardRewardClaimersArgs = {
  leaderboardRewardClaimersInput: LeaderboardRewardClaimersInput;
};

export type QueryLendingLoansArgs = {
  pagination: OffsetPagination;
};

export type QueryListPublishedOffersArgs = {
  filter?: InputMaybe<OffersFilterInput>;
  pagination: OffsetPagination;
};

export type QueryListRentalContractsArgs = {
  pagination: OffsetPagination;
};

export type QueryMyTournamentsArgs = {
  pagination: OffsetPagination;
};

export type QueryNftAssetsArgs = {
  filter?: InputMaybe<NftAssetsFilterInput>;
  pagination: OffsetPagination;
  sort?: InputMaybe<NftAssetsSortInput>;
};

export type QueryOfferArgs = {
  offerGetByIdParams: GetByIdParamsInput;
};

export type QueryOffersArgs = {
  filter?: InputMaybe<OffersFilterInput>;
  pagination: OffsetPagination;
};

export type QueryOwnerOfferArgs = {
  offerGetByIdParams: GetByIdParamsInput;
};

export type QueryOwnerOffersArgs = {
  filter?: InputMaybe<OffersFilterInput>;
  pagination: OffsetPagination;
};

export type QueryPayoutRequestsArgs = {
  filter?: InputMaybe<PayoutRequestListInput>;
  pagination: OffsetPagination;
};

export type QuerySocialEventRewardArgs = {
  eventId: Scalars['String'];
};

export type QuerySocialEventRewardUnionArgs = {
  eventId: Scalars['String'];
};

export type QueryTournamentArgs = {
  slug: Scalars['String'];
};

export type QueryTournamentExtraFieldsSchemasArgs = {
  slug: Scalars['String'];
};

export type QueryTournamentParticipantArgs = {
  tournamentId: Scalars['String'];
};

export type QueryTournamentParticipantsArgs = {
  pagination: OffsetPagination;
};

export type QueryTournamentTeamByCodeArgs = {
  code: Scalars['String'];
};

export type QueryTournamentTeamByTournamentIdArgs = {
  tournamentId: Scalars['String'];
};

export type QueryTournamentsArgs = {
  filter?: InputMaybe<TournamentsFilterInput>;
  pagination: OffsetPagination;
};

export type QueryWaitlistRequestArgs = {
  featureId: Scalars['String'];
};

export type RentalContractList = {
  __typename?: 'RentalContractList';
  items?: Maybe<Array<RentalContractType>>;
  total: Scalars['Int'];
};

export type RentalContractLogType = {
  __typename?: 'RentalContractLogType';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  reason?: Maybe<Scalars['String']>;
  rentalContractId: Scalars['String'];
  state?: Maybe<RentalContractState>;
  status?: Maybe<Status>;
  updatedBy: Scalars['String'];
};

export enum RentalContractState {
  Active = 'active',
  Cancelled = 'cancelled',
  Completed = 'completed',
  Inactive = 'inactive',
}

export type RentalContractType = {
  __typename?: 'RentalContractType';
  applicationName: ApplicationName;
  createdAt: Scalars['DateTime'];
  customerId: Scalars['String'];
  endAt: Scalars['DateTime'];
  id: Scalars['ID'];
  logs: Array<RentalContractLogType>;
  nftAssetId: Scalars['String'];
  offerId: Scalars['String'];
  orderId: Scalars['String'];
  orderItemId: Scalars['String'];
  smartContractName: Scalars['String'];
  startAt: Scalars['DateTime'];
  state: RentalContractState;
  status: Status;
  updatedAt: Scalars['DateTime'];
};

export type RetryTournamentParticipantRegistrationInput = {
  tournamentId: Scalars['String'];
};

export type SendNotificationBroadcastInput = {
  categoryId?: InputMaybe<Scalars['String']>;
  categorySlug?: InputMaybe<Scalars['String']>;
  deliveryType: NotificationDeliveryType;
  expireAt?: InputMaybe<Scalars['DateTime']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  resourceUrl?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type SendNotificationBroadcastType = {
  __typename?: 'SendNotificationBroadcastType';
  workflowId: Scalars['String'];
};

export type SendNotificationInput = {
  categoryId?: InputMaybe<Scalars['String']>;
  categorySlug?: InputMaybe<Scalars['String']>;
  deliveryType: NotificationDeliveryType;
  /**
   * If set, a TTL policy would be applied.
   * The policy will delete the document within 72 hours after the specified moment.
   * For additional details @see {@link https://firebase.google.com/docs/firestore/ttl}
   */
  expireAt?: InputMaybe<Scalars['DateTime']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  recipientId: Scalars['String'];
  /**
   * The URL can contain a protocol, top-level domain, etc.
   * Or it can be a relative one (points to some internal resource).
   * So, URL like `/internal/resource/1` should accept as well.
   */
  resourceUrl?: InputMaybe<Scalars['String']>;
  text: Scalars['String'];
  title: Scalars['String'];
};

export enum SmartContractName {
  Aavegotchi = 'Aavegotchi',
  AnitoLegends = 'AnitoLegends',
  AxieInfinity = 'AxieInfinity',
  CryptoRaiders = 'CryptoRaiders',
  CyBloc = 'CyBloc',
  FancyBirds = 'FancyBirds',
  Kryptomon = 'Kryptomon',
  NiNoKuniCrossWorlds = 'NiNoKuniCrossWorlds',
  PlaydexTestGame = 'PlaydexTestGame',
  RebelBots = 'RebelBots',
  Revoland = 'Revoland',
  SpecialForceRush = 'SpecialForceRush',
  TapFantasy = 'TapFantasy',
  ThetanArena = 'ThetanArena',
  Warena = 'Warena',
  Wonderhero = 'Wonderhero',
}

export enum SocialConnectionProvider {
  Discord = 'Discord',
  Facebook = 'Facebook',
  Google = 'Google',
}

export type SocialEventRewardClaimersType = {
  __typename?: 'SocialEventRewardClaimersType';
  rewardClaimers: Array<Scalars['JSONObject']>;
};

export enum SocialEventRewardErrorDescription {
  SocialEventRewardAmountsNotValid = 'SocialEventRewardAmountsNotValid',
  SocialEventRewardNotFound = 'SocialEventRewardNotFound',
}

export type SocialEventRewardExceptionType = {
  __typename?: 'SocialEventRewardExceptionType';
  description: SocialEventRewardErrorDescription;
  message: Scalars['String'];
};

export type SocialEventRewardResult = SocialEventRewardExceptionType | SocialEventRewardType;

export type SocialEventRewardType = {
  __typename?: 'SocialEventRewardType';
  chainId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  erc20Addresses: Array<Scalars['String']>;
  erc20Amounts: Array<Scalars['String']>;
  eventId: Scalars['ID'];
  id: Scalars['ID'];
  merkleTree: Scalars['JSONObject'];
  rewardStructure: Scalars['JSONObject'];
  smartContractAddress: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type SocialEventRewardUnionResult = SocialEventRewardExceptionType | SocialEventRewardType;

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

export enum Status {
  Completed = 'completed',
  Failed = 'failed',
  Processing = 'processing',
}

export enum TournamentBracketType {
  DoubleElimination = 'DoubleElimination',
  RoundRobin = 'RoundRobin',
  SingleElimination = 'SingleElimination',
}

export enum TournamentErrorDescription {
  ProvidedTournamentIdAndTeamTournamentIdMismatch = 'ProvidedTournamentIdAndTeamTournamentIdMismatch',
  TournamentExtraFieldsSchemaMismatch = 'TournamentExtraFieldsSchemaMismatch',
  TournamentTeamCodeIsNotDefined = 'TournamentTeamCodeIsNotDefined',
  TournamentTeamDoesntExist = 'TournamentTeamDoesntExist',
  TournamentTeamMembersLimitReached = 'TournamentTeamMembersLimitReached',
}

export type TournamentExceptionType = {
  __typename?: 'TournamentExceptionType';
  description: TournamentErrorDescription;
  message: Scalars['String'];
};

export enum TournamentExtraFieldsSchema {
  AxieSingle = 'AxieSingle',
  CallOfDutyMobileTeam = 'CallOfDutyMobileTeam',
  DebugAllFields = 'DebugAllFields',
  Email = 'Email',
  EmailReferredBy = 'EmailReferredBy',
  MobileLegendsBangBangTeam = 'MobileLegendsBangBangTeam',
  Wallet = 'Wallet',
  WalletReferredBy = 'WalletReferredBy',
  WithTeamName = 'WithTeamName',
}

export type TournamentExtraFieldsSchemasType = {
  __typename?: 'TournamentExtraFieldsSchemasType';
  participantExtraFieldsSchema?: Maybe<Scalars['JSONObject']>;
  schema?: Maybe<Scalars['JSONObject']>;
  teamExtraFieldsSchema?: Maybe<Scalars['JSONObject']>;
};

export type TournamentList = {
  __typename?: 'TournamentList';
  items?: Maybe<Array<TournamentType>>;
  total: Scalars['Int'];
};

export type TournamentParticipantCreateResult = TournamentExceptionType | TournamentParticipantType;

export type TournamentParticipantListType = {
  __typename?: 'TournamentParticipantListType';
  items?: Maybe<Array<TournamentParticipantType>>;
  total: Scalars['Int'];
};

export enum TournamentParticipantStatus {
  CheckedIn = 'CheckedIn',
  Qualified = 'Qualified',
  Removed = 'Removed',
}

export type TournamentParticipantType = {
  __typename?: 'TournamentParticipantType';
  createdAt: Scalars['DateTime'];
  extraFieldValues?: Maybe<Scalars['JSONObject']>;
  id: Scalars['ID'];
  playUrl?: Maybe<Scalars['String']>;
  registrationStatus: TournamentRegistrationStatus;
  registrationStatusMessage?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['String']>;
  tournamentId: Scalars['String'];
  tournamentParticipantStatus?: Maybe<TournamentParticipantStatus>;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
};

export enum TournamentParticipationType {
  Individual = 'Individual',
  None = 'None',
  Team = 'Team',
}

export enum TournamentRegistrationStatus {
  Confirmed = 'Confirmed',
  Failed = 'Failed',
  Processing = 'Processing',
  Registered = 'Registered',
  Rejected = 'Rejected',
}

export type TournamentTeamCreateResult = TournamentExceptionType | TournamentTeamType;

export type TournamentTeamType = {
  __typename?: 'TournamentTeamType';
  captainUserId: Scalars['String'];
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  extraFieldValues?: Maybe<Scalars['JSONObject']>;
  id: Scalars['ID'];
  members: Array<TournamentParticipantType>;
  tournamentId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type TournamentType = {
  __typename?: 'TournamentType';
  applicationName?: Maybe<ApplicationName>;
  bracketType?: Maybe<TournamentBracketType>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  extraFieldsSchema?: Maybe<TournamentExtraFieldsSchema>;
  gameUid?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imageUrl: Scalars['String'];
  isFinished: Scalars['Boolean'];
  isPreviewVisible?: Maybe<Scalars['Boolean']>;
  isVisible: Scalars['Boolean'];
  leaderboard?: Maybe<LeaderboardType>;
  minTeamSize?: Maybe<Scalars['Int']>;
  participants: Array<TournamentParticipantType>;
  participantsLimitPerTeam?: Maybe<Scalars['Int']>;
  participationType: TournamentParticipationType;
  playUrl?: Maybe<Scalars['String']>;
  previewRank?: Maybe<Scalars['Int']>;
  prize?: Maybe<Scalars['String']>;
  registrationCompleteMessage?: Maybe<Scalars['String']>;
  registrationEndDate: Scalars['DateTime'];
  rulesUrl?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  teamExtraFieldsSchema?: Maybe<TournamentExtraFieldsSchema>;
  title: Scalars['String'];
  tournamentFinishDate?: Maybe<Scalars['DateTime']>;
  tournamentStartDate: Scalars['DateTime'];
  typeformUrl?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  webhookUrl?: Maybe<Scalars['String']>;
};

export type TournamentsFilterInput = {
  applicationName?: InputMaybe<ApplicationName>;
  gameUid?: InputMaybe<Scalars['String']>;
};

export type UpdateLeaderboardInput = {
  id: Scalars['String'];
  rewardStructure: Scalars['JSONObject'];
};

export type UpdateTournamentInput = {
  applicationName?: InputMaybe<ApplicationName>;
  bracketType?: InputMaybe<TournamentBracketType>;
  description?: InputMaybe<Scalars['String']>;
  extraFieldsSchema?: InputMaybe<TournamentExtraFieldsSchema>;
  gameUid?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  isFinished?: InputMaybe<Scalars['Boolean']>;
  isPreviewVisible?: InputMaybe<Scalars['Boolean']>;
  isVisible?: InputMaybe<Scalars['Boolean']>;
  minTeamSize?: InputMaybe<Scalars['Int']>;
  participantsLimitPerTeam?: InputMaybe<Scalars['Int']>;
  participationType?: InputMaybe<TournamentParticipationType>;
  playUrl?: InputMaybe<Scalars['String']>;
  previewRank?: InputMaybe<Scalars['Int']>;
  prize?: InputMaybe<Scalars['String']>;
  registrationCompleteMessage?: InputMaybe<Scalars['String']>;
  registrationEndDate?: InputMaybe<Scalars['String']>;
  rulesUrl?: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
  teamExtraFieldsSchema?: InputMaybe<TournamentExtraFieldsSchema>;
  title?: InputMaybe<Scalars['String']>;
  tournamentFinishDate?: InputMaybe<Scalars['String']>;
  tournamentStartDate?: InputMaybe<Scalars['String']>;
  typeformUrl?: InputMaybe<Scalars['String']>;
  webhookApiKey?: InputMaybe<Scalars['String']>;
  webhookUrl?: InputMaybe<Scalars['String']>;
};

export type UpsertSocialEventRewardInput = {
  addressRewards: Array<Scalars['JSONObject']>;
  chainId: Scalars['Int'];
  erc20Addresses: Array<Scalars['String']>;
  erc20Amounts: Array<Scalars['String']>;
  eventId: Scalars['String'];
  rewardStructure: Scalars['JSONObject'];
  smartContractAddress: Scalars['String'];
};

export type UserAuthCustomResult = UserAuthCustomType | UserExceptionType;

export type UserAuthCustomType = {
  __typename?: 'UserAuthCustomType';
  authDetails: AuthDetailsType;
};

export enum UserErrorDescription {
  UserAuthCustomTokenRetrieveFailed = 'UserAuthCustomTokenRetrieveFailed',
  UserAuthSignatureValidationFailed = 'UserAuthSignatureValidationFailed',
  UserNotFound = 'UserNotFound',
  UserPhoneInvalidBelonging = 'UserPhoneInvalidBelonging',
  UserSocialInvalidSocialConnectionBelonging = 'UserSocialInvalidSocialConnectionBelonging',
  UserSocialInvalidSocialConnectionCredentials = 'UserSocialInvalidSocialConnectionCredentials',
  UserSocialInvalidSocialConnectionProvider = 'UserSocialInvalidSocialConnectionProvider',
}

export type UserExceptionType = {
  __typename?: 'UserExceptionType';
  description: UserErrorDescription;
  message: Scalars['String'];
};

export type UserLinkPhoneNumberInput = {
  token: Scalars['String'];
};

export type UserLinkSocialConnectionInput = {
  codeOrToken: Scalars['String'];
  provider: SocialConnectionProvider;
};

export type UserProfileResult = UserExceptionType | UserProfileType;

export type UserProfileType = {
  __typename?: 'UserProfileType';
  diallingCode?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isEmailConfirmed: Scalars['Boolean'];
  isPhoneConfirmed: Scalars['Boolean'];
  isWaiting: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  orders: OrderList;
  phoneNumber?: Maybe<Scalars['String']>;
  socialConnections: Array<UserSocialType>;
  updatedAt: Scalars['DateTime'];
  walletAddress?: Maybe<Scalars['String']>;
};

export type UserProfileTypeOrdersArgs = {
  pagination: OffsetPagination;
};

export type UserProfileUpdateInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type UserSignInWithMetamaskInput = {
  message: Scalars['String'];
  nonce: Scalars['Float'];
  signature: Scalars['String'];
  walletAddress: Scalars['String'];
};

export type UserSignedInResult = UserExceptionType | UserSignedInType;

export type UserSignedInType = {
  __typename?: 'UserSignedInType';
  authDetails: AuthDetailsType;
  user: UserProfileType;
};

export type UserSocialLinkGetAuthLinkInput = {
  provider: SocialConnectionProvider;
};

export type UserSocialLinkGetAuthLinkType = {
  __typename?: 'UserSocialLinkGetAuthLinkType';
  authLink: Scalars['String'];
};

export type UserSocialSignInInput = {
  provider: SocialConnectionProvider;
  /** Token or code to validate. */
  token: Scalars['String'];
};

export type UserSocialSignedInResult = UserExceptionType | UserSocialSignedInType;

export type UserSocialSignedInType = {
  __typename?: 'UserSocialSignedInType';
  authDetails: AuthDetailsType;
  user: UserProfileType;
  userSocial: UserSocialType;
};

export type UserSocialType = {
  __typename?: 'UserSocialType';
  createdAt: Scalars['DateTime'];
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  pictureUrl?: Maybe<Scalars['String']>;
  provider: SocialConnectionProvider;
  socialId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
};

export type WaitlistRequestType = {
  __typename?: 'WaitlistRequestType';
  createdAt: Scalars['DateTime'];
  featureId: Scalars['String'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
};

export type WinLossStatType = {
  __typename?: 'WinLossStatType';
  address: Scalars['String'];
  loss: Scalars['Int'];
  win: Scalars['Int'];
};

export type WinLossTimeStatType = {
  __typename?: 'WinLossTimeStatType';
  all: WinLossStatType;
  monthly: WinLossStatType;
  weekly: WinLossStatType;
};

export type LeaderboardFragment = {
  __typename?: 'LeaderboardType';
  id: string;
  ranking: any;
  rewardStructure?: any | null;
  updatedAt: any;
};

export type NftAssetFragmentFragment = {
  __typename?: 'NftAssetType';
  applicationName: ApplicationName;
  createdAt: any;
  displayStatus: NftAssetDisplayStatus;
  id: string;
  isAvailable: boolean;
  ownerId: string;
  properties: any;
  smartContractName: string;
  tokenId: string;
  tokenUri: string;
};

export type OfferItemFragmentFragment = {
  __typename?: 'OfferType';
  applicationName: ApplicationName;
  createdAt: any;
  deferredTokenId: string;
  id: string;
  smartContractName: string;
  state: OfferState;
  status: Status;
  terms: any;
  nftAsset?: {
    __typename?: 'NftAssetType';
    applicationName: ApplicationName;
    createdAt: any;
    displayStatus: NftAssetDisplayStatus;
    id: string;
    isAvailable: boolean;
    ownerId: string;
    properties: any;
    smartContractName: string;
    tokenId: string;
    tokenUri: string;
  } | null;
};

export type UserSocialConnectionsFragmentFragment = {
  __typename?: 'UserSocialType';
  provider: SocialConnectionProvider;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  displayName?: string | null;
  pictureUrl?: string | null;
  socialId: string;
  userId: string;
  createdAt: any;
  updatedAt: any;
};

export type TournamentFragmentFragment = {
  __typename?: 'TournamentType';
  id: string;
  title: string;
  description?: string | null;
  slug: string;
  applicationName?: ApplicationName | null;
  gameUid?: string | null;
  bracketType?: TournamentBracketType | null;
  imageUrl: string;
  typeformUrl?: string | null;
  rulesUrl?: string | null;
  isFinished: boolean;
  isVisible: boolean;
  participationType: TournamentParticipationType;
  minTeamSize?: number | null;
  participantsLimitPerTeam?: number | null;
  prize?: string | null;
  playUrl?: string | null;
  registrationCompleteMessage?: string | null;
  registrationEndDate: any;
  tournamentStartDate: any;
  tournamentFinishDate?: any | null;
  extraFieldsSchema?: TournamentExtraFieldsSchema | null;
  teamExtraFieldsSchema?: TournamentExtraFieldsSchema | null;
  updatedAt: any;
  createdAt: any;
  leaderboard?: { __typename?: 'LeaderboardType'; id: string } | null;
};

export type TournamentParticipantFragment = {
  __typename?: 'TournamentParticipantType';
  userId: string;
  tournamentId: string;
  tournamentParticipantStatus?: TournamentParticipantStatus | null;
  registrationStatus: TournamentRegistrationStatus;
  registrationStatusMessage?: string | null;
  extraFieldValues?: any | null;
  playUrl?: string | null;
  createdAt: any;
};

export type TournamentTeamFragmentFragment = {
  __typename?: 'TournamentTeamType';
  code: string;
  captainUserId: string;
  tournamentId: string;
  extraFieldValues?: any | null;
  members: Array<{
    __typename?: 'TournamentParticipantType';
    userId: string;
    tournamentId: string;
    tournamentParticipantStatus?: TournamentParticipantStatus | null;
    registrationStatus: TournamentRegistrationStatus;
    registrationStatusMessage?: string | null;
    extraFieldValues?: any | null;
    playUrl?: string | null;
    createdAt: any;
  }>;
};

export type UserExceptionTypeFragmentFragment = {
  __typename?: 'UserExceptionType';
  description: UserErrorDescription;
  message: string;
};

export type UserProfileFragmentFragment = {
  __typename?: 'UserProfileType';
  id: string;
  name?: string | null;
  email?: string | null;
  diallingCode?: string | null;
  phoneNumber?: string | null;
  isActive: boolean;
  isWaiting: boolean;
  isEmailConfirmed: boolean;
  isPhoneConfirmed: boolean;
  walletAddress?: string | null;
  updatedAt: any;
  socialConnections: Array<{
    __typename?: 'UserSocialType';
    provider: SocialConnectionProvider;
    email?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    displayName?: string | null;
    pictureUrl?: string | null;
    socialId: string;
    userId: string;
    createdAt: any;
    updatedAt: any;
  }>;
};

export type UserProfileFragmentWithoutSocialConnectionsFragment = {
  __typename?: 'UserProfileType';
  id: string;
  name?: string | null;
  email?: string | null;
  diallingCode?: string | null;
  phoneNumber?: string | null;
  isActive: boolean;
  isWaiting: boolean;
  isEmailConfirmed: boolean;
  isPhoneConfirmed: boolean;
  walletAddress?: string | null;
  updatedAt: any;
};

export type UserExceptionFragmentFragment = {
  __typename?: 'UserExceptionType';
  message: string;
  description: UserErrorDescription;
};

export type WaitlistRequestFragmentFragment = {
  __typename?: 'WaitlistRequestType';
  id: string;
  userId: string;
  featureId: string;
  isActive: boolean;
  createdAt: any;
  updatedAt: any;
};

export type AuthDetailsFragment = { __typename?: 'AuthDetailsType'; authToken: string };

export type ChalengeNonceFragmentFragment = { __typename?: 'ChallengeNonceType'; message: string; nonce: number };

export type UserSignedInSuccessFragmentFragment = {
  __typename?: 'UserSignedInType';
  authDetails: { __typename?: 'AuthDetailsType'; authToken: string };
  user: {
    __typename?: 'UserProfileType';
    id: string;
    name?: string | null;
    email?: string | null;
    diallingCode?: string | null;
    phoneNumber?: string | null;
    isActive: boolean;
    isWaiting: boolean;
    isEmailConfirmed: boolean;
    isPhoneConfirmed: boolean;
    walletAddress?: string | null;
    updatedAt: any;
  };
};

export type UserSignedInExceptionFragmentFragment = {
  __typename?: 'UserExceptionType';
  message: string;
  description: UserErrorDescription;
};

export type GetAuthLinkMutationVariables = Exact<{
  userSocialLinkGetAuthLinkInput: UserSocialLinkGetAuthLinkInput;
}>;

export type GetAuthLinkMutation = {
  __typename?: 'Mutation';
  getAuthLink: { __typename?: 'UserSocialLinkGetAuthLinkType'; authLink: string };
};

export type GetCustomAuthTokenMutationVariables = Exact<{ [key: string]: never }>;

export type GetCustomAuthTokenMutation = {
  __typename?: 'Mutation';
  getCustomAuthToken:
    | { __typename: 'UserAuthCustomType'; authDetails: { __typename?: 'AuthDetailsType'; authToken: string } }
    | { __typename: 'UserExceptionType'; description: UserErrorDescription; message: string };
};

export type LinkPhoneNumberMutationVariables = Exact<{
  userLinkPhoneNumberInput: UserLinkPhoneNumberInput;
}>;

export type LinkPhoneNumberMutation = {
  __typename?: 'Mutation';
  linkPhoneNumber:
    | { __typename?: 'UserExceptionType'; message: string; description: UserErrorDescription }
    | {
        __typename?: 'UserProfileType';
        id: string;
        name?: string | null;
        email?: string | null;
        diallingCode?: string | null;
        phoneNumber?: string | null;
        isActive: boolean;
        isWaiting: boolean;
        isEmailConfirmed: boolean;
        isPhoneConfirmed: boolean;
        walletAddress?: string | null;
        updatedAt: any;
        socialConnections: Array<{
          __typename?: 'UserSocialType';
          provider: SocialConnectionProvider;
          email?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          displayName?: string | null;
          pictureUrl?: string | null;
          socialId: string;
          userId: string;
          createdAt: any;
          updatedAt: any;
        }>;
      };
};

export type LinkSocialConnectionMutationVariables = Exact<{
  userLinkSocialConnectionInput: UserLinkSocialConnectionInput;
}>;

export type LinkSocialConnectionMutation = {
  __typename?: 'Mutation';
  linkSocialConnection:
    | { __typename?: 'UserExceptionType'; message: string; description: UserErrorDescription }
    | {
        __typename?: 'UserProfileType';
        id: string;
        name?: string | null;
        email?: string | null;
        diallingCode?: string | null;
        phoneNumber?: string | null;
        isActive: boolean;
        isWaiting: boolean;
        isEmailConfirmed: boolean;
        isPhoneConfirmed: boolean;
        walletAddress?: string | null;
        updatedAt: any;
        socialConnections: Array<{
          __typename?: 'UserSocialType';
          provider: SocialConnectionProvider;
          email?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          displayName?: string | null;
          pictureUrl?: string | null;
          socialId: string;
          userId: string;
          createdAt: any;
          updatedAt: any;
        }>;
      };
};

export type SignInWithMetamaskMutationVariables = Exact<{
  userSignInWithMetamaskInput: UserSignInWithMetamaskInput;
}>;

export type SignInWithMetamaskMutation = {
  __typename?: 'Mutation';
  signInWithMetamask:
    | { __typename: 'UserExceptionType'; message: string; description: UserErrorDescription }
    | {
        __typename: 'UserSignedInType';
        authDetails: { __typename?: 'AuthDetailsType'; authToken: string };
        user: {
          __typename?: 'UserProfileType';
          id: string;
          name?: string | null;
          email?: string | null;
          diallingCode?: string | null;
          phoneNumber?: string | null;
          isActive: boolean;
          isWaiting: boolean;
          isEmailConfirmed: boolean;
          isPhoneConfirmed: boolean;
          walletAddress?: string | null;
          updatedAt: any;
        };
      };
};

export type CreateOfferMutationVariables = Exact<{
  input: CreateOfferInput;
}>;

export type CreateOfferMutation = {
  __typename?: 'Mutation';
  createOffer: {
    __typename?: 'OwnerOfferType';
    id: string;
    state: OfferState;
    status: Status;
    applicationName: ApplicationName;
    terms: any;
    smartContractName: string;
    deferredTokenId: string;
    createdAt: any;
    nftAsset?: {
      __typename?: 'NftAssetType';
      id: string;
      properties: any;
      applicationName: ApplicationName;
      isAvailable: boolean;
      ownerId: string;
      tokenId: string;
      tokenUri: string;
      smartContractName: string;
      createdAt: any;
    } | null;
  };
};

export type CreateOrderMutationVariables = Exact<{
  input: CreateOrderInput;
}>;

export type CreateOrderMutation = { __typename?: 'Mutation'; createOrder: { __typename?: 'OrderType'; id: string } };

export type CreatePayoutRequestMutationVariables = Exact<{
  input: PayoutRequestCreateInput;
}>;

export type CreatePayoutRequestMutation = {
  __typename?: 'Mutation';
  createPayoutRequest: {
    __typename?: 'PayoutRequestType';
    id: string;
    currency: Currency;
    txnHash?: string | null;
    status: Status;
    amount: string;
    destination: string;
    createdAt: any;
  };
};

export type CreateTournamentParticipantMutationVariables = Exact<{
  createTournamentParticipantInput: CreateTournamentParticipantInput;
}>;

export type CreateTournamentParticipantMutation = {
  __typename?: 'Mutation';
  createTournamentParticipant:
    | { __typename: 'TournamentExceptionType'; description: TournamentErrorDescription; message: string }
    | {
        __typename: 'TournamentParticipantType';
        userId: string;
        tournamentId: string;
        tournamentParticipantStatus?: TournamentParticipantStatus | null;
        registrationStatus: TournamentRegistrationStatus;
        registrationStatusMessage?: string | null;
        extraFieldValues?: any | null;
        playUrl?: string | null;
        createdAt: any;
      };
};

export type CreateTournamentTeamMutationVariables = Exact<{
  createTournamentTeamInput: CreateTournamentTeamInput;
}>;

export type CreateTournamentTeamMutation = {
  __typename?: 'Mutation';
  createTournamentTeam:
    | { __typename: 'TournamentExceptionType'; description: TournamentErrorDescription; message: string }
    | {
        __typename: 'TournamentTeamType';
        code: string;
        captainUserId: string;
        tournamentId: string;
        extraFieldValues?: any | null;
        members: Array<{
          __typename?: 'TournamentParticipantType';
          userId: string;
          tournamentId: string;
          tournamentParticipantStatus?: TournamentParticipantStatus | null;
          registrationStatus: TournamentRegistrationStatus;
          registrationStatusMessage?: string | null;
          extraFieldValues?: any | null;
          playUrl?: string | null;
          createdAt: any;
        }>;
      };
};

export type CreateWaitlistRequestMutationVariables = Exact<{
  featureId: Scalars['String'];
}>;

export type CreateWaitlistRequestMutation = {
  __typename?: 'Mutation';
  createWaitlistRequest: {
    __typename?: 'WaitlistRequestType';
    id: string;
    userId: string;
    featureId: string;
    isActive: boolean;
    createdAt: any;
    updatedAt: any;
  };
};

export type UpdateUserProfileMutationVariables = Exact<{
  input: UserProfileUpdateInput;
}>;

export type UpdateUserProfileMutation = {
  __typename?: 'Mutation';
  updateProfile:
    | { __typename: 'UserExceptionType'; description: UserErrorDescription; message: string }
    | {
        __typename: 'UserProfileType';
        id: string;
        name?: string | null;
        email?: string | null;
        diallingCode?: string | null;
        phoneNumber?: string | null;
        isActive: boolean;
        isEmailConfirmed: boolean;
        isPhoneConfirmed: boolean;
        updatedAt: any;
      };
};

export type RetryTournamentParticipantRegistrationMutationVariables = Exact<{
  input: RetryTournamentParticipantRegistrationInput;
}>;

export type RetryTournamentParticipantRegistrationMutation = {
  __typename?: 'Mutation';
  retryTournamentParticipantRegistration:
    | { __typename?: 'TournamentExceptionType' }
    | {
        __typename?: 'TournamentParticipantType';
        userId: string;
        tournamentId: string;
        tournamentParticipantStatus?: TournamentParticipantStatus | null;
        registrationStatus: TournamentRegistrationStatus;
        registrationStatusMessage?: string | null;
        extraFieldValues?: any | null;
        playUrl?: string | null;
        createdAt: any;
      };
};

export type SocialSignInMutationVariables = Exact<{
  userSocialSignInInput: UserSocialSignInInput;
}>;

export type SocialSignInMutation = {
  __typename?: 'Mutation';
  socialSignIn:
    | { __typename: 'UserExceptionType'; description: UserErrorDescription; message: string }
    | {
        __typename: 'UserSocialSignedInType';
        authDetails: { __typename?: 'AuthDetailsType'; authToken: string };
        userSocial: {
          __typename?: 'UserSocialType';
          email?: string | null;
          displayName?: string | null;
          pictureUrl?: string | null;
          updatedAt: any;
        };
      };
};

export type GetAccountBalanceQueryVariables = Exact<{
  input: AccountBalanceGetInput;
}>;

export type GetAccountBalanceQuery = {
  __typename?: 'Query';
  accountBalance: {
    __typename?: 'AccountBalanceType';
    amount: string;
    currency: Currency;
    reference: string;
    createdAt: any;
  };
};

export type ApplicationSettingsQueryVariables = Exact<{
  name: SmartContractName;
}>;

export type ApplicationSettingsQuery = {
  __typename?: 'Query';
  applicationSettings: { __typename?: 'ApplicationSettingsType'; schemas: any };
};

export type ChallengeNonceQueryVariables = Exact<{
  challengeNonceInput: ChallengeNonceInput;
}>;

export type ChallengeNonceQuery = {
  __typename?: 'Query';
  challengeNonce: { __typename?: 'ChallengeNonceType'; message: string; nonce: number };
};

export type GetMarketplaceOfferByIdQueryVariables = Exact<{
  input: GetByIdParamsInput;
}>;

export type GetMarketplaceOfferByIdQuery = {
  __typename?: 'Query';
  offer: {
    __typename?: 'OfferType';
    applicationName: ApplicationName;
    createdAt: any;
    deferredTokenId: string;
    id: string;
    smartContractName: string;
    state: OfferState;
    status: Status;
    terms: any;
    nftAsset?: {
      __typename?: 'NftAssetType';
      applicationName: ApplicationName;
      createdAt: any;
      displayStatus: NftAssetDisplayStatus;
      id: string;
      isAvailable: boolean;
      ownerId: string;
      properties: any;
      smartContractName: string;
      tokenId: string;
      tokenUri: string;
    } | null;
  };
};

export type GetOrderByIdQueryVariables = Exact<{
  input: GetByIdParamsInput;
}>;

export type GetOrderByIdQuery = {
  __typename?: 'Query';
  getOrderById: {
    __typename?: 'OrderType';
    id: string;
    status: Status;
    customerId: string;
    createdAt: any;
    updatedAt: any;
    items: Array<{
      __typename?: 'OrderItemType';
      id: string;
      terms: any;
      orderId: string;
      createdAt: any;
      rentalContract?: {
        __typename?: 'RentalContractType';
        applicationName: ApplicationName;
        createdAt: any;
        customerId: string;
        endAt: any;
        id: string;
        nftAssetId: string;
        orderId: string;
        orderItemId: string;
        smartContractName: string;
        startAt: any;
        state: RentalContractState;
        status: Status;
        updatedAt: any;
        logs: Array<{
          __typename?: 'RentalContractLogType';
          id: string;
          state?: RentalContractState | null;
          createdAt: any;
          reason?: string | null;
        }>;
      } | null;
      parties: Array<{ __typename?: 'OrderItemPartyType'; role: OrderPartyRole; terms: any }>;
      nftAsset: {
        __typename?: 'NftAssetType';
        id: string;
        properties: any;
        applicationName: ApplicationName;
        isAvailable: boolean;
        ownerId: string;
        tokenId: string;
        tokenUri: string;
        smartContractName: string;
        createdAt: any;
        displayStatus: NftAssetDisplayStatus;
      };
    }>;
    logs: Array<{
      __typename?: 'OrderLogType';
      id: string;
      status: Status;
      orderId: string;
      updatedBy: string;
      createdAt: any;
    }>;
  };
};

export type GetOwnerOfferByIdQueryVariables = Exact<{
  input: GetByIdParamsInput;
}>;

export type GetOwnerOfferByIdQuery = {
  __typename?: 'Query';
  ownerOffer: {
    __typename?: 'OwnerOfferType';
    applicationName: ApplicationName;
    createdAt: any;
    deferredTokenId: string;
    id: string;
    smartContractName: string;
    state: OfferState;
    status: Status;
    terms: any;
    txHash?: string | null;
    updatedAt: any;
    nftAsset?: {
      __typename?: 'NftAssetType';
      id: string;
      properties: any;
      applicationName: ApplicationName;
      isAvailable: boolean;
      ownerId: string;
      tokenId: string;
      tokenUri: string;
      smartContractName: string;
      createdAt: any;
    } | null;
    rentalContract?: {
      __typename?: 'RentalContractType';
      applicationName: ApplicationName;
      createdAt: any;
      customerId: string;
      endAt: any;
      id: string;
      nftAssetId: string;
      orderId: string;
      orderItemId: string;
      smartContractName: string;
      startAt: any;
      state: RentalContractState;
      status: Status;
      updatedAt: any;
    } | null;
  };
};

export type LeaderboardQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type LeaderboardQuery = {
  __typename?: 'Query';
  leaderboard:
    | { __typename: 'LeaderboardExceptionType'; description: LeaderboardErrorDescription; message: string }
    | { __typename: 'LeaderboardType'; id: string; ranking: any; rewardStructure?: any | null; updatedAt: any };
};

export type LeaderboardRewardClaimersQueryVariables = Exact<{
  input: LeaderboardRewardClaimersInput;
}>;

export type LeaderboardRewardClaimersQuery = {
  __typename?: 'Query';
  leaderboardRewardClaimers: { __typename?: 'SocialEventRewardClaimersType'; rewardClaimers: Array<any> };
};

export type LendingLoansQueryVariables = Exact<{
  input: OffsetPagination;
}>;

export type LendingLoansQuery = {
  __typename?: 'Query';
  lendingLoans: {
    __typename?: 'LendingLoanList';
    total: number;
    items?: Array<{
      __typename?: 'LoanType';
      lender: string;
      tokenId: string;
      isWhiteList: boolean;
      sharePercent: number;
      class: number;
      overall: number;
      borrowDate: number;
      status: LoanStatus;
      cancelAt: number;
      startedAt: number;
      totalOwnerReward: number;
      totalBorrowerReward: number;
      borrowerReward: number;
      ownerReward: number;
      timeUpdateReward: number;
      createdAt: string;
      updatedAt: string;
      extraBorrowerData: {
        __typename?: 'ExtraBorrowerDataType';
        energy: number;
        winLoss: {
          __typename?: 'WinLossTimeStatType';
          weekly: { __typename?: 'WinLossStatType'; win: number; loss: number };
        };
      };
    }> | null;
  };
};

export type GetUserProfileQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserProfileQuery = {
  __typename?: 'Query';
  me:
    | { __typename: 'UserExceptionType'; description: UserErrorDescription; message: string }
    | {
        __typename: 'UserProfileType';
        id: string;
        name?: string | null;
        email?: string | null;
        diallingCode?: string | null;
        phoneNumber?: string | null;
        isActive: boolean;
        isWaiting: boolean;
        isEmailConfirmed: boolean;
        isPhoneConfirmed: boolean;
        walletAddress?: string | null;
        updatedAt: any;
        socialConnections: Array<{
          __typename?: 'UserSocialType';
          provider: SocialConnectionProvider;
          email?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          displayName?: string | null;
          pictureUrl?: string | null;
          socialId: string;
          userId: string;
          createdAt: any;
          updatedAt: any;
        }>;
      };
};

export type ListOrdersQueryVariables = Exact<{
  input: OffsetPagination;
}>;

export type ListOrdersQuery = {
  __typename?: 'Query';
  me:
    | { __typename: 'UserExceptionType'; description: UserErrorDescription; message: string }
    | {
        __typename: 'UserProfileType';
        orders: {
          __typename: 'OrderList';
          total: number;
          items?: Array<{
            __typename?: 'OrderType';
            id: string;
            status: Status;
            customerId: string;
            createdAt: any;
            items: Array<{
              __typename?: 'OrderItemType';
              id: string;
              terms: any;
              orderId: string;
              createdAt: any;
              rentalContract?: {
                __typename?: 'RentalContractType';
                applicationName: ApplicationName;
                createdAt: any;
                customerId: string;
                endAt: any;
                id: string;
                nftAssetId: string;
                orderId: string;
                orderItemId: string;
                smartContractName: string;
                startAt: any;
                state: RentalContractState;
                status: Status;
                updatedAt: any;
              } | null;
              parties: Array<{ __typename?: 'OrderItemPartyType'; role: OrderPartyRole; terms: any }>;
              nftAsset: {
                __typename?: 'NftAssetType';
                id: string;
                properties: any;
                applicationName: ApplicationName;
                isAvailable: boolean;
                ownerId: string;
                tokenId: string;
                tokenUri: string;
                smartContractName: string;
                createdAt: any;
              };
            }>;
            logs: Array<{
              __typename?: 'OrderLogType';
              id: string;
              createdAt: any;
              orderId: string;
              updatedBy: string;
              status: Status;
            }>;
          }> | null;
        };
      };
};

export type GameNftAssetsQueryVariables = Exact<{
  pagination: OffsetPagination;
  filter?: InputMaybe<NftAssetsFilterInput>;
}>;

export type GameNftAssetsQuery = {
  __typename?: 'Query';
  nftAssets: {
    __typename?: 'NftAssetsType';
    total: number;
    items?: Array<{
      __typename: 'NftAssetType';
      applicationName: ApplicationName;
      createdAt: any;
      displayStatus: NftAssetDisplayStatus;
      id: string;
      isAvailable: boolean;
      ownerId: string;
      properties: any;
      smartContractName: string;
      tokenId: string;
      tokenUri: string;
      offers: Array<{ __typename?: 'OfferType'; id: string; terms: any; smartContractName: string; state: OfferState }>;
    }> | null;
  };
};

export type GetMarketplaceOffersQueryVariables = Exact<{
  pagination: OffsetPagination;
  filter?: InputMaybe<OffersFilterInput>;
}>;

export type GetMarketplaceOffersQuery = {
  __typename?: 'Query';
  offers: {
    __typename?: 'OffersType';
    total: number;
    items?: Array<{
      __typename?: 'OfferType';
      applicationName: ApplicationName;
      createdAt: any;
      deferredTokenId: string;
      id: string;
      smartContractName: string;
      state: OfferState;
      status: Status;
      terms: any;
      nftAsset?: {
        __typename?: 'NftAssetType';
        applicationName: ApplicationName;
        createdAt: any;
        displayStatus: NftAssetDisplayStatus;
        id: string;
        isAvailable: boolean;
        ownerId: string;
        properties: any;
        smartContractName: string;
        tokenId: string;
        tokenUri: string;
      } | null;
    }> | null;
  };
};

export type OwnerNftAssetsQueryVariables = Exact<{ [key: string]: never }>;

export type OwnerNftAssetsQuery = {
  __typename?: 'Query';
  ownerNftAssets: Array<{
    __typename?: 'NftAssetType';
    id: string;
    properties: any;
    applicationName: ApplicationName;
    isAvailable: boolean;
    ownerId: string;
    tokenId: string;
    tokenUri: string;
    smartContractName: string;
    createdAt: any;
    displayStatus: NftAssetDisplayStatus;
  }>;
};

export type OwnerOffersQueryVariables = Exact<{
  input: OffsetPagination;
}>;

export type OwnerOffersQuery = {
  __typename?: 'Query';
  ownerOffers: {
    __typename?: 'OwnerOffersType';
    total: number;
    items?: Array<{
      __typename?: 'OwnerOfferType';
      applicationName: ApplicationName;
      createdAt: any;
      deferredTokenId: string;
      id: string;
      smartContractName: string;
      state: OfferState;
      status: Status;
      terms: any;
      txHash?: string | null;
      updatedAt: any;
      nftAsset?: {
        __typename?: 'NftAssetType';
        id: string;
        properties: any;
        applicationName: ApplicationName;
        isAvailable: boolean;
        ownerId: string;
        tokenId: string;
        tokenUri: string;
        smartContractName: string;
        createdAt: any;
      } | null;
      rentalContract?: {
        __typename?: 'RentalContractType';
        applicationName: ApplicationName;
        createdAt: any;
        customerId: string;
        endAt: any;
        id: string;
        nftAssetId: string;
        orderId: string;
        orderItemId: string;
        smartContractName: string;
        startAt: any;
        state: RentalContractState;
        status: Status;
        updatedAt: any;
      } | null;
    }> | null;
  };
};

export type GetPayoutRequestsQueryVariables = Exact<{
  pagination: OffsetPagination;
  filter?: InputMaybe<PayoutRequestListInput>;
}>;

export type GetPayoutRequestsQuery = {
  __typename?: 'Query';
  payoutRequests: {
    __typename?: 'PayoutRequestList';
    total: number;
    items?: Array<{
      __typename?: 'PayoutRequestType';
      id: string;
      currency: Currency;
      txnHash?: string | null;
      status: Status;
      amount: string;
      destination: string;
      createdAt: any;
    }> | null;
  };
};

export type SocialEventRewardQueryVariables = Exact<{
  eventId: Scalars['String'];
}>;

export type SocialEventRewardQuery = {
  __typename?: 'Query';
  socialEventReward:
    | { __typename?: 'SocialEventRewardExceptionType'; description: SocialEventRewardErrorDescription; message: string }
    | {
        __typename?: 'SocialEventRewardType';
        id: string;
        eventId: string;
        merkleTree: any;
        smartContractAddress: string;
        chainId: number;
      };
};

export type TournamentQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type TournamentQuery = {
  __typename?: 'Query';
  tournament: {
    __typename?: 'TournamentType';
    id: string;
    title: string;
    description?: string | null;
    slug: string;
    applicationName?: ApplicationName | null;
    gameUid?: string | null;
    bracketType?: TournamentBracketType | null;
    imageUrl: string;
    typeformUrl?: string | null;
    rulesUrl?: string | null;
    isFinished: boolean;
    isVisible: boolean;
    participationType: TournamentParticipationType;
    minTeamSize?: number | null;
    participantsLimitPerTeam?: number | null;
    prize?: string | null;
    playUrl?: string | null;
    registrationCompleteMessage?: string | null;
    registrationEndDate: any;
    tournamentStartDate: any;
    tournamentFinishDate?: any | null;
    extraFieldsSchema?: TournamentExtraFieldsSchema | null;
    teamExtraFieldsSchema?: TournamentExtraFieldsSchema | null;
    updatedAt: any;
    createdAt: any;
    participants: Array<{
      __typename?: 'TournamentParticipantType';
      id: string;
      userId: string;
      tournamentId: string;
      tournamentParticipantStatus?: TournamentParticipantStatus | null;
      createdAt: any;
      updatedAt: any;
    }>;
    leaderboard?: { __typename?: 'LeaderboardType'; id: string } | null;
  };
};

export type TournamentExtraFieldsSchemasQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type TournamentExtraFieldsSchemasQuery = {
  __typename?: 'Query';
  tournamentExtraFieldsSchemas: {
    __typename?: 'TournamentExtraFieldsSchemasType';
    participantExtraFieldsSchema?: any | null;
    teamExtraFieldsSchema?: any | null;
  };
};

export type TournamentParticipantQueryVariables = Exact<{
  tournamentId: Scalars['String'];
}>;

export type TournamentParticipantQuery = {
  __typename?: 'Query';
  tournamentParticipant?: {
    __typename?: 'TournamentParticipantType';
    userId: string;
    tournamentId: string;
    tournamentParticipantStatus?: TournamentParticipantStatus | null;
    registrationStatus: TournamentRegistrationStatus;
    registrationStatusMessage?: string | null;
    extraFieldValues?: any | null;
    playUrl?: string | null;
    createdAt: any;
  } | null;
};

export type TournamentTeamByCodeQueryVariables = Exact<{
  code: Scalars['String'];
}>;

export type TournamentTeamByCodeQuery = {
  __typename?: 'Query';
  tournamentTeamByCode?: {
    __typename?: 'TournamentTeamType';
    code: string;
    captainUserId: string;
    tournamentId: string;
    extraFieldValues?: any | null;
    members: Array<{
      __typename?: 'TournamentParticipantType';
      userId: string;
      tournamentId: string;
      tournamentParticipantStatus?: TournamentParticipantStatus | null;
      registrationStatus: TournamentRegistrationStatus;
      registrationStatusMessage?: string | null;
      extraFieldValues?: any | null;
      playUrl?: string | null;
      createdAt: any;
    }>;
  } | null;
};

export type TournamentTeamByTournamentIdQueryVariables = Exact<{
  tournamentId: Scalars['String'];
}>;

export type TournamentTeamByTournamentIdQuery = {
  __typename?: 'Query';
  tournamentTeamByTournamentId?: {
    __typename?: 'TournamentTeamType';
    code: string;
    captainUserId: string;
    tournamentId: string;
    extraFieldValues?: any | null;
    members: Array<{
      __typename?: 'TournamentParticipantType';
      userId: string;
      tournamentId: string;
      tournamentParticipantStatus?: TournamentParticipantStatus | null;
      registrationStatus: TournamentRegistrationStatus;
      registrationStatusMessage?: string | null;
      extraFieldValues?: any | null;
      playUrl?: string | null;
      createdAt: any;
    }>;
  } | null;
};

export type TournamentsQueryVariables = Exact<{
  pagination: OffsetPagination;
  filter?: InputMaybe<TournamentsFilterInput>;
}>;

export type TournamentsQuery = {
  __typename?: 'Query';
  tournaments: {
    __typename?: 'TournamentList';
    total: number;
    items?: Array<{
      __typename?: 'TournamentType';
      id: string;
      title: string;
      description?: string | null;
      slug: string;
      applicationName?: ApplicationName | null;
      gameUid?: string | null;
      bracketType?: TournamentBracketType | null;
      imageUrl: string;
      typeformUrl?: string | null;
      rulesUrl?: string | null;
      isFinished: boolean;
      isVisible: boolean;
      participationType: TournamentParticipationType;
      minTeamSize?: number | null;
      participantsLimitPerTeam?: number | null;
      prize?: string | null;
      playUrl?: string | null;
      registrationCompleteMessage?: string | null;
      registrationEndDate: any;
      tournamentStartDate: any;
      tournamentFinishDate?: any | null;
      extraFieldsSchema?: TournamentExtraFieldsSchema | null;
      teamExtraFieldsSchema?: TournamentExtraFieldsSchema | null;
      updatedAt: any;
      createdAt: any;
      leaderboard?: { __typename?: 'LeaderboardType'; id: string } | null;
    }> | null;
  };
};

export type TournamentsPreviewQueryVariables = Exact<{ [key: string]: never }>;

export type TournamentsPreviewQuery = {
  __typename?: 'Query';
  tournamentsPreview: {
    __typename?: 'TournamentList';
    items?: Array<{
      __typename?: 'TournamentType';
      id: string;
      applicationName?: ApplicationName | null;
      gameUid?: string | null;
      title: string;
      slug: string;
      imageUrl: string;
      isFinished: boolean;
      isVisible: boolean;
      registrationEndDate: any;
      tournamentStartDate: any;
      tournamentFinishDate?: any | null;
    }> | null;
  };
};

export type WaitlistRequestQueryVariables = Exact<{
  featureId: Scalars['String'];
}>;

export type WaitlistRequestQuery = {
  __typename?: 'Query';
  waitlistRequest?: {
    __typename?: 'WaitlistRequestType';
    id: string;
    userId: string;
    featureId: string;
    isActive: boolean;
    createdAt: any;
    updatedAt: any;
  } | null;
};

export const LeaderboardFragmentDoc = gql`
  fragment Leaderboard on LeaderboardType {
    id
    ranking
    rewardStructure
    updatedAt
  }
`;
export const NftAssetFragmentFragmentDoc = gql`
  fragment NftAssetFragment on NftAssetType {
    applicationName
    createdAt
    displayStatus
    id
    isAvailable
    ownerId
    properties
    smartContractName
    tokenId
    tokenUri
  }
`;
export const OfferItemFragmentFragmentDoc = gql`
  fragment OfferItemFragment on OfferType {
    applicationName
    createdAt
    deferredTokenId
    id
    nftAsset {
      ...NftAssetFragment
    }
    smartContractName
    state
    status
    terms
  }
  ${NftAssetFragmentFragmentDoc}
`;
export const TournamentFragmentFragmentDoc = gql`
  fragment TournamentFragment on TournamentType {
    id
    title
    description
    slug
    applicationName
    gameUid
    bracketType
    imageUrl
    typeformUrl
    rulesUrl
    isFinished
    isVisible
    participationType
    minTeamSize
    participantsLimitPerTeam
    prize
    playUrl
    registrationCompleteMessage
    registrationEndDate
    tournamentStartDate
    tournamentFinishDate
    extraFieldsSchema
    teamExtraFieldsSchema
    leaderboard {
      id
    }
    updatedAt
    createdAt
  }
`;
export const TournamentParticipantFragmentDoc = gql`
  fragment TournamentParticipant on TournamentParticipantType {
    userId
    tournamentId
    tournamentParticipantStatus
    registrationStatus
    registrationStatusMessage
    extraFieldValues
    playUrl
    createdAt
  }
`;
export const TournamentTeamFragmentFragmentDoc = gql`
  fragment TournamentTeamFragment on TournamentTeamType {
    code
    captainUserId
    tournamentId
    extraFieldValues
    members {
      ...TournamentParticipant
    }
  }
  ${TournamentParticipantFragmentDoc}
`;
export const UserExceptionTypeFragmentFragmentDoc = gql`
  fragment UserExceptionTypeFragment on UserExceptionType {
    description
    message
  }
`;
export const UserSocialConnectionsFragmentFragmentDoc = gql`
  fragment UserSocialConnectionsFragment on UserSocialType {
    provider
    email
    firstName
    lastName
    displayName
    pictureUrl
    socialId
    userId
    createdAt
    updatedAt
  }
`;
export const UserProfileFragmentFragmentDoc = gql`
  fragment UserProfileFragment on UserProfileType {
    id
    name
    email
    diallingCode
    phoneNumber
    isActive
    isWaiting
    isEmailConfirmed
    isPhoneConfirmed
    walletAddress
    updatedAt
    socialConnections {
      ...UserSocialConnectionsFragment
    }
  }
  ${UserSocialConnectionsFragmentFragmentDoc}
`;
export const UserExceptionFragmentFragmentDoc = gql`
  fragment UserExceptionFragment on UserExceptionType {
    message
    description
  }
`;
export const WaitlistRequestFragmentFragmentDoc = gql`
  fragment WaitlistRequestFragment on WaitlistRequestType {
    id
    userId
    featureId
    isActive
    createdAt
    updatedAt
  }
`;
export const ChalengeNonceFragmentFragmentDoc = gql`
  fragment ChalengeNonceFragment on ChallengeNonceType {
    message
    nonce
  }
`;
export const AuthDetailsFragmentDoc = gql`
  fragment AuthDetails on AuthDetailsType {
    authToken
  }
`;
export const UserProfileFragmentWithoutSocialConnectionsFragmentDoc = gql`
  fragment UserProfileFragmentWithoutSocialConnections on UserProfileType {
    id
    name
    email
    diallingCode
    phoneNumber
    isActive
    isWaiting
    isEmailConfirmed
    isPhoneConfirmed
    walletAddress
    updatedAt
  }
`;
export const UserSignedInSuccessFragmentFragmentDoc = gql`
  fragment UserSignedInSuccessFragment on UserSignedInType {
    authDetails {
      ...AuthDetails
    }
    user {
      ...UserProfileFragmentWithoutSocialConnections
    }
  }
  ${AuthDetailsFragmentDoc}
  ${UserProfileFragmentWithoutSocialConnectionsFragmentDoc}
`;
export const UserSignedInExceptionFragmentFragmentDoc = gql`
  fragment UserSignedInExceptionFragment on UserExceptionType {
    message
    description
  }
`;
export const GetAuthLinkDocument = gql`
  mutation getAuthLink($userSocialLinkGetAuthLinkInput: UserSocialLinkGetAuthLinkInput!) {
    getAuthLink(userSocialLinkGetAuthLinkInput: $userSocialLinkGetAuthLinkInput) {
      authLink
    }
  }
`;
export type GetAuthLinkMutationFn = Apollo.MutationFunction<GetAuthLinkMutation, GetAuthLinkMutationVariables>;
export type GetAuthLinkMutationResult = Apollo.MutationResult<GetAuthLinkMutation>;
export type GetAuthLinkMutationOptions = Apollo.BaseMutationOptions<GetAuthLinkMutation, GetAuthLinkMutationVariables>;
export const GetCustomAuthTokenDocument = gql`
  mutation getCustomAuthToken {
    getCustomAuthToken {
      __typename
      ... on UserAuthCustomType {
        authDetails {
          authToken
        }
      }
      ... on UserExceptionType {
        description
        message
      }
    }
  }
`;
export type GetCustomAuthTokenMutationFn = Apollo.MutationFunction<
  GetCustomAuthTokenMutation,
  GetCustomAuthTokenMutationVariables
>;
export type GetCustomAuthTokenMutationResult = Apollo.MutationResult<GetCustomAuthTokenMutation>;
export type GetCustomAuthTokenMutationOptions = Apollo.BaseMutationOptions<
  GetCustomAuthTokenMutation,
  GetCustomAuthTokenMutationVariables
>;
export const LinkPhoneNumberDocument = gql`
  mutation linkPhoneNumber($userLinkPhoneNumberInput: UserLinkPhoneNumberInput!) {
    linkPhoneNumber(userLinkPhoneNumberInput: $userLinkPhoneNumberInput) {
      ... on UserProfileType {
        ...UserProfileFragment
      }
      ... on UserExceptionType {
        ...UserExceptionFragment
      }
    }
  }
  ${UserProfileFragmentFragmentDoc}
  ${UserExceptionFragmentFragmentDoc}
`;
export type LinkPhoneNumberMutationFn = Apollo.MutationFunction<
  LinkPhoneNumberMutation,
  LinkPhoneNumberMutationVariables
>;
export type LinkPhoneNumberMutationResult = Apollo.MutationResult<LinkPhoneNumberMutation>;
export type LinkPhoneNumberMutationOptions = Apollo.BaseMutationOptions<
  LinkPhoneNumberMutation,
  LinkPhoneNumberMutationVariables
>;
export const LinkSocialConnectionDocument = gql`
  mutation linkSocialConnection($userLinkSocialConnectionInput: UserLinkSocialConnectionInput!) {
    linkSocialConnection(userLinkSocialConnectionInput: $userLinkSocialConnectionInput) {
      ... on UserProfileType {
        ...UserProfileFragment
      }
      ... on UserExceptionType {
        ...UserExceptionFragment
      }
    }
  }
  ${UserProfileFragmentFragmentDoc}
  ${UserExceptionFragmentFragmentDoc}
`;
export type LinkSocialConnectionMutationFn = Apollo.MutationFunction<
  LinkSocialConnectionMutation,
  LinkSocialConnectionMutationVariables
>;
export type LinkSocialConnectionMutationResult = Apollo.MutationResult<LinkSocialConnectionMutation>;
export type LinkSocialConnectionMutationOptions = Apollo.BaseMutationOptions<
  LinkSocialConnectionMutation,
  LinkSocialConnectionMutationVariables
>;
export const SignInWithMetamaskDocument = gql`
  mutation signInWithMetamask($userSignInWithMetamaskInput: UserSignInWithMetamaskInput!) {
    signInWithMetamask(userSignInWithMetamaskInput: $userSignInWithMetamaskInput) {
      __typename
      ... on UserSignedInType {
        ...UserSignedInSuccessFragment
      }
      ... on UserExceptionType {
        ...UserExceptionFragment
      }
    }
  }
  ${UserSignedInSuccessFragmentFragmentDoc}
  ${UserExceptionFragmentFragmentDoc}
`;
export type SignInWithMetamaskMutationFn = Apollo.MutationFunction<
  SignInWithMetamaskMutation,
  SignInWithMetamaskMutationVariables
>;
export type SignInWithMetamaskMutationResult = Apollo.MutationResult<SignInWithMetamaskMutation>;
export type SignInWithMetamaskMutationOptions = Apollo.BaseMutationOptions<
  SignInWithMetamaskMutation,
  SignInWithMetamaskMutationVariables
>;
export const CreateOfferDocument = gql`
  mutation createOffer($input: CreateOfferInput!) {
    createOffer(createOfferInput: $input) {
      id
      state
      status
      applicationName
      terms
      smartContractName
      deferredTokenId
      createdAt
      nftAsset {
        id
        properties
        applicationName
        isAvailable
        ownerId
        tokenId
        tokenUri
        smartContractName
        createdAt
      }
    }
  }
`;
export type CreateOfferMutationFn = Apollo.MutationFunction<CreateOfferMutation, CreateOfferMutationVariables>;
export type CreateOfferMutationResult = Apollo.MutationResult<CreateOfferMutation>;
export type CreateOfferMutationOptions = Apollo.BaseMutationOptions<CreateOfferMutation, CreateOfferMutationVariables>;
export const CreateOrderDocument = gql`
  mutation createOrder($input: CreateOrderInput!) {
    createOrder(createOrderInput: $input) {
      id
    }
  }
`;
export type CreateOrderMutationFn = Apollo.MutationFunction<CreateOrderMutation, CreateOrderMutationVariables>;
export type CreateOrderMutationResult = Apollo.MutationResult<CreateOrderMutation>;
export type CreateOrderMutationOptions = Apollo.BaseMutationOptions<CreateOrderMutation, CreateOrderMutationVariables>;
export const CreatePayoutRequestDocument = gql`
  mutation createPayoutRequest($input: PayoutRequestCreateInput!) {
    createPayoutRequest(payoutRequestCreateInput: $input) {
      id
      currency
      txnHash
      status
      amount
      destination
      createdAt
    }
  }
`;
export type CreatePayoutRequestMutationFn = Apollo.MutationFunction<
  CreatePayoutRequestMutation,
  CreatePayoutRequestMutationVariables
>;
export type CreatePayoutRequestMutationResult = Apollo.MutationResult<CreatePayoutRequestMutation>;
export type CreatePayoutRequestMutationOptions = Apollo.BaseMutationOptions<
  CreatePayoutRequestMutation,
  CreatePayoutRequestMutationVariables
>;
export const CreateTournamentParticipantDocument = gql`
  mutation createTournamentParticipant($createTournamentParticipantInput: CreateTournamentParticipantInput!) {
    createTournamentParticipant(createTournamentParticipantInput: $createTournamentParticipantInput) {
      __typename
      ... on TournamentParticipantType {
        ...TournamentParticipant
      }
      ... on TournamentExceptionType {
        description
        message
      }
    }
  }
  ${TournamentParticipantFragmentDoc}
`;
export type CreateTournamentParticipantMutationFn = Apollo.MutationFunction<
  CreateTournamentParticipantMutation,
  CreateTournamentParticipantMutationVariables
>;
export type CreateTournamentParticipantMutationResult = Apollo.MutationResult<CreateTournamentParticipantMutation>;
export type CreateTournamentParticipantMutationOptions = Apollo.BaseMutationOptions<
  CreateTournamentParticipantMutation,
  CreateTournamentParticipantMutationVariables
>;
export const CreateTournamentTeamDocument = gql`
  mutation createTournamentTeam($createTournamentTeamInput: CreateTournamentTeamInput!) {
    createTournamentTeam(createTournamentTeamInput: $createTournamentTeamInput) {
      __typename
      ... on TournamentTeamType {
        ...TournamentTeamFragment
      }
      ... on TournamentExceptionType {
        description
        message
      }
    }
  }
  ${TournamentTeamFragmentFragmentDoc}
`;
export type CreateTournamentTeamMutationFn = Apollo.MutationFunction<
  CreateTournamentTeamMutation,
  CreateTournamentTeamMutationVariables
>;
export type CreateTournamentTeamMutationResult = Apollo.MutationResult<CreateTournamentTeamMutation>;
export type CreateTournamentTeamMutationOptions = Apollo.BaseMutationOptions<
  CreateTournamentTeamMutation,
  CreateTournamentTeamMutationVariables
>;
export const CreateWaitlistRequestDocument = gql`
  mutation createWaitlistRequest($featureId: String!) {
    createWaitlistRequest(featureId: $featureId) {
      ...WaitlistRequestFragment
    }
  }
  ${WaitlistRequestFragmentFragmentDoc}
`;
export type CreateWaitlistRequestMutationFn = Apollo.MutationFunction<
  CreateWaitlistRequestMutation,
  CreateWaitlistRequestMutationVariables
>;
export type CreateWaitlistRequestMutationResult = Apollo.MutationResult<CreateWaitlistRequestMutation>;
export type CreateWaitlistRequestMutationOptions = Apollo.BaseMutationOptions<
  CreateWaitlistRequestMutation,
  CreateWaitlistRequestMutationVariables
>;
export const UpdateUserProfileDocument = gql`
  mutation updateUserProfile($input: UserProfileUpdateInput!) {
    updateProfile(userProfileUpdateInput: $input) {
      __typename
      ... on UserProfileType {
        id
        name
        email
        diallingCode
        phoneNumber
        isActive
        isEmailConfirmed
        isPhoneConfirmed
        updatedAt
      }
      ... on UserExceptionType {
        description
        message
      }
    }
  }
`;
export type UpdateUserProfileMutationFn = Apollo.MutationFunction<
  UpdateUserProfileMutation,
  UpdateUserProfileMutationVariables
>;
export type UpdateUserProfileMutationResult = Apollo.MutationResult<UpdateUserProfileMutation>;
export type UpdateUserProfileMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserProfileMutation,
  UpdateUserProfileMutationVariables
>;
export const RetryTournamentParticipantRegistrationDocument = gql`
  mutation retryTournamentParticipantRegistration($input: RetryTournamentParticipantRegistrationInput!) {
    retryTournamentParticipantRegistration(retryTournamentParticipantRegistrationInput: $input) {
      ...TournamentParticipant
    }
  }
  ${TournamentParticipantFragmentDoc}
`;
export type RetryTournamentParticipantRegistrationMutationFn = Apollo.MutationFunction<
  RetryTournamentParticipantRegistrationMutation,
  RetryTournamentParticipantRegistrationMutationVariables
>;
export type RetryTournamentParticipantRegistrationMutationResult =
  Apollo.MutationResult<RetryTournamentParticipantRegistrationMutation>;
export type RetryTournamentParticipantRegistrationMutationOptions = Apollo.BaseMutationOptions<
  RetryTournamentParticipantRegistrationMutation,
  RetryTournamentParticipantRegistrationMutationVariables
>;
export const SocialSignInDocument = gql`
  mutation socialSignIn($userSocialSignInInput: UserSocialSignInInput!) {
    socialSignIn(userSocialSignInInput: $userSocialSignInInput) {
      __typename
      ... on UserSocialSignedInType {
        authDetails {
          authToken
        }
        userSocial {
          email
          displayName
          pictureUrl
          updatedAt
        }
      }
      ... on UserExceptionType {
        description
        message
      }
    }
  }
`;
export type SocialSignInMutationFn = Apollo.MutationFunction<SocialSignInMutation, SocialSignInMutationVariables>;
export type SocialSignInMutationResult = Apollo.MutationResult<SocialSignInMutation>;
export type SocialSignInMutationOptions = Apollo.BaseMutationOptions<
  SocialSignInMutation,
  SocialSignInMutationVariables
>;
export const GetAccountBalanceDocument = gql`
  query getAccountBalance($input: AccountBalanceGetInput!) {
    accountBalance(accountBalanceGetInput: $input) {
      amount
      currency
      reference
      createdAt
    }
  }
`;
export type GetAccountBalanceQueryResult = Apollo.QueryResult<GetAccountBalanceQuery, GetAccountBalanceQueryVariables>;
export const ApplicationSettingsDocument = gql`
  query applicationSettings($name: SmartContractName!) {
    applicationSettings(smartContractName: $name) {
      schemas
    }
  }
`;
export type ApplicationSettingsQueryResult = Apollo.QueryResult<
  ApplicationSettingsQuery,
  ApplicationSettingsQueryVariables
>;
export const ChallengeNonceDocument = gql`
  query challengeNonce($challengeNonceInput: ChallengeNonceInput!) {
    challengeNonce(challengeNonceInput: $challengeNonceInput) {
      ...ChalengeNonceFragment
    }
  }
  ${ChalengeNonceFragmentFragmentDoc}
`;
export type ChallengeNonceQueryResult = Apollo.QueryResult<ChallengeNonceQuery, ChallengeNonceQueryVariables>;
export const GetMarketplaceOfferByIdDocument = gql`
  query getMarketplaceOfferById($input: GetByIdParamsInput!) {
    offer(offerGetByIdParams: $input) {
      ...OfferItemFragment
    }
  }
  ${OfferItemFragmentFragmentDoc}
`;
export type GetMarketplaceOfferByIdQueryResult = Apollo.QueryResult<
  GetMarketplaceOfferByIdQuery,
  GetMarketplaceOfferByIdQueryVariables
>;
export const GetOrderByIdDocument = gql`
  query getOrderById($input: GetByIdParamsInput!) {
    getOrderById(orderGetByIdParams: $input) {
      id
      status
      items {
        id
        terms
        rentalContract {
          applicationName
          createdAt
          customerId
          endAt
          id
          nftAssetId
          orderId
          orderItemId
          smartContractName
          startAt
          state
          status
          updatedAt
          logs {
            id
            state
            createdAt
            reason
          }
        }
        parties {
          role
          terms
        }
        nftAsset {
          id
          properties
          applicationName
          isAvailable
          ownerId
          tokenId
          tokenUri
          smartContractName
          createdAt
          displayStatus
        }
        orderId
        createdAt
      }
      logs {
        id
        status
        orderId
        updatedBy
        createdAt
      }
      customerId
      createdAt
      updatedAt
    }
  }
`;
export type GetOrderByIdQueryResult = Apollo.QueryResult<GetOrderByIdQuery, GetOrderByIdQueryVariables>;
export const GetOwnerOfferByIdDocument = gql`
  query getOwnerOfferById($input: GetByIdParamsInput!) {
    ownerOffer(offerGetByIdParams: $input) {
      applicationName
      createdAt
      deferredTokenId
      id
      nftAsset {
        id
        properties
        applicationName
        isAvailable
        ownerId
        tokenId
        tokenUri
        smartContractName
        createdAt
      }
      rentalContract {
        applicationName
        createdAt
        customerId
        endAt
        id
        nftAssetId
        orderId
        orderItemId
        smartContractName
        startAt
        state
        status
        updatedAt
      }
      smartContractName
      state
      status
      terms
      txHash
      updatedAt
    }
  }
`;
export type GetOwnerOfferByIdQueryResult = Apollo.QueryResult<GetOwnerOfferByIdQuery, GetOwnerOfferByIdQueryVariables>;
export const LeaderboardDocument = gql`
  query leaderboard($id: String!) {
    leaderboard(id: $id) {
      __typename
      ... on LeaderboardType {
        ...Leaderboard
      }
      ... on LeaderboardExceptionType {
        description
        message
      }
    }
  }
  ${LeaderboardFragmentDoc}
`;
export type LeaderboardQueryResult = Apollo.QueryResult<LeaderboardQuery, LeaderboardQueryVariables>;
export const LeaderboardRewardClaimersDocument = gql`
  query leaderboardRewardClaimers($input: LeaderboardRewardClaimersInput!) {
    leaderboardRewardClaimers(leaderboardRewardClaimersInput: $input) {
      rewardClaimers
    }
  }
`;
export type LeaderboardRewardClaimersQueryResult = Apollo.QueryResult<
  LeaderboardRewardClaimersQuery,
  LeaderboardRewardClaimersQueryVariables
>;
export const LendingLoansDocument = gql`
  query lendingLoans($input: OffsetPagination!) {
    lendingLoans(pagination: $input) {
      total
      items {
        lender
        tokenId
        isWhiteList
        sharePercent
        class
        overall
        borrowDate
        status
        cancelAt
        startedAt
        totalOwnerReward
        totalBorrowerReward
        borrowerReward
        ownerReward
        timeUpdateReward
        createdAt
        updatedAt
        extraBorrowerData {
          energy
          winLoss {
            weekly {
              win
              loss
            }
          }
        }
      }
    }
  }
`;
export type LendingLoansQueryResult = Apollo.QueryResult<LendingLoansQuery, LendingLoansQueryVariables>;
export const GetUserProfileDocument = gql`
  query getUserProfile {
    me {
      __typename
      ... on UserProfileType {
        ...UserProfileFragment
      }
      ... on UserExceptionType {
        ...UserExceptionTypeFragment
      }
    }
  }
  ${UserProfileFragmentFragmentDoc}
  ${UserExceptionTypeFragmentFragmentDoc}
`;
export type GetUserProfileQueryResult = Apollo.QueryResult<GetUserProfileQuery, GetUserProfileQueryVariables>;
export const ListOrdersDocument = gql`
  query listOrders($input: OffsetPagination!) {
    me {
      __typename
      ... on UserProfileType {
        orders(pagination: $input) {
          __typename
          ... on OrderList {
            items {
              id
              status
              items {
                id
                terms
                orderId
                createdAt
                rentalContract {
                  applicationName
                  createdAt
                  customerId
                  endAt
                  id
                  nftAssetId
                  orderId
                  orderItemId
                  smartContractName
                  startAt
                  state
                  status
                  updatedAt
                }
                parties {
                  role
                  terms
                }
                nftAsset {
                  id
                  properties
                  applicationName
                  isAvailable
                  ownerId
                  tokenId
                  tokenUri
                  smartContractName
                  createdAt
                }
              }
              logs {
                id
                createdAt
                orderId
                updatedBy
                status
              }
              customerId
              createdAt
            }
            total
          }
        }
      }
      ... on UserExceptionType {
        description
        message
      }
    }
  }
`;
export type ListOrdersQueryResult = Apollo.QueryResult<ListOrdersQuery, ListOrdersQueryVariables>;
export const GameNftAssetsDocument = gql`
  query gameNftAssets($pagination: OffsetPagination!, $filter: NftAssetsFilterInput) {
    nftAssets(filter: $filter, pagination: $pagination) {
      items {
        __typename
        ...NftAssetFragment
        offers(take: 1) {
          id
          terms
          smartContractName
          state
        }
      }
      total
    }
  }
  ${NftAssetFragmentFragmentDoc}
`;
export type GameNftAssetsQueryResult = Apollo.QueryResult<GameNftAssetsQuery, GameNftAssetsQueryVariables>;
export const GetMarketplaceOffersDocument = gql`
  query getMarketplaceOffers($pagination: OffsetPagination!, $filter: OffersFilterInput) {
    offers(pagination: $pagination, filter: $filter) {
      items {
        ...OfferItemFragment
      }
      total
    }
  }
  ${OfferItemFragmentFragmentDoc}
`;
export type GetMarketplaceOffersQueryResult = Apollo.QueryResult<
  GetMarketplaceOffersQuery,
  GetMarketplaceOffersQueryVariables
>;
export const OwnerNftAssetsDocument = gql`
  query ownerNftAssets {
    ownerNftAssets {
      id
      properties
      applicationName
      isAvailable
      ownerId
      tokenId
      tokenUri
      smartContractName
      createdAt
      displayStatus
    }
  }
`;
export type OwnerNftAssetsQueryResult = Apollo.QueryResult<OwnerNftAssetsQuery, OwnerNftAssetsQueryVariables>;
export const OwnerOffersDocument = gql`
  query ownerOffers($input: OffsetPagination!) {
    ownerOffers(pagination: $input) {
      total
      items {
        applicationName
        createdAt
        deferredTokenId
        id
        nftAsset {
          id
          properties
          applicationName
          isAvailable
          ownerId
          tokenId
          tokenUri
          smartContractName
          createdAt
        }
        rentalContract {
          applicationName
          createdAt
          customerId
          endAt
          id
          nftAssetId
          orderId
          orderItemId
          smartContractName
          startAt
          state
          status
          updatedAt
        }
        smartContractName
        state
        status
        terms
        txHash
        updatedAt
      }
    }
  }
`;
export type OwnerOffersQueryResult = Apollo.QueryResult<OwnerOffersQuery, OwnerOffersQueryVariables>;
export const GetPayoutRequestsDocument = gql`
  query getPayoutRequests($pagination: OffsetPagination!, $filter: PayoutRequestListInput) {
    payoutRequests(pagination: $pagination, filter: $filter) {
      total
      items {
        id
        currency
        txnHash
        status
        amount
        destination
        createdAt
      }
    }
  }
`;
export type GetPayoutRequestsQueryResult = Apollo.QueryResult<GetPayoutRequestsQuery, GetPayoutRequestsQueryVariables>;
export const SocialEventRewardDocument = gql`
  query socialEventReward($eventId: String!) {
    socialEventReward(eventId: $eventId) {
      ... on SocialEventRewardType {
        id
        eventId
        merkleTree
        smartContractAddress
        chainId
      }
      ... on SocialEventRewardExceptionType {
        description
        message
      }
    }
  }
`;
export type SocialEventRewardQueryResult = Apollo.QueryResult<SocialEventRewardQuery, SocialEventRewardQueryVariables>;
export const TournamentDocument = gql`
  query tournament($slug: String!) {
    tournament(slug: $slug) {
      ...TournamentFragment
      participants {
        id
        userId
        tournamentId
        tournamentParticipantStatus
        createdAt
        updatedAt
      }
    }
  }
  ${TournamentFragmentFragmentDoc}
`;
export type TournamentQueryResult = Apollo.QueryResult<TournamentQuery, TournamentQueryVariables>;
export const TournamentExtraFieldsSchemasDocument = gql`
  query tournamentExtraFieldsSchemas($slug: String!) {
    tournamentExtraFieldsSchemas(slug: $slug) {
      participantExtraFieldsSchema
      teamExtraFieldsSchema
    }
  }
`;
export type TournamentExtraFieldsSchemasQueryResult = Apollo.QueryResult<
  TournamentExtraFieldsSchemasQuery,
  TournamentExtraFieldsSchemasQueryVariables
>;
export const TournamentParticipantDocument = gql`
  query tournamentParticipant($tournamentId: String!) {
    tournamentParticipant(tournamentId: $tournamentId) {
      ...TournamentParticipant
    }
  }
  ${TournamentParticipantFragmentDoc}
`;
export type TournamentParticipantQueryResult = Apollo.QueryResult<
  TournamentParticipantQuery,
  TournamentParticipantQueryVariables
>;
export const TournamentTeamByCodeDocument = gql`
  query tournamentTeamByCode($code: String!) {
    tournamentTeamByCode(code: $code) {
      ...TournamentTeamFragment
    }
  }
  ${TournamentTeamFragmentFragmentDoc}
`;
export type TournamentTeamByCodeQueryResult = Apollo.QueryResult<
  TournamentTeamByCodeQuery,
  TournamentTeamByCodeQueryVariables
>;
export const TournamentTeamByTournamentIdDocument = gql`
  query tournamentTeamByTournamentId($tournamentId: String!) {
    tournamentTeamByTournamentId(tournamentId: $tournamentId) {
      ...TournamentTeamFragment
    }
  }
  ${TournamentTeamFragmentFragmentDoc}
`;
export type TournamentTeamByTournamentIdQueryResult = Apollo.QueryResult<
  TournamentTeamByTournamentIdQuery,
  TournamentTeamByTournamentIdQueryVariables
>;
export const TournamentsDocument = gql`
  query tournaments($pagination: OffsetPagination!, $filter: TournamentsFilterInput) {
    tournaments(pagination: $pagination, filter: $filter) {
      items {
        ...TournamentFragment
      }
      total
    }
  }
  ${TournamentFragmentFragmentDoc}
`;
export type TournamentsQueryResult = Apollo.QueryResult<TournamentsQuery, TournamentsQueryVariables>;
export const TournamentsPreviewDocument = gql`
  query tournamentsPreview {
    tournamentsPreview {
      items {
        id
        applicationName
        gameUid
        title
        slug
        imageUrl
        isFinished
        isVisible
        registrationEndDate
        tournamentStartDate
        tournamentFinishDate
      }
    }
  }
`;
export type TournamentsPreviewQueryResult = Apollo.QueryResult<
  TournamentsPreviewQuery,
  TournamentsPreviewQueryVariables
>;
export const WaitlistRequestDocument = gql`
  query waitlistRequest($featureId: String!) {
    waitlistRequest(featureId: $featureId) {
      ...WaitlistRequestFragment
    }
  }
  ${WaitlistRequestFragmentFragmentDoc}
`;
export type WaitlistRequestQueryResult = Apollo.QueryResult<WaitlistRequestQuery, WaitlistRequestQueryVariables>;
