import { Combobox, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { Dispatch, Fragment, SetStateAction, useMemo, useState } from 'react';

import { ReactComponent as IconChevronDown } from '@public/icons/custom/chevron-down.svg';

import { Checkbox } from '../Checkbox';

type ComboBoxFilterProps<T> = {
  predicate: (option: T, searchQuery: string) => boolean;
  placeholder: string;
};

type ComboBoxProps<T> = {
  options: ComboBoxOption<T>[];
  filterProps?: ComboBoxFilterProps<T>;
  children: React.ReactNode;
  selected: T[];
  onChange: Dispatch<SetStateAction<T[]>>;
};

type ComboBoxOption<T> = {
  id: string;
  value: T;
  children: React.ReactNode;
};

const ComboBoxItem = <T,>({ option }: { option: ComboBoxOption<T> }): JSX.Element => {
  return (
    <Combobox.Option
      className={({ active }) =>
        classNames('select-none p-2 text-labelPrimary cursor-pointer rounded', active && 'bg-bgTertiary')
      }
      value={option.value}
    >
      {({ selected }) => (
        <div className="flex items-center">
          <Checkbox checked={selected} applyFocusRing={false} />

          <span className="block truncate ml-2">{option.children}</span>
        </div>
      )}
    </Combobox.Option>
  );
};

export const ComboBox = <T,>({ options, filterProps, children, selected, onChange }: ComboBoxProps<T>): JSX.Element => {
  const [searchQuery, setSearchQuery] = useState('');

  const filteredValues = useMemo(() => {
    return !filterProps || searchQuery === ''
      ? options
      : options.filter(e => filterProps.predicate(e.value, searchQuery));
  }, [options, filterProps, searchQuery]);

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Combobox value={selected} onChange={onChange} multiple>
      <div className="relative flex-shrink-0">
        <Combobox.Button
          className={classNames(
            'py-1 px-2 relative cursor-pointer overflow-hidden rounded-lg',
            'bg-selectBg hover:bg-selectBgHover text-left flex items-center',
            'border-selectBorder border-1 text-sm text-selectTypo',
          )}
        >
          {children}

          <IconChevronDown className="h-6 w-6 text-labelTertiary ml-1" aria-hidden="true" />
        </Combobox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setSearchQuery('')}
        >
          <Combobox.Options
            className={classNames(
              'absolute mt-1 max-h-60 rounded-md max-w-72 w-56',
              'bg-bgSecondary p-2 text-sm z-50 overflow-auto',
            )}
          >
            {filterProps ? (
              <Combobox.Input
                className={classNames(
                  'w-full py-1.5 text-sm',
                  'bg-transparent focus:outline-none focus:ring-0',
                  'leading-4 placeholder-labelPlaceholder',
                  'border-x-0 border-t-0 border-b-1 border-bgStripe mb-3',
                )}
                value={searchQuery}
                displayValue={() => searchQuery}
                placeholder={filterProps.placeholder}
                onChange={event => setSearchQuery(event.target.value)}
              />
            ) : null}

            {!filteredValues.length && searchQuery.trim() ? (
              <div className="relative cursor-default select-none py-2 px-4 text-labelSecondary">Nothing found.</div>
            ) : (
              filteredValues.map(option => <ComboBoxItem key={option.id} option={option} />)
            )}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
};
