import { Transition } from '@headlessui/react';
import classNames from 'classnames';
import React, { ReactNode, useState } from 'react';
import { IconBaseProps } from 'react-icons';
import { FaQuestionCircle } from 'react-icons/fa';

import { fastFadeTransitionClassNames } from '@features/common';

export enum TooltipDirection {
  left = 'left',
  right = 'right',
  top = 'top',
  bottom = 'bottom',
}

export enum TooltipType {
  QUESTION_WRAPPER = 'QUESTION_WRAPPER',
  COMPONENT_WRAPPER = 'COMPONENT_WRAPPER',
}

export type TootipConfigParams = {
  direction?: TooltipDirection;
  duration?: number;
  iconProps?: IconBaseProps;
  tooltipWindowProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
  triangleWindowProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
};

type ElTooltipProps = {
  config?: TootipConfigParams;
  tooltipType: TooltipType;
  tooltipContent: string | JSX.Element | ReactNode;
  onTooltipShowed?: () => void;
  onTooltipHided?: () => void;
  children?: React.ReactNode;
};

type NotifyTriangleProps = {
  direction: TooltipDirection;
  triangleWindowProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
};

const NotifyTriangle = ({ direction, triangleWindowProps }: NotifyTriangleProps): JSX.Element | null => {
  switch (direction) {
    case TooltipDirection.top:
      return (
        <div className="w-2 absolute left-2 -bottom-0 overflow-hidden inline-block">
          <div
            {...triangleWindowProps}
            className={classNames('h-8 bg-gray-900 rotate-45 origin-top-right', triangleWindowProps?.className)}
          />
        </div>
      );
    case TooltipDirection.bottom:
      return (
        <div className="w-2 absolute top-0 left-2 overflow-hidden inline-block">
          <div
            {...triangleWindowProps}
            className={classNames('h-8 bg-gray-900 -rotate-45 origin-bottom-right', triangleWindowProps?.className)}
          />
        </div>
      );
    case TooltipDirection.left:
      return (
        <div className="w-2 absolute bottom-2 -left-3 overflow-hidden inline-block">
          <div
            {...triangleWindowProps}
            className={classNames('h-8 bg-gray-900 -rotate-45 origin-bottom-right', triangleWindowProps?.className)}
          />
        </div>
      );
    case TooltipDirection.right:
      return (
        <div className="w-2 absolute top-2 left-5 overflow-hidden inline-block">
          <div
            {...triangleWindowProps}
            className={classNames('h-8 bg-gray-900 -rotate-45 origin-top-left', triangleWindowProps?.className)}
          />
        </div>
      );
    default:
      return null;
  }
};

const ElTooltip = ({
  config,
  tooltipContent,
  tooltipType,
  onTooltipShowed,
  onTooltipHided,
  children,
}: ElTooltipProps): JSX.Element => {
  const {
    duration = 1000,
    direction = TooltipDirection.top,
    iconProps,
    tooltipWindowProps,
    triangleWindowProps,
  } = config || {};

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [timeoutId, setTimeoutId] = useState<null | NodeJS.Timeout>(null);

  const tooltipMouseMoveHandler = (): void => {
    clearTimeout(timeoutId as NodeJS.Timeout);
    setIsTooltipOpen(true);
    onTooltipShowed?.();
  };

  const tooltipMouseLeaveHandler = (): void => {
    clearTimeout(timeoutId as NodeJS.Timeout);

    const id = setTimeout(() => {
      setIsTooltipOpen(false);
      clearTimeout(id as NodeJS.Timeout);
      onTooltipHided?.();
    }, duration);

    setTimeoutId(id);
  };

  const positionTooltip = (direction: TooltipDirection): string => {
    switch (direction) {
      case TooltipDirection.top:
        return 'bottom-8 -left-4';
      case TooltipDirection.bottom:
        return 'top-8 -left-8';
      case TooltipDirection.left:
        return 'right-7 -top-2';
      case TooltipDirection.right:
        return 'left-7 -top-1';
      default:
        return '';
    }
  };

  return (
    <div onMouseMove={tooltipMouseMoveHandler} onMouseLeave={tooltipMouseLeaveHandler} className="relative">
      {tooltipType === TooltipType.COMPONENT_WRAPPER && children ? children : <FaQuestionCircle {...iconProps} />}
      {isTooltipOpen && (
        <>
          <Transition appear={true} show={true} {...fastFadeTransitionClassNames}>
            <div
              {...tooltipWindowProps}
              className={classNames(
                'absolute z-50',
                'rounded-md',
                positionTooltip(direction),
                tooltipWindowProps?.className,
              )}
              onMouseMove={tooltipMouseMoveHandler}
              onMouseLeave={tooltipMouseLeaveHandler}
            >
              {tooltipContent}
            </div>
          </Transition>
          {tooltipType === TooltipType.QUESTION_WRAPPER && (
            <NotifyTriangle direction={direction} triangleWindowProps={triangleWindowProps} />
          )}
        </>
      )}
    </div>
  );
};

export default ElTooltip;
