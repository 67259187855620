const pluralRules = new Intl.PluralRules('en-US');

export const Pluralize = ({
  count,
  singular,
  plural,
}: {
  count?: number;
  singular: React.ReactNode;
  plural: React.ReactNode;
}): JSX.Element | null => {
  if (!count) {
    return null;
  }

  const grammaticalNumber = pluralRules.select(count);
  switch (grammaticalNumber) {
    case 'one':
      return (
        <>
          {count} {singular}
        </>
      );
    case 'other':
      return (
        <>
          {count} {plural}
        </>
      );
    default:
      return null;
  }
};
