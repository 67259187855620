import classNames from 'classnames';

import { GradientSpinner } from './GradientSpinner';

type Props = {
  label: string;
  className?: string;
  spinnerClassName?: string;
};

export const GradientSpinnerWithLabel = ({ label, className, spinnerClassName }: Props): JSX.Element => {
  return (
    <div className={classNames('w-full flex flex-col items-center py-16', className)}>
      <GradientSpinner className={spinnerClassName} />
      <span className="text-sm pt-2 text-center">{label}</span>
    </div>
  );
};
