import { useRouter } from 'next/router';
import React, { createContext, PropsWithChildren, useContext, useState } from 'react';

import { GAME_HISTORY_ORDERS_PAGE_ROUTE } from '@constants/routes';
import { ApplicationSettings } from '@features/common';
import { useOrderRepository } from '@features/marketplace/hooks';
import type { MarketplaceSlice } from '@features/marketplace/store';
import { EntityWithId } from '@globalTypes/globals';
import { CreateOrderInput, SmartContractName } from '@graphql/generated';
import { gameSliceSelector } from '@store/slices';
import { useStore } from '@store/zustand';

import { ModalStatus } from './enum';

type OrderFormModalContextInterface = {
  selectedOffer: NonNullable<MarketplaceSlice['marketplaceSlice']['selectedOffer']>;
  acceptedOrder: EntityWithId | null;
  applicationSettings: ApplicationSettings | null;
  status: ModalStatus;
  closeModal: () => void;
  closeModalAfterSuccess: () => void;
  onSubmitRentModal: (terms: CreateOrderInput['terms']) => void;
  onFailedRentModal: () => void;
  onTryRentAgain: () => void;
};

const initialValue = {} as OrderFormModalContextInterface;

export const OrderFormModalContext = createContext<OrderFormModalContextInterface>(initialValue);

type Props = {
  closeModal: () => void;
  selectedOffer: NonNullable<MarketplaceSlice['marketplaceSlice']['selectedOffer']>;
};

export const OrderFormModalContextProvider = ({
  closeModal,
  selectedOffer,
  children,
}: PropsWithChildren<Props>): JSX.Element | null => {
  const router = useRouter();

  const [status, setStatus] = useState<ModalStatus>(ModalStatus.ACTIVE);
  const [acceptedOrder, setAcceptedOrder] = useState<EntityWithId | null>(null);

  const { createOrder } = useOrderRepository();

  const submitRentModalHandler = async (terms: CreateOrderInput['terms']): Promise<void> => {
    const order = await createOrder({
      input: {
        terms: { ...terms, smartContractName: selectedOffer.smartContractName },
        offerIds: [selectedOffer.id],
      },
    });
    setAcceptedOrder(order);
    setStatus(ModalStatus.SUCCESS);
  };

  const failedRentModalHandler = (): void => {
    setStatus(ModalStatus.FAIL);
  };

  const tryRentAgainHandler = (): void => {
    setStatus(ModalStatus.ACTIVE);
  };

  const closeModalAfterSuccess = (): void => {
    closeModal();
    if (acceptedOrder) {
      router.push({
        pathname: GAME_HISTORY_ORDERS_PAGE_ROUTE,
        query: {
          orderId: acceptedOrder.id,
        },
      });
    }
  };

  const smartContractName = selectedOffer.smartContractName as SmartContractName;
  const { currentGameSettings } = useStore(gameSliceSelector);

  return (
    <OrderFormModalContext.Provider
      value={{
        selectedOffer,
        acceptedOrder,
        status,
        closeModal,
        closeModalAfterSuccess,
        applicationSettings: currentGameSettings[smartContractName] ?? null,
        onSubmitRentModal: submitRentModalHandler,
        onFailedRentModal: failedRentModalHandler,
        onTryRentAgain: tryRentAgainHandler,
      }}
    >
      {children}
    </OrderFormModalContext.Provider>
  );
};

export const useOrderFormModalContext = (): OrderFormModalContextInterface => {
  return useContext(OrderFormModalContext);
};
