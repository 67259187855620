import classNames from 'classnames';

import styles from './PulsingLiveStatus.module.css';

type PulsingLiveStatusProps = {
  className?: string;
};

export const PulsingLiveStatus = ({ className }: PulsingLiveStatusProps): JSX.Element => {
  return (
    <div className={classNames('relative w-5 h-5', className)}>
      <div
        className={classNames('center-absolute bg-successDarker', 'w-5 h-5 rounded-xl', styles.scaleUpScaleDownRepeat)}
      />
      <div className="center-absolute bg-successDark w-3 h-3 rounded-xl" />
      <div className="center-absolute bg-success w-1 h-1 rounded-lg" />
    </div>
  );
};
