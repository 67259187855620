import cn from 'classnames';
import Link from 'next/link';

import { createShareActions, logTournamentShare } from '@features/common';
import { TournamentFragmentFragment as TournamentFragment } from '@graphql/generated';

type Props = {
  tournament: TournamentFragment;
};

export const TournamentShareActions = ({ tournament }: Props) => {
  // eslint-disable-next-line max-len
  const inviteText = `Join the ${tournament.title} on %playdex% and show your gaming skills to the world! Compete against fellow gamers and climb the ranks to become a top player!`;
  const { facebook, twitter, telegram, link } = createShareActions(window.location.href, inviteText);

  return (
    <div className="flex w-full flex-wrap mt-4 gap-2 justify-center text-labelSecondary">
      {[facebook, twitter, telegram, link].map(action => {
        const Icon = action.icon;
        const className = cn(
          'flex flex-col w-14 h-14 items-center justify-center shrink-0 rounded-full',
          'cursor-pointer hover:text-labelPrimary transition duration-100 ease-out text-inherit',
        );
        const content = (
          <>
            <Icon className="w-6 h-6 shrink-0" />
            <div className="mt-2 text-xs">{action.title}</div>
          </>
        );

        if (action.href) {
          return (
            <Link
              key={action.title}
              href={action.href}
              target="_blank"
              rel="noopener noreferrer"
              className={className}
              onClick={() => {
                logTournamentShare({ tournamentId: tournament.id, shareMethod: action.title });
              }}
            >
              {content}
            </Link>
          );
        }

        if (action.onClick) {
          return (
            <button
              key={action.title}
              onClick={() => {
                logTournamentShare({ tournamentId: tournament.id, shareMethod: action.title });
                action.onClick?.();
              }}
              className={className}
            >
              {content}
            </button>
          );
        }
      })}
    </div>
  );
};
