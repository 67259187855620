import { Address } from 'wagmi';

import { compareAddresses } from '@features/common';

import { getCurrencyTokenListByChainId } from './getCurrencyTokenListByChainId';
import { CurrencyToken } from '../types';

export const getTokenIconAndSymbol = ({
  address,
  chainId,
}: {
  address?: Address;
  chainId?: number;
}): CurrencyToken | undefined => {
  if (!address || !chainId) {
    return;
  }

  const tokens = getCurrencyTokenListByChainId(chainId);
  return tokens.find(t => compareAddresses(address, t.address));
};
