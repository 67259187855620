import { Transition } from '@headlessui/react';
import classNames from 'classnames';
import { Fragment, ReactNode, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { normalFadeTransitionClassNames, useOnKeyboardKeyPress } from '@features/common';

export type ElModalProps = {
  title?: string;
  children?: ReactNode;
  testChildren?: ReactNode;
  className?: string;
  titleSectionStyles?: string;
  onCloseModal?: () => void;
  modalWrapperStyles?: string;
};

export const PORTAL_NODE_ID = 'modal-portal';

export const ElModal = ({
  title,
  children,
  className,
  titleSectionStyles,
  modalWrapperStyles,
  onCloseModal,
}: ElModalProps): JSX.Element | null => {
  useOnKeyboardKeyPress('Escape', () => onCloseModal?.());

  const [portalContainer, setPortalContainer] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (document) {
      document.body.classList.add('overflow-hidden');
      setPortalContainer(document.getElementById(PORTAL_NODE_ID));
    }

    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, []);

  if (!portalContainer) return null;

  return createPortal(
    <div
      onClick={onCloseModal}
      className={classNames(
        'fixed w-[100vw] h-[100vh] inset-0 flex items-center bg-[#12131799]',
        'justify-center z-[100] p-0 md:p-1 md:px-4',
        modalWrapperStyles,
      )}
    >
      <Transition as={Fragment} appear={true} show={true} {...normalFadeTransitionClassNames}>
        <div
          onClick={e => e.stopPropagation()}
          className={classNames(
            'justify-center',
            'flex',
            'flex-col',
            'rounded-none md:rounded-2.5',
            'border border-none md:border-bgStripe md:border-solid',
            'bg-modalWindowBgPrimary',
            'z-[100]',
            'overflow-auto',
            className,
          )}
        >
          {title && (
            <div
              className={classNames(
                'flex',
                'flex-row',
                'justify-between',
                'p-6',
                'bg-white',
                'border-b',
                'border-gray-200',
                'rounded-tl-lg',
                'rounded-tr-lg',
                'text-gray-800',
                titleSectionStyles,
              )}
            >
              <p className="font-semibold">{title}</p>
            </div>
          )}
          {children}
        </div>
      </Transition>
    </div>,
    portalContainer,
  );
};

export default ElModal;
