import React from 'react';

import { useToken, ConnectWalletButton } from '@features/auth';
import { GradientSpinner } from '@features/common';
import { GamesAndTournamentsSearchBar, MyAccountSection } from '@features/header';

export const UserAuthInfoSection = (): JSX.Element => {
  const { user, isUserLoading } = useToken();

  if (isUserLoading) {
    return (
      <div className="relative flex gap-2 items-center">
        <div className="block md:hidden">
          <GamesAndTournamentsSearchBar />
        </div>
        <GradientSpinner />
      </div>
    );
  }

  return (
    <div className="flex gap-2">
      <div className="block md:hidden">
        <GamesAndTournamentsSearchBar />
      </div>
      {user ? (
        <div className="flex gap-2 items-center">
          <MyAccountSection user={user} />
        </div>
      ) : (
        <ConnectWalletButton />
      )}
    </div>
  );
};
