import { useQuery } from '@apollo/client';
import * as Sentry from '@sentry/nextjs';

import {
  LeaderboardDocument,
  LeaderboardQuery,
  LeaderboardQueryResult,
  LeaderboardQueryVariables,
} from '@graphql/generated';

export const useLeaderboardQuery = (id?: string): LeaderboardQueryResult => {
  return useQuery<LeaderboardQuery, LeaderboardQueryVariables>(LeaderboardDocument, {
    variables: { id: id as string },
    onError: e => {
      if (e.message === 'No Leaderboard found') return;

      Sentry.captureException(e, {
        extra: {
          message: 'Leaderboard table load error',
        },
      });
    },
    skip: !id,
    pollInterval: 60000,
  });
};
