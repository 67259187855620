/* eslint-disable */
import { isApolloError } from '@apollo/client';
import { AxiosError } from 'axios';
import { GraphQLError } from 'graphql/error/GraphQLError';

import { notice, ToastType } from '@utils/notice';
import { UserExceptionType } from '@graphql/generated';
import { isNonNullObject } from '@apollo/client/utilities';

type ErrorHandlerProps = {
  error: AxiosError | unknown;
  disconnect?: () => Promise<void>;
  customHandler?: () => void | Promise<void>;
  custom401Handler?: () => void | Promise<void>;
};

// Only for FrontEnd side usage
export const errorHandler = ({ error, disconnect, customHandler, custom401Handler }: ErrorHandlerProps): void => {
  const axiosRes = (error as AxiosError).response;

  if (axiosRes?.status === 401 || axiosRes?.status === 403) {
    if (custom401Handler) {
      custom401Handler();

      return;
    }

    if (disconnect) {
      disconnect().then(() => {
        notice(ToastType.ERROR, 'We lost connection to your account.\n Please, login and try again!');
      });

      return;
    }
  }

  if (customHandler) {
    customHandler();

    return;
  }

  const errorMessage =
    (axiosRes && typeof axiosRes.data.detail === 'string' && axiosRes.data.detail) ||
    'Something went wrong! \n Please try again!';

  notice(ToastType.ERROR, errorMessage);
};

export type ExceptionResultType = Required<UserExceptionType>;

export const isExceptionResultType = (error: unknown): error is ExceptionResultType => {
  return isNonNullObject(error) && ['UserExceptionType'].includes(error.__typename);
};

export const apolloErrorHandler = (error: unknown) => {
  const baseResponse = 'Something went wrong!';

  // @ts-ignore
  if (isApolloError(error)) {
    error.graphQLErrors.forEach((gqlError: GraphQLError) => {
      notice(ToastType.ERROR, gqlError.message || baseResponse);
    });
    return error;
  }

  if (isExceptionResultType(error)) {
    notice(ToastType.ERROR, error.message);
    return error;
  }

  notice(ToastType.ERROR, baseResponse);
  return error;
};

export enum HttpErrorCodes {
  UnprocessableEntity = 422,
}
/**
 * EIP-1193
 * @link https://eips.ethereum.org/EIPS/eip-1193#rpc-errors
 * @example https://docs.metamask.io/guide/ethereum-provider.html#using-the-provider
 */
export enum EthereumProviderRpcErrorCodes {
  UserRejectedRequest = 4001,
}
export enum MetaMaskErrorCodes {
  ChainHasNotBeenAddedToMetaMask = 4902,
}

type EthereumError = {
  message: string;
  code: EthereumProviderRpcErrorCodes | MetaMaskErrorCodes;
  data?: unknown;
};

export function isEthereumError(err: unknown): err is EthereumError {
  return typeof err === 'object' && err !== null && 'code' in err;
}
