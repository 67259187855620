import classNames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';

import {
  BasicTournamentStatusIndicator,
  tournamentOpenedAnalyticsEventHandler,
  TournamentEventSource,
} from '@features/tournaments';
import { TournamentsPreviewResponse } from '@features/tournaments/utils/fetchTournamentsPreview';
import { ArrayElement } from '@globalTypes/globals';

export type TournamentsPreviewProps = {
  tournaments: TournamentsPreviewResponse;
  onTournamentClick?: (tournament: ArrayElement<TournamentsPreviewResponse>) => void;
};

export const TournamentItem = ({
  tournament,
  onClick,
}: {
  tournament: ArrayElement<TournamentsPreviewResponse>;
  onClick?: VoidFunction;
}): JSX.Element => {
  const tournamentHref = `/tournaments/${tournament.slug}`;

  const onClickRedirectHandler = (): void => {
    onClick?.();

    tournamentOpenedAnalyticsEventHandler({
      source: TournamentEventSource.FROM_TOURNAMENT_PREVIEW,
      tournamentSlug: tournament.slug,
      gameUid: tournament?.gameUid || null,
    });
  };

  return (
    <Link
      href={tournamentHref}
      onClick={onClickRedirectHandler}
      className={classNames(
        'flex items-center px-2 md:px-3 py-2.5 cursor-pointer',
        'hover:bg-bgSecondary rounded-lg text-labelPrimary',
      )}
    >
      <Image
        src={tournament.gameLogo ?? tournament.imageUrl}
        width={24}
        height={24}
        alt=""
        className="rounded object-cover w-6 h-6"
      />
      <span className="flex-1 px-2 text-sm text-wrap-1">{tournament.title}</span>
      <BasicTournamentStatusIndicator
        tournamentStartDate={tournament.tournamentStartDate}
        tournamentFinishDate={tournament.tournamentFinishDate}
        className="text-labelSecondary"
      />
    </Link>
  );
};

export const TournamentsPreview = ({ tournaments, onTournamentClick }: TournamentsPreviewProps): JSX.Element | null => {
  if (!tournaments?.length) {
    return null;
  }

  return (
    <div className="px-2 pt-5">
      <div className="items-center flex pb-4 px-3">
        <span className="text-labelSecondary text-sm font-medium flex-1">Tournaments</span>
        <Link href="/tournaments" className="text-xs text-labelSecondary hover:text-white transition-colors">
          See All
        </Link>
      </div>
      {tournaments?.map(tournament => (
        <TournamentItem tournament={tournament} key={tournament.id} onClick={() => onTournamentClick?.(tournament)} />
      ))}
    </div>
  );
};
