/* eslint-disable functional/immutable-data */

import { Draft } from 'immer';

import { OfferType } from '@graphql/generated';
import { StateCreatorWithInitialState } from '@store/types';

type Offer = Pick<OfferType, 'id' | 'terms' | 'smartContractName'>;

export type MarketplaceSlice = {
  marketplaceSlice: {
    selectedOffer: Offer | null;
  };
  marketplaceSliceActions: {
    setSelectedOffer: (offer: Offer | null) => void;
  };
};

export const createMarketplaceSlice: StateCreatorWithInitialState<MarketplaceSlice> = set => {
  return {
    marketplaceSlice: {
      selectedOffer: null,
    },
    marketplaceSliceActions: {
      setSelectedOffer: offer => {
        set((state: Draft<MarketplaceSlice>) => {
          state.marketplaceSlice.selectedOffer = offer;
        });
      },
    },
  };
};

export const marketplaceSliceSelector = (state: MarketplaceSlice): MarketplaceSlice['marketplaceSlice'] =>
  state.marketplaceSlice;
export const marketplaceSliceActionsSelector = (state: MarketplaceSlice): MarketplaceSlice['marketplaceSliceActions'] =>
  state.marketplaceSliceActions;
