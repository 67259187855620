import { PrismicLink } from '@prismicio/react';
import cn from 'classnames';
import classNames from 'classnames';
import { MouseEvent, useMemo } from 'react';

import { useUrlBuilder } from '@entities/urlBuilder';
import { NullComponent } from '@features/common';
import {
  GameSidebarNewStatusLabel,
  GameSidebarSoonStatusLabel,
} from '@features/common/components/Sidebar/GameSidebarStatus';

import { GameConfig } from '../../types';
import { ImageWithFallback } from '../ImageWithFallback';

type GamesListItemProps = {
  game: GameConfig;
  isActive: boolean;
  showSidebarStatus?: boolean;
  onClick: () => void;
};

export const GamesListItem = ({ game, isActive, showSidebarStatus, onClick }: GamesListItemProps): JSX.Element => {
  const urlBuilder = useUrlBuilder();

  const StatusLabel = useMemo(() => {
    if (game.sidebarStatus === 'new') return GameSidebarNewStatusLabel;
    if (game.sidebarStatus === 'soon') return GameSidebarSoonStatusLabel;

    return NullComponent;
  }, [game.sidebarStatus]);

  return (
    <PrismicLink
      href={game.redirectLocation || urlBuilder.buildGameUrl({ gameId: game.id })}
      key={game.id}
      className={cn(
        'flex items-center py-2.5 px-3',
        'rounded-lg  cursor-pointer text-labelPrimary hover:bg-bgSecondary',
        'transition-all duration-150',
        isActive && 'bg-bgSecondary cursor-default',
      )}
      data-active-game={isActive}
      onClick={(e: MouseEvent<HTMLAnchorElement>) => {
        if (isActive) {
          e.preventDefault();
        }

        onClick();
      }}
    >
      <ImageWithFallback
        alt=""
        src={game.imgs.logo ?? 'invalid'}
        className={classNames('w-6 h-6 bg-cover rounded', isActive && 'rounded-sm')}
        fallbackSrc="/icons/game-logo.svg"
      />

      <span className="pl-3 flex-1 pr-1 text-wrap-1 text-sm">{game.title}</span>

      {showSidebarStatus && <StatusLabel />}
    </PrismicLink>
  );
};
