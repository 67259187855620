/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/ban-ts-comment,functional/no-let */
/* eslint functional/immutable-data: 0 */
import { Draft } from 'immer';
import { mergeDeepRight } from 'ramda';
import { useLayoutEffect } from 'react';
import { create, StoreApi, UseBoundStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { createMarketplaceSlice } from '@features/marketplace';
import { featureChecker } from '@services/featureChecker';

import { createUserSlice, createGameSlice, createInnerWalletSlice, createTournamentSlice } from './slices';
import {
  BasicStoreState,
  InitialStoreState,
  Locales,
  StateCreatorWithInitialState,
  StoreApis,
  StoreState,
  UserTimeZones,
} from './types';

export type ZustandStore = StoreState & {
  api: StoreApis;
};

const basicState: BasicStoreState = {
  userTimeZone: UserTimeZones.LOCAL,
  locale: Locales.EN_US,
  modalControllers: {
    redirectOnNonAvailableModalHandler: null,
    closeAllHomePageModals: null,
  },
};

const createRootSlice: StateCreatorWithInitialState<StoreState> = (...args) => ({
  ...basicState,
  ...createUserSlice(...args),
  ...createGameSlice(...args),
  ...createInnerWalletSlice(...args),
  ...createMarketplaceSlice(...args),
  ...createTournamentSlice(...args),
});

export const initializeStore = (preloadedState: InitialStoreState): UseBoundStore<StoreApi<ZustandStore>> =>
  create(
    devtools(
      immer((set, get, api) => ({
        ...createRootSlice(set, get, api, preloadedState),
        api: {
          updateUserTimeZone(timeZone: UserTimeZones) {
            set((state: Draft<ZustandStore>) => {
              state.userTimeZone = timeZone;
            });
          },
          updateLocale(locale: Locales) {
            set((state: Draft<ZustandStore>) => {
              state.locale = locale;
            });
          },
          updateRedirectOnNonAvailableModalHandler(handler) {
            set((state: Draft<ZustandStore>) => {
              state.modalControllers.redirectOnNonAvailableModalHandler = handler;
            });
          },
          updateCloseAllHomePageModals(handler) {
            set((state: Draft<ZustandStore>) => {
              state.modalControllers.closeAllHomePageModals = handler;
            });
          },
        },
      })),
      { enabled: featureChecker.isDevelopmentAppEnv() },
    ),
  );

export let useStore: UseBoundStore<StoreApi<ZustandStore>>;

const parseInitialPayload = (initialPayloadState: InitialStoreState): InitialStoreState => {
  try {
    return typeof initialPayloadState === 'string' ? JSON.parse(initialPayloadState) : initialPayloadState;
  } catch {
    return {
      gameSlice: {},
    };
  }
};

export function useCreateStore(serverInitialState: InitialStoreState): void {
  // Server side code: For SSR & SSG, always use a new store.
  if (typeof window === 'undefined') {
    useStore ??= initializeStore(parseInitialPayload(serverInitialState));
    return;
  }
  // End of server side code

  // Client side code:
  // Next.js always re-uses same store regardless of whether page is a SSR or SSG or CSR type.
  const isReusingStore = Boolean(useStore);
  useStore ??= initializeStore(parseInitialPayload(serverInitialState));
  // When next.js re-renders _app while re-using an older store, then replace current state with
  // the new state (in the next render cycle).
  // (Why next render cycle? Because react cannot re-render while a render is already in progress.
  // i.e. we cannot do a setState() as that will initiate a re-render)
  //
  // eslint complaining "React Hooks must be called in the exact same order in every component render"
  // is ignorable as this code runs in same order in a given environment (i.e. client or server)
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useLayoutEffect(() => {
    // serverInitialState is undefined for CSR pages. It is up to you if you want to reset
    // states on CSR page navigation or not. I have chosen not to, but if you choose to,
    // then add `serverInitialState = getDefaultInitialState()` here.
    if (serverInitialState && isReusingStore) {
      const newState = mergeDeepRight(
        // re-use functions from existing store
        useStore.getState() ?? {},
        // but reset all other properties.
        serverInitialState,
      ) as StoreState;
      useStore.setState(
        newState,
        true, // replace states, rather than shallow merging
      );
    }
  });

  return;
}
