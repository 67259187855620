import { createContext, useEffect, useRef, useState } from 'react';

export type WindowOrientation = {
  isXsMobile: boolean;
  isMobile: boolean;
  isDesktop: boolean;
  isTablet: boolean;
};

export type WindowSizeContextType = {
  orientation: WindowOrientation;
};

const defaultContext: WindowSizeContextType = {
  orientation: {
    isXsMobile: true,
    isMobile: true,
    isTablet: false,
    isDesktop: false,
  },
};

export const WindowSizeContext = createContext(defaultContext);

export const windowWidthBreakpoints = {
  xs: 480,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1536,
};

export const WindowSizeContextProvider = ({ children }: React.PropsWithChildren): JSX.Element => {
  const matchMediaRef = useRef<{
    xsMobile?: MediaQueryList;
    mobile?: MediaQueryList;
    tablet?: MediaQueryList;
    desktop?: MediaQueryList;
  }>();

  // eslint-disable-next-line functional/immutable-data
  matchMediaRef.current ??=
    typeof window === 'undefined'
      ? {}
      : {
          xsMobile: window.matchMedia(`(max-width: ${windowWidthBreakpoints.xs - 1}px)`),
          mobile: window.matchMedia(`(max-width: ${windowWidthBreakpoints.sm - 1}px)`),
          tablet: window.matchMedia(
            `(min-width: ${windowWidthBreakpoints.sm}px) and (max-width: ${windowWidthBreakpoints.xl - 1}px)`,
          ),
        };

  const [isXsMobile, setIsXsMobile] = useState<boolean>(() => matchMediaRef.current?.xsMobile?.matches ?? false);
  const [isMobile, setIsMobile] = useState<boolean>(() => matchMediaRef.current?.mobile?.matches ?? false);
  const [isTablet, setIsTablet] = useState<boolean>(() => matchMediaRef.current?.tablet?.matches ?? false);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }
    const handleIsXsMobileChange = (e: MediaQueryListEvent): void => setIsXsMobile(e.matches);
    const handleIsMobileChange = (e: MediaQueryListEvent): void => setIsMobile(e.matches);
    const handleIsTabletChange = (e: MediaQueryListEvent): void => setIsTablet(e.matches);

    matchMediaRef.current?.xsMobile?.addEventListener('change', handleIsXsMobileChange);
    matchMediaRef.current?.mobile?.addEventListener('change', handleIsMobileChange);
    matchMediaRef.current?.tablet?.addEventListener('change', handleIsTabletChange);
    return () => {
      matchMediaRef.current?.xsMobile?.removeEventListener('change', handleIsXsMobileChange);
      matchMediaRef.current?.mobile?.removeEventListener('change', handleIsMobileChange);
      matchMediaRef.current?.tablet?.removeEventListener('change', handleIsTabletChange);
    };
  }, []);

  const newValue = {
    orientation: {
      isXsMobile,
      isMobile,
      isTablet,
      isDesktop: !isXsMobile && !isMobile && !isTablet,
    },
  };

  return <WindowSizeContext.Provider value={newValue}>{children}</WindowSizeContext.Provider>;
};
