import React from 'react';

import { useAuthController } from '@features/auth';
import { Button } from '@features/common/components';

export const ConnectWalletButton = (): JSX.Element => {
  const { requireAuth } = useAuthController();

  return (
    <Button
      size="m"
      onClick={() =>
        requireAuth({
          includeDiscordVerification: false,
          includePhoneVerification: false,
        })
      }
    >
      Connect wallet
    </Button>
  );
};
