import classNames from 'classnames';
import { DateTime } from 'luxon';
import React from 'react';

import { translateTournamentBracketType } from '@features/tournaments/utils/tournament-bracket-type-i18n';
import { TournamentBracketType } from '@graphql/generated';
import { ReactComponent as IconBracketSystem } from '@public/icons/bracket-system.svg';
import { ReactComponent as IconCalendar } from '@public/icons/calendar.svg';
import { useStore } from '@store/zustand';
import { getLocaleDateTime } from '@utils/dateTime';

const Container = ({ label, icon }: { label: string; icon?: JSX.Element }): JSX.Element => {
  return (
    <div className="flex items-center">
      {icon && <span className="w-4 h-4 mr-1">{icon}</span>}
      <span className="text-xs" suppressHydrationWarning={true}>
        {label}
      </span>
    </div>
  );
};

type TournamentDateAndEliminationTypeProps = {
  startDate: string;
  finishDate?: string;
  bracketType?: TournamentBracketType | null;
  className?: string;
};

export const TournamentDateAndEliminationType = ({
  startDate,
  finishDate,
  bracketType,
  className,
}: TournamentDateAndEliminationTypeProps): JSX.Element => {
  const userTimeZone = useStore(state => state.userTimeZone);
  const locale = useStore(state => state.locale);

  return (
    <div className={classNames('flex gap-4 flex-wrap', className)}>
      <div className="flex px-2 flex-wrap text-labelPrimary bg-bgSecondary rounded-xl border-1 border-bgStripe">
        <Container
          label={getLocaleDateTime(startDate, userTimeZone, locale).toLocaleString(DateTime.DATE_MED)}
          icon={<IconCalendar />}
        />
        {finishDate && (
          <>
            <div className="mx-1">-</div>
            <Container label={getLocaleDateTime(finishDate, userTimeZone, locale).toLocaleString(DateTime.DATE_MED)} />
          </>
        )}
      </div>

      {bracketType && (
        <div className="flex items-center px-2 text-labelPrimary bg-bgSecondary rounded-xl border-1 border-bgStripe">
          <Container label={translateTournamentBracketType(bracketType)} icon={<IconBracketSystem />} />
        </div>
      )}
    </div>
  );
};
