import React, { PropsWithChildren } from 'react';

type Props<WC extends React.ElementType> = {
  condition: boolean | (() => boolean);
  wrapper?: WC;
  children: React.ReactNode;
} & PropsWithChildren<React.ComponentPropsWithoutRef<WC>>;

export const ConditionalWrapper = <WC extends React.ElementType>({
  condition,
  wrapper,
  children,
  ...props
}: Props<WC>): JSX.Element | null => {
  const Wrapper = wrapper || React.Fragment;
  const satisfy = typeof condition === 'function' ? condition() : condition;
  return satisfy ? <Wrapper {...props}>{children}</Wrapper> : <>{children}</>;
};
