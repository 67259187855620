import { mapObjIndexed } from 'ramda';

import { ValidationError } from '@features/common';
import { sentenceCase } from '@utils/strings';

export const formatConstraintErrors = <T>(validationError: ValidationError<T>): Record<string, string> => {
  const property = validationError.property.toString();
  return mapObjIndexed(message => {
    return message.replaceAll(property, sentenceCase(property));
  }, validationError.constraints ?? {});
};

export const constraintsToString = <T>(validationError: ValidationError<T>): string =>
  Object.values(validationError.constraints ?? {}).join('. ');
