import getConfig from 'next/config';
import { useRouter } from 'next/router';

const { publicRuntimeConfig } = getConfig();
const { PLAYDEX_BASE_URL, DISALLOWED_FROM_INDEXING_URL } = publicRuntimeConfig;

const baseUrl: string = PLAYDEX_BASE_URL || 'https://playdex.io';
const nonCanonicalUrls: Array<string> = (DISALLOWED_FROM_INDEXING_URL || '').split(',');

export const useCanonicalLink = (): {
  canonicalHref: string;
  isCanonical: boolean;
} => {
  const { asPath } = useRouter();

  const isCanonical = !nonCanonicalUrls.some(url => asPath.startsWith(url));

  const url = new URL(asPath, baseUrl);
  const canonicalHref = url.origin + url.pathname;

  return {
    canonicalHref,
    isCanonical,
  };
};
