import classNames from 'classnames';
import Link from 'next/link';
import React, { useMemo } from 'react';

import { ImageWithFallback } from '@features/common';
import {
  ButtonRegisterOnTournament,
  tournamentOpenedAnalyticsEventHandler,
  TournamentEventSource,
  useCountdown,
  useTournamentRegistration,
} from '@features/tournaments';
import { TournamentDateAndEliminationType, TournamentStatusIndicator } from '@features/tournaments';
import { GameDocument } from '@globalTypes/prismic/documents';
import { TournamentFragmentFragment as TournamentFragment, TournamentType } from '@graphql/generated';

type TournamentItemProps = {
  tournament: TournamentType;
  game?: GameDocument;
  amplitudeEventParentComponent: TournamentEventSource;
};

const TournamentItem = ({ tournament, game, amplitudeEventParentComponent }: TournamentItemProps): JSX.Element => {
  const { countdown } = useCountdown(tournament.tournamentStartDate);
  const isFinished = new Date(tournament.tournamentFinishDate || '').getTime() < Date.now();
  const isLive = !isFinished && countdown <= 0;

  const { participant, isLoading, modal, register } = useTournamentRegistration(tournament);

  const tournamentUrlParams = useMemo(
    () => ({
      pathname: `/tournaments/${tournament.slug}`,
      query: {
        ...(isLive && { initialTab: 'Live Stream' }),
      },
    }),
    [tournament, isLive],
  );

  const onTournamentCardClick = (): void => {
    tournamentOpenedAnalyticsEventHandler({
      source: amplitudeEventParentComponent,
      tournamentSlug: tournament.slug,
      gameUid: game?.uid ?? null,
    });
  };

  return (
    <>
      <Link
        href={tournamentUrlParams}
        className={classNames(
          'border-gradient-bg-secondary',
          'group cursor-pointer flex flex-col text-white overflow-hidden',
        )}
        onClick={onTournamentCardClick}
      >
        <ImageWithFallback
          alt=""
          className="w-full object-cover aspect-[5/2]"
          src={tournament.imageUrl}
          fallbackSrc="/icons/fallback/tournament-card.svg"
        />
        <div className={classNames('p-4 w-full h-full', 'bg-bgSecondary group-hover:bg-bgTertiary', 'flex flex-col')}>
          <TournamentStatusIndicator
            tournamentStartDate={tournament.tournamentStartDate}
            tournamentFinishDate={tournament.tournamentFinishDate}
          />

          <div className="text-base font-medium pt-4 pb-1 text-wrap-2">{tournament.title}</div>

          <TournamentDateAndEliminationType
            startDate={tournament.tournamentStartDate}
            finishDate={tournament.tournamentFinishDate}
            bracketType={tournament.bracketType}
          />

          <div className="mt-4 flex items-end flex-1">
            {isFinished ? (
              <div className="flex justify-center w-full text-labelSecondary text-sm">The tournament is finished</div>
            ) : (
              <ButtonRegisterOnTournament
                participant={participant}
                tournament={tournament}
                isLoading={isLoading}
                onRegister={e => {
                  e.stopPropagation();
                  return register();
                }}
              />
            )}
          </div>
        </div>
      </Link>
      {modal}
    </>
  );
};

type TournamentsGridProps = {
  tournaments?: TournamentFragment[] | null;
  tournamentGames?: Record<string, GameDocument>;
  showGameTitle?: boolean;
  amplitudeEventParentComponent?: TournamentEventSource;
  className?: string;
};

export const TournamentsGrid = ({
  tournaments,
  tournamentGames,
  amplitudeEventParentComponent = TournamentEventSource.TOURNAMENT_LIST,
  className,
}: TournamentsGridProps): JSX.Element => {
  return (
    <div className={classNames('grid gap-6 grid-cols-1', className)}>
      {tournaments?.map(tournament => (
        <TournamentItem
          key={tournament.id}
          game={tournamentGames?.[tournament.id]}
          amplitudeEventParentComponent={amplitudeEventParentComponent}
          tournament={tournament as TournamentType}
        />
      ))}
    </div>
  );
};
