import '@rainbow-me/rainbowkit/styles.css';

import { connectorsForWallets, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { coinbaseWallet, trustWallet, walletConnectWallet } from '@rainbow-me/rainbowkit/wallets';
import { noopStorage } from '@wagmi/core';
import React from 'react';
import { configureChains, createClient, WagmiConfig, createStorage } from 'wagmi';

import { getLocalStorage } from '@utils/storage';
import { rainbowkitTheme } from 'styles/rainbowkitTheme';

import { customMetamaskWallet } from '../customWallets';
import { chainProviderConstructors, getAppEnvDefaultChains } from '../utils';
import { createAutoConnectStatusRepository } from '../utils/createAutoConnectStatusRepository';

const { chains, provider, webSocketProvider } = configureChains(getAppEnvDefaultChains(), chainProviderConstructors);

const connectors = connectorsForWallets([
  {
    groupName: 'Popular',
    wallets: [
      customMetamaskWallet({ chains }),
      trustWallet({ chains }),
      walletConnectWallet({ chains }),
      coinbaseWallet({ appName: 'playdex', chains }),
    ],
  },
]);

const { readAutoConnectStatus } = createAutoConnectStatusRepository(getLocalStorage());

export const wagmiClient = createClient({
  webSocketProvider,
  autoConnect: readAutoConnectStatus(),
  connectors,
  provider,
  /**
   * @description The following expression creates [null object](https://en.wikipedia.org/wiki/Null_object_pattern) that stores nothing actually. Localstorage was removed for a while due to unpredictable behavior of underlying react-query that had requested all cached pages at once. For example if you viewed 20 pages they were stored in localstorage. Next react-query loaded all 20 pages at once.
   */
  storage: createStorage({
    storage: noopStorage,
  }),
});

const RainbowWalletConnectorProvider = ({ children }: { children?: React.ReactNode }): JSX.Element => {
  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider chains={chains} theme={rainbowkitTheme}>
        {children}
      </RainbowKitProvider>
    </WagmiConfig>
  );
};

export default RainbowWalletConnectorProvider;
