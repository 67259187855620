/* eslint-disable functional/no-throw-statement */
import { ApolloClient } from '@apollo/client';

import {
  GetCustomAuthTokenDocument,
  GetCustomAuthTokenMutation,
  GetCustomAuthTokenMutationVariables,
} from '@graphql/generated';

export const firebaseCustomAuthToken = async (appoloClient: ApolloClient<unknown>): Promise<string | undefined> => {
  const { data } = await appoloClient.mutate<GetCustomAuthTokenMutation, GetCustomAuthTokenMutationVariables>({
    mutation: GetCustomAuthTokenDocument,
  });

  if (data?.getCustomAuthToken.__typename === 'UserAuthCustomType') {
    return data.getCustomAuthToken.authDetails.authToken;
  }

  if (data?.getCustomAuthToken.__typename === 'UserExceptionType') {
    // TODO: Handle notification exception when we need it
    console.error(data.getCustomAuthToken.message);
  }
};
