import { Transition } from '@headlessui/react';
import classNames from 'classnames';
import React from 'react';
import { match } from 'ts-pattern';

import { fastFadeTransitionClassNames, GradientSpinner, NullComponent, useClickOutsideElement } from '@features/common';
import {
  NotificationListDropdown,
  SomethingWentWrongWithItemsBlock,
  useNotificationList,
} from '@features/notification';
import { useBoolean } from '@hooks/useBoolean';
import { ReactComponent as EmptyNotificationBell } from '@public/icons/bell.svg';
import { ReactComponent as BellNotificationIcon } from '@public/icons/bell_notification.svg';

export const NotificationDropdownButton = (): JSX.Element => {
  const { notificationList, isSuccess, isLoading, isError } = useNotificationList();
  const [isDropdownOpen, , closeDropdown, toggleDropdown] = useBoolean(false);

  const containerRef = useClickOutsideElement<HTMLDivElement>(closeDropdown);

  const isNotificationListEmpty = notificationList.length === 0;

  return (
    <div className="relative" ref={containerRef}>
      <div
        className={classNames(
          'p-1.5 cursor-pointer rounded-md hover:bg-dropdownButtonSecondary',
          isDropdownOpen && 'bg-dropdownButtonSecondary',
        )}
        onClick={toggleDropdown}
      >
        {isNotificationListEmpty ? (
          <EmptyNotificationBell className="w-6 h-6" />
        ) : (
          <BellNotificationIcon className="w-6 h-6" />
        )}
      </div>
      {isDropdownOpen && (
        <div className="fixed md:absolute top-[71px] md:top-12 right-0 z-1">
          <Transition show={true} appear={true} {...fastFadeTransitionClassNames}>
            <div
              className={classNames(
                ' border-dropdownBorder border-1 border-x-0 border-b-0 md:border-b-1 md:border-x-1 ',
                'bg-dashboardSecondaryBg pb-0 md:rounded-md',
                'min-w-[calc(100vw-4px)] md:min-w-[400px] md:max-w-[400px] h-screen md:h-[450px]',
              )}
            >
              <header className="hidden md:block bg-modalWindowBgSecondary rounded-t-md px-4 pt-3 pb-2">
                <span className="text-labelPrimary text-sm">Notifications</span>
              </header>
              {match({ isSuccess, isLoading, isError })
                .with({ isLoading: true }, () => (
                  <div className="w-full flex justify-center relative">
                    <GradientSpinner />
                  </div>
                ))
                .with({ isSuccess: true }, () => (
                  <NotificationListDropdown closeDropdown={closeDropdown} items={notificationList} />
                ))
                .with({ isError: true }, () => <SomethingWentWrongWithItemsBlock />)
                .otherwise(() => (
                  <NullComponent />
                ))}
            </div>
          </Transition>
        </div>
      )}
    </div>
  );
};
