import amplitude from 'amplitude-js';

import { LogAmplitudeEvent } from '@features/common';
import { featureChecker, FeatureGroup } from '@services/featureChecker';

const isAmplitudeEventLogsAvailable = featureChecker.isAvailable(FeatureGroup.AmplitudeEventLogs, 'AVAILABLE');

export const initAmplitudeAnalytics = (apiKey: string): void => {
  // https://www.docs.developers.amplitude.com/data/sdks/javascript/#set-configuration-options
  // While Amplitude’s JavaScript SDK doesn't collect web attribution data by default, setting it up is simple.
  // The SDK can automatically collect this information if you enable attribution configuration options.
  // https://www.docs.developers.amplitude.com/data/sdks/javascript/#web-attribution
  return amplitude.getInstance().init(apiKey, undefined, {
    // If true, finds UTM parameters in the query string or the _utmz cookie, parses, and includes them as user
    // properties on all events uploaded. This also captures initial UTM parameters for each session via a setOnce
    // operation.
    includeUtm: true,
    // If true, captures the referrer and referring_domain for each session, as well as the user's initial_referrer and
    // initial_referring_domain via a setOnce operation.
    includeReferrer: true,
    // If true, captures the fbclid URL parameter as well as the user's initial_fbclid via a setOnce operation.
    includeFbclid: true,
    // If true, captures the gclid URL parameter as well as the user's initial_gclid via a setOnce operation.
    includeGclid: true,
  });
};

export const logAmplitudeEvent: LogAmplitudeEvent = (...args) => {
  if (isAmplitudeEventLogsAvailable) {
    console.info('AMPLITUDE_ANALYTICS_EVENT:', args);
  }
  return amplitude.getInstance().logEvent(...args);
};
