import { useEffect } from 'react';

function setViewportProperty(doc: HTMLElement): () => void {
  // eslint-disable-next-line functional/no-let
  let prevClientHeight: number;

  function handleResize(): void {
    const clientHeight = doc.clientHeight;
    if (clientHeight === prevClientHeight) return;
    requestAnimationFrame(function updateViewportHeight() {
      doc.style.setProperty('--vh', clientHeight * 0.01 + 'px');
      prevClientHeight = clientHeight;
    });
  }
  handleResize();
  return handleResize;
}

export const useCustomViewportProperty = (): void => {
  useEffect(() => {
    const onResize = setViewportProperty(document.documentElement);

    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  });
};
