import React, { useEffect } from 'react';

import { useToken } from '@features/auth';
import withSafeHydration from '@features/common/hocs/withSafeHydration';
import { MarketplaceSlice } from '@features/marketplace/store';
import { notice, ToastType } from '@utils/notice';

import { OrderAssetFormModalBase } from './OrderAssetFormBase';
import { OrderFormModalContextProvider } from './OrderFormModalContext';

type Props = {
  activeOffer: NonNullable<MarketplaceSlice['marketplaceSlice']['selectedOffer']>;
  closeModal: () => void;
};

const OrderAssetFormModalLocal = ({ closeModal, activeOffer }: Props): JSX.Element | null => {
  const { user } = useToken();

  useEffect(() => {
    if (!user) {
      notice(ToastType.WARNING, 'Please login first!');
      closeModal();
    }
  }, [user]);

  if (!user) {
    return null;
  }

  return (
    <OrderFormModalContextProvider selectedOffer={activeOffer} closeModal={closeModal}>
      <OrderAssetFormModalBase />
    </OrderFormModalContextProvider>
  );
};

export const OrderAssetFormModal = withSafeHydration(OrderAssetFormModalLocal);
