type EntityLocalStorage = {
  set: (value: string) => void;
  get: () => string | null;
  remove: () => void;
};

export const createEntityLocalStorage = (key: string): EntityLocalStorage => {
  const set = (value: string): void => {
    if (typeof window === 'undefined') return;

    localStorage.setItem(key, value);
  };

  const get = (): string | null => {
    if (typeof window === 'undefined') return null;

    return localStorage.getItem(key);
  };

  const remove = (): void => {
    if (typeof window === 'undefined') return;

    localStorage.removeItem(key);
  };

  return { set, get, remove };
};
