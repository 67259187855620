import { createContext, useContext } from 'react';

import { FeatureChecker } from '@services/featureChecker';

type FeatureCheckerContextInterface = FeatureChecker;

const initialValue = {} as FeatureCheckerContextInterface;

export const FeatureCheckerContext = createContext<FeatureCheckerContextInterface>(initialValue);

/**
 * Expected usage
 * @example
 * ```
 * const currentAddress = useCurrentAddress();
 * const { isAvailableFor } = useFeatureChecker();
 * …
 * {isAvailableFor(currentAddress) && <div>Hidden content</div>}
 * ```
 */
export const useFeatureChecker = (): FeatureCheckerContextInterface => {
  return useContext(FeatureCheckerContext);
};
