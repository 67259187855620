/* eslint-disable functional/immutable-data */

import { ApplicationSettings, GameConfig } from '@features/common';
import { SmartContractName } from '@graphql/generated';
import { StateCreatorWithInitialState } from '@store/types';

export type GameSlice = {
  gameSlice: {
    sidebarGames: GameConfig[];
    currentGame: GameConfig | null;
    currentGameSettings: Partial<Record<SmartContractName, ApplicationSettings>>;
  };
};

export type InitialGameSliceState = {
  gameSlice?: Partial<GameSlice['gameSlice']>;
};

export const createInitialGameSliceData = (patch: InitialGameSliceState['gameSlice']): GameSlice['gameSlice'] => {
  const currentGame = patch?.currentGame ?? (patch?.sidebarGames ?? [])[0];

  return {
    sidebarGames: patch?.sidebarGames ?? [],
    currentGame,
    currentGameSettings: {},
    ...patch,
  };
};

export const createGameSlice: StateCreatorWithInitialState<GameSlice> = (_set, _get, _api, initialState) => {
  return {
    gameSlice: createInitialGameSliceData(initialState?.gameSlice),
  };
};

export const gameSliceSelector = (state: GameSlice): GameSlice['gameSlice'] => state.gameSlice;
