import { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import { useNftFilterContext } from './NftFilterContext';

/** Maybe extract in the future */
export const useIsMount = (): boolean => {
  const isMountRef = useRef(false);
  useEffect(() => {
    // eslint-disable-next-line functional/immutable-data
    isMountRef.current = true;
  }, []);
  return isMountRef.current;
};

export const useOnChangeSubmit = (name: string): void => {
  const { watch, handleSubmit } = useFormContext();

  const data = watch(name);
  const { onSubmit } = useNftFilterContext();

  const isMount = useIsMount();

  useEffect(() => {
    /** skip submit on the initial effect */
    isMount && handleSubmit(onSubmit)();
  }, [data, handleSubmit]);
};
