import classnames from 'classnames';
import React from 'react';

import { useClickOutsideElement } from '@features/common';
import { UserMenuLinksDropdown } from '@features/header';
import { useBoolean } from '@hooks/useBoolean';
import { ReactComponent as UserIcon } from '@public/icons/user_circle.svg';

export const MyAccountLinkDropdown = (): JSX.Element => {
  const [isDropdownMenuOpen, , closeDropdown, toggleDropdown] = useBoolean(false);

  const containerRef = useClickOutsideElement<HTMLDivElement>(closeDropdown);

  return (
    <div className="relative" ref={containerRef}>
      <div
        className={classnames(
          'p-1.5 flex items-center gap-2 text-base font-medium cursor-pointer h-full',
          'text-labelPrimary rounded-md hover:bg-dropdownButtonSecondary',
          isDropdownMenuOpen && 'bg-dropdownButtonSecondary',
        )}
        onClick={toggleDropdown}
      >
        <UserIcon className="w-6 h-6 text-labelTertiary" />
      </div>
      {isDropdownMenuOpen && (
        <div className="absolute top-12 right-0">
          <div className="relative !z-50 isolation-auto">
            <UserMenuLinksDropdown closeDropdown={closeDropdown} />
          </div>
        </div>
      )}
    </div>
  );
};
