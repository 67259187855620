/* eslint-disable functional/immutable-data */
import { Draft } from 'immer';

import { UserProfile } from '@services/userProfileService/types';
import { StateCreatorWithInitialState } from '@store/types';

export type UserSlice = {
  userSlice: {
    user: UserProfile | null;
    setUser: (user: UserProfile | null) => void;
  };
};

export const createUserSlice: StateCreatorWithInitialState<UserSlice> = set => {
  return {
    userSlice: {
      user: null,
      setUser: (user: UserProfile | null) => {
        set((state: Draft<UserSlice>) => {
          state.userSlice.user = user;
        });
      },
    },
  };
};

export const userSliceSelector = (state: UserSlice): UserSlice['userSlice'] => state.userSlice;
