import classNames from 'classnames';
import Link from 'next/link';
import { HTMLProps, useRef } from 'react';

import { GamesListItem } from './GamesListItem';
import { useIsomorphicLayoutEffect } from '../../hooks';
import { GameConfig } from '../../types';

export type GamesListProps = {
  games: GameConfig[];
  selectedGameUid?: string;
  className?: string;
  style?: HTMLProps<HTMLDivElement>['style'];
  shouldScrollToSelectedGame?: boolean;
  onGameClick?: (game: GameConfig) => void;
};

export const GamesList = ({
  games,
  selectedGameUid,
  className,
  style,
  shouldScrollToSelectedGame,
  onGameClick,
}: GamesListProps): JSX.Element => {
  const containerRef = useRef<HTMLDivElement>(null);

  useIsomorphicLayoutEffect(() => {
    if (shouldScrollToSelectedGame) {
      containerRef.current?.querySelector<HTMLAnchorElement>('[data-active-game=true]')?.scrollIntoView();
    }
  }, [shouldScrollToSelectedGame]);

  return (
    <div className={classNames('px-2', className)} style={{ ...style, scrollPaddingTop: '20px' }} ref={containerRef}>
      <div className="px-3 pt-8 pb-2.5 items-center flex">
        <span className="text-labelSecondary text-sm font-medium flex-1">Games</span>
        <Link href="/games" className="text-xs text-labelSecondary hover:text-labelPrimary transition-colors">
          See All
        </Link>
      </div>

      {games.map(game => (
        <GamesListItem
          key={game.id}
          game={game}
          isActive={game.id === selectedGameUid}
          onClick={() => onGameClick?.(game)}
        />
      ))}
    </div>
  );
};
