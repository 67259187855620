import { useMemo, useState } from 'react';

import { useAuthController } from '@features/auth';
import { useToken } from '@features/auth';
import { JsonSchema, RefSchema } from '@features/common';
import {
  tournamentRegisterFailedAnalyticsEventHandler,
  tournamentRegisterStartedAnalyticsEventHandler,
  tournamentRegisterSuccessAnalyticsEventHandler,
} from '@features/tournaments';
import {
  TournamentFragmentFragment as TournamentFragment,
  TournamentParticipationType,
  TournamentParticipantFragment,
  TournamentTeamFragmentFragment as TournamentTeamFragment,
} from '@graphql/generated';
import { notice, ToastType } from '@utils/notice';

import { useTournamentExtraFields } from './useTournamentExtraFields';
import { useTournamentParticipant } from './useTournamentParticipant';
import { useTournamentTeam } from './useTournamentTeam';
import {
  ExtraFieldsFormModal,
  TournamentRegistrationSuccessModal,
  TournamentRegistrationTypeSelectionModal,
} from '../components';
import { TournamentRegistrationType } from '../constants';
import { pickExtraFieldValues } from '../utils';

type UseTournamentRegistration = (
  tournament: TournamentFragment,
  gameUid?: string | null,
) => {
  participant: TournamentParticipantFragment | null;
  team: TournamentTeamFragment | null;
  extraFields: Record<string, JsonSchema | RefSchema>;
  isLoading: boolean;
  modal: JSX.Element | null;
  register: () => Promise<void>;
  retryParticipantRegistration: () => Promise<unknown>;
  isRetryParticipantRegistrationPending: boolean;
};

export const useTournamentRegistration: UseTournamentRegistration = (tournament, gameUid) => {
  const isTeamTournament = tournament.participationType === TournamentParticipationType.Team;

  const { isAuthCompleted, requireAuth } = useAuthController();
  const { isUserLoading, user } = useToken();
  const [isExtraFieldsFormModalOpen, setIsExtraFieldsFormModalOpen] = useState(false);
  const [selectedRegistrationType, setSelectedRegistrationType] = useState<TournamentRegistrationType>(
    TournamentRegistrationType.Individual,
  );
  const [isRegistrationTypeSelectionModalOpen, setIsRegistrationTypeSelectionModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const {
    participant,
    isParticipantLoading,
    isParticipantCreationPending,
    createParticipant,
    retryParticipantRegistration,
    isRetryParticipantRegistrationPending,
  } = useTournamentParticipant({ tournament, skip: !isAuthCompleted });

  const { team, isTeamLoading, isTeamCreationPending, createTeam } = useTournamentTeam({
    tournament,
    skip: !isAuthCompleted || !isTeamTournament,
  });

  const { areExtraFieldsSchemasLoading, participantExtraFields, teamExtraFields } =
    useTournamentExtraFields(tournament);

  const isLoading =
    isUserLoading ||
    isParticipantLoading ||
    isTeamLoading ||
    isParticipantCreationPending ||
    isTeamCreationPending ||
    areExtraFieldsSchemasLoading;

  const handleRegistrationPromise = async (p: Promise<unknown>): Promise<void> => {
    const tournamentEventBasePayload = {
      tournamentSlug: tournament.slug,
      gameUid: gameUid ?? null,
    };

    try {
      tournamentRegisterStartedAnalyticsEventHandler(tournamentEventBasePayload);

      await p;

      tournamentRegisterSuccessAnalyticsEventHandler(tournamentEventBasePayload);
      setIsSuccessModalOpen(true);
    } catch (e) {
      notice(ToastType.ERROR, 'Tournament registration failed 🤕');

      tournamentRegisterFailedAnalyticsEventHandler(tournamentEventBasePayload);

      // eslint-disable-next-line functional/no-throw-statement
      throw e;
    }
  };

  const register = async (): Promise<void> => {
    // @todo we skipping requiring discord and phone connection until will fix issues with user profile
    const isAuthCompleted = requireAuth({
      includeDiscordVerification: false,
      includePhoneVerification: false,
      onLoginSuccess: () => {
        register();
      },
    });

    if (!isAuthCompleted) {
      return;
    }

    if (isTeamTournament) {
      setIsRegistrationTypeSelectionModalOpen(true);

      return;
    }

    if (tournament.extraFieldsSchema || tournament.teamExtraFieldsSchema) {
      setIsExtraFieldsFormModalOpen(true);

      return;
    }

    return handleRegistrationPromise(createParticipant());
  };

  const modal = useMemo(() => {
    if (isSuccessModalOpen) {
      return (
        <TournamentRegistrationSuccessModal
          team={team}
          tournament={tournament}
          registrationCompleteMessage={tournament.registrationCompleteMessage}
          closeModal={() => setIsSuccessModalOpen(false)}
        />
      );
    }

    if (isRegistrationTypeSelectionModalOpen) {
      return (
        <TournamentRegistrationTypeSelectionModal
          closeModal={() => setIsRegistrationTypeSelectionModalOpen(false)}
          onRegistrationTypeSelect={type => {
            setSelectedRegistrationType(type);

            const shouldEnterExtraFields = tournament.extraFieldsSchema || tournament.teamExtraFieldsSchema;

            const shouldEnterTeamCode = isTeamTournament && type === TournamentRegistrationType.Member;

            if (shouldEnterExtraFields || shouldEnterTeamCode) {
              setIsExtraFieldsFormModalOpen(true);
            } else {
              if (type === TournamentRegistrationType.Member) {
                return handleRegistrationPromise(createParticipant());
              } else {
                return handleRegistrationPromise(createTeam());
              }
            }
          }}
        />
      );
    }

    if (isExtraFieldsFormModalOpen) {
      return (
        <ExtraFieldsFormModal
          tournament={tournament}
          registrationType={selectedRegistrationType}
          extraFields={{
            ...(selectedRegistrationType === TournamentRegistrationType.Captain ? teamExtraFields : {}),
            ...participantExtraFields,
          }}
          closeModal={() => setIsExtraFieldsFormModalOpen(false)}
          onSubmit={async extraFieldValues => {
            const participantExtraFieldValues = pickExtraFieldValues(
              extraFieldValues,
              participantExtraFields,
              tournament.extraFieldsSchema,
            );

            if (selectedRegistrationType === TournamentRegistrationType.Captain) {
              const teamExtraFieldValues = pickExtraFieldValues(
                extraFieldValues,
                teamExtraFields,
                tournament.teamExtraFieldsSchema,
              );

              await handleRegistrationPromise(
                createTeam({
                  participantExtraFieldValues,
                  teamExtraFieldValues,
                }),
              );
            } else {
              await handleRegistrationPromise(
                createParticipant({
                  teamCode: extraFieldValues.teamCode?.toLocaleUpperCase(),
                  extraFieldValues: participantExtraFieldValues,
                }),
              );
            }

            setIsExtraFieldsFormModalOpen(false);
          }}
        />
      );
    }

    return null;
  }, [
    isSuccessModalOpen,
    isRegistrationTypeSelectionModalOpen,
    isExtraFieldsFormModalOpen,
    selectedRegistrationType,
    participantExtraFields,
    teamExtraFields,
  ]);

  return {
    participant: participant ?? team?.members.find(member => member.userId === user?.id) ?? null,
    team,
    extraFields: {
      ...teamExtraFields,
      ...participantExtraFields,
    },
    isLoading,
    modal,
    register,
    retryParticipantRegistration: () => retryParticipantRegistration(),
    isRetryParticipantRegistrationPending,
  };
};
