import { useRouter } from 'next/router';
import React, { useEffect } from 'react';

import { AXIE_INFINITY_MARKETPLACE_BUNDLE } from '@constants/routes';
import { useAuthController } from '@features/auth';
import { useToken } from '@features/auth';
import { getJWTFromCookies } from '@utils/cookies';
import { notice, ToastType } from '@utils/notice';

const publicRoutes: Array<string> = [
  '/',
  '/axie-infinity',
  AXIE_INFINITY_MARKETPLACE_BUNDLE,
  '/cce',
  '/cce/map',
  '/blog',
  '/blog/[uid]',
  '/landing',
  '/login',
  '/terms',
  '/private',
  '/faq',
  '/support',
  '/guild/overview',
  '/maintenance',
  '/slice-simulator',
  '/games',
  '/games/[uid]',
  '/games/[uid]/reviews',
  '/games/[uid]/assets/[tokenId]',
  '/tournaments',
  '/tournaments/[tournamentSlug]',
  '/inventory/[address]',
];

const AccessGuard = ({ children }: React.PropsWithChildren): JSX.Element | null => {
  const router = useRouter();
  const { user } = useToken();
  const { requireAuth, closeAuthModal, authModal } = useAuthController();

  const jwtToken = getJWTFromCookies();

  useEffect(() => {
    if (jwtToken) {
      closeAuthModal();
    }

    if (!jwtToken && !publicRoutes.includes(router.pathname)) {
      router.push('/').then(() => {
        notice(ToastType.WARNING, 'Please login first!');
        requireAuth({
          includeDiscordVerification: false,
          includePhoneVerification: false,
        });
      });
    }
  }, [jwtToken, router.pathname]);

  if (user || publicRoutes.includes(router.pathname)) {
    return (
      <>
        {children}
        {authModal}
      </>
    );
  }

  return null;
};

export default AccessGuard;
