import { TournamentsPreviewResponse } from '@features/tournaments';
import { StateCreatorWithInitialState } from '@store/types';

export type TournamentSlice = {
  tournamentSlice: {
    tournamentsPreview: TournamentsPreviewResponse;
  };
};

export const createTournamentSlice: StateCreatorWithInitialState<TournamentSlice> = (
  _set,
  _get,
  _api,
  initialState,
): TournamentSlice => {
  return {
    tournamentSlice: {
      tournamentsPreview: initialState?.tournamentSlice?.tournamentsPreview ?? [],
    },
  };
};

export const tournamentSliceSelector = (state: TournamentSlice): TournamentSlice['tournamentSlice'] =>
  state.tournamentSlice;
