import cn from 'classnames';
import React, { forwardRef } from 'react';

type Props = React.ComponentPropsWithRef<'input'>;

export const FilterInputText = forwardRef<HTMLInputElement, Props>(
  ({ className, ...restProps }: Props, ref): JSX.Element => (
    <input
      className={cn(
        'border border-white/30 bg-neutral-950 rounded-md focus:ring-0 focus:border-yellow-400',
        'p-3.5 leading-5',
        className,
      )}
      {...restProps}
      ref={ref}
    />
  ),
);

// eslint-disable-next-line functional/immutable-data
FilterInputText.displayName = FilterInputText.name;
