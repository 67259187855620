import classNames from 'classnames';
import React from 'react';

export type BadgeProps = {
  children: React.ReactNode;
  className?: string;
  useBorderContainer?: boolean;
  onClick?: VoidFunction;
  isActive?: boolean;
};

export const StatusBadge = ({
  children,
  className,
  useBorderContainer,
  onClick,
  isActive,
}: BadgeProps): JSX.Element => {
  return (
    <div
      onClick={onClick}
      className={classNames(
        'flex flex-shrink-0 items-center text-sm',
        useBorderContainer && 'px-2 py-1 rounded border-1 border-bgStripe',
        onClick && 'cursor-pointer',
        isActive ? 'text-white bg-bgSecondary' : 'text-labelSecondary',
        className,
      )}
      suppressHydrationWarning={true}
    >
      {children}
    </div>
  );
};
