import { isSchemaObject, JsonSchema, JsonSchemaPropertiesValues } from '@features/common';

import { FilterBooleanInput } from './FilterBooleanInput';
import { FilterEnumInput } from './FilterEnumInput';
import { FilterNumberField } from './FilterNumberField';
import { FilterRangeField } from './FilterRangeField';
import { isRangeFilterField } from '../utils';

type FilterInputFactoryProps = {
  name: string;
  schema: JsonSchemaPropertiesValues;
  rootSchema: JsonSchema;
};

export const FilterInputFactory = ({ name, schema, rootSchema }: FilterInputFactoryProps): JSX.Element | null => {
  if (isRangeFilterField(schema)) {
    return <FilterRangeField name={name} schema={schema} rootSchema={rootSchema} />;
  }

  if (isSchemaObject(schema) && schema.type === 'boolean') {
    return <FilterBooleanInput name={name} schema={schema} rootSchema={rootSchema} />;
  }

  if (isSchemaObject(schema) && schema.type === 'number' && schema.enum != null) {
    return <FilterEnumInput name={name} schema={schema} rootSchema={rootSchema} />;
  }

  if (isSchemaObject(schema) && schema.type === 'number') {
    return <FilterNumberField name={name} schema={schema} rootSchema={rootSchema} />;
  }

  return null;
};
