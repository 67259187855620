import classNames from 'classnames';
import React, { ComponentProps, ComponentPropsWithoutRef, forwardRef, useState } from 'react';

import { ElInputDecoration } from './ElInputDecoration';

type Props = {
  isError?: boolean;
  onWrapClick?: ComponentProps<typeof ElInputDecoration>['onClick'];
} & ComponentPropsWithoutRef<'input'>;

const ElInput = forwardRef<HTMLInputElement, Props>(({ isError, onWrapClick, ...props }, ref) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <ElInputDecoration disabled={props.disabled} isFocused={isFocused} isError={isError} onClick={onWrapClick}>
      <input
        {...props}
        className={classNames(
          'w-full',
          'pl-3 pr-2 py-2.5',
          'rounded-lg',
          'leading-5',
          'border-none',
          'bg-transparent',
          'placeholder-labelPlaceholder',
          'outline-none',
          '!ring-0',
          props.className,
        )}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        ref={ref}
      />
    </ElInputDecoration>
  );
});

// eslint-disable-next-line functional/immutable-data
ElInput.displayName = 'ElInput';

export default ElInput;
