import React, { useEffect } from 'react';
import { useAccount, useBalance, useNetwork } from 'wagmi';

import { formatBalanceValue } from '@features/balance';
import { ReactComponent as BNBIcon } from '@public/icons/currencies/binance.svg';
import { ReactComponent as ETHIcon } from '@public/icons/currencies/eth.svg';
import { ReactComponent as MATICIcon } from '@public/icons/currencies/polygon.svg';

const IconByTokenSymbol: Record<
  string,
  React.ComponentType<{
    className?: string;
  }>
> = {
  MATIC: MATICIcon,
  BNB: BNBIcon,
  tBNB: BNBIcon,
  ETH: ETHIcon,
};

export const AccountBalanceLabel = (): JSX.Element | null => {
  const { address } = useAccount();
  const { chain } = useNetwork();

  const {
    data: balance,
    refetch,
    isIdle,
  } = useBalance({
    address,
    chainId: chain?.id,
  });

  const Icon = balance?.symbol && IconByTokenSymbol[balance.symbol];

  useEffect(() => {
    if (isIdle && address && chain) {
      refetch();
    }
  }, [address, chain]);

  if (!balance || !Icon) {
    return null;
  }

  const formattedBalanceValue = formatBalanceValue(balance.formatted || 0);

  return (
    <div className="flex items-center py-1 px-1.5 bg-dashboardSecondaryBg rounded gap-1 md:gap-2">
      <span className="font-normal text-xs md:text-sm">{formattedBalanceValue}</span>
      <Icon className="w-4 h-4 md:w-5 md:h-5" />
    </div>
  );
};
