import cn from 'classnames';
import React from 'react';

import { NftMeta } from '@gql/types';

export const AssetCard = ({
  meta,
  image,
  button,
  terms,
}: {
  meta: NftMeta;
  image?: React.ReactNode;
  button?: React.ReactNode;
  terms?: Array<[name: string, value: string | number]>;
}): JSX.Element => {
  const { name, attributes } = meta;

  return (
    <div
      className={cn(
        'flex flex-col justify-between',
        'border border-solid border-earlGrey-150 rounded-2.5 bg-earlGrey-700 overflow-hidden',
      )}
    >
      {image && (
        <div className="flex p-6 h-50 bg-earlGrey-500 justify-center border-b border-solid border-earlGrey-150">
          {image}
        </div>
      )}
      <div className="flex flex-1 flex-col p-5 pt-4 gap-5 lg:pt-6">
        <div className="flex-1">
          <h3 className="text-base font-bold">{name}</h3>

          <div className="text-gray-400 text-sm tracking-wide flex flex-wrap gap-x-2.5 gap-y-1">
            {attributes.map((trait, i) => (
              <dl key={i} className="flex gap-1">
                <dt className="font-bold">{trait.trait_type}:</dt>
                <dd>{trait.value}</dd>
              </dl>
            ))}
          </div>
        </div>

        {terms && (
          <div className="grid grid-cols-2 gap-5">
            {terms.map(([name, value]) => {
              return (
                <dl key={name}>
                  <dt className="text-gray-400 text-xxs tracking-widest uppercase font-bold">{name}</dt>
                  <dd className="text-white text-sm font-bold ">{value}</dd>
                </dl>
              );
            })}
          </div>
        )}

        {button}
      </div>
    </div>
  );
};
