import { useQuery } from '@apollo/client';
import * as Sentry from '@sentry/nextjs';

import {
  SocialEventRewardDocument,
  SocialEventRewardQuery,
  TournamentFragmentFragment as TournamentFragment,
} from '@graphql/generated';

export const useRewardQuery = (tournament: TournamentFragment) => {
  const leaderboardRewardQuery = useQuery<SocialEventRewardQuery>(SocialEventRewardDocument, {
    variables: { eventId: tournament?.leaderboard?.id },
    onError: e => {
      if (e.message === 'No SocialEventReward found') return;

      Sentry.captureException(e, {
        extra: {
          message: 'Reward load error',
        },
      });
    },
  });

  const shouldHandleLeaderboardReward = Boolean(leaderboardRewardQuery.loading || leaderboardRewardQuery.data);

  const tournamentRewardQuery = useQuery<SocialEventRewardQuery>(SocialEventRewardDocument, {
    variables: { eventId: tournament.id },
    onError: e => {
      if (e.message === 'No SocialEventReward found') return;

      Sentry.captureException(e, {
        extra: {
          message: 'Reward load error',
        },
      });
    },
    skip: shouldHandleLeaderboardReward,
  });

  return shouldHandleLeaderboardReward ? leaderboardRewardQuery : tournamentRewardQuery;
};
