import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';

import { getLocaleDateTime } from '@utils/dateTime';

import { PulsingLiveStatus } from '../../TournamentStatusIndicator';

const getLastUpdate = (isoDate: string): string => {
  const dateTime = getLocaleDateTime(isoDate);
  const diff = DateTime.now().diff(dateTime, ['days', 'hours', 'minutes', 'seconds']);

  if (diff.days > 0) {
    return `${diff.days} days and ${diff.hours} hours ago`;
  }

  if (diff.hours > 0) {
    return `${diff.hours} hours and ${diff.minutes} minutes ago`;
  }

  if (diff.minutes > 0) {
    return `${diff.minutes} minutes ago`;
  }

  return 'Less than a minute ago';
};

export const LastUpdateStatistic = ({ leaderboardUpdatedAt }: { leaderboardUpdatedAt: string }): JSX.Element => {
  const [lastUpdate, setLastUpdate] = useState(getLastUpdate(leaderboardUpdatedAt));

  useEffect(() => {
    const interval = setInterval(() => {
      setLastUpdate(getLastUpdate(leaderboardUpdatedAt));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex gap-2 items-center">
      <div>
        <PulsingLiveStatus />
      </div>
      <div>
        <div className="text-xs text-labelSecondary">Last update</div>
        <div className="text-sm text-labelPrimary">{lastUpdate}</div>
      </div>
    </div>
  );
};
