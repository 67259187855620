import { useState } from 'react';
import { useQuery } from 'wagmi';

import { fetchTournaments, searchBarGameQueryParams } from '@features/tournaments';
import { GameDocument } from '@globalTypes/prismic/documents';
import { useWindowSize } from '@hooks/useWindowSize';

import { createClient } from '../../../prismicio';

const client = createClient();

export type SearchBarGame = GameDocument & {
  data: Pick<GameDocument['data'], 'name' | 'redirectLocation' | 'logo'>;
};
const formatFilterString = (str: string): string => str.trim().toLowerCase();
const isValidFilteredValue = (valueToCompare: string, value: string): boolean =>
  formatFilterString(valueToCompare).includes(formatFilterString(value));

export const useGamesAndTournamentsSearch = () => {
  const {
    orientation: { isMobile },
  } = useWindowSize();

  const maxLengthOfFilteredArr = isMobile ? 5 : 3;

  const { data: gamesAndTournamentsList = [], isLoading } = useQuery(['searchbar_games_and_tournaments'], {
    queryFn: async () => {
      return await Promise.all([
        // TODO: Consider to refactor client side filtering by moving it to prismic backend (with predicate methods)
        client.getAllByType<SearchBarGame>('game', searchBarGameQueryParams),
        fetchTournaments(),
      ]);
    },
    onSuccess: data => {
      const [games, tournaments] = data;
      setFilteredGames(games.slice(0, maxLengthOfFilteredArr));
      setFilteredTournaments(tournaments?.slice(0, maxLengthOfFilteredArr) || []);
    },
    refetchOnMount: true,
  });

  const [initialGames = [], initialTournaments = []] = gamesAndTournamentsList;

  const [filteredGames, setFilteredGames] = useState<(typeof gamesAndTournamentsList)[0]>([]);
  const [filteredTournaments, setFilteredTournaments] = useState<(typeof gamesAndTournamentsList)[1]>([]);

  const filterGamesAndTournamentsByValue = (value: string): void => {
    const filteredGames = initialGames
      .filter(game => isValidFilteredValue(game?.data?.name || '', value))
      .slice(0, maxLengthOfFilteredArr);

    const filteredTournaments = initialTournaments
      ?.filter(tournament => isValidFilteredValue(tournament?.title || '', value))
      ?.slice(0, maxLengthOfFilteredArr);

    setFilteredGames(filteredGames);
    setFilteredTournaments(filteredTournaments);
  };

  return {
    filterGamesAndTournamentsByValue,
    filteredGames,
    filteredTournaments,
    isLoading,
  };
};
