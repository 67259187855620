import { PrismicNextImage } from '@prismicio/next';
import { DateTime } from 'luxon';
import React, { ReactNode } from 'react';

import { PrismicRichTextWrapper } from '@features/prismic';
import { BlogPostDocument } from '@globalTypes/prismic/documents';
import { getLocaleDateTime } from '@utils/dateTime';

export const BlogPostTemplate = ({
  selectedBlogPost,
  metaDataContent,
}: {
  selectedBlogPost: BlogPostDocument;
  metaDataContent?: ReactNode;
}): JSX.Element => {
  const { showcaseImage, createdAt, title, showcaseDescription, postContent } = selectedBlogPost?.data;
  const createdAtDateFormat = createdAt ? getLocaleDateTime(createdAt).toLocaleString(DateTime.DATE_FULL) : null;

  return (
    <div>
      {createdAtDateFormat && (
        <div className="text-labelTertiary text-sm mb-2 md:mb-4.5 flex justify-between">
          <span>{createdAtDateFormat}</span>
          {metaDataContent && metaDataContent}
        </div>
      )}
      {title && <h1 className="text-labelPrimary text-7 mb-6 font-medium">{title}</h1>}
      {showcaseDescription && <p className="text-labelSecondary text-base leading-6 mb-6">{showcaseDescription}</p>}
      {showcaseImage?.url && <PrismicNextImage className="rounded-3 mb-8 w-full" field={showcaseImage} />}
      <PrismicRichTextWrapper
        components={{
          paragraph: ({ text, children }) =>
            text && <p className="text-labelSecondary text-base mb-10 leading-7 flex flex-wrap">{children}</p>,
        }}
        field={postContent}
      />
    </div>
  );
};
