import React from 'react';

import { MyAccountLinkDropdown, MyAccountWalletDropdownSection, NotificationDropdownButton } from '@features/header';
import { featureChecker, FeatureGroup } from '@services/featureChecker';
import { UserProfile } from '@services/userProfileService/types';

const isNotificationFeatureAvailable = featureChecker.isAvailable(FeatureGroup.Notification, 'AVAILABLE');

export const AuthenticatedUserInfoBlock = ({ user }: { user: UserProfile }): JSX.Element => {
  return user.walletAddress ? (
    <MyAccountWalletDropdownSection user={user} />
  ) : (
    <span className="text-sm">{user.name}</span>
  );
};

export const MyAccountSection = ({ user }: { user: UserProfile }): JSX.Element => {
  return (
    <div className="flex gap-2 md:gap-4 items-center">
      {isNotificationFeatureAvailable && <NotificationDropdownButton />}
      <AuthenticatedUserInfoBlock user={user} />

      <MyAccountLinkDropdown />
    </div>
  );
};
