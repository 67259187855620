import { ApolloClient } from '@apollo/client';

import { createMutationFactory } from '@features/common';
import { CreateOrderDocument, CreateOrderMutation, CreateOrderMutationVariables } from '@graphql/generated';

export type OrderRepository = {
  createOrder: (variables: CreateOrderMutationVariables) => Promise<CreateOrderMutation['createOrder']>;
};

export const createOrderRepository = <T>({ playdexClient }: { playdexClient: ApolloClient<T> }): OrderRepository => {
  const mutationFromDocument = createMutationFactory(playdexClient);

  return {
    createOrder: mutationFromDocument<CreateOrderMutation, CreateOrderMutationVariables>(
      CreateOrderDocument,
      ({ createOrder }) => createOrder,
    ),
  };
};
