import cn from 'classnames';
import { IoMdClose } from 'react-icons/io';

export const ModalCloseButton = ({ className, onClick }: { className?: string; onClick?: () => void }): JSX.Element => (
  <IoMdClose
    className={cn(
      'w-5 h-5 text-labelSecondary hover:text-labelPrimary cursor-pointer transition duration-100',
      className,
    )}
    onClick={onClick}
  />
);
