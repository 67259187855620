import { isApolloError } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorBoundary } from '@sentry/nextjs';
import classNames from 'classnames';
import React, { Fragment, Suspense, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IoMdClose } from 'react-icons/io';

import ElCheckbox from '@components/elements/ElCheckbox';
import { ElFormField } from '@components/elements/ElFormField';
import { ErrorFallback } from '@components/modules/ErrorFallback';
import Loader from '@components/modules/Loader/Loader';
import ElButton from '@elements/ElButton';
import { ValidationError } from '@features/common';
import { AdditionalMetaKeyword } from '@features/gamehub';
import { AnyObject } from '@globalTypes/globals';
import { errorHandler, HttpErrorCodes } from '@utils/error';
import { sentenceCase } from '@utils/strings';

import { FormField } from './FormField';
import { useOrderFormModalContext } from './OrderFormModalContext';
import formCheckboxes from './formCheckboxes';
import { rentAssetFormSchema } from './formSchema';
import { constraintsToString, formatConstraintErrors } from './helpers';
import { mapToFields } from '../../utils';

type ErrorResponse = {
  statusCode: number;
  message: ValidationError<unknown>[];
  error: string;
};

export const OrderAssetForm = (): JSX.Element => {
  const { selectedOffer, applicationSettings, onSubmitRentModal, onFailedRentModal, closeModal } =
    useOrderFormModalContext();

  const { register, formState, handleSubmit, setError, control } = useForm({
    resolver: yupResolver(rentAssetFormSchema),
    mode: 'onChange',
  });

  const [formErrors, setFormErrors] = useState<string[]>([]);

  const formSubmitHandler = async (terms: AnyObject): Promise<void> => {
    setFormErrors([]);
    try {
      const filterOut: string[] = formCheckboxes.map(({ checkboxRefName }) => checkboxRefName);
      const filteredTerms = Object.fromEntries(Object.entries(terms).filter(([name]) => !filterOut.includes(name)));
      await onSubmitRentModal(filteredTerms);
    } catch (error) {
      if (error instanceof Error && isApolloError(error) && error.graphQLErrors[0]?.extensions?.response) {
        const response = error.graphQLErrors[0]?.extensions?.response as ErrorResponse;
        if (response.statusCode === HttpErrorCodes.UnprocessableEntity) {
          response.message.forEach(modelError => {
            if (modelError.property === 'terms') {
              modelError.children?.forEach(termError => {
                setError(termError.property.toString(), {
                  type: 'server',
                  types: formatConstraintErrors(termError),
                });
              });
            } else {
              setFormErrors(errors => errors.concat(constraintsToString(modelError)));
            }
          });
          return;
        }
      }
      errorHandler({ error, customHandler: onFailedRentModal });
    }
  };

  return (
    <form onSubmit={handleSubmit(formSubmitHandler)}>
      <header className={classNames('bg-earlGrey-500 text-lg p-5 flex justify-between items-center')}>
        <h2 className="font-bold">Confirm Rent</h2>
        <IoMdClose className="cursor-pointer opacity-50 text-2xl" onClick={closeModal} />
      </header>
      <div className="bg-white/20 h-[1px]" />
      <main className="overflow-auto">
        <ErrorBoundary fallback={ErrorFallback}>
          <Suspense
            fallback={
              <div className={classNames('flex justify-center h-40')}>
                <div className={classNames('relative w-40')}>
                  <Loader />
                </div>
              </div>
            }
          >
            <section className={classNames('flex flex-col p-7 gap-6')}>
              {formErrors.length > 0 && <div>{formErrors.join(',')}</div>}
              {mapToFields(applicationSettings?.schemas.offerTerms?.properties ?? {}).map(([name, schema]) => {
                const value = selectedOffer.terms[name];
                const formattedLabel = sentenceCase(name);
                const formattedValue: string = [value.toString(), schema[AdditionalMetaKeyword.Units]]
                  .filter(Boolean)
                  .join(' ');
                return (
                  <Fragment key={name}>
                    <ElFormField
                      label={formattedLabel}
                      labelClassName="flex gap-2 mb-1 !text-xxs tracking-widest"
                      className="text-xl"
                      shownResultValue={formattedValue}
                    />
                  </Fragment>
                );
              })}
              {mapToFields(applicationSettings?.schemas.orderTerms?.properties ?? {}).map(([name, schema]) => (
                <section key={name} className="relative">
                  <FormField
                    className={classNames(
                      'min-w-full border-white/30 focus:outline-none',
                      'focus:ring-white focus:ring-1 focus:border-[#005fcc]',
                    )}
                    labelClassName="flex gap-2 mb-1 !text-xxs tracking-widest"
                    schema={schema}
                    name={name}
                    label={schema.title ?? sentenceCase(name)}
                    register={register}
                    control={control}
                  />
                  {formState.errors[name] && (
                    <div className="text-uppercase text-red-2">
                      {Object.entries(formState.errors[name]?.types ?? {}).map(([name, message]) => (
                        <div key={name}>{Array.isArray(message) ? message.join(', ') : message?.toString()}</div>
                      ))}
                    </div>
                  )}
                </section>
              ))}

              <div className={classNames('flex flex-col gap-4 text-sm')}>
                {formCheckboxes.map(({ checkboxRefName, CheckboxChildren }) => (
                  <div className="relative" key={checkboxRefName}>
                    <ElCheckbox {...register(checkboxRefName)}>
                      <CheckboxChildren />
                    </ElCheckbox>
                    {formState.errors[checkboxRefName] && (
                      <div className="text-uppercase text-red-2">
                        <>{formState.errors[checkboxRefName]?.message}</>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </section>
            <div className="bg-white/20 h-[1px]" />
            <footer className={classNames('bg-earlGrey-500 p-5')}>
              <ElButton
                variant={formState.isValid ? 'solid-blue' : 'disabled'}
                className={classNames(
                  'py-4 font-bold border-none w-full flex justify-center',
                  'transition ease-in-out duration-[300ms]',
                  'bg-gradient-to-r from-sky-500 to-blue-600 text-white',
                  'disabled:from-gray-700 disabled:to-gray-800 disabled:text-gray-400',
                )}
                style={{
                  boxShadow: formState.isValid ? '0px 0px 20px 4px #3b82f694' : '',
                }}
                type="submit"
              >
                Confirm
              </ElButton>
            </footer>
          </Suspense>
        </ErrorBoundary>
      </main>
    </form>
  );
};
