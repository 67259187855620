import { CurrencyToken, getTokenIconAndSymbol } from '@entities/currency';
import { ethers } from 'ethers';
import React, { useMemo } from 'react';
import { match } from 'ts-pattern';
import { Address, useNetwork } from 'wagmi';

import { TokenBalance, useERC20TokenBalance, formatBalanceValue, useNativeCurrencyBalance } from '@features/balance';
import { Button, GradientSpinner } from '@features/common';
import { ReactComponent as PancakeIcon } from '@public/icons/pancake_logo.svg';

const AccountCurrencyItem = ({ currencyToken: { address, Icon, label }, balanceValue }: TokenBalance): JSX.Element => (
  <div className="flex justify-between" key={address}>
    <div className="flex gap-2">
      <Icon className="w-5 h-5" />
      <span>{label}</span>
    </div>
    <div>{formatBalanceValue(balanceValue)}</div>
  </div>
);

export const AccountCurrencyList = (): JSX.Element => {
  const { chain } = useNetwork();

  const {
    data: nativeBalanceValue,
    isLoading: isNativeCurrencyLoading,
    isError: isNativeCurrencyError,
  } = useNativeCurrencyBalance();

  const { Icon: NativeIcon, label: nativeSymbol } =
    useMemo<CurrencyToken | undefined>(
      () => getTokenIconAndSymbol({ address: ethers.constants.AddressZero, chainId: chain?.id }),
      [chain],
    ) ?? {};

  const { isSuccess, isError, isLoading, erc20TokenBalanceList } = useERC20TokenBalance();

  const nativeCurrencyWithBalance: TokenBalance | null =
    NativeIcon && nativeSymbol && nativeBalanceValue
      ? {
          balanceValue: nativeBalanceValue,
          currencyToken: {
            address: ethers.constants.AddressZero as Address,
            Icon: NativeIcon as TokenBalance['currencyToken']['Icon'],
            label: nativeSymbol,
          },
        }
      : null;

  const currencyTokenBalanceList =
    (nativeCurrencyWithBalance && erc20TokenBalanceList.concat(nativeCurrencyWithBalance)) ?? [];

  return (
    <div className="flex flex-col gap-2">
      <main className="relative text-labelPrimary text-sm font-medium">
        {match({ isLoading, isNativeCurrencyLoading, isError: isNativeCurrencyError || isError, isSuccess })
          .with({ isLoading: true }, { isNativeCurrencyLoading: true }, () => (
            <div className="relative flex justify-center">
              <GradientSpinner />
            </div>
          ))
          .with({ isError: true }, () => <div>Sorry, something went wrong! Please try again</div>)
          .with({ isSuccess: true }, () => (
            <div className="flex flex-col gap-4">
              <TokenList currencyTokenBalanceList={currencyTokenBalanceList} />

              <Button
                variant="secondary"
                className="w-full font-medium text-base md:text-sm py-2 md:py-1 flex items-center gap-1"
                target="_blank"
                rel="noopener noreferer"
                href="https://pancakeswap.finance/swap?chain=bsc"
              >
                <PancakeIcon className="w-5 h-5" />
                <span className="leading-6">Swap</span>
              </Button>
            </div>
          ))
          .otherwise(() => null)}
      </main>
    </div>
  );
};

const TokenList = ({ currencyTokenBalanceList }: { currencyTokenBalanceList: TokenBalance[] }): JSX.Element => {
  return currencyTokenBalanceList.length > 0 ? (
    <>
      {currencyTokenBalanceList?.map(tokenItem => (
        <AccountCurrencyItem key={tokenItem.currencyToken.label} {...tokenItem} />
      ))}
    </>
  ) : (
    <div className="text-center">Sorry, some unsupported tokens are not displayed on our network yet</div>
  );
};
