import { ethers } from 'ethers';
import { useContractReads } from 'wagmi';

import { HexString } from '@globalTypes/contracts/metamask';
import { erc20ABI } from 'typings/abis';

export const useFormattedErc20Tokens = ({
  tokens,
  amounts,
  chainId,
  enabled,
}: {
  tokens?: string[];
  amounts?: string[];
  chainId: number;
  enabled: boolean;
}): string[] | null => {
  const getErc20TokenSymbolResults = useContractReads({
    contracts: tokens?.map(address => ({
      address: address as HexString,
      chainId: chainId,
      abi: erc20ABI,
      functionName: 'symbol',
    })),
    enabled,
  });

  const getErc20TokenDecimalsResults = useContractReads({
    contracts: tokens?.map(address => ({
      address: address as HexString,
      chainId: chainId,
      abi: erc20ABI,
      functionName: 'decimals',
    })),
    enabled,
  });

  if (!getErc20TokenSymbolResults.data || !getErc20TokenDecimalsResults.data || !amounts) {
    return null;
  }

  const formattedErc20Tokens = amounts.map((amount, index) => {
    const symbol = getErc20TokenSymbolResults.data?.[index] as string | undefined;
    const decimals = getErc20TokenDecimalsResults.data?.[index] as number | undefined;
    const formattedAmount = ethers.utils.formatUnits(amount, decimals);
    const [integer, decimal] = formattedAmount.split('.');

    return `${decimal === '0' ? integer : formattedAmount} ${symbol ?? ''}`;
  });

  return formattedErc20Tokens;
};
