import { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { JsonSchema } from '@features/common';

import { FilterInputVariant } from '../FilterInputVariant';
import { useOnChangeSubmit } from '../useOnChangeSubmit';

const valueEntries = [
  [undefined, 'All'],
  [true, 'Yes'],
  [false, 'No'],
] as const;

export const FilterBooleanInput = memo(
  ({ name }: { name: string; schema: JsonSchema; rootSchema: JsonSchema }): JSX.Element => {
    const { register, control } = useFormContext();

    useOnChangeSubmit(name);

    return (
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, onBlur, value, ref } }) => {
          return (
            <div className="flex flex-col gap-5 items-start text-sm">
              {valueEntries.map(([inputValue, title], i) => {
                return (
                  <FilterInputVariant
                    key={title + i}
                    className="flex gap-2.5 items-center"
                    {...register(name)}
                    type="radio"
                    onBlur={onBlur}
                    onChange={() => onChange(inputValue)}
                    checked={value == inputValue}
                    ref={ref}
                  >
                    {title}
                  </FilterInputVariant>
                );
              })}
            </div>
          );
        }}
      />
    );
  },
);
// eslint-disable-next-line functional/immutable-data
FilterBooleanInput.displayName = FilterBooleanInput.name;
