import { Control, Controller, FieldValues, UseFormRegister } from 'react-hook-form';

import { ElFormField } from '@components/elements/ElFormField';
import { JsonSchema } from '@features/common';

const formatToTypeMapper = (format: JsonSchema['format']): React.InputHTMLAttributes<HTMLInputElement>['type'] => {
  switch (format) {
    case 'email':
      return 'email';
    default:
      return 'text';
  }
};

type Props = {
  labelClassName?: string;
  className?: string;
  schema: JsonSchema;
  name: string;
  label: string;
  register: UseFormRegister<FieldValues>;
  // eslint-disable-next-line @typescript-eslint/ban-types
  control: Control<FieldValues, object>;
} & React.ComponentPropsWithoutRef<typeof ElFormField>;

export const FormField = ({
  labelClassName,
  className,
  label,
  name,
  register,
  control,
  schema,
  ...inputProps
}: Props): JSX.Element | null => {
  if (schema.type === 'string') {
    if (schema.format === 'duration') {
      return (
        <Controller
          control={control}
          render={({ field }) => {
            return (
              <ElFormField
                {...inputProps}
                id={name}
                label={label}
                className={className}
                labelClassName={labelClassName}
                type="number"
                onChange={e => field.onChange(`P${e.target.valueAsNumber}D`)}
                value={field.value?.slice(1, -1) ?? ''}
                required
              />
            );
          }}
          name={name}
        />
      );
    }
    return (
      <ElFormField
        {...inputProps}
        id={name}
        label={label}
        className={className}
        labelClassName={labelClassName}
        type={formatToTypeMapper(schema.format)}
        required
        {...register(name)}
      />
    );
  }
  if (schema.type === 'number') {
    return (
      <ElFormField
        {...inputProps}
        id={name}
        label={label}
        className={className}
        labelClassName={labelClassName}
        type="number"
        min={schema.minimum}
        max={schema.maximum}
        required
        {...register(name)}
      />
    );
  }
  console.error('Unknown input type', name, schema);
  return null;
};
