import classNames from 'classnames';
import React, { useId } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-types
const ElCheckbox = React.forwardRef<
  HTMLInputElement,
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
    children?: React.ReactNode;
  }
>(({ children, ...props }, ref) => {
  const defaultId = useId();
  const id = props?.id || defaultId;

  return (
    <div className="flex items-top">
      <div>
        <input
          id={id}
          type="checkbox"
          {...props}
          ref={ref}
          className={classNames(
            'min-w-[1rem] min-h-[1rem] text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500',
            'focus:ring-blue-600 focus:text-blue-400 ring-offset-gray-800 focus:ring-2 bg-gray-700 border-gray-600',
            'cursor-pointer',
          )}
        />
      </div>
      <label htmlFor={id} className="ml-2 text-sm font-medium cursor-pointer">
        {children}
      </label>
    </div>
  );
});

// eslint-disable-next-line functional/immutable-data
ElCheckbox.displayName = 'ElCheckbox';

export default ElCheckbox;
