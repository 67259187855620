import classNames from 'classnames';
import cn from 'classnames';
import { ChangeEventHandler, forwardRef, ReactNode, Ref, useState } from 'react';

type Props = {
  size?: 'md' | 'lg';
  label?: ReactNode;
  hint?: ReactNode;
  isDisabled?: boolean;
  isRequired?: boolean;
  isError?: boolean;
  className?: string;
  inputWrapClassName?: string;
  innerRef?: Ref<HTMLInputElement>;
  onClick?: React.HTMLProps<HTMLLabelElement>['onClick'];
  onChange?: ChangeEventHandler<HTMLInputElement>;
  renderInputElement?: (defaultProps: React.HTMLProps<HTMLInputElement>) => JSX.Element | null;
} & Omit<React.HTMLProps<HTMLInputElement>, 'size' | 'onClick' | 'onChange'>;

type InputLabelProps = {
  children?: string | JSX.Element;
  isRequired?: boolean;
  className?: string;
};

export const InputLabel = ({ children, isRequired, className }: InputLabelProps): JSX.Element | null => {
  return typeof children === 'string' ? (
    <div className={classNames('mb-2 leading-5 text-sm text-labelSecondary', className)}>
      {children}
      {isRequired && <span className="text-error"> *</span>}
    </div>
  ) : (
    children ?? null
  );
};

export const Input = forwardRef<HTMLInputElement, Props>(
  (
    {
      className,
      inputWrapClassName,
      size = 'md',
      isError,
      isDisabled: isDisabledProp,
      disabled,
      isRequired,
      label: labelProp,
      hint: hintProp,
      onFocus,
      onBlur,
      onClick,
      renderInputElement = props => <input {...props} />,
      ...props
    },
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false);
    const isDisabled = disabled || isDisabledProp;

    const hint =
      typeof hintProp === 'string' ? (
        <div className={cn('mt-1 text-xs', isError && 'text-error')}>{hintProp}</div>
      ) : (
        hintProp
      );

    const inputProps: React.HTMLProps<HTMLInputElement> = {
      ...props,
      disabled: isDisabled,
      className: cn(
        'w-full',
        'pl-3 pr-2',
        'leading-5',
        'border-none',
        'bg-transparent',
        'placeholder-labelPlaceholder',
        'outline-none',
        '!ring-0',
        {
          'text-sm py-1.5': size === 'md',
          'text-base py-2.5': size === 'lg',
        },
      ),
      onFocus: e => {
        setIsFocused(true);

        return onFocus?.(e);
      },
      onBlur: e => {
        setIsFocused(false);

        return onBlur?.(e);
      },
      ref,
    };

    return (
      <label className={cn('block leading-5 text-sm text-labelSecondary', className)} onClick={onClick}>
        <InputLabel isRequired={isRequired}>{labelProp}</InputLabel>
        <div
          className={cn(
            'flex items-center leading-5 w-full',
            'shadow-fieldBorder',
            'rounded-lg overflow-hidden',
            isFocused && 'shadow-fieldBorderFocused',
            isError && '!shadow-fieldBorderError',
            isDisabled ? 'bg-fieldBgDisabled text-labelPlaceholder' : 'bg-fieldBg text-labelPrimary',
            inputWrapClassName,
          )}
        >
          {renderInputElement(inputProps)}
        </div>
        {hint}
      </label>
    );
  },
);
// eslint-disable-next-line functional/immutable-data
Input.displayName = Input.name;
