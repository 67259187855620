import { logBlogOpenedAmplitudeEvent } from './amplitudeEvents';
import { BlogOpenedEventPayload } from './types';
import { BlogAnalyticsEvent } from './types';

type BlogAnalyticsEventWithPayload = {
  type: BlogAnalyticsEvent.BLOG_OPENED;
  payload: BlogOpenedEventPayload;
};

export const dispatchBlogAnalyticsEvent = ({ type, payload }: BlogAnalyticsEventWithPayload): void => {
  switch (type) {
    case BlogAnalyticsEvent.BLOG_OPENED:
      logBlogOpenedAmplitudeEvent(payload);
      break;
  }
};
