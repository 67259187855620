import * as yup from 'yup';

export const rentAssetFormSchema = yup
  .object({
    termsOfUse: yup.boolean().oneOf([true], 'You must accept Terms of Use'),
    termsOfDeduction: yup.boolean().oneOf([true], 'You must accept Terms of Deduction'),
  })
  .required();

export type RentAssetFormSchema = {
  termsOfUse: boolean;
  termsOfDeduction: boolean;
};
