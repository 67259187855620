import cn from 'classnames';
import { MouseEvent } from 'react';

import { Button } from '@features/common';
import {
  TournamentFragmentFragment as TournamentFragment,
  TournamentParticipantFragment,
  TournamentParticipationType,
} from '@graphql/generated';

type Props = {
  tournament: TournamentFragment;
  participant: TournamentParticipantFragment | null;
  isLoading: boolean;
  className?: string;
  onRegister: (e: React.MouseEvent) => Promise<void>;
};

export const ButtonRegisterOnTournament = ({
  participant,
  tournament,
  isLoading,
  className,
  onRegister,
}: Props): JSX.Element | null => {
  const { registrationEndDate, participationType, typeformUrl = '' } = tournament;
  const isRegistrationEnded = new Date(registrationEndDate).getTime() < Date.now();

  if (isRegistrationEnded || participationType === TournamentParticipationType.None) {
    return null;
  }

  const isDisabled = (!typeformUrl && Boolean(isLoading || participant)) || isRegistrationEnded;

  const dynamicLabel = isLoading ? 'Loading...' : participant ? '🎉 You are registered' : 'Register Now';

  const registerParticipantHandler = async (
    e: MouseEvent<HTMLButtonElement> & MouseEvent<HTMLAnchorElement>,
  ): Promise<void> => {
    e.preventDefault();

    if (typeformUrl) {
      window.open(typeformUrl, '_newtab');
      return;
    }

    await onRegister(e);
  };

  return (
    <Button
      className={cn('w-full', className)}
      variant={isDisabled ? 'secondary' : 'primary'}
      disabled={isDisabled}
      onClick={registerParticipantHandler}
    >
      {isRegistrationEnded ? 'Registration Finished' : <>{typeformUrl ? 'Register Now' : dynamicLabel}</>}
    </Button>
  );
};
