import cn from 'classnames';
import { Ref } from 'react';
import { match } from 'ts-pattern';

import { truncateWalletAddress } from '@features/common';
import { copyToBufferAndNotify } from '@utils/helpers';

import { LeaderboardRankingColumnValueType } from '../../../types';
import { formatNumberValue } from '../utils';

type Props = {
  display?: LeaderboardRankingColumnValueType | LeaderboardRankingColumnValueType[];
  value: React.ReactNode;
  className?: string;
  innerRef?: Ref<HTMLDivElement> | null;
};

export const CellValue = ({ display, value, className, innerRef }: Props): JSX.Element | null => {
  if (typeof value === 'number') {
    return (
      <div ref={innerRef} className={className}>
        {formatNumberValue(value)}
      </div>
    );
  }

  if (typeof value === 'string') {
    return match(display)
      .with(LeaderboardRankingColumnValueType.ImageUrl, () => (
        <div ref={innerRef} className="w-7">
          <img className={cn('w-7 h-7 rounded', className)} src={value} alt="" />
        </div>
      ))
      .with(LeaderboardRankingColumnValueType.WalletAddress, () => (
        <div
          ref={innerRef}
          className={cn('cursor-pointer', className)}
          title={value}
          onClick={() => copyToBufferAndNotify(value, 'Wallet address copied to clipboard')}
        >
          {truncateWalletAddress(value)}
        </div>
      ))
      .otherwise(() => (
        <div ref={innerRef} className={className}>
          {value}
        </div>
      ));
  }

  return <>{value}</>;
};
