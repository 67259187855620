import { useEffect, useState } from 'react';

import { TimerFormatConfig } from '@utils/getTimerFormat';

import { getCountdown, Countdown } from '../utils';

export const useCountdown = (dateIsoString?: string, formatConfig?: TimerFormatConfig): Countdown => {
  const [countdown, setCountdown] = useState(getCountdown(dateIsoString, formatConfig));

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown(getCountdown(dateIsoString, formatConfig));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return countdown;
};
