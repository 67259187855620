import React, { useEffect, useRef } from 'react';

import { useIntersection } from '@hooks/useIntersection';

import { GradientSpinner } from './GradientSpinner';

type Props = {
  children: React.ReactNode;
  isLoading?: boolean;
  isFetchingNextPage?: boolean;
  onRequestToLoadMore: () => void;
  hasNextPage: boolean;
  className?: string;
};

export const PaginatedView = ({
  children,
  isLoading,
  isFetchingNextPage,
  onRequestToLoadMore,
  hasNextPage,
  className,
}: Props): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);

  const intersection = useIntersection(ref, {
    root: null,
    rootMargin: '200px 0px',
    threshold: 0,
  });

  useEffect(() => {
    if (!hasNextPage) {
      return;
    }

    if (intersection?.isIntersecting && !isLoading) {
      onRequestToLoadMore();
    }
  }, [intersection?.isIntersecting, onRequestToLoadMore, isLoading, hasNextPage]);

  return (
    <div className={className}>
      {isLoading ? null : children}

      <div ref={ref} />

      {isLoading || isFetchingNextPage ? (
        <div className="w-full flex flex-col items-center py-3">
          <GradientSpinner />
        </div>
      ) : null}
    </div>
  );
};
