import { StoreApi, StateCreator } from 'zustand';

import type { MarketplaceSlice } from '@features/marketplace';
import { AnyObject } from '@globalTypes/globals';

import type { GameSlice, InnerWalletSlice, TournamentSlice, UserSlice } from './slices';

export enum UserTimeZones {
  LOCAL = 'local',
}

export enum Locales {
  EN_US = 'en-US',
}

export type PartialRoot<T extends AnyObject> = {
  [K in keyof T]?: Partial<T[K]>;
};

type StateCreatorParams<T extends AnyObject> = Parameters<
  StateCreator<T, [['zustand/devtools', never], ['zustand/immer', never]]>
>;

export type StateCreatorWithInitialState<T extends AnyObject> = (
  set: StateCreatorParams<T>[0],
  get: StateCreatorParams<T>[1],
  api: StoreApi<T>,
  initialState: PartialRoot<T>,
) => T;

export type ModalStateControllerHandler = () => void;

export type StoreApis = {
  updateUserTimeZone: (timeZone: UserTimeZones) => void;
  updateLocale: (locale: Locales) => void;
  updateRedirectOnNonAvailableModalHandler: (handler: ModalStateControllerHandler) => void;
  updateCloseAllHomePageModals: (handler: ModalStateControllerHandler) => void;
};

export type BasicStoreState = {
  userTimeZone: UserTimeZones;
  locale: string;
  modalControllers: {
    redirectOnNonAvailableModalHandler: ModalStateControllerHandler | null;
    closeAllHomePageModals: ModalStateControllerHandler | null;
  };
};

export type StoreState = BasicStoreState &
  GameSlice &
  InnerWalletSlice &
  UserSlice &
  MarketplaceSlice &
  TournamentSlice;

export type InitialStoreState = PartialRoot<StoreState>;
