import { FirebaseOptions, getApp, initializeApp, FirebaseApp } from '@firebase/app';
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();
const { FIREBASE_API_KEY, FIREBASE_AUTH_DOMAIN, FIREBASE_PROJECT_ID, FIREBASE_APP_ID } = publicRuntimeConfig ?? {};

const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  projectId: FIREBASE_PROJECT_ID,
  appId: FIREBASE_APP_ID,
};

const createFirebaseApp = (config: FirebaseOptions): FirebaseApp => {
  try {
    return getApp();
  } catch {
    return initializeApp(config);
  }
};

export const clientFirebaseApp = createFirebaseApp(firebaseConfig);
