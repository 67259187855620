import classNames from 'classnames';

import withSafeHydration from '@features/common/hocs/withSafeHydration';
import { PulsingLiveStatus, useCountdown } from '@features/tournaments';

type BasicTournamentStatusIndicatorProps = {
  tournamentStartDate: string;
  tournamentFinishDate?: string | null;
  useBackgroundContainer?: boolean;
  className?: string;
};

export const BasicTournamentStatusIndicator = withSafeHydration(
  ({
    tournamentStartDate,
    tournamentFinishDate,
    useBackgroundContainer,
    className,
  }: BasicTournamentStatusIndicatorProps): JSX.Element => {
    const { countdown, countdownString } = useCountdown(tournamentStartDate);
    const isFinished = new Date(tournamentFinishDate || '').getTime() < Date.now();
    const isLive = !isFinished && countdown <= 0;

    return (
      <div
        className={classNames(
          'rounded text-xs flex justify-center items-center',
          useBackgroundContainer && 'px-1.5 min-w-15',
          useBackgroundContainer && (isLive ? 'pl-1 bg-successDarkest' : 'bg-bgSecondary'),
          className,
        )}
      >
        {isLive ? <PulsingLiveStatus className="mr-1" /> : null}
        <span className={classNames('leading-6', isLive && 'text-labelSuccess')}>
          {isLive ? 'Live' : isFinished ? 'Finished' : countdownString}
        </span>
      </div>
    );
  },
);
