import { Transition } from '@headlessui/react';
import classNames from 'classnames';

import { fastFadeTransitionClassNames } from '@features/common';
import { UserStaticLinks } from '@modules/Navigation/UserSidebar/UserStaticLinks';

export const UserMenuLinksDropdown = ({ closeDropdown }: { closeDropdown: () => void }): JSX.Element => {
  return (
    <Transition show={true} appear={true} {...fastFadeTransitionClassNames}>
      <div className={classNames('bg-dropdownMenuBg border-dropdownBorder border-1 pb-0 rounded-md')}>
        <UserStaticLinks onItemClick={closeDropdown} itemClassName="!p-2" />
      </div>
    </Transition>
  );
};
