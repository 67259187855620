import classNames from 'classnames';

import { ReactComponent as IconSpinner } from '@public/icons/spinner.svg';

type GradientSpinnerProps = {
  className?: string;
};

export const GradientSpinner = ({ className }: GradientSpinnerProps): JSX.Element => {
  return <IconSpinner className={classNames('w-10 h-10 p-1', 'animate-spin duration-500', className)} />;
};
