import { bsc, bscTestnet, hardhat } from '@wagmi/chains';

import { Address } from '../types';

export type GameId = 'kryptomon' | 'anito-legends' | 'playdex-test-game' | 'playdex-test-wrapper';

const bscAddresses = {
  kryptomon: '0x04b0f7d5cb2ce4688497f2525748fb7a9affa394',
  'playdex-test-game': '0x5B94FDB26D9e5EcD73132F7c6BDF481Af76E09F3',
  'playdex-test-wrapper': '0x6d67D8e60e51499D76A5f5a19c0642bC3ff37910',
} satisfies Partial<Record<GameId, Address>>;

const bscTestnetAddresses = {
  kryptomon: '0x0D205D5BE22a39a870e0BA00ebE24Fc1beEED932',
  'anito-legends': '0x79C41e3C5eF3AcBF3F8fB958312A840Cd92316A9',
  'playdex-test-game': '0xf2842986b1E7dCE47F1fF330a2e7fAFbcF02bab9',
  'playdex-test-wrapper': '0x97CFA60aBEd32f2c76fe529Ec3B18f6aE6731D22',
} satisfies Partial<Record<GameId, Address>>;

const hardhatAddresses = {
  kryptomon: '0xCf7Ed3AccA5a467e9e704C703E8D87F634fB0Fc9',
} satisfies Partial<Record<GameId, Address>>;

export const collectionAddresses = {
  [bscTestnet.id]: bscTestnetAddresses,
  [bsc.id]: bscAddresses,
  [hardhat.id]: hardhatAddresses,
};
