import { PropsWithChildren } from 'react';

import { featureChecker } from '@services/featureChecker';

import { FeatureCheckerContext } from './FeatureCheckerContext';

type Props = {
  //
};

/**
 * Expected usage
 * @example
 * ```
 * <FeatureCheckerContextProvider>
 *  <SomeDependentComponent />
 * </FeatureCheckerContextProvider>
 * ```
 */
export const FeatureCheckerContextProvider = ({ children }: PropsWithChildren<Props>): JSX.Element => {
  return <FeatureCheckerContext.Provider value={featureChecker}>{children}</FeatureCheckerContext.Provider>;
};
