import cn from 'classnames';
import React from 'react';

type Props<Elem extends React.ElementType> = {
  as?: Elem;
} & React.ComponentPropsWithoutRef<Elem>;

export const FieldIcon = <Elem extends React.ElementType>({
  as,
  children,
  className,
  ...restProps
}: React.PropsWithChildren<Props<Elem>>): JSX.Element => {
  const Component = as || 'span';
  return (
    <Component className={cn('opacity-50', className)} {...restProps}>
      {children}
    </Component>
  );
};
