export type CellValue = string | number | string[] | number[] | null;

export type RawRowData = {
  data: Record<
    string,
    {
      value: CellValue;
    }
  >;
  group?: string;
};

export type FormattedRowData = {
  id: string;
  [key: string]: CellValue;
};

export enum LeaderboardRankingColumnValueType {
  Default = 'default',
  ImageUrl = 'imageUrl',
  WalletAddress = 'walletAddress',
}

export type ColumnsConfig = Record<
  string,
  {
    title: string;
    display?: LeaderboardRankingColumnValueType | LeaderboardRankingColumnValueType[];
    order?: number;
  }
>;

export type GroupsConfig = Record<
  string,
  {
    title: string;
    description?: string;
    order?: number;
  }
>;

export type RankRewardItem = {
  rank: number | { from: number; to: number };
  erc20AmountsWithoutDecimals: Record<string, string>;
};

export type LeaderboardRewardStructure = {
  erc20Tokens: {
    name: string;
    address: string;
    decimals: number;
  }[];
  presetRewards?: {
    walletAddress: string;
    erc20AmountsWithoutDecimals: Record<string, string>;
  }[];

  participantMilestones?: {
    minParticipantCount: number;
    rewards: RankRewardItem[];
  }[];
  rankRewards?: RankRewardItem[];
  groupRewards?: Record<string, RankRewardItem[]>;
};

export type Leaderboard = {
  id: string;
  ranking: {
    columns: ColumnsConfig;
    rows: RawRowData[];
    groups?: GroupsConfig;
  };
  rewardStructure?: LeaderboardRewardStructure;
  updatedAt: string;
};

export type LeaderboardRewardClaimer = {
  walletAddress: string;
  erc20Amounts: string[];
};
