import classNames from 'classnames';

import { GameDocument } from '@globalTypes/prismic/documents';

import { BadgeProps, StatusBadge } from '../StatusBadge';

type GameStatus = GameDocument['data']['status'] | null;

type GameStatusBadgeProps = {
  status: GameStatus;
  end?: React.ReactNode;
} & Omit<BadgeProps, 'children'>;

type GameStatusIndicatorProps = {
  status: GameStatus;
  className?: string;
};

const GameStatusIndicator = ({ status, className }: GameStatusIndicatorProps): JSX.Element | null => {
  // eslint-disable-next-line functional/no-let
  let colorClassName: string | null = null;

  switch (status) {
    case 'in-dev':
      colorClassName = 'bg-warning';
      break;
    case 'alpha':
      colorClassName = 'bg-lightPink';
      break;
    case 'beta':
      colorClassName = 'bg-link';
      break;
    case 'playable':
      colorClassName = 'bg-success';
      break;
    default:
      break;
  }

  return <div className={classNames('w-2 h-2 rounded-lg', colorClassName, className)} />;
};

const getGameStatusLabel = (status: GameStatus): string | null => {
  switch (status) {
    case 'in-dev':
      return 'Development';
    case 'alpha':
      return 'Alpha';
    case 'beta':
      return 'Beta';
    case 'playable':
      return 'Playable';
    default:
      return null;
  }
};

export const GameStatusBadge = ({
  status,
  useBorderContainer,
  className,
  onClick,
  isActive,
  end,
}: GameStatusBadgeProps): JSX.Element => {
  return (
    <StatusBadge useBorderContainer={useBorderContainer} className={className} onClick={onClick} isActive={isActive}>
      <div className="p-1.5">
        <GameStatusIndicator status={status} className="mr-1" />
      </div>

      {getGameStatusLabel(status) ?? ''}
      {end}
    </StatusBadge>
  );
};
