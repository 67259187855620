import { ApolloProvider } from '@apollo/client';
import { useMemo } from 'react';

import { useToken } from '@features/auth';
import { createPlaydexClient } from '@gql/apollo-client';

const PlaydexApolloClientContext = ({ children }: { children?: React.ReactNode }): JSX.Element => {
  const { token } = useToken();
  const playdexClient = useMemo(() => createPlaydexClient(undefined), [token]);
  return <ApolloProvider client={playdexClient}>{children}</ApolloProvider>;
};

export default PlaydexApolloClientContext;
