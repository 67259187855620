interface StandardMerkleTreeData<T extends unknown[]> {
  format: 'standard-v1';
  tree: string[];
  values: {
    value: T;
    treeIndex: number;
  }[];
  leafEncoding: string[];
}

export type RewardsMerkleTreeData = StandardMerkleTreeData<[string, string[]]>;

export enum EventState {
  NO_DEPOSIT = 0,
  GOT_DEPOSIT = 1,
  CLAIMING_STARTED = 2,
  CLAIMING_STOPPED = 3,
}
