import cn from 'classnames';
import { ReactNode } from 'react';
import { toast, ToastOptions } from 'react-toastify';

import { ReactComponent as SuccessIcon } from '@public/icons/custom/check-circle-filled.svg';
import { ReactComponent as ErrorIcon } from '@public/icons/custom/error-circle.svg';
import { ReactComponent as InfoIcon } from '@public/icons/custom/info-circle.svg';
import { ReactComponent as WarningIcon } from '@public/icons/custom/warning-triangle.svg';

export enum ToastType {
  'SUCCESS' = 'success',
  'INFO' = 'info',
  'ERROR' = 'error',
  'WARNING' = 'warning',
}

const commonIconClassName = 'w-5 h-5 shrink-0';
const iconToType: Record<ToastType, ReactNode> = {
  [ToastType.INFO]: <InfoIcon className={cn(commonIconClassName, 'text-link')} />,
  [ToastType.SUCCESS]: <SuccessIcon className={cn(commonIconClassName, 'text-success')} />,
  [ToastType.ERROR]: <ErrorIcon className={cn(commonIconClassName, 'text-error')} />,
  [ToastType.WARNING]: <WarningIcon className={cn(commonIconClassName, 'text-warning')} />,
};

type Notice = (
  type: ToastType,
  message?: string | { title: string; description: string },
  toastProps?: Partial<ToastOptions>,
) => void;

export const notice: Notice = (type = ToastType.INFO, content, toastProps) => {
  const showToast = toast[type];

  const message = (
    <div className="leading-5 pr-2">
      {typeof content === 'object' ? (
        <>
          <div>{content.title}</div>
          <div className="mt-0.5 text-labelSecondary">{content.description}</div>
        </>
      ) : (
        content
      )}
    </div>
  );

  showToast(message, {
    ...toastProps,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore because according to https://fkhadra.github.io/react-toastify/icons#custom-icons, function is a valid value
    icon: ({ type }) => iconToType[type as ToastType],
  });
};
