import { useRouter } from 'next/router';
import { default as RcPagination } from 'rc-pagination';
import React, { useCallback, useEffect } from 'react';

import { ReactComponent as IconDotsHorizontal } from '@public/icons/dots-horizontal.svg';

type PaginationProps = {
  page: number;
  pageSize: number;
  onChange: (page: number, pageSize: number) => void;
  total: number;
  className?: string;
};

export const Pagination = ({ page, pageSize, onChange, total, className }: PaginationProps): JSX.Element | null => {
  const router = useRouter();

  useEffect(() => {
    const queryPage = parseInt(router.query['page'] as string);
    const queryPageSize = parseInt(router.query['pageSize'] as string);

    if (queryPage && queryPageSize) {
      onChange(queryPage, queryPageSize);
    }
  }, [router.query]);

  const onPageOrPageSizeChange = useCallback(
    async (newPage: number, newPageSize: number) => {
      await router.replace(
        {
          pathname: router.asPath.split('?')[0],
          query: { page: newPage, pageSize: newPageSize },
        },
        undefined,
        { shallow: true },
      );
    },
    [page, pageSize, router],
  );

  if (total === 0) {
    return null;
  }

  // @todo export inline svg to public svg files
  return (
    <RcPagination
      onChange={onPageOrPageSizeChange}
      total={total}
      current={page}
      pageSize={pageSize}
      showTitle={false}
      jumpNextIcon={<IconDotsHorizontal />}
      jumpPrevIcon={<IconDotsHorizontal />}
      className={className}
      prevIcon={
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8.54166 5.625L3.95833 10L8.54166 14.375"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M16.0417 10H4.16667" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      }
      nextIcon={
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.4583 5.625L16.0417 10L11.4583 14.375"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M15.8333 10H3.95833" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      }
    />
  );
};
