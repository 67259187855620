import Popup from 'reactjs-popup';
import { PopupProps } from 'reactjs-popup/dist/types';
import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from '../../../../tailwind.config';
import { OnlyClientSide } from '../OnlyClientSide';

const fullConfig = resolveConfig(tailwindConfig);

type PopupPosition =
  | 'top left'
  | 'top center'
  | 'top right'
  | 'right top'
  | 'right center'
  | 'right bottom'
  | 'bottom left'
  | 'bottom center'
  | 'bottom right'
  | 'left top'
  | 'left center'
  | 'left bottom';

type TooltipProps = {
  tooltipContent: React.ReactNode;
  children: JSX.Element | ((isOpen: boolean) => JSX.Element);
  position?: PopupPosition | PopupPosition[];
  popupProps?: Partial<PopupProps>;
};

export const Tooltip = ({
  children,
  tooltipContent,
  position = 'right center',
  popupProps,
}: TooltipProps): JSX.Element => {
  return (
    <OnlyClientSide>
      <Popup
        trigger={children}
        on={['hover']}
        position={position}
        closeOnDocumentClick
        arrow={false}
        offsetX={4}
        offsetY={6}
        contentStyle={{
          backgroundColor: (fullConfig.theme?.colors as Record<string, string>)['bgSecondary'],
          borderColor: (fullConfig.theme?.colors as Record<string, string>)['buttonSecondaryBorder'],
          borderWidth: '1px',
          width: 'fit-content',
          animation: 'fade-in 0.3s ease',
          backdropFilter: 'blur(8px)',
          fontSize: '14px',
          ...popupProps?.contentStyle,
        }}
        {...popupProps}
      >
        {tooltipContent}
      </Popup>
    </OnlyClientSide>
  );
};
