import cn from 'classnames';

import ElTooltip, { TooltipDirection, TooltipType } from '@components/elements/ElTooltip';
import { Input } from '@features/common';
import {
  TournamentFragmentFragment as TournamentFragment,
  TournamentTeamFragmentFragment as TournamentTeamFragment,
} from '@graphql/generated';
import { useWindowSize } from '@hooks/useWindowSize';
import { ReactComponent as IconCopy } from '@public/copy.svg';
import { ReactComponent as InfoCircle } from '@public/icons/custom/info-circle.svg';
import { copyToBufferAndNotify } from '@utils/helpers';

type Props = {
  className?: string;
  tournament: TournamentFragment;
  team: TournamentTeamFragment;
  isMultiline?: boolean;
  participantsLimitPerTeam?: number | null;
};

export const TeamInvitationCopyInput = ({ className, isMultiline, tournament, team }: Props): JSX.Element | null => {
  const {
    orientation: { isMobile },
  } = useWindowSize();

  if (typeof window === 'undefined') {
    return null;
  }

  const captainMessage =
    `Use the code ${team.code} to join the team!\n\n` +
    `Join the tournament ${tournament.title} by this link ${window.location.href}`;

  const usageInstruction = (
    <div className="leading-5 text-labelSecondary text-sm">
      Copy and send information below to your team members.{' '}
      {tournament.participantsLimitPerTeam && (
        <>
          <br />
          Code can be used only {tournament.participantsLimitPerTeam - 1} times
        </>
      )}
    </div>
  );

  const onTeamInfoInputClick = (): void => {
    copyToBufferAndNotify(captainMessage, 'Copied to clipboard');
  };

  return (
    <div className={cn(className, 'w-full')}>
      {isMultiline && usageInstruction}

      <div className="flex items-center gap-3">
        <Input
          isDisabled={true}
          className="cursor-pointer w-[calc(100%-36px)]"
          inputWrapClassName="md:max-w-95"
          onClick={onTeamInfoInputClick}
          renderInputElement={defaultProps => (
            <div className={cn('flex w-full', isMultiline && 'flex-col items-end')}>
              <div
                className={cn(
                  defaultProps.className,
                  'flex-1 cursor-pointer ',
                  isMultiline ? 'whitespace-pre-wrap' : 'text-ellipsis whitespace-nowrap overflow-hidden',
                )}
              >
                {captainMessage}
              </div>
              <div className="flex mx-2.5 gap-1 shrink-0">
                <IconCopy width={24} className={cn('text-labelTertiary', isMultiline && 'mb-1')} />
                {isMultiline && <div className="text-labelSecondary">Copy</div>}
              </div>
            </div>
          )}
        />
        <ElTooltip
          config={{
            duration: 0,
            direction: isMobile ? TooltipDirection.left : TooltipDirection.right,
            tooltipWindowProps: {
              className: cn(
                'w-[280px] rounded-lg bg-bgSecondary border-1 border-bgStripe',
                'text-sm text-labelPrimary py-2 px-4',
              ),
            },
          }}
          tooltipType={TooltipType.COMPONENT_WRAPPER}
          tooltipContent={isMultiline ? null : usageInstruction}
        >
          <InfoCircle width={24} height={24} className="inline-block text-labelTertiary shrink-0" />
        </ElTooltip>
      </div>
    </div>
  );
};
