import assert from 'assert';
import { useContext } from 'react';

import { GameDocument } from '@globalTypes/prismic/documents';

import CurrentGameContext from './CurrentGameContext';

export default function useCurrentGameContext(): GameDocument {
  const currentGame = useContext(CurrentGameContext);

  assert(currentGame);

  return currentGame as GameDocument;
}
