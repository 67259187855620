import classNames from 'classnames';
import React, { useMemo } from 'react';

import ElModal from '@elements/ElModal';

import { OrderAssetForm } from './OrderAssetForm';
import { useOrderFormModalContext } from './OrderFormModalContext';
import { FailedModalContent, SuccessModalContent } from './StatusModalContents';
import { ModalStatus } from './enum';

type ModalByStatusMapper = {
  [key in ModalStatus]?: () => JSX.Element;
};

const modalByStatusMapper: ModalByStatusMapper = {
  [ModalStatus.ACTIVE]: OrderAssetForm,
  [ModalStatus.SUCCESS]: SuccessModalContent,
  [ModalStatus.FAIL]: FailedModalContent,
};

export const OrderAssetFormModalBase = (): JSX.Element | null => {
  const { status, closeModal } = useOrderFormModalContext();

  const CurrentModal = useMemo(() => modalByStatusMapper[status] ?? (() => null), [status]);

  return (
    <ElModal
      titleSectionStyles="hidden"
      className={classNames(
        '!bg-earlGrey-700 text-white flex-1 max-w-[400px]',
        'relative !border-white/20 border-1 shadow-md',
        status === ModalStatus.ACTIVE ? 'md:max-h-[810px] overflow-hidden' : 'p-1 md:p-8',
      )}
      modalWrapperStyles="bg-black/90"
      onCloseModal={closeModal}
    >
      <div className={classNames(status === ModalStatus.ACTIVE && 'overflow-auto')}>
        <CurrentModal />
      </div>
    </ElModal>
  );
};
