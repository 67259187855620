import { erc20Tokens } from '@playdex-mono/shared';
import { ethers } from 'ethers';
import { bsc, bscTestnet } from 'wagmi/chains';

import { ReactComponent as BNBCurrency } from '@public/icons/currencies/binance.svg';
import { ReactComponent as BUSDCurrency } from '@public/icons/currencies/busd.svg';
import { ReactComponent as ETHCurrency } from '@public/icons/currencies/eth.svg';
import { ReactComponent as KMONCurrency } from '@public/icons/currencies/kmon.svg';
import { ReactComponent as MATICCurrency } from '@public/icons/currencies/polygon.svg';
import { ReactComponent as USDTCurrency } from '@public/icons/currencies/usdt.svg';
import { ReactComponent as PXTCurrency } from '@public/playdex_single_logo.svg';

import { BaseCurrencyTokenList as CurrencyTokenList } from './types';

export const mainnetTokens: CurrencyTokenList = [
  { address: ethers.constants.AddressZero, label: 'ETH', Icon: ETHCurrency },
];

export const polygonTokens: CurrencyTokenList = [
  { address: ethers.constants.AddressZero, label: 'MATIC', Icon: MATICCurrency },
];

export const hardhatTokens: CurrencyTokenList = [
  { address: ethers.constants.AddressZero, label: 'tBNB', Icon: BNBCurrency },
];

export const bscTestnetTokens: CurrencyTokenList = [
  { address: erc20Tokens[bscTestnet.id].USDT, label: 'USDT', Icon: USDTCurrency },
  { address: erc20Tokens[bscTestnet.id].BUSD, label: 'BUSD', Icon: BUSDCurrency },
  { address: ethers.constants.AddressZero, label: 'tBNB', Icon: BNBCurrency },
  { address: erc20Tokens[bscTestnet.id].KMON, label: 'KMON', Icon: KMONCurrency },
  { address: erc20Tokens[bscTestnet.id].PXT, label: 'PXT', Icon: PXTCurrency },
];

export const bscTokens: CurrencyTokenList = [
  { address: erc20Tokens[bsc.id].USDT, label: 'USDT', Icon: USDTCurrency },
  { address: erc20Tokens[bsc.id].BUSD, label: 'BUSD', Icon: BUSDCurrency },
  { address: ethers.constants.AddressZero, label: 'BNB', Icon: BNBCurrency },
  { address: erc20Tokens[bsc.id].KMON, label: 'KMON', Icon: KMONCurrency },
];
