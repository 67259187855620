import { DateTime } from 'luxon';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

import { BlogPostDocument } from '@globalTypes/prismic/documents';
import { getLocaleDateTime } from '@utils/dateTime';

type BlogPostItemProps = { post: BlogPostDocument };

const BlogPostItem = ({ post }: BlogPostItemProps): JSX.Element => {
  const { showcaseImage, createdAt, title, showcaseDescription } = post.data;

  const createdAtDateFormat = createdAt ? getLocaleDateTime(createdAt).toLocaleString(DateTime.DATE_FULL) : null;

  return (
    <Link href={`/blog/${post.uid}`}>
      <div className="flex flex-col cursor-pointer">
        {showcaseImage?.url && (
          <Image
            className="rounded-3 mb-4 w-full"
            width={446}
            height={220}
            src={showcaseImage.url}
            alt={showcaseImage.alt || title || 'blog post img'}
          />
        )}
        {createdAtDateFormat && <span className="text-labelTertiary text-sm mb-2">{createdAtDateFormat}</span>}
        {title && <h3 className="text-labelPrimary text-xl mb-2 font-medium">{title}</h3>}
        {showcaseDescription && (
          <p className="text-labelSecondary text-sm leading-6 !line-clamp-8">{showcaseDescription}</p>
        )}
      </div>
    </Link>
  );
};

export const BlogPostList = ({ blogPostList }: { blogPostList: Array<BlogPostDocument> }): JSX.Element => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-6">
      {blogPostList.map(post => {
        return <BlogPostItem key={post.id} post={post} />;
      })}
    </div>
  );
};
