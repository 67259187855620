import { Switch } from '@headlessui/react';
import classNames from 'classnames';

type Props = {
  checked: boolean;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
};

export const Toggle = ({ checked, onChange, disabled }: Props): JSX.Element => {
  return (
    <Switch
      checked={checked}
      onChange={onChange}
      className="flex justify-start items-center flex-wrap gap-2 group"
      disabled={disabled}
    >
      <div
        className={classNames(
          {
            'bg-radioCheckboxBgSelected group-hover:bg-radioCheckboxBgSelectedHover': checked && !disabled,
            'bg-radioCheckboxBg group-hover:bg-radioCheckboxBgHover': !checked && !disabled,
            'bg-radioCheckboxBgDisabled': !checked && disabled,
            'bg-radioCheckboxBgSelectedDisabled': checked && disabled,
          },
          !disabled && 'cursor-pointer',
          'focus:ring-2 focus:ring-outlineActive',
          'relative inline-flex items-center h-5 w-9 shrink-0 duration-200 ease-in-out',
          'rounded-full border-2 border-transparent transition-colors',
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            {
              'bg-radioCheckboxCircleOn': checked && !disabled,
              'bg-radioCheckboxCircleOff': !checked && !disabled,
              'bg-radioCheckboxCircleOnDisabled': checked && disabled,
              'bg-radioCheckboxCircleOffDisabled': !checked && disabled,
            },
            checked ? 'translate-x-4' : 'translate-x-0',
            'pointer-events-none inline-block h-4 w-4 rounded-full',
            'ring-0 transition duration-200 ease-in-out',
          )}
        />
      </div>
    </Switch>
  );
};
