import { Transition } from '@headlessui/react';
import cn from 'classnames';
import { useState } from 'react';

import { Button, JsonSchema, RefSchema } from '@features/common';
import {
  TournamentFragmentFragment as TournamentFragment,
  TournamentParticipantFragment,
  TournamentRegistrationStatus,
  TournamentTeamFragmentFragment as TournamentTeamFragment,
  UserProfileFragmentFragment as UserProfileFragment,
} from '@graphql/generated';
import { ReactComponent as ChevronDown } from '@public/icons/custom/chevron-down.svg';

import { TeamInvitationCopyInput } from './TeamInvitationCopyInput';
import { formatExtraFieldValues } from '../utils';

type Props = {
  className?: string;
  user: UserProfileFragment;
  tournament: TournamentFragment;
  team: TournamentTeamFragment | null;
  participant: TournamentParticipantFragment | null;
  extraFields: Record<string, JsonSchema | RefSchema>;
  retryParticipantRegistration: () => Promise<unknown>;
  isRetryParticipantRegistrationPending: boolean;
};

export const RegisteredSection = ({
  className,
  user,
  tournament,
  team,
  participant,
  extraFields,
  retryParticipantRegistration,
  isRetryParticipantRegistrationPending,
}: Props): JSX.Element | null => {
  const [isMembersSectionOpen, setIsMembersSectionOpen] = useState(false);
  const membersCount = team ? team.members.length : 0;
  const { participantsLimitPerTeam: participantsLimit, minTeamSize, tournamentFinishDate } = tournament;

  const isFinished = new Date(tournamentFinishDate || '').getTime() < Date.now();
  if (isFinished) {
    return null;
  }

  const registrationStatus = participant?.registrationStatus;
  const registrationStatusMessage = participant?.registrationStatusMessage;

  return (
    <div className={cn('text-sm text-labelPrimary', className)}>
      <div className="flex gap-2 items-center">
        {registrationStatus ? (
          <div className="flex gap-3 flex-col md:flex-row">
            <div>
              <div className="inline-flex items-center gap-2 px-2 py-1 bg-bgSecondary rounded-1">
                <div
                  className={cn('h-3 w-3 rounded-sm', {
                    'bg-warning':
                      registrationStatus === TournamentRegistrationStatus.Processing ||
                      isRetryParticipantRegistrationPending,
                    'bg-error': [TournamentRegistrationStatus.Failed, TournamentRegistrationStatus.Rejected].includes(
                      registrationStatus,
                    ),
                    'bg-success': registrationStatus === TournamentRegistrationStatus.Confirmed,
                    'bg-link': registrationStatus === TournamentRegistrationStatus.Registered,
                  })}
                />
                {isRetryParticipantRegistrationPending ? 'Pending' : registrationStatus}
              </div>
            </div>
            {registrationStatusMessage && (
              <>
                <div className="w-0.5 my-1.5 h-auto ml-3 hidden md:block bg-bgStripe" />
                <div className="text-sm text-labelSecondary mt-1">{registrationStatusMessage}</div>
              </>
            )}
            {registrationStatus === TournamentRegistrationStatus.Failed && (
              <div>
                <Button
                  variant="secondary"
                  onClick={retryParticipantRegistration}
                  disabled={isRetryParticipantRegistrationPending}
                >
                  Retry
                </Button>
              </div>
            )}
          </div>
        ) : (
          <div className="h-6 px-2 py-1 leading-4 text-xs rounded-1 bg-successDarkest text-labelSuccess">
            Registered
          </div>
        )}
        {team?.extraFieldValues &&
          formatExtraFieldValues(team.extraFieldValues, extraFields).map(([fieldLabel, value]) => (
            <div key={fieldLabel} className="font-medium">
              <span className="text-labelSecondary">{fieldLabel}</span> <span>{value}</span>
            </div>
          ))}
        {team && team.captainUserId === user?.id && (
          <div className="font-medium">
            <span className="text-labelSecondary">Team code</span> <span>{team.code}</span>
          </div>
        )}
      </div>
      {team && team.captainUserId === user?.id && (
        <>
          <div
            className={cn(
              'flex mt-3 mb-2 items-center font-medium cursor-pointer select-none',
              'text-labelSecondary hover:text-labelPrimary active:text-labelPrimary',
            )}
            onClick={() => setIsMembersSectionOpen(v => !v)}
          >
            <div>
              <span
                className={cn({
                  'text-error': minTeamSize && membersCount < minTeamSize,
                  'text-success': minTeamSize ? membersCount >= minTeamSize : true,
                })}
              >
                {team.members.length}
                {participantsLimit ? <>/{participantsLimit}</> : ''}{' '}
              </span>
              members registered
            </div>
            <ChevronDown
              className={cn(
                'text-md w-6 transition-transform duration-150 ease-in-out',
                isMembersSectionOpen && '-rotate-180',
              )}
            />
          </div>

          <Transition
            show={isMembersSectionOpen}
            unmount={false}
            enter="transition-all ease-out duration-200"
            enterFrom="opacity-0 max-h-0"
            enterTo="opacity-100 max-h-64"
            leave="transition-all ease-out duration-200"
            leaveFrom="opacity-100 max-h-64"
            leaveTo="opacity-0 max-h-0"
          >
            <div className="flex max-w-[267px] p-3 gap-4 flex-col overflow-auto bg-bgSecondary rounded-lg">
              {[...team.members]
                .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
                .map(({ userId, extraFieldValues }) => {
                  return (
                    <div key={userId} className="flex justify-between items-center">
                      <div>{extraFieldValues?.ign}</div>
                      <div className="text-xs text-labelSecondary">
                        {team.captainUserId === userId ? 'Captain' : 'Member'}
                      </div>
                    </div>
                  );
                })}
            </div>
          </Transition>

          <TeamInvitationCopyInput
            className="pt-2"
            participantsLimitPerTeam={participantsLimit}
            tournament={tournament}
            team={team}
          />
        </>
      )}
    </div>
  );
};
