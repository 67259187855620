import { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { JsonSchema } from '@features/common';
import { AdditionalMetaKeyword } from '@features/gamehub/types';

import { FilterInputVariant } from '../FilterInputVariant';
import { useOnChangeSubmit } from '../useOnChangeSubmit';

export const FilterEnumInput = memo(
  ({ name, schema }: { name: string; schema: JsonSchema; rootSchema: JsonSchema }): JSX.Element => {
    const { register, control } = useFormContext();

    useOnChangeSubmit(name);

    return (
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, onBlur, value, ref } }) => {
          return (
            <div className="flex flex-col gap-5 items-start text-sm">
              <FilterInputVariant
                className="flex gap-2.5 items-center"
                {...register(name)}
                type="radio"
                onBlur={onBlur}
                onChange={() => onChange(undefined)}
                checked={value == undefined}
                ref={ref}
              >
                All
              </FilterInputVariant>
              {schema.enum?.map((item: string | number, i) => (
                <FilterInputVariant
                  key={item}
                  className="flex gap-2.5 items-center"
                  type="radio"
                  {...register(name)}
                  onBlur={onBlur}
                  onChange={() => onChange(item)}
                  checked={value == item}
                  ref={ref}
                >
                  {schema[AdditionalMetaKeyword.EnumTitles]?.[i] ?? item}
                </FilterInputVariant>
              ))}
            </div>
          );
        }}
      />
    );
  },
);
// eslint-disable-next-line functional/immutable-data
FilterEnumInput.displayName = FilterEnumInput.name;
