import cn from 'classnames';
import React from 'react';

type ElShinnedImageProps = React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> & {
  blur?: 'sm' | 'md' | 'lg' | 'xl' | '2xl';
  secondBlurredImgSrc?: string;
  imgContainerClassName?: string;
  secondBlurredImgClassName?: string;
};

const getBlurEffect = (blur: ElShinnedImageProps['blur']): string => {
  switch (blur) {
    case 'sm':
      return 'blur-sm';
    case 'md':
      return 'blur-md';
    case 'lg':
      return 'blur-lg';
    case 'xl':
      return 'blur-xl';
    case '2xl':
      return 'blur-2xl';
    default:
      return '';
  }
};

const ElBluredImage = ({
  secondBlurredImgSrc,
  imgContainerClassName,
  secondBlurredImgClassName,
  ...imgProps
}: ElShinnedImageProps): JSX.Element => {
  const blurEffect = getBlurEffect(imgProps.blur);

  return (
    <div className={cn('relative w-fit', imgContainerClassName)}>
      <img
        {...imgProps}
        src={secondBlurredImgSrc || imgProps.src}
        alt=""
        className={cn(
          'absolute inset-0 pointer-events-none',
          blurEffect,
          imgProps.className,
          secondBlurredImgClassName,
        )}
      />
      <img {...imgProps} className={cn('relative', imgProps.className)} alt={imgProps.alt || ''} />
    </div>
  );
};

export default ElBluredImage;
