import cn from 'classnames';

import ElBlurredImage from '@components/elements/ElBluredImage';
import ElModal from '@components/elements/ElModal';
import { ModalCloseButton } from '@features/common';
import withSafeHydration from '@features/common/hocs/withSafeHydration';
import { TournamentFragmentFragment as TournamentFragment } from '@graphql/generated';

import { TournamentShareActions } from './TournamentShareActions';

type Props = {
  tournament: TournamentFragment;
  closeModal: () => void;
};

export const TournamentShareModal = withSafeHydration(({ tournament, closeModal }: Props): JSX.Element => {
  return (
    <ElModal
      onCloseModal={closeModal}
      className={cn('relative w-[100vw] md:w-[400px] h-[100vh] md:h-max', '!justify-start', 'text-labelPrimary')}
      modalWrapperStyles="!p-0"
    >
      <ModalCloseButton className="absolute right-3 top-3" onClick={closeModal} />
      <div
          className={cn(
            'absolute h-[150px] w-full mt-[-75px] bg-gradient-radial from-controlPrimary to-60% opacity-10',
            'pointer-events-none',
          )}
        />
      <div className="px-6 pb-6 bg-bgPrimary border-t border-bgStripePrimary">
        <div className="flex justify-center">
          <ElBlurredImage
            className="w-[140px] h-[140px]"
            width={60}
            height={60}
            blur="xl"
            src="/icons/invite-pulse.svg"
          />
        </div>

        <h1 className="font-medium text-center -mt-3">
          Invite your friends to join the tournament and&nbsp;enjoy competing together!
        </h1>
        <TournamentShareActions tournament={tournament} />
      </div>
    </ElModal>
  );
});
