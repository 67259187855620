import { SignInWithMetamaskMutation } from '@graphql/generated';

export type SignInWithMetamaskResponse = SignInWithMetamaskMutation['signInWithMetamask'];

export type SignInWithMetamaskException = Extract<SignInWithMetamaskResponse, { __typename: 'UserExceptionType' }>;

export type SignInWithMetamaskAuthToken = Extract<SignInWithMetamaskResponse, { __typename: 'UserSignedInType' }>;

export enum AUTH_PROVIDER {
  GOOGLE = 'GOOGLE',
  FACEBOOK = 'FACEBOOK',
  WALLET_CONNECTOR = 'WALLET_CONNECTOR',
}

export enum AUTH_SOURCE {
  CCE = 'CCE',
}

export type AuthAmplitudeBasePayload = {
  provider: AUTH_PROVIDER;
  source?: AUTH_SOURCE;
};

export type AuthAmplitudeOnErrorPayload = AuthAmplitudeBasePayload & {
  message: string;
};
