/* eslint-disable functional/immutable-data */
import { PrismicNextImage } from '@prismicio/next';
import cn from 'classnames';
import { isEmpty } from 'ramda';
import React, { useState } from 'react';

import { Button, Tooltip, GoBackLink } from '@features/common';
import { PrismicRichTextWrapper } from '@features/prismic';
import { ReactComponent as ArrowIcon } from '@public/icons/custom/chevron-down.svg';
import { ReactComponent as RatingStar } from '@public/icons/star-gradient.svg';
import { ReactComponent as VerifiedIcon } from '@public/icons/verified.svg';
import { GameDocument, GameReviewsSlice } from 'lib/types/prismic/documents';

import GameWaitlistButton from '../../components/GameWaitlistButton';
import { useCurrentGameContext } from '../../contexts/currentGame';
import { getPrismicSlice, getGameRatings } from '../../utils';
import { GameLinks } from '../GameLinks';

export function GameHeader({ children }: React.PropsWithChildren): JSX.Element {
  return <header className="min-h-65">{children}</header>;
}

GameHeader.Logo = function GameHeaderLogo(): JSX.Element | null {
  const {
    data: { logo, isVerified },
  } = useCurrentGameContext();

  if (isEmpty(logo)) {
    return null;
  }

  return (
    <div className="relative flex w-10 h-10 shrink-0">
      <PrismicNextImage className="rounded-lg" field={logo} alt="" />
      {isVerified && (
        <Tooltip tooltipContent={<div className="text-xs">Verified by Playdex</div>}>
          <div>
            <VerifiedIcon className="absolute w-4 h-4 -top-2 -right-2" />
          </div>
        </Tooltip>
      )}
    </div>
  );
};

GameHeader.Title = function GameHeaderTitle(): JSX.Element {
  const {
    data: { name },
  } = useCurrentGameContext();

  return <h1 className="font-medium text-2xl">{name}</h1>;
};

GameHeader.Back = function GameHeaderNavigation(): JSX.Element {
  return <GoBackLink href="/games" label="Game discovery" />;
};

GameHeader.Rating = function GameHeaderRating(): JSX.Element | null {
  const game = useCurrentGameContext();
  const gameReviews = getPrismicSlice<GameDocument, GameReviewsSlice>(game, 'game_review')?.items;

  const { ratingAvg, ratersCount } = getGameRatings(gameReviews || []);

  return ratersCount > 0 ? (
    <div className="flex items-center gap-1">
      <RatingStar className="w-6 h-6" />
      <span className="text-labelPrimary font-medium">{ratingAvg}</span>
      <span className="text-labelSecondary text-sm">({ratersCount})</span>
    </div>
  ) : null;
};

GameHeader.Description = function GameHeaderDescription(): JSX.Element {
  const {
    data: { description },
  } = useCurrentGameContext();
  const [isBriefDescriptionOpen, setBriefDescriptionOpen] = useState(false);

  return (
    <>
      <PrismicRichTextWrapper
        components={{
          paragraph: ({ children }) => (
            <p className={cn('text-labelSecondary text-sm ', !isBriefDescriptionOpen && '!truncate')}>{children}</p>
          ),
        }}
        field={description}
      />

      <Button
        className={cn('!px-0 whitespace-nowrap text-labelSecondary hover:text-labelPrimary transition !gap-0')}
        size="xs"
        variant="transparent"
        onClick={() => setBriefDescriptionOpen(isOpen => !isOpen)}
      >
        {isBriefDescriptionOpen ? 'See less' : 'See more'}
        <ArrowIcon className={cn('w-6 h-6', isBriefDescriptionOpen ? 'rotate-180' : 'rotate-0')} />
      </Button>
    </>
  );
};

GameHeader.Links = function GameHeaderLinks({ className }: { className?: string }): JSX.Element | null {
  const {
    data: { links },
  } = useCurrentGameContext();
  const gameLinks = (links?.filter(({ url }) => url) as GameDocument['data']['links']) || [];

  return gameLinks.length > 0 ? <GameLinks className={cn('items-center', className)} gameLinks={gameLinks} /> : null;
};

const usePlayNowLink = (): string | undefined => {
  const {
    data: { playNowHref },
  } = useCurrentGameContext();

  return Object(playNowHref).hasOwnProperty('url') && playNowHref.url;
};

GameHeader.PlayNowAction = function GameHeaderActions({ className }: { className?: string }): JSX.Element | null {
  const playNowLink = usePlayNowLink();

  return playNowLink ? (
    <Button
      className={cn('px-11', 'whitespace-nowrap', className)}
      href={playNowLink}
      target="_blank"
      rel="noopener noreferrer"
    >
      Play Now
    </Button>
  ) : null;
};

GameHeader.WaitlistAction = function GameHeaderActions(): JSX.Element | null {
  return <GameWaitlistButton variant={usePlayNowLink() ? 'secondary' : 'primary'} />;
};
