import { useQuery } from '@apollo/client';

import {
  LeaderboardRewardClaimersDocument,
  LeaderboardRewardClaimersQuery,
  LeaderboardRewardClaimersQueryVariables,
} from '@graphql/generated';

import { LeaderboardRewardClaimer } from '../types';

export const useLeaderboardRewardClaimersQuery = (leaderboardId?: string) => {
  const { data, ...query } = useQuery<LeaderboardRewardClaimersQuery, LeaderboardRewardClaimersQueryVariables>(
    LeaderboardRewardClaimersDocument,
    {
      variables: {
        input: {
          id: leaderboardId as string,
        },
      },
      skip: !leaderboardId,
    },
  );

  return { data: data?.leaderboardRewardClaimers?.rewardClaimers as LeaderboardRewardClaimer[] | undefined, ...query };
};
