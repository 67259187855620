import { Disclosure, Transition } from '@headlessui/react';
import classNames from 'classnames';

import { ReactComponent as IconChevronDown } from '@public/icons/custom/chevron-down.svg';

type TriggerIconAlignment = 'start' | 'end';

type DisclosurePanelProps = {
  trigger: React.ReactNode;
  children: React.ReactNode;
  defaultOpen?: boolean;
  className?: string;
  triggerClassName?: string;
  panelClassName?: string;
  withAnimation?: boolean;
  triggerIconAlignment?: TriggerIconAlignment | null;
};

const TriggerIcon = ({ open }: { open: boolean }): JSX.Element => {
  return (
    <div className={classNames('h-6 w-6 text-labelTertiary', open && 'rotate-180 transform')}>
      <IconChevronDown />
    </div>
  );
};

export const DisclosurePanel = ({
  trigger,
  children,
  defaultOpen,
  className,
  triggerClassName,
  withAnimation = true,
  panelClassName,
  triggerIconAlignment = 'end',
}: DisclosurePanelProps): JSX.Element => {
  return (
    <Disclosure defaultOpen={defaultOpen} as="div" className={className}>
      {({ open }) => (
        <>
          <Disclosure.Button
            className={classNames(
              'flex w-full rounded p-2',
              'text-left hover:bg-bgTertiary',
              triggerIconAlignment === 'end' && 'flex-row-reverse justify-between',
              triggerClassName,
            )}
          >
            <TriggerIcon open={open} />
            {trigger}
          </Disclosure.Button>
          {withAnimation ? (
            <Transition
              show={open}
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
            >
              <Disclosure.Panel className={classNames('p-2', panelClassName)} static>
                {children}
              </Disclosure.Panel>
            </Transition>
          ) : (
            open && (
              <Disclosure.Panel className={classNames('p-2', panelClassName)} static>
                {children}
              </Disclosure.Panel>
            )
          )}
        </>
      )}
    </Disclosure>
  );
};
