/* eslint-disable functional/immutable-data */
/* eslint-disable @typescript-eslint/no-var-requires */
const colors = require('tailwindcss/colors');
const defaultTheme = require('tailwindcss/defaultTheme');

// fix tailwind build issue https://github.com/tailwindlabs/tailwindcss/issues/4690
delete colors['lightBlue'];
delete colors['warmGray'];
delete colors['trueGray'];
delete colors['coolGray'];
delete colors['blueGray'];

/**
 * @link https://tailwindcss.com/blog/tailwindcss-v3-1#first-party-type-script-types
 * @type {import('tailwindcss').Config}
 */
// eslint-disable-next-line functional/immutable-data
module.exports = {
  mode: 'jit',
  content: [
    './features/**/*.{js,ts,jsx,tsx}',
    './pages/**/*.{js,ts,jsx,tsx}',
    './components/**/*.{js,ts,jsx,tsx}',
    './slices/**/*.{js,ts,jsx,tsx}',
    './widgets/**/*.{js,ts,jsx,tsx}',
  ],
  safelist: [
    'text-red-400',
    'text-red-300',
    'text-red-200',
    'text-green-200',
    'text-green-300',
    'text-green-400',
    'border-none',
    'border-gray',
    'border-green-500',
    'border-emerald-700',
    'border-red-400',
    'border-red-600',
    'bg-green-500',
    'bg-red-400',
    'bg-yellow-500',
    'text-emerald-50',
    'border-teal-1 bg-teal-1 text-teal-1 text-teal-3 bg-teal-3',
    'border-red-1 bg-red-1 text-red-1 text-red-3 bg-red-3',
    'w-1/3',
    'w-1/2',
  ],
  darkMode: 'class', // or 'media' or 'class'
  theme: {
    screens: {
      xs: '480px',
      ...defaultTheme.screens,
    },
    fontFamily: {
      sans: ['Inter', ...defaultTheme.fontFamily.sans],
      mono: ['Space Mono', ...defaultTheme.fontFamily.mono],
      cce: ['GTWalsheimPro', ...defaultTheme.fontFamily.serif],
    },
    extend: {
      lineClamp: {
        2: '2',
        7: '7',
        8: '8',
        9: '9',
        10: '10',
      },
      screens: {
        '3xl': '1792px',
      },
      fontFamily: {
        sans: ['Inter', ...defaultTheme.fontFamily.sans],
        mono: ['Space Mono', ...defaultTheme.fontFamily.mono],
        walsheim: ['GTWalsheimPro', ...defaultTheme.fontFamily.serif],
      },
      colors: {
        ...colors,

        // Elements
        body: '#f9f9fa',
        bgPrimary: '#181922',
        bgSecondary: '#222330',
        bgTertiary: '#282939',
        bgStripe: '#2A2C3C',
        bgStripePrimary: '#282A39',
        bgStripeSecondary: '#333548',
        bgStripeHover: '#787CA0',
        bgActiveStripe: '#393B51',
        bgSecondaryOverlay: '#20212D',

        // Brands
        metamask: '#F6851B',

        // Components
        cceLandingBg: '#0D0D12',

        fieldBg: '#13141B',
        fieldBgDisabled: '#20212D',
        fieldBorder: '#2C2E3F',
        fieldBorderFocused: '#7373E1',
        headerDropdown: '#181922C7',
        modalWindowBgPrimary: '#181922',
        modalWindowBgSecondary: '#1B1D27',
        modalWindowOverlayBg: '#12131799',
        dropdownMenuBg: '#222330',
        dropdownBorder: '#2A2C3C',
        dropdownHoveredItem: '#262736',
        dropdownButtonSecondary: '#262736',

        dashboardSecondaryBg: '#1F1F2C',

        buttonSecondaryBg: '#2e3042',
        buttonSecondaryBgHover: '#34364b',
        buttonSecondaryBorder: '#36394E',

        buttonPrimaryBg: '#5656e7',
        buttonPrimaryBgHover: '#4b4be2',

        // Expanded Colors
        gray: colors.neutral,
        yellow: {
          400: '#FFB801',
        },
        teal: {
          1: '#65AE9C',
          3: '#DDF5EF',
          200: '#65AE9C',
        },
        red: {
          1: '#FF5722',
          2: '#FF3E01',
          3: '#FFEBE4',
        },
        earlGrey: {
          150: '#2c2c2c',
          200: '#272727',
          400: '#1A1A1A',
          500: '#1E1E1E',
          600: '#141414',
          700: '#151515',
        },
        error: '#E23D5A',
        neutral: {
          950: '#0d0d0d',
        },

        // Controls
        controlPrimary: '#5656E7',
        controlPrimaryHover: '#4B4BE2',
        controlPrimaryTextActive: '#C3C3F8',
        controlPrimaryTextDisabled: '#9A9AF4',
        controlSecondary: '#2E3042',
        controlSecondaryHover: '#34364B',
        controlSecondaryTextActive: '#ADADB8',
        controlSecondaryTextDisabled: '#535360',
        controlSuccess: '#33FFB6',
        controlSuccessDark: '#005B4B',
        controlSuccessDarker: '#1D443E',
        controlContainerOnBackgroundSecondary: '#2E2F42',

        controlDefaultBg: '#1e1e2a',
        controlDefaultBorder: '#2A2C3C',

        outlineActive: '#D6D6FA',
        outlineDisabled: '#777788',

        // Text
        labelPrimary: '#E0E1EB',
        labelSecondary: '#989DB3',
        labelTertiary: '#616784',
        labelPlaceholder: '#575C75',
        labelSuccess: '#33FFB6',

        brandWhite: '#EAEAF0',

        link: '#849FFF',
        linkHover: '#4E5C91',
        warning: '#f2c94c',
        warningDark: '#383433',
        success: '#33FFB6',
        successDark: '#005B4B',
        successDarker: '#1D443E',
        successDarkest: '#1D3A3D',

        iconPrimary: '#616784',
        iconTertiary: '#616784',
        iconDisabled: '#4c4c67',
        iconBlue: '#849FFF',
        iconRedSecondary: '#E96C83',
        iconGreen: '#33FFB6',

        selectBgHover: '#282939',
        selectBg: '#222330',
        selectTypo: '#e0e1eb',
        selectBorder: '#2c2e3f',
        baseShadow: 'rgba(13,13,18,0.65)',

        lightPink: '#E0AEF7',

        radioCheckboxBorder: '#4a4d69',
        radioCheckboxBg: '#303245',
        radioCheckboxBgHover: '#2A2B3C',
        radioCheckboxBgDisabled: '#313345',
        radioCheckboxCircleOff: '#C5C6D3',
        radioCheckboxCircleOffDisabled: '#5C5E70',
        radioCheckboxCircleOn: '#D2D2F9',
        radioCheckboxCircleOnDisabled: '#8686EF',
        radioCheckboxBgSelected: '#5656E7',
        radioCheckboxBgSelectedHover: '#4B4BE2',
        radioCheckboxBgSelectedDisabled: '#5656E7',
      },
      backgroundImage: {
        'gradient-radial': 'radial-gradient(var(--tw-gradient-stops))',
      },
      fontSize: {
        xxxs: '9px',
        xxs: '0.625rem',
        sm: '0.875rem',
        5: '1.25rem', // 20px
        6: '1.5rem', // 24px
        7: '1.75rem', // 28px
        8: '2rem', // 32px
        9: '2.25rem', // 36px
        10: '2.5rem', // 40px
      },
      opacity: {
        80: '0.80',
      },
      borderWidth: {
        1: '1px',
      },
      borderRadius: {
        1: '4px',
        2: '8px',
        2.5: '10px',
        3: '12px',
        6: '24px',
        xl: '32px',
      },
      borderColor: {
        disabled: 'hsla(0, 0%, 100%, 0.2)',
      },
      boxShadow: {
        xs: '0 0 0 1px rgba(0, 0, 0, 0.05)',
        outline: '0 0 0 2px #D6D6FA',
        buttonSecondaryBorder: '0 0 0 1px #36394E',
        fieldBorder: '0 0 0 1px #2C2E3F',
        fieldBorderFocused: '0 0 0 1px #7373E1',
        fieldBorderError: '0 0 0 1px #E23D5A',
        blueShiny: '0px 4px 34px #005d91',
        'sm-blueShiny': '0px 4px 30px #005d91',
      },
      animation: {
        float: 'float 3s ease-in-out infinite',
        bluring: 'bluring 3s ease-in-out infinite',
      },
      outline: {
        blue: '2px solid rgba(0, 51, 230, 1)',
      },
      padding: {
        1.5: '6px',
        2.5: '0.625rem', // 10px
        4.5: '1.125rem', // 18px
        7.5: '30px',
        18: '4.5rem', // 72px
      },
      spacing: {
        4.5: '1.125rem',
        5.5: '1.375rem',
        7.5: '1.875rem',
        14: '56px',
        26: '6.5rem',
        50: '12.5rem',
        220: '55rem',
      },
      minHeight: {
        14: '56px',
        32: '128px',
        40: '160px',
        55: '220px',
        64: '256px',
        65: '260px',
      },
      maxHeight: {
        115: '460px',
        125: '500px',
      },
      minWidth: {
        6: '24px',
        15: '60px',
        25: '100px',
        30: '120px',
        37.5: '150px',
        55: '220px',
        72: '288px',
        75: '300px',
        80: '320px',
        87.5: '350px',
      },
      maxWidth: {
        62: '248px',
        80: '320px',
        87.5: '350px',
        95: '380px',
        100: '400px',
        172: '688px',
        220: '880px',
        360: '1440px',
      },
      height: {
        15: '60px',
        18: '72px',
        22: '88px',
        35: '140px',
        55: '220px',
        65: '260px',
      },
      width: {
        15: '60px',
        18: '72px',
        30: '120px',
        32.5: '130px',
        55: '220px',
        68: '272px',
        75: '300px',
        86: '344px',
        87.5: '350px',
        100: '400px',
        112.5: '450px',
      },
    },
  },
  future: {
    hoverOnlyWhenSupported: true,
  },
  plugins: [
    require('@tailwindcss/forms'),
    require('@tailwindcss/typography'),
    require('tailwindcss-dir')(),
    require('@tailwindcss/line-clamp'),
  ],
};
