import cn from 'classnames';

import withSafeHydration from '@features/common/hocs/withSafeHydration';

import { PulsingLiveStatus } from './TournamentStatusIndicator';
import { useCountdown } from '../hooks';

type Props = {
  tournamentStartDate: string;
  tournamentFinishDate?: string;
};

const formatCountdown = (countdown?: number): string => {
  return countdown ? String(Math.round(countdown)).padStart(2, '0') : '00';
};

const CountdownUnit = ({ value, label }: { value?: string; label: string }): JSX.Element => {
  return (
    <div className="flex flex-col items-center">
      <div
        className={cn(
          'rounded p-2 text-2xl font-medium border border-bgStripe w-12 text-center',
          value?.length === 2 ? 'w-12' : 'w-16',
        )}
      >
        {value}
      </div>
      <div className="text-labelSecondary text-xs">{label}</div>
    </div>
  );
};

const Title = ({ isLive, isFinished }: { isLive: boolean; isFinished: boolean }): JSX.Element => {
  if (isLive) {
    return (
      <div className="flex items-center uppercase text-labelSecondary text-xs">
        <PulsingLiveStatus className="mr-1" /> Live
      </div>
    );
  }

  if (isFinished) {
    return <div className="uppercase text-labelSecondary text-xs">Finished</div>;
  }

  return <div className="uppercase text-labelSecondary text-xs">Starting in</div>;
};

export const CountdownSection = withSafeHydration(
  ({ tournamentStartDate, tournamentFinishDate }: Props): JSX.Element => {
    const { countdownObject: startCountdownObject, countdown: startCountdown } = useCountdown(tournamentStartDate, {
      withSeconds: true,
    });
    const { countdownObject: finishCountdownObject } = useCountdown(tournamentFinishDate, {
      withSeconds: true,
    });
    const isFinished = new Date(tournamentFinishDate || '').getTime() < Date.now();

    const isLive = !isFinished && startCountdown <= 0;

    const countdown = isLive ? finishCountdownObject : startCountdownObject;

    return (
      <section className="mt-4 mb-6">
        <Title isLive={isLive} isFinished={isFinished} />
        <div className="flex items-center gap-2 my-2">
          <CountdownUnit value={formatCountdown(countdown?.days)} label="days" />
          <div className="w-[1px] h-2 -mt-4 bg-bgStripe" />
          <CountdownUnit value={formatCountdown(countdown?.hours)} label="hours" />
          <div className="w-[1px] h-2 -mt-4 bg-bgStripe" />
          <CountdownUnit value={formatCountdown(countdown?.minutes)} label="minutes" />
          <div className="w-[1px] h-2 -mt-4 bg-bgStripe" />
          <CountdownUnit value={formatCountdown(countdown?.seconds)} label="seconds" />
        </div>
      </section>
    );
  },
);
