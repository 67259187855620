import { useContractRead } from 'wagmi';

import { HexString } from '@globalTypes/contracts/metamask';
import { socialEventRewardsABI } from 'typings/abis';

export const useSocialEventRewardContractRead = ({
  tournamentId,
  leaderboardId,
  contractAddress,
  chainId,
  rewardHash,
}: {
  tournamentId: string;
  leaderboardId: string;
  contractAddress: HexString;
  chainId: number;
  rewardHash?: string;
}) => {
  const getLeaderboardEventResult = useContractRead({
    address: contractAddress,
    chainId,
    abi: socialEventRewardsABI,
    functionName: 'getEvent',
    args: [leaderboardId],
  });

  const doesLeaderboardEventHasDeposit = Boolean(getLeaderboardEventResult.data?.state);

  const getTournamentEventResult = useContractRead({
    address: contractAddress,
    chainId,
    abi: socialEventRewardsABI,
    functionName: 'getEvent',
    args: [tournamentId],
    enabled: !(getLeaderboardEventResult.isLoading || doesLeaderboardEventHasDeposit),
  });

  const eventId = (() => {
    if (getLeaderboardEventResult.data) {
      return leaderboardId;
    } else if (getTournamentEventResult.data) {
      return tournamentId;
    }

    return undefined;
  })();

  const checkIsRewardClaimedResult = useContractRead({
    address: contractAddress,
    chainId: chainId,
    abi: socialEventRewardsABI,
    functionName: 'checkIsRewardClaimed',
    args: [eventId, rewardHash] as [string, HexString],
    enabled: Boolean(eventId) && Boolean(rewardHash),
  });

  const isClaimed = checkIsRewardClaimedResult.data;

  return {
    eventId,
    isClaimed,
    eventRewardOnchainData: doesLeaderboardEventHasDeposit
      ? getLeaderboardEventResult.data
      : getTournamentEventResult.data,
  };
};
