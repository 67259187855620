/* eslint-disable @typescript-eslint/explicit-function-return-type,@typescript-eslint/explicit-module-boundary-types */
import { AuthAmplitudeBasePayload, AuthAmplitudeOnErrorPayload } from '@features/auth';
import { AMPLITUDE_EVENTS, GameEventPayload, TournamentEventPayload, logAmplitudeEvent } from '@features/common';

// COMMON
export const logOpenedGamePage = (data: GameEventPayload) => logAmplitudeEvent(AMPLITUDE_EVENTS.OPENED_GAME_PAGE, data);

// AUTH FLOW
export const logAuthStarted = (data: AuthAmplitudeBasePayload) =>
  logAmplitudeEvent(AMPLITUDE_EVENTS.AUTH_STARTED, data);

export const logAuthSuccess = (data: AuthAmplitudeBasePayload) =>
  logAmplitudeEvent(AMPLITUDE_EVENTS.AUTH_SUCCESS, data);

export const logAuthFailed = (data: AuthAmplitudeOnErrorPayload) =>
  logAmplitudeEvent(AMPLITUDE_EVENTS.AUTH_FAILED, data);

// WAITLIST FLOW
export const logWaitlistStarted = (data: GameEventPayload) =>
  logAmplitudeEvent(AMPLITUDE_EVENTS.WAITLIST_STARTED, data);

export const logWaitlistSuccess = (data: GameEventPayload) =>
  logAmplitudeEvent(AMPLITUDE_EVENTS.WAITLIST_SUCCESS, data);

export const logWaitlistFail = (data: GameEventPayload) => logAmplitudeEvent(AMPLITUDE_EVENTS.WAITLIST_FAIL, data);

// TOURNAMENT
export const logTournamentShare = (data: TournamentEventPayload) =>
  logAmplitudeEvent(AMPLITUDE_EVENTS.TOURNAMENT_SHARE, data);
