import { ReactComponent as FacebookIcon } from '@public/icons/brands/facebook.svg';
import { ReactComponent as TelegramIcon } from '@public/icons/brands/telegram.svg';
import { ReactComponent as TwitterIcon } from '@public/icons/brands/twitter.svg';
import { ReactComponent as LinkIcon } from '@public/icons/link.svg';
import { copyToBufferAndNotify } from '@utils/helpers';

export const createShareActions = (
  currentUrl: string,
  shareText: string,
): Record<
  string,
  {
    title: string;
    icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
    onClick?: () => void;
    href?: string;
  }
> => {
  const objectToUrlParams = (object: Record<string, string>): string => new URLSearchParams(object).toString();
  const shareTextWithUrl = `${shareText}\n\n${currentUrl}`;

  return {
    link: {
      title: 'Copy link',
      icon: LinkIcon,
      onClick: () => {
        copyToBufferAndNotify(currentUrl);
      },
    },
    facebook: {
      title: 'Facebook',
      icon: FacebookIcon,
      href: `https://www.facebook.com/sharer/sharer.php?${objectToUrlParams({
        u: currentUrl,
        quote: shareTextWithUrl.replace('%playdex%', '@playdex'),
      })}`,
    },
    twitter: {
      title: 'Twitter',
      icon: TwitterIcon,
      href: `https://twitter.com/intent/tweet?${objectToUrlParams({
        text: shareTextWithUrl.replace('%playdex%', '@playdex'),
      })}`,
    },
    telegram: {
      title: 'Telegram',
      icon: TelegramIcon,
      href: `https://t.me/share/url?${objectToUrlParams({
        url: currentUrl,
        text: shareText.replace('%playdex%', '@playdexcommunity'),
      })}`,
    },
  };
};
