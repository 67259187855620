import { Transition } from '@headlessui/react';
import { PrismicLink } from '@prismicio/react';
import classNames from 'classnames';
import cn from 'classnames';
import React, { useRef } from 'react';
import { match, P } from 'ts-pattern';

import { useUrlBuilder } from '@entities/urlBuilder';
import {
  fastFadeTransitionClassNames,
  GradientSpinner,
  ImageWithFallback,
  Input,
  NullComponent,
  TournamentItem,
  useClickOutsideElement,
  useOnKeyboardKeyPress,
} from '@features/common';
import { SearchBarGame, useGamesAndTournamentsSearch } from '@features/header';
import { useBoolean } from '@hooks/useBoolean';
import { ReactComponent as CloseIcon } from '@public/icons/custom/close.svg';
import { ReactComponent as HashIcon } from '@public/icons/custom/hash.svg';
import { ReactComponent as SearchIcon } from '@public/icons/custom/search.svg';
import { ReactComponent as NoItemsIcon } from '@public/landing/empty_state_search.svg';

const GameLinkItem = ({ game, onClick }: { game: SearchBarGame; onClick?: () => void }): JSX.Element => {
  const urlBuilder = useUrlBuilder();

  return (
    <PrismicLink
      href={game.data.redirectLocation || urlBuilder.buildGameUrl({ gameId: game.uid })}
      key={game.id}
      onClick={onClick}
      className={cn(
        'flex items-center py-2.5 px-2 md:px-3',
        'rounded-lg  cursor-pointer text-labelPrimary hover:bg-bgSecondary',
        'transition-all duration-150',
      )}
    >
      <ImageWithFallback
        alt=""
        src={game.data.logo.url ?? 'invalid'}
        className={classNames('w-6 h-6 bg-cover rounded')}
        fallbackSrc="/icons/game-logo.svg"
      />

      <span className="pl-3 flex-1 pr-1 text-wrap-1 text-sm">{game.data.name}</span>
    </PrismicLink>
  );
};

const HOTKEY_VALUE = '/';

export const GamesAndTournamentsSearchBar = (): JSX.Element => {
  const [isSearchDashboardOpen, openSearchDashboard, closeSearchDashboard] = useBoolean(false);
  const containerRef = useClickOutsideElement<HTMLDivElement>(closeSearchDashboard);
  const inputRef = useRef<HTMLInputElement>(null);

  const { filterGamesAndTournamentsByValue, filteredTournaments, filteredGames, isLoading } =
    useGamesAndTournamentsSearch();

  useOnKeyboardKeyPress(
    HOTKEY_VALUE,
    () => {
      openSearchDashboard();
      inputRef.current?.focus();
    },
    'keyup',
  );

  return (
    <div ref={containerRef} className={cn('relative')}>
      <header>
        <div
          className="md:hidden p-1.5 rounded-md cursor-pointer hover:bg-dropdownButtonSecondary"
          onClick={openSearchDashboard}
        >
          <SearchIcon className="w-6 h-6 text-labelTertiary" />
        </div>

        <div className={cn('hidden md:block', isSearchDashboardOpen ? 'w-100' : 'w-[256px]')}>
          <Input
            id="main-id"
            ref={inputRef}
            placeholder="Games, tournaments"
            onChange={event => filterGamesAndTournamentsByValue(event.target.value)}
            onFocus={openSearchDashboard}
            className="w-full"
            renderInputElement={defaultProps => (
              <div className="flex w-full gap-2 py-1.5 px-2">
                <SearchIcon className="w-5 h-5 text-labelTertiary" />

                <input {...defaultProps} className={classNames(defaultProps.className, 'h-5 flex-1 !p-0')} />

                <HashIcon className="w-5 h-5 text-labelTertiary hidden md:block" />
              </div>
            )}
          />
        </div>
      </header>
      <main>
        {isSearchDashboardOpen && (
          <div
            className={classNames('fixed inset-0 md:inset-auto md:absolute md:top-10 md:right-0 min-w-full z-[1000]')}
          >
            <Transition show={true} appear={true} {...fastFadeTransitionClassNames}>
              <section
                className={classNames(
                  'bg-dashboardSecondaryBg md:border-bgStripe md:border-1 md:rounded-2 md:p-2',
                  'min-w-full h-screen md:h-full',
                )}
              >
                <header
                  className={cn(
                    'flex bg-bgPrimary md:hidden items-center gap-4 w-full px-3 pt-5.5 pb-2 border-b-1 border-bgStripe',
                  )}
                >
                  <Input
                    placeholder="Games, tournaments"
                    onChange={event => filterGamesAndTournamentsByValue(event.target.value)}
                    onFocus={openSearchDashboard}
                    className="flex-1"
                    size="md"
                    renderInputElement={defaultProps => (
                      <div className="flex flex-1 w-full items-center gap-2 py-2 px-3">
                        <SearchIcon className="w-5 h-5 text-labelTertiary" />

                        <input
                          {...defaultProps}
                          className={classNames(defaultProps.className, 'h-6 flex-1 text-base !p-0')}
                        />

                        <HashIcon className="w-5 h-5 text-labelTertiary hidden md:block" />
                      </div>
                    )}
                  />

                  <div
                    className="p-1.5 rounded-md cursor-pointer hover:bg-dropdownButtonSecondary"
                    onClick={closeSearchDashboard}
                  >
                    <CloseIcon className="text-labelSecondary w-6 h-6" />
                  </div>
                </header>
                <main className="p-4 md:p-0">
                  {match([isLoading, Number(filteredGames?.length), Number(filteredTournaments?.length)])
                    .with([true, P.number, P.number], () => (
                      <div className="w-full flex justify-center">
                        <GradientSpinner />
                      </div>
                    ))
                    .with([false, 0, 0], () => (
                      <div className="px-10 py-4 flex flex-col items-center justify-center">
                        <NoItemsIcon className="w-[140px]" />
                        <div className="flex flex-col items-center gap-2">
                          <span className="text-labelPrimary text-sm text-center">
                            We couldn’t find what you were looking for. Try to change your request
                          </span>
                        </div>
                      </div>
                    ))
                    .with([false, P.number, P.number], () => (
                      <div className="flex flex-col gap-2 md:gap-0">
                        <div className={classNames(filteredTournaments?.length === 0 && 'hidden')}>
                          <div className="items-center flex">
                            <span className="text-labelSecondary text-xs leading-4 font-medium flex-1 px-2 py-1">
                              Tournaments
                            </span>
                          </div>

                          {filteredTournaments?.map(tournament => (
                            <TournamentItem
                              tournament={tournament}
                              key={tournament.id}
                              onClick={closeSearchDashboard}
                            />
                          ))}
                        </div>
                        <div className={classNames(filteredGames?.length === 0 && 'hidden')}>
                          <div className="items-center flex">
                            <span className="text-labelSecondary text-xs leading-4 font-medium flex-1 px-2 py-1">
                              Games
                            </span>
                          </div>

                          {filteredGames?.map(game => (
                            <GameLinkItem game={game} key={game.id} onClick={closeSearchDashboard} />
                          ))}
                        </div>
                      </div>
                    ))
                    .otherwise(NullComponent)}
                </main>
              </section>
            </Transition>
          </div>
        )}
      </main>
    </div>
  );
};
