import cn from 'classnames';
import { Fragment, useRef } from 'react';
import { match } from 'ts-pattern';

import { getErc20TotalAmounts } from '@features/tournaments/utils';
import { TournamentFragmentFragment as TournamentFragment } from '@graphql/generated';
import { ReactComponent as ParticipantsIcon } from '@public/icons/participants.svg';
import { ReactComponent as PlusIcon } from '@public/icons/plus.svg';
import { ReactComponent as PrizeIcon } from '@public/icons/prize.svg';
import { ReactComponent as RocketIcon } from '@public/icons/rocket.svg';

import { InviteButton } from './components';
import { ReactComponent as MedalBronzeIcon } from './icons/medal-bronze.svg';
import { ReactComponent as MedalGoldIcon } from './icons/medal-gold.svg';
import { ReactComponent as MedalIronIcon } from './icons/medal-iron.svg';
import { ReactComponent as MedalPlatinumIcon } from './icons/medal-platinum.svg';
import { ReactComponent as MedalSilverIcon } from './icons/medal-silver.svg';
import { LeaderboardRewardStructure } from '../../types';

type Props = {
  className?: string;
  tournament: TournamentFragment;
  rewardStructure: LeaderboardRewardStructure;
  participantCount: number;
  RewardClaimSection: ({ className }: { className?: string }) => JSX.Element;
};

const ProgressBar = ({
  className,
  currentCount,
  totalCount,
  showCount,
}: {
  className?: string;
  currentCount: number;
  totalCount: number;
  showCount: boolean;
}): JSX.Element => {
  const progressPercentage = (currentCount / totalCount) * 100;

  return (
    <div className={cn('flex h-9 flex-col items-center', className)}>
      <div className="text-xxs">
        {showCount ? (
          <>
            {currentCount} / {totalCount}
          </>
        ) : (
          <>&nbsp;</>
        )}
      </div>
      <div className="w-full h-1 flex bg-[#29293D] rounded-[19px]">
        <div className="h-1 flex bg-[#7070F9] rounded-[19px]" style={{ width: `${progressPercentage}%` }}></div>
      </div>
    </div>
  );
};

const TOKEN_LINE_HEIGHT = 24;

export const RewardMilestones = ({
  className,
  tournament,
  rewardStructure,
  participantCount,
  RewardClaimSection,
}: Props): JSX.Element | null => {
  const milestonesContainerRef = useRef<HTMLDivElement>(null);

  if (!rewardStructure.participantMilestones) {
    return null;
  }

  const milestones = rewardStructure.participantMilestones;
  const milestoneMedalIcons = match(milestones.length)
    .with(2, () => [MedalSilverIcon, MedalGoldIcon])
    .with(3, () => [MedalBronzeIcon, MedalSilverIcon, MedalGoldIcon])
    .with(4, () => [MedalBronzeIcon, MedalSilverIcon, MedalGoldIcon, MedalPlatinumIcon])
    .with(5, () => [MedalIronIcon, MedalBronzeIcon, MedalSilverIcon, MedalGoldIcon, MedalPlatinumIcon])
    .otherwise(() => []);

  const currentMilestone = milestones.reduce((currentMilestone, milestone) =>
    participantCount >= milestone.minParticipantCount ? milestone : currentMilestone,
  );
  const lastMilestone = milestones[milestones.length - 1];

  const currentPrizePool = currentMilestone ? getErc20TotalAmounts(currentMilestone.rewards) : null;
  const maxPrizePool = lastMilestone ? getErc20TotalAmounts(lastMilestone.rewards) : null;
  const milestoneLabelLinesCount = 1 + Object.keys(rewardStructure.erc20Tokens).length;

  return (
    <div className={cn('p-4 border-1 border-bgStripe rounded-3 bg-[#1F1F2E]', className)}>
      <div className="flex gap-2 flex-col md:items-center md:flex-row text-lg font-medium">
        <RocketIcon className="w-6 h-6 text-iconBlue" />
        Invite more friends to&nbsp;increase the prize pool
      </div>
      <div className="mt-2 p-4 rounded-3 border-1 border-[#2D2D43] bg-[#232334]">
        <div className="md:flex gap-4 justify-between">
          <div className="flex gap-2 xs:gap-8 flex-col xs:flex-row">
            <div>
              <div className="text-xs mb-1">Participants</div>
              <div className="font-medium text-xl md:text-2xl">{participantCount}</div>
            </div>

            <div className="flex gap-8">
              <div>
                <div className="text-xs mb-1">Current Prize Pool</div>
                <div className="md:flex items-center gap-2">
                  {currentPrizePool &&
                    Object.entries(currentPrizePool).map(([currency, reward], i, { length }) => {
                      const isLast = i === length - 1;

                      return (
                        <Fragment key={i}>
                          <div className="flex items-center gap-1">
                            <div className="font-medium text-xl md:text-2xl">{reward}</div>
                            <div className="text-sm text-labelSecondary">${currency}</div>
                          </div>
                          {!isLast && <PlusIcon className="hidden md:block w-4 h-4 text-labelSecondary" />}
                        </Fragment>
                      );
                    })}
                </div>
              </div>

              <div>
                <div className="text-xs mb-1">Max Prize Pool</div>
                <div className="md:flex items-center gap-2">
                  {maxPrizePool &&
                    Object.entries(maxPrizePool).map(([currency, reward], i, { length }) => {
                      const isLast = i === length - 1;

                      return (
                        <Fragment key={i}>
                          <div className="flex items-center gap-1">
                            <div className="font-medium text-xl md:text-2xl">{reward}</div>
                            <div className="text-sm text-labelSecondary">${currency}</div>
                          </div>
                          {!isLast && <PlusIcon className="hidden md:block w-4 h-4 text-labelSecondary" />}
                        </Fragment>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>

          <div className="mt-4">
            <InviteButton tournament={tournament} />
          </div>
        </div>

        <div className="w-full my-4 h-[1px] bg-[#2D2D43]" />
        <div className="w-full overflow-auto" ref={milestonesContainerRef}>
          <div
            className="flex gap-1"
            style={{
              height: 36 + milestoneLabelLinesCount * TOKEN_LINE_HEIGHT,
            }}
          >
            {milestones.map((milestone, i) => {
              const isFirst = i === 0;
              const isLast = i === milestones.length - 1;
              const MedalIcon = milestoneMedalIcons[i];

              const isReached = isFirst || participantCount >= milestone.minParticipantCount;
              const nextMilestoneThreshold = isLast ? 0 : milestones[i + 1].minParticipantCount;
              const erc20Amounts = getErc20TotalAmounts(milestone.rewards);

              return (
                <Fragment key={i}>
                  <div className={cn('relative', !isReached && 'text-labelSecondary')}>
                    <MedalIcon className={cn('w-8 h-8', !isReached && 'opacity-50')} />
                    <div
                      className={cn(
                        'absolute mt-1',
                        milestones.length === 2 && isLast && 'flex flex-col items-end text-right right-0',
                      )}
                    >
                      <div className="flex gap-1">
                        {isFirst && <PrizeIcon className="w-4 h-4" style={{ height: TOKEN_LINE_HEIGHT }} />}
                        <div>
                          {Object.entries(erc20Amounts).map(([symbol, amount], index) => (
                            <div className="text-sm" style={{ lineHeight: `${TOKEN_LINE_HEIGHT}px` }} key={index}>
                              {amount}&nbsp;${symbol}
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="flex items-center gap-1 text-sm">
                        {isFirst && <ParticipantsIcon className="w-4 h-4" />}
                        {milestone.minParticipantCount || 'Initial'}
                      </div>
                    </div>
                  </div>
                  {!isLast && (
                    <ProgressBar
                      className={milestones.length === 2 ? 'flex-1' : 'w-[87px] shrink-0'}
                      currentCount={isReached ? participantCount : 0}
                      totalCount={nextMilestoneThreshold}
                      showCount={isReached && participantCount < nextMilestoneThreshold}
                    />
                  )}
                </Fragment>
              );
            })}
          </div>
        </div>

        <RewardClaimSection className="mt-3 pt-4 border-t border-solid border-bgStripe" />
      </div>
    </div>
  );
};
