import cn from 'classnames';

import ElBlurredImage from '@components/elements/ElBluredImage';
import ElModal from '@components/elements/ElModal';
import { Button, GradientSpinner, ModalHeader, ExternalLink } from '@features/common';

type Props = {
  txUrl: string | null;
  tournamentTitle: string;
  isSuccess?: boolean;
  onClose: () => void;
};

export const TxProgressModal = ({ txUrl, tournamentTitle, isSuccess, onClose }: Props): JSX.Element => {
  return (
    <ElModal className={cn('relative w-screen h-screen xs:max-w-100 xs:h-fit')} onCloseModal={onClose}>
      <ModalHeader onCloseModal={onClose} />
      <div className="mx-6 mb-6">
        {isSuccess ? (
          <>
            <ElBlurredImage
              className="w-[60px] h-[60px] mb-4"
              width={60}
              height={60}
              blur="xl"
              src="/icons/success-pulse-frame.svg"
            />
            <div className="mt-4 text-primary font-medium text-base">Congratulations!</div>
            <div className="my-2 text-labelSecondary text-sm">
              Thank you for participating in {tournamentTitle}. Your prize will be sent directly to your wallet.
            </div>
            {txUrl && (
              <ExternalLink size="xs" href={txUrl} includeArrowIcon={true}>
                View transaction on Explorer
              </ExternalLink>
            )}
          </>
        ) : (
          <>
            <GradientSpinner />
            <div className="mt-4 text-primary font-medium text-base">
              Waiting for your transaction to be confirmed on the blockchain
            </div>
            <div className="my-2 text-labelSecondary text-sm">
              We will notify you as soon as it is confirmed. Please, don’t close this window.
            </div>
            {txUrl && (
              <ExternalLink size="xs" href={txUrl} includeArrowIcon={true}>
                View transaction on Explorer
              </ExternalLink>
            )}
            <Button className="w-full mt-4" variant="secondary" onClick={onClose}>
              Close
            </Button>
          </>
        )}
      </div>
    </ElModal>
  );
};
