import cn from 'classnames';
import { parse } from 'tiny-markdown-parser';

import ElBlurredImage from '@components/elements/ElBluredImage';
import ElModal from '@components/elements/ElModal';
import { ModalCloseButton } from '@features/common';
import withSafeHydration from '@features/common/hocs/withSafeHydration';
import {
  TournamentFragmentFragment as TournamentFragment,
  TournamentTeamFragmentFragment as TournamentTeamFragment,
} from '@graphql/generated';
import { ReactComponent as MagicIcon } from '@public/icons/magic.svg';

import { TeamInvitationCopyInput } from './TeamInvitationCopyInput';
import { TournamentShareActions } from './TournamentShareActions';

type Props = {
  tournament: TournamentFragment;
  team: TournamentTeamFragment | null;
  registrationCompleteMessage?: string | null;
  closeModal: () => void;
};

export const TournamentRegistrationSuccessModal = withSafeHydration(
  ({ tournament, team, registrationCompleteMessage, closeModal }: Props): JSX.Element => {
    return (
      <ElModal
        onCloseModal={closeModal}
        className="relative w-[100vw] md:w-[400px] h-[100vh] md:h-max !justify-start items-center text-labelPrimary"
        modalWrapperStyles="!p-0"
      >
        <ModalCloseButton className="absolute right-3 top-3" onClick={closeModal} />
        <div
          className={cn(
            'absolute h-[150px] w-full mt-[-75px] bg-gradient-radial from-success to-60% opacity-10',
            'pointer-events-none',
          )}
        />
        <header className="w-full px-12 pt-10 pb-4 bg-modalWindowBgSecondary">
          <div className="flex justify-center">
            <ElBlurredImage
              className="w-[60px] h-[60px] mb-4"
              width={60}
              height={60}
              blur="xl"
              src="/icons/success-pulse-frame.svg"
            />
          </div>

          <h1 className="font-medium text-lg leading-6 text-center">
            You are now registered! {team && <>Send the information below to your team members!</>}
          </h1>

          {team && <TeamInvitationCopyInput isMultiline={true} className="my-4" tournament={tournament} team={team} />}

          <p className="mt-2 text-sm leading-5 text-labelSecondary text-center">
            {registrationCompleteMessage ? (
              <div dangerouslySetInnerHTML={{ __html: parse(registrationCompleteMessage) }} />
            ) : (
              <>
                Selected challengers will receive a text and email. The list of players will be posted on our social
                media pages. Follow us!
              </>
            )}
          </p>
        </header>

        <div className="p-6 bg-bgPrimary border-t border-bgStripePrimary">
          <h2 className="font-medium text-center">
            <span className="text-labelSuccess">
              <MagicIcon className="inline-block -mt-1 w-6 h-6" /> Invite your friends
            </span>{' '}
            to join the tournament and enjoy competing together!
          </h2>

          <TournamentShareActions tournament={tournament} />
        </div>
      </ElModal>
    );
  },
);
