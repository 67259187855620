import { CurrencyToken } from '../types';

type Props = {
  token: CurrencyToken;
  value: number | string;
  iconClassName?: string;
  labelClassName?: string;
  valueClassName?: string;
};

export const Price = ({
  token: { Icon, label },
  value,
  iconClassName,
  labelClassName,
  valueClassName,
}: Props): JSX.Element => {
  return (
    <>
      {Icon ? <Icon className={iconClassName} /> : null}
      <span className={valueClassName}>{value}</span>
      <span className={labelClassName}>{label}</span>
    </>
  );
};
