import { Duration } from 'luxon';
import { useMemo } from 'react';

import { guardNftAssetMeta, isSchemaObject, JsonSchemaPropertiesValues } from '@features/common';
import { NftAssetAttribute, NftMeta } from '@gql/types';
import { NftAssetType, SmartContractName } from '@graphql/generated';
import { gameSliceSelector } from '@store/slices';
import { useStore } from '@store/zustand';

import { AdditionalFormat, AdditionalMetaKeyword } from '../types';

type Input = Pick<NftAssetType, 'tokenId' | 'properties' | 'smartContractName'>;

export const useNftMetaTraits = (item: Input): NftMeta => {
  const { currentGameSettings: settings } = useStore(gameSliceSelector);

  const metaRaw = guardNftAssetMeta(item);

  const attributes = useMemo<Array<NftAssetAttribute>>(() => {
    const smartContractSettings = settings[item.smartContractName as SmartContractName];

    const metaTraits = Object.values<JsonSchemaPropertiesValues>(
      smartContractSettings?.schemas.metaTraits?.properties ?? {},
    );

    if (metaTraits.length === 0) {
      return metaRaw.attributes;
    }

    return (
      metaTraits
        .filter(isSchemaObject)
        /** filter traits having field with associated trait_type */
        .filter(metaTrait => !!metaTrait[AdditionalMetaKeyword.OriginalFieldName])
        .map(metaTrait => {
          /** find corresponding attribute or create a default one */
          const attribute = metaRaw.attributes.find(
            attribute => metaTrait[AdditionalMetaKeyword.OriginalFieldName] === attribute.trait_type,
          ) ?? {
            trait_type: metaTrait.title ?? (metaTrait[AdditionalMetaKeyword.OriginalFieldName] as string),
            value: '--',
          };
          return [attribute, metaTrait] as const;
        })
        .map<NftAssetAttribute>(([attribute, trait]) => {
          // eslint-disable-next-line functional/no-let
          let value = attribute.value;

          if (trait.enum && trait[AdditionalMetaKeyword.EnumTitles]) {
            const index = trait.enum.findIndex(enumValue => {
              return enumValue === attribute.value;
            });
            value = trait[AdditionalMetaKeyword.EnumTitles][index] ?? '--';
          }

          if (trait.format === AdditionalFormat.DurationSeconds && value > 0) {
            const seconds = +value;
            value = Duration.fromMillis(seconds * 1_000).toFormat('hh:mm:ss');
          }

          return {
            trait_type: trait.title ?? '',
            value,
          };
        })
    );
  }, [item, metaRaw, settings]);

  return {
    ...metaRaw,
    attributes,
  };
};
