import { useApolloClient } from '@apollo/client';
import { onSnapshot, QuerySnapshot } from 'firebase/firestore';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'wagmi';

import { useToken } from '@features/auth';
import {
  firebaseCustomAuthToken,
  IFirebaseFirestoreNotification,
  INotification,
  userNotificationsQuery,
} from '@features/notification';
import { getAuthorizedWithFirebaseCustomToken, getUserIdFromFirebaseCustomToken } from '@services/firebase';

import { updateNotificationList } from '../helpers';

export const useNotificationList = () => {
  const { token } = useToken();
  const [notificationList, setNotificationList] = useState<Array<INotification>>([]);
  const playdexClient = useApolloClient();

  const firebaseCustomAuthTokenQuery = useQuery(['FirebaseCustomAuthToken', token], {
    queryFn: () => firebaseCustomAuthToken(playdexClient),
    enabled: Boolean(token),
  });

  const userId = useMemo(() => {
    if (!firebaseCustomAuthTokenQuery.data) {
      return;
    }

    return getUserIdFromFirebaseCustomToken(firebaseCustomAuthTokenQuery.data);
  }, [firebaseCustomAuthTokenQuery.data]);

  const firebaseAuthQuery = useQuery(['FirebaseAuth', firebaseCustomAuthTokenQuery.data], {
    queryFn: () => {
      if (!firebaseCustomAuthTokenQuery.data) {
        return;
      }

      return getAuthorizedWithFirebaseCustomToken(firebaseCustomAuthTokenQuery.data);
    },
    enabled: firebaseCustomAuthTokenQuery.isSuccess,
  });

  const extractSnapshotFromEvent = (userNotificationsSnap: QuerySnapshot<IFirebaseFirestoreNotification>): void => {
    const changedDocuments = userNotificationsSnap.docChanges();
    setNotificationList(prevNotificationList => updateNotificationList(prevNotificationList, changedDocuments));
  };

  useEffect(() => {
    if (!userId) {
      return;
    }

    // @todo Make a dedicated function (in service/firestore?) after dealing with errors in observer below:
    const unsubscribe = onSnapshot<IFirebaseFirestoreNotification>(userNotificationsQuery(userId), {
      next: extractSnapshotFromEvent,
      error: error => {
        // @todo Taking care of the error code perform the following flow:
        // - unsubscribe first (might be redundant)
        // - try call `firebaseAuthQuery`
        // - try call `firebaseCustomAuthTokenQuery`
        // - if success, the rest might happen automatically
        console.error('error happened:', error.message, error);
      },
    });

    // MUST CLEAN UP! DON'T DELETE!
    return unsubscribe;
  }, [userId]);

  const isLoading = firebaseAuthQuery.isLoading || firebaseCustomAuthTokenQuery.isLoading;
  const isSuccess = firebaseAuthQuery.isSuccess && firebaseCustomAuthTokenQuery.isSuccess;
  const isError = firebaseAuthQuery.isError || firebaseCustomAuthTokenQuery.isError;

  return {
    firebaseCustomAuthTokenQuery,
    firebaseAuthQuery,
    notificationList,
    isLoading,
    isSuccess,
    isError,
  };
};
