import { Button } from '@features/common';
import { HexString } from '@globalTypes/contracts/metamask';
import { TournamentFragmentFragment as TournamentFragment } from '@graphql/generated';

import { ClaimableReward } from './components';
import { RewardContainer } from './components/RewardContainer';
import { useRewardQuery } from './hooks';

type Props = {
  tournament: TournamentFragment;
  className?: string;
};

export const RewardClaimSection = ({ tournament, className }: Props): JSX.Element | null => {
  const { data, error, refetch } = useRewardQuery(tournament);

  if (error && error.message !== 'No SocialEventReward found') {
    return (
      <RewardContainer modals={null} className={className}>
        <div>Unable to load reward information</div>

        <Button className="shrink-0" variant="secondary" onClick={() => refetch()}>
          Retry
        </Button>
      </RewardContainer>
    );
  }

  if (data && data.socialEventReward.__typename === 'SocialEventRewardType') {
    const { smartContractAddress, chainId, merkleTree } = data.socialEventReward;

    return (
      <ClaimableReward
        tournament={tournament}
        contractAddress={smartContractAddress as HexString}
        chainId={chainId}
        merkleTree={merkleTree}
        className={className}
      />
    );
  }

  return null;
};
