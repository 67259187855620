import { PrismicPreview } from '@prismicio/next';
import { PrismicProvider } from '@prismicio/react';
import Link from 'next/link';

import { createClient, linkResolver, repositoryName } from '../../prismicio';

const client = createClient();

export const Prismic = ({ children }: { children: JSX.Element }): JSX.Element => {
  return (
    <PrismicProvider
      client={client}
      linkResolver={linkResolver}
      internalLinkComponent={({ href, children, ...props }) => (
        <Link href={href} {...props}>
          {children}
        </Link>
      )}
    >
      <PrismicPreview repositoryName={repositoryName}>{children}</PrismicPreview>
    </PrismicProvider>
  );
};
