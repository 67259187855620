import { useRouter } from 'next/router';

import ElButton from '@elements/ElButton';

export const ErrorFallback = (): JSX.Element => {
  const router = useRouter();

  return (
    <div className="text-white">
      <h1>Something went wrong! Please try to reload your page!</h1>
      <ElButton onClick={() => router.reload()}>Reload Page</ElButton>
    </div>
  );
};
