import { flatten, isNil, map, mapObjIndexed, pipe, prop, reject, sort, splitEvery, toLower, values } from 'ramda';

import { CellValue, ColumnsConfig, LeaderboardRankingColumnValueType, RawRowData, FormattedRowData } from '../../types';

export const formatNumberValue = (value: CellValue): string => {
  if (value == null) {
    return '';
  }

  return typeof value === 'number' ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : value.toString();
};

export const formatRankingRows = (rows: RawRowData[]): FormattedRowData[] => {
  if (!Array.isArray(rows)) {
    return [];
  }

  return rows.map((row, index) => ({
    id: String(index),
    ...mapObjIndexed(({ value }) => value, row.data),
  }));
};

export const getWalletColumnKey = (columns: ColumnsConfig): string | undefined => {
  const walletColumnEntry = Object.entries(columns ?? {}).find(([, columnConfig]) => {
    return columnConfig.display === LeaderboardRankingColumnValueType.WalletAddress;
  });
  const [walletColumnKey] = walletColumnEntry ?? [];

  return walletColumnKey;
};

export const getUserIndex = ({
  walletAddress,
  walletColumnKey,
  rows,
}: {
  walletAddress?: string | null;
  walletColumnKey?: string | null;
  rows: RawRowData[];
}): number | null => {
  if (!walletAddress || !walletColumnKey) {
    return null;
  }

  const index = rows.findIndex(
    row => row?.data?.[walletColumnKey]?.value?.toString().toLowerCase() === walletAddress?.toLowerCase(),
  );

  return index === -1 ? null : index;
};

export const getUserPoints = ({
  index,
  rows,
  columns,
}: {
  index: number | null;
  rows: RawRowData[];
  columns: ColumnsConfig;
}): null | CellValue => {
  if (index === null) {
    return null;
  }

  const currentUserRow = rows[index];

  if (!currentUserRow) {
    return null;
  }

  const pointsColumnEntry = Object.entries(columns).find(([, columnConfig]) => {
    const title = columnConfig.title.toLowerCase();

    return title.includes('points') || title.includes('score');
  });
  const [pointsColumnKey] = pointsColumnEntry ?? [];

  if (!pointsColumnKey) {
    return null;
  }

  return currentUserRow.data[pointsColumnKey]?.value;
};

const normalizedRowSearchWeakMap = new WeakMap<RawRowData, string[]>();
const normalizeSearchItems = pipe(values, map(prop('value')), flatten, reject(isNil), map(String), map(toLower));
const cacheSearchItems = (row: RawRowData): void => {
  if (!normalizedRowSearchWeakMap.has(row)) {
    normalizedRowSearchWeakMap.set(row, normalizeSearchItems(row.data));
  }
};

/**
 * Splits array computation to microtasks to unblock UI-thread
 */
const processInBatches = async <T, R>(items: T[], process: (items: T[]) => R[]): Promise<R[]> => {
  const BATCH_SIZE = 1000;
  const AWAIT_TIMEOUT = 10;

  const rowBatches = splitEvery(BATCH_SIZE, items);

  const batchResults = await Promise.all(
    rowBatches.map(async batch => {
      await new Promise(resolve => setTimeout(resolve, AWAIT_TIMEOUT));

      return process(batch);
    }),
  );

  return batchResults.flat() as R[];
};
export const filterRowsByGroup = async (rows: RawRowData[], group: string | null): Promise<RawRowData[]> => {
  if (!group) {
    return rows;
  }

  return processInBatches(rows, batch => batch.filter(row => row.group === group));
};

export const filterRowsBySearchString = async (rows: RawRowData[], searchString: string): Promise<RawRowData[]> => {
  if (!searchString.trim()) {
    return rows;
  }
  rows.forEach(cacheSearchItems);

  const lowerSearchString = toLower(searchString);

  return await processInBatches(rows, batch =>
    batch.filter(row => {
      const isMatchingSearch = normalizedRowSearchWeakMap.get(row)?.some(v => v.includes(lowerSearchString));

      return isMatchingSearch;
    }),
  );
};

export const sortKeysByOrderProperty = <T extends Record<string, { order?: number }>>(object?: T): (keyof T)[] => {
  if (!object) {
    return [];
  }

  return sort(
    (a, b) => (object[a]?.order ?? Number.MAX_SAFE_INTEGER) - (object[b]?.order ?? Number.MAX_SAFE_INTEGER),
    Object.keys(object),
  );
};
