import { TournamentFragmentFragment as TournamentFragment } from '@graphql/generated';
import { useBoolean } from '@hooks/useBoolean';

import { TournamentShareModal } from '../components';

export const useShareTournamentModal = (tournament: TournamentFragment) => {
  const [isShareModalOpen, openShareModal, closeShareModal] = useBoolean(false);

  const shareTournamentModal = isShareModalOpen ? (
    <TournamentShareModal tournament={tournament} closeModal={closeShareModal} />
  ) : null;

  return { shareTournamentModal, openShareModal };
};
