import { FetchResult, useMutation, useQuery } from '@apollo/client';
import { useCallback } from 'react';

import {
  CreateTournamentTeamDocument,
  CreateTournamentTeamMutation,
  CreateTournamentTeamMutationVariables,
  TournamentFragmentFragment as TournamentFragment,
  TournamentTeamByTournamentIdDocument,
  TournamentTeamByTournamentIdQuery,
  TournamentTeamByTournamentIdQueryVariables,
  TournamentTeamFragmentFragment as TournamentTeamFragment,
} from '@graphql/generated';

type CreateTeam = (teamProperties?: {
  participantExtraFieldValues?: Record<string, string>;
  teamExtraFieldValues?: Record<string, string>;
}) => Promise<FetchResult<CreateTournamentTeamMutation> | null>;

type UseTournamentTeam = (params: { tournament: TournamentFragment; skip?: boolean }) => {
  team: TournamentTeamFragment | null;
  isTeamLoading: boolean;
  isTeamCreationPending: boolean;
  createTeam: CreateTeam;
};

export const useTournamentTeam: UseTournamentTeam = ({ tournament, skip }) => {
  const { loading: isTeamLoading, data: existingTournamentTeam } = useQuery<
    TournamentTeamByTournamentIdQuery,
    TournamentTeamByTournamentIdQueryVariables
  >(TournamentTeamByTournamentIdDocument, {
    variables: { tournamentId: tournament.id },
    skip,
  });

  const [createTeamMutation, { loading: isTeamCreationPending, data: createdTeamData }] = useMutation<
    CreateTournamentTeamMutation,
    CreateTournamentTeamMutationVariables
  >(CreateTournamentTeamDocument);

  const createTeam: CreateTeam = useCallback(
    teamProperies => {
      return createTeamMutation({
        variables: {
          createTournamentTeamInput: {
            tournamentId: tournament.id,
            ...(teamProperies ?? {}),
          },
        },
      });
    },
    [createTeamMutation, tournament.id],
  );

  const createdTeam =
    createdTeamData?.createTournamentTeam?.__typename == 'TournamentTeamType'
      ? createdTeamData?.createTournamentTeam
      : null;

  const team = createdTeam ?? existingTournamentTeam?.tournamentTeamByTournamentId ?? null;

  return {
    team,
    isTeamLoading,
    isTeamCreationPending,
    createTeam,
  };
};
