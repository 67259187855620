import {
  BaseTournamentEventPayload,
  logTournamentOpenedAmplitudeEvent,
  logTournamentRegisterFailedAmplitudeEvent,
  logTournamentRegisterStartedAmplitudeEvent,
  logTournamentRegisterSuccessAmplitudeEvent,
  logTournamentTabOpenedAmplitudeEvent,
  pushTournamentOpenedCrispEvent,
  pushTournamentRegisterFailedCrispEvent,
  pushTournamentRegisterStartedCrispEvent,
  pushTournamentRegisterSuccessCrispEvent,
  pushTournamentTabOpenedCrispEvent,
  TournamentEventWithSourcePayload,
} from '@features/tournaments';

export const tournamentTabOpenedAnalyticsEventHandler = (data: TournamentEventWithSourcePayload) => {
  logTournamentTabOpenedAmplitudeEvent(data);
  pushTournamentTabOpenedCrispEvent(data);
};

export const tournamentOpenedAnalyticsEventHandler = (data: TournamentEventWithSourcePayload) => {
  logTournamentOpenedAmplitudeEvent(data);
  pushTournamentOpenedCrispEvent(data);
};

export const tournamentRegisterStartedAnalyticsEventHandler = (data: BaseTournamentEventPayload) => {
  logTournamentRegisterStartedAmplitudeEvent(data);
  pushTournamentRegisterStartedCrispEvent(data);
};

export const tournamentRegisterSuccessAnalyticsEventHandler = (data: BaseTournamentEventPayload) => {
  logTournamentRegisterSuccessAmplitudeEvent(data);
  pushTournamentRegisterSuccessCrispEvent(data);
};

export const tournamentRegisterFailedAnalyticsEventHandler = (data: BaseTournamentEventPayload) => {
  logTournamentRegisterFailedAmplitudeEvent(data);
  pushTournamentRegisterFailedCrispEvent(data);
};
