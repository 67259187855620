import cn from 'classnames';
import React, { useMemo } from 'react';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import { IoMdClose } from 'react-icons/io';

import { JsonSchema, JsonSchemaPropertiesValues } from '@features/common';
import { NftAssetsFilterInput } from '@graphql/generated';

import { FieldIcon } from './FieldIcon';
import { FilterButton } from './FilterButton';
import { FilterField } from './FilterField';
import { useNftFilterContext } from './NftFilterContext';

type Field = [string, JsonSchemaPropertiesValues, JsonSchema];

type Props = {
  className?: string;
  onClose: () => void;
  onFilter: (input: NftAssetsFilterInput) => void;
};

export const NftFilter = ({ className, onClose, onFilter }: Props): JSX.Element => {
  const {
    settings: {
      schemas: {
        nftFilter = { properties: {} },
        nftFilterExtra: { attributes = { properties: {} }, offerTerms = { properties: {} } },
      },
    },
  } = useNftFilterContext();

  const { reset, handleSubmit } = useFormContext<NftAssetsFilterInput>();

  const submitHandler: SubmitHandler<NftAssetsFilterInput> = data => {
    onFilter(data);
  };

  const fieldList = useMemo(() => {
    const fields: Field[] = Object.entries<JsonSchemaPropertiesValues>(
      nftFilter.properties as Record<string, JsonSchemaPropertiesValues>,
    ).map<Field>(field => [...field, nftFilter]);

    const attributeEntries: Field[] = Object.entries<JsonSchemaPropertiesValues>(
      attributes.properties as Record<string, JsonSchemaPropertiesValues>,
    ).map<Field>(([name, schema]) => [`attributes.${name}`, schema, attributes]);

    const offerTermsEntries: Field[] = Object.entries<JsonSchemaPropertiesValues>(
      offerTerms.properties as Record<string, JsonSchemaPropertiesValues>,
    ).map<Field>(([name, schema]) => [`offersTerms.${name}`, schema, offerTerms]);

    return fields.concat(attributeEntries).concat(offerTermsEntries);
  }, [nftFilter, attributes, offerTerms]);

  return (
    <form
      className={cn(
        'border border-solid border-earlGrey-150 rounded-2.5',
        'bg-earlGrey-700 overflow-hidden',
        'divide-y divide-earlGrey-150',
        className,
      )}
      onSubmit={handleSubmit(submitHandler)}
    >
      <div className="flex p-5 w-full place-items-center bg-earlGrey-500 font-bold">
        Filter
        <FieldIcon
          as="button"
          className="ml-auto"
          /**
           * We have explicitly set a type to prevent onclick triggering
           * @link https://github.com/facebook/react/issues/3907
           */
          type="button"
          onClick={onClose}
        >
          <IoMdClose />
        </FieldIcon>
      </div>
      {fieldList.map(([name, schema, rootSchema]) => (
        <FilterField key={name} name={name} schema={schema} rootSchema={rootSchema} />
      ))}
      <div className="p-5 bg-earlGrey-500">
        <FilterButton className="w-full" onClick={() => reset()}>
          Clear filter
        </FilterButton>
      </div>
    </form>
  );
};
