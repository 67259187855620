import cn from 'classnames';
import React from 'react';

import ElBluredImage from '@elements/ElBluredImage';
import ElModal from '@elements/ElModal';
import { Button, GradientSpinner, ModalCloseButton } from '@features/common';
import { PlaydexLogoImage } from '@features/header';
import { useDiscord } from '@features/social-connections';
import useAsync from '@hooks/useAsync';
import { ReactComponent as GamepadIcon } from '@public/icons/darkGrey-game-controller.svg';
import { ReactComponent as MarketplaceIcon } from '@public/icons/darkGrey-marketplace.svg';
import { ReactComponent as ShieldIcon } from '@public/icons/darkGrey-shield.svg';

type ConnectDiscordModalProps = {
  closeModal: () => void;
};

const advantagesOfHavingDiscord = [
  {
    label: 'Access for games waitlist',
    Icon: GamepadIcon,
  },
  {
    label: 'Rent assets',
    Icon: MarketplaceIcon,
  },
  {
    label: 'Register for tournaments',
    Icon: ShieldIcon,
  },
];

export const ConnectDiscordModal = ({ closeModal }: ConnectDiscordModalProps): JSX.Element => {
  const { redirectOnDiscord } = useDiscord();

  const [redirectOnDiscordState, redirectOnDiscordHandler] = useAsync(redirectOnDiscord);

  const isModalLoading = redirectOnDiscordState.isPending();

  return (
    <ElModal
      titleSectionStyles="hidden"
      className={cn(
        'relative !justify-start !bg-modalWindowBgSecondary text-labelPrimary',
        'w-full max-w-full md:max-w-[400px] min-h-screen md:min-h-40 md:rounded-lg',
      )}
      onCloseModal={closeModal}
    >
      <header className="flex justify-between md:justify-end items-center mt-6 mb-10 md:m-0 px-3 md:px-0">
        <div className="block md:hidden">
          <PlaydexLogoImage />
        </div>
        <ModalCloseButton className="m-3" onClick={closeModal} />
      </header>
      <main className="px-4 md:px-6">
        <ElBluredImage src="/icons/discord_pulse.svg" imgContainerClassName="w-[60px] h-[60px] mb-4" blur="lg" />
        <section className="flex flex-col mb-4">
          <span className="mb-2 text-lg md:text-base text-labelPrimary font-medium">
            It looks like you&apos;re here for the first time. Please, connect your Discord account
          </span>
          <span className=" text-base md:text-sm text-labelSecondary">
            It only needs to be connected one time. Then it will link to your wallet number and allow you to:
          </span>
        </section>
        <section className="flex flex-col gap-2">
          {advantagesOfHavingDiscord.map(({ label, Icon }) => (
            <div className="flex gap-2" key={label}>
              <Icon className="w-6 h-6 text-labelPrimary text-sm" />
              <span className="text-base md:text-sm">{label}</span>
            </div>
          ))}
        </section>
      </main>
      <footer className="flex-1 flex flex-col justify-end px-4 gap-2 mt-4 mb-6 md:mb-3 md:px-6">
        <Button
          className="py-2 md:py-1.5 text-base md:text-sm text-labelPrimary !leading-6"
          onClick={redirectOnDiscordHandler}
        >
          Link Discord
        </Button>
        <Button className="!bg-transparent !py-1.5 !md:py-2" onClick={closeModal}>
          <span
            className={cn(
              'w-full h-full text-base md:text-sm !leading-6 text-labelSecondary hover:text-labelPrimary ',
              'transition-colors duration-100',
            )}
          >
            I’ll do this later
          </span>
        </Button>
      </footer>
      {isModalLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-black/70">
          <GradientSpinner />
        </div>
      )}
    </ElModal>
  );
};
