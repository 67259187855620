import { useToken } from '@features/auth';
import { GradientSpinner } from '@features/common';
import { LeaderboardQueryResult } from '@graphql/generated';

import { LeaderboardTable } from './components';
import { useLeaderboardRewardClaimersQuery } from '../../hooks';
import { isLeaderboardExceptionType } from '../../utils';

type Props = {
  leaderboardQueryResult: LeaderboardQueryResult;
};

export const LeaderboardTab = ({ leaderboardQueryResult }: Props): JSX.Element | null => {
  const { user, isUserLoading } = useToken();
  const { data, loading, error } = leaderboardQueryResult;
  const { data: rewardClaimers, loading: areRewardClaimersLoading } = useLeaderboardRewardClaimersQuery(
    data?.leaderboard.__typename === 'LeaderboardType' ? data.leaderboard.id : undefined,
  );

  if (loading || isUserLoading || areRewardClaimersLoading) {
    return (
      <div className="flex items-center justify-center p-8">
        <GradientSpinner />
      </div>
    );
  }

  if (error || isLeaderboardExceptionType(data?.leaderboard)) {
    return (
      <p className="p-4 bg-bgSecondary text-sm text-center text-labelSecondary">
        Sorry, there seems to be a problem loading the leaderboard data. We&apos;re working on fixing it as soon as
        possible. <br />
        <br />
        In the meantime, please try again later.
      </p>
    );
  }

  if (!data) {
    return null;
  }

  return (
    <LeaderboardTable
      leaderboard={data.leaderboard}
      walletAddress={user?.walletAddress}
      rewardClaimers={rewardClaimers}
    />
  );
};
